import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  JobList : [],
  MonthList : [],
  DepartmentList : [],
  StationList : [],
  CorridorList : [],
  Model : {
    StationID: [],
    Year : new Date().getFullYear(),
    DepartmentID: 0,
    JobAssigned: false,
    AssignedEmployee: 0,
    IsJobAssigner: false,
    FileBlob: null,
    FileName: "",
    CalSrtDate: "",
    MonthID : new Date().getMonth(),
    DescriptionRefreshID : "",
    URL : "",
    Status : "",
  }
};


export const PeriodicMaintenanceSlice = createSlice({
  name: "PeriodicMaintenance",
  initialState,
  reducers: {
    setInitialState: (state,action) => {
      
      Object.assign(state,action.payload)
    },
    setModelState : (state,action) => {
      Object.assign(state.Model,action.payload)
    }
  },

});


export const PeriodicStateSelector = (state) => state.PeriodicMaintenance  

export const { setInitialState,setModelState, } = PeriodicMaintenanceSlice.actions;





export default PeriodicMaintenanceSlice.reducer;
