import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useReducer,
} from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-big-calendar";
import $ from "jquery";
import { HtmlTable } from "../../../components/HtmlTable";
import {
  Decrypt,
  GetCurrentWeek,
  GetPeriodicStatus,
  GetSelect,
  GetToDateAndWeek,
  HalfYearlyMOnthForPM,
  QuarterMOnthForPM,
  YearlyMOnthForPM,
  findKeyByValue,
  getL0RoleID,
  getPrivilege,
  getUserDetails,
} from "../../../services/CommonService";
import {
  PeriodicMaintenanceGetEquipment,
  PeriodicMaintenance_AssignEmployee,
  PeriodicMaintenance_GetEmployeeWithEquipmentDetails,
  PeriodicMaintenance_GetEquipmentDetailForJobSave,
} from "../PeriodicService";
import { AppContext } from "./../../../services/ContextProvider";
import {
  COOKIES,
  MaintenanceDuration,
  MaintenanceTypes,
} from "../../../services/Global";
import { DownloadModel } from "../../BreakDownMaintenance/DownloadModel";
import BootrapModel from "../../../components/BootrapModel";
import { useNavigate } from "react-router";

import { getYMDdate, GetLoginUserID } from "../../../services/CommonService";

import { Link } from "react-router-dom";
import DescriptionList from "../DescriptionList";
import BreakdownMaintenanceService from "../../BreakDownMaintenance/BreakdownMaintenanceService";
import { ContextValues } from "../PeriodicContextProvider";
import {
  Dropdown,
  showSaveError,
  showSaveSuccess,
  ReactFileViewer,
  ReactMultiSelect,
} from "../../../components";
import { PeriodicJobAssign } from "../PeriodicJobAssign";
import { v4 as uuid } from "uuid";

import moment from "moment";
import { WithSelectDropDown } from "../../../components/WithSelectDropDown";
import ErrorBoundary from "../../../components/ErrorBoundary";
import { getDate } from "date-fns";

const _breakdown = new BreakdownMaintenanceService();

export const DailyCustomView = (
  {
    date,
    localizer,
    max = localizer.endOf(new Date(), "day"),
    min = localizer.startOf(new Date(), "day"),
    scrollToTime = localizer.startOf(new Date(), "day"),
    ...props
  },
  cusDate
) => {
  const [Flags, setFlag] = useState(7);

  const navigate = useNavigate();

  const [rowList, setRowList] = useState(null);

  const {
    getCookies,
    showLoading,
    hideLoading,
    handleGlobalStationName,
    GetLoginStationTypes,
    GetLoginStationBaseOnDep,
    setRowData,
  } = useContext(AppContext);

  const {
    PeriodicModel,
    setPeriodicModel,
    setDropdownModel,
    DropdownModel,
    MonthConfigObj,
  } = ContextValues();

  const [title, setTitle] = useState("");

  const [DownLoadRefresh, setDownLoadRefresh] = useReducer((x) => x + 1, 0);
  const [FileDatas, setFileDatas] = useState([]);

  const columnNames = [
    {
      title: "<input type='checkbox' id='selectall' class='form-check-input'/>",
      data: "JobAssigned",
      className: `${PeriodicModel?.IsJobAssigner ? "" : "hide"}`,
      createdCell: (td, cellData, rowData, row, col) => {
        let IsDisabled = cellData ? "" : "disabled";

        let checkbox = "";
        if (!cellData) {
          checkbox = $(
            `<input type='checkbox'   class='checks SelectedEquip form-check-input ${IsDisabled}' />`
          );
          checkbox.on("change", function (e) {
            rowData.IsAssigned = e.target.checked;
            checkAnyOneSelected();
          });
        }
        $(td).html(checkbox);
      },
    },
    {
      title: "Equipment Name",
      data: "EquipmentShortName",
      createdCell: (td, cellData, rowData, row, col) => {
        $(td).html(
          `<span title='${rowData.SystemTypeName}'>${cellData}</span>`
        );
      },
    },

    {
      title: "Assigned To",
      data: "JobDoneBy",
    },
    {
      title: "Approved By",
      data: "ApprovedBy",
    },
    {
      title: "Rejected By",
      data: "RejectedBy",
    },
    {
      title: "Status",
      data: "StatusName",
      createdCell: (td, cellData, rowData, row, col) => {
        var b = GetPeriodicStatus(`${rowData.Status}|${cellData}`);

        if (rowData.RejectedBy || rowData.Status == 4) {
          $(td).parent().addClass("RejectedTableBack");
        } else if (rowData.ApprovedBy) {
          $(td).parent().css("background", "#87f093");
        } else if (rowData.JobDoneBy) {
          $(td).parent().css("background", "#ffff72");
        } else {
          $(td).parent().css("background", "#93a7fd");
        }

        $(td).html(b);
      },
    },

    {
      title: "Submit",
      data: "EquipmentID",
      visible: getPrivilege().includes("PERIODICMAINTENANCEEDIT"),
      createdCell: (td, cellData, rowData, row, col) => {
        let { JobAssigned, JobAssigner, RejectedBy, ApprovedBy, Status } =
          rowData;

        let EnableEdit =
          rowData?.PeriodicCheckListMaintenanceID > 0 || JobAssigner;

        const { DownloadFlag } = rowData;

        let editBtn = $(`<button class='btn icon-btn' ${
          EnableEdit ? "" : "disabled"
        } title='Edit'>
        <i class='fa fa-pencil m-r-5'></i>
      </button>`);

        if (EnableEdit) {
          editBtn.on("click", function () {
            EditPeriodicSchedule(rowData);
          });
        }

        var JobDescription =
          $(`<button class='btn icon-btn text-center' data-bs-toggle="modal" data-bs-target="#Description" title='Job Description'  >
                  <i class=" fa fa-solid fa-calendar-check-o m-r-5"></i>
                  </button>`).on("click", function () {
            setPeriodicModel((pre) => ({
              ...pre,
              EquipmentID: rowData.EquipmentID,
              DescriptionRefreshID: uuid(),
            }));
          });

        let JobAsinEnable =
          (JobAssigner || getL0RoleID() == getUserDetails().RoleID) &&
          !["Approved", "Closed"].includes(rowData.StatusName);

  

        let jobAssign = $(`<button class='btn icon-btn text-center' ${
          JobAsinEnable ? "" : "disabled"
        }
       data-bs-toggle="modal" data-bs-target="#PeriodicJobAssign" title='Job Assign' >
        <i class=" fa fa-solid fa-opencart m-r-5"></i>
        </button>`);

        if (JobAsinEnable) {
          jobAssign.on("click", function () {
            setPeriodicModel((pre) => ({
              ...pre,
              EquipmentID: rowData.EquipmentID,
              RefreshID: uuid(),              
            }));
          });
        }

        var download = "";
        if (DownloadFlag) {
          download =
            $(`<button class='btn icon-btn text-center' data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'  >
            <i class='fa fa-download m-r-5'></i>
                  </button>`).on("click", function () {
              // SetIsView(true)
              DownloadFiles(rowData.PeriodicCheckListMaintenanceID, 0, 7);
            });
        } else {
          download =
            $(`<button class='btn icon-btn text-center' disabled title='Download'  >
            <i class='fa fa-download m-r-5'></i>
                  </button>`);
        }

        $(td).html(JobDescription);
        $(td).append(jobAssign);
        $(td).append(editBtn);
        $(td).append(download);
        // $(td).append(e);
      },
    },
  ];

  useEffect(() => {
    getStationList();
  }, []);

  useEffect(() => {
    setTitle(GetTitle());
    getJobs();
    getEmployeeList();
  }, [
    PeriodicModel?.FromDate,
    PeriodicModel?.StationID?.value,
    PeriodicModel?.DepartmentID,
  ]);

  useEffect(() => {
    checkAnyOneSelected();
  }, [PeriodicModel?.AssignedEmployee]);

  useEffect(() => {
    if (1 != localStorage.getItem("NNR")) {
      onNavigate(Navigate.TODAY);
    } else {
      localStorage.removeItem("NNR");
    }
  }, [JSON.stringify(MonthConfigObj)]);

  const getStationList = () => {
    var Stations = [];
    if (!DropdownModel?.StationList.length) {
      var stationList = GetLoginStationBaseOnDep();
      if (stationList?.length) {
        stationList.map((data) => {
          Stations.push({
            key: data.label,
            ...data,
          });
        });
      }
    }

    if (!DropdownModel?.DepartmentList?.length) {
      let stationTypes = GetLoginStationTypes();
      let DepartmentList = Decrypt(getCookies(COOKIES.Departments));

      let Department = [];
      if (DepartmentList?.length) {
        const itemsWithoutChildren = DepartmentList.filter(
          (item) => !DepartmentList.some((child) => child.parentId === item.id)
        );

        itemsWithoutChildren.map((data) => {
          Department.push({ value: data.id, key: data.name, Type: data.Type });
        });
        let FilStations = Stations.filter(
          (ele) =>
            ele.CorridorID == stationTypes[0].value &&
            ele.DepartmentID == Department[0].value
        );

        setDropdownModel((pre) => ({
          ...pre,
          FilterStationList: Stations,
          StationList: FilStations,
          StationTypes: stationTypes,
          DepartmentList: Department,
          FilterDepartmentList: Department,
        }));

        setPeriodicModel((pre) => ({
          ...pre,
          DepartmentID: Department?.[0]?.value ?? 0,
          StationTypeID: stationTypes?.[0]?.value ?? 0,
          StationID: FilStations?.[0] ?? {},
        }));
      }
    }
  };

  const EditPeriodicSchedule = async (data) => {
    debugger;
    const FromDate = PeriodicModel?.FromDate;

    const { PeriodicCheckListMaintenanceID, DepartmentID, EquipmentID } = data;
    let EmployeeID = GetLoginUserID();

    await PeriodicMaintenance_GetEquipmentDetailForJobSave(
      PeriodicCheckListMaintenanceID,
      EmployeeID
    )
      .then((res) => {
        if (res?.data?.data?.length) {
          debugger;
          const { CurrentWeek, CurrentView, StationID } = PeriodicModel;
          const equipmentShortName = data?.EquipmentShortName?.toUpperCase()?.replaceAll(" ", "");
          if (
            data?.EquipmentShortName?.toUpperCase()?.replaceAll(" ", "") ==
            "TELECOM_ANNEXURE-(TELECOM_ANNEXURE)"
          ) {
            debugger;
            const RedirectUSl =
              CurrentView == "monthly"
                ? "/PeriodicMonthly_Annexure"
                : "/TelecomeQuarterly";
            navigate(RedirectUSl, {
              state: {
                PeriodicCheckListMaintenanceID,
                DepartmentID,
                FromDate,
                EndDate: FromDate,
                EquipmentDetails: res.data.data,
                EquipmentID,
                CurrentWeek,
                CurrentView,
                StationID: StationID?.value ?? 0,
              },
            });
          } 

          else if(equipmentShortName=="ICF-1-OCEFR-V-(ICF-1O/CEFRELAY)"){
            navigate("/OverCurrent_EarthFaultRelay_110_33KV", {
              state: {
                PeriodicCheckListMaintenanceID,
                DepartmentID,
                FromDate,
                EndDate: FromDate,
                EquipmentDetails: res.data.data,
                EquipmentID,
                CurrentWeek,
                CurrentView,
                StationID: StationID?.value ?? 0,
              },
            });
          }
          else {
            navigate("/periodic/Depart-periodics", {
              state: {
                PeriodicCheckListMaintenanceID,
                DepartmentID,
                FromDate,
                EndDate: FromDate,
                EquipmentDetails: res.data.data,
                EquipmentID,
                CurrentWeek,
                CurrentView,
                StationID: StationID?.value ?? 0,
              },
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DownloadFiles = (ticketID, AttachmentID, Flag) => {
    _breakdown
      .GetFilesUsingTicketID(ticketID, AttachmentID, Flag)
      .then((res) => {
        if (res?.data) {
          setFileDatas(res.data);
        } else {
          setFileDatas([]);
        }
        setDownLoadRefresh();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobs = () => {
    let EmployeeID = GetLoginUserID();

    const {
      StationID,
      FromDate,
      CurrentView,
      CurrentWeek,
      DepartmentID,
      IsAPICallNeed,
    } = PeriodicModel;
    let { ScheduleType, ToDate, week } = GetToDateAndWeek(
      CurrentView,
      CurrentWeek,
      FromDate
    );
    try {
      if (
        StationID?.value &&
        FromDate &&
        ScheduleType &&
        DepartmentID
        // &&
        //IsAPICallNeed
      ) {
        showLoading();
        PeriodicMaintenanceGetEquipment(
          StationID?.value,
          EmployeeID,
          FromDate,
          ToDate,
          DepartmentID,
          ScheduleType,
          week
        )
          .then((res) => {
            hideLoading();
            if (res?.data?.data) {
              setRowList([...res.data.data]);
              setPeriodicModel((pre) => ({
                ...pre,
                IsJobAssigner: res.data.data[0]?.JobAssigner == 1,
              }));
            } else {
              setRowList([]);
              setPeriodicModel((pre) => ({
                ...pre,
                IsJobAssigner: false,
              }));
            }
          })
          .catch((err) => {
            hideLoading();
            console.log(err);
          });
      }
    } catch (err) {
      hideLoading();
      console.log(err);
    }
  };

  const Toolbar = () => {
    return (
      <>
        <div className="row d-flex justify-content-lg-between">
          <div className="col  rbc-toolbar d-flex justify-content-start">
            <span class="rbc-btn-group">
              <button type="button" onClick={() => onNavigate("PREV")}>
                Back
              </button>
              <button type="button" onClick={() => onNavigate("TODAY")}>
                Today
              </button>

              <button type="button" onClick={() => onNavigate("NEXT")}>
                Next
              </button>
            </span>
          </div>

          <div className="col">
            <Dropdown
              data={DropdownModel?.DepartmentList ?? []}
              value={PeriodicModel.DepartmentID}
              placeholder={"Department"}
              name={"DepartmentID"}
              HandleChange={HandleChange}
            >
              {" "}
            </Dropdown>
          </div>
          <div className="col-2">
            <Dropdown
              data={DropdownModel?.StationTypes ?? []}
              value={PeriodicModel.StationTypeID}
              name={"StationTypeID"}
              placeholder={"Station Types"}
              HandleChange={HandleChange}
            >
              {" "}
            </Dropdown>
          </div>
          <div className="col">
            <ReactMultiSelect
              options={DropdownModel.StationList}
              value={PeriodicModel.StationID}
              placeholder={"Stations"}
              name={"StationID"}
              onChange={(e) => HandleChange(e, "StationID")}
            >
              {" "}
            </ReactMultiSelect>
          </div>

          {PeriodicModel?.IsJobAssigner && (
            <div className="col text-end" style={{ paddingLeft: "10px" }}>
              {" "}
              <button
                type="button"
                disabled={!PeriodicModel?.IsAssigned}
                className="btn add-btn  "
                style={{
                  backgroundColor: "#b3b3b3",
                  borderColor: "#b3b3b3",
                  borderRadius: 5,
                }}
                onClick={SavePeriodicAssign}
              >
                Assign
              </button>
            </div>
          )}

          {PeriodicModel?.IsJobAssigner && (
            <div className="col">
              <WithSelectDropDown
                data={DropdownModel?.EmployeeList}
                value={PeriodicModel?.AssignedEmployee}
                initialVal={0}
                placeholder={"Select"}
                HandleChange={(e) => {
                  setPeriodicModel((pre) => ({
                    ...pre,
                    AssignedEmployee: e.target.value,
                  }));
                }}
              />
            </div>
          )}

          <div className="col" style={{ paddingLeft: "10px" }}>
            <Link to="/Periodic-Department" state={{ view: "month" }}>
              {" "}
              <button
                type="button"
                className="btn add-btn"
                style={{
                  backgroundColor: "#b3b3b3",
                  borderColor: "#b3b3b3",
                  borderRadius: 5,
                }}
              >
                Go Back
              </button>
            </Link>
          </div>
        </div>
      </>
    );
  };

  const GetTitle = () => {
    var cusDate = getYMDdate();
    if (PeriodicModel?.FromDate) {
      cusDate = PeriodicModel.FromDate;
    }

    if (localStorage.getItem("dailyDate") == null) {
      localStorage.setItem("dailyDate", getYMDdate(cusDate));
    }

    //   var stationID = PeriodicModel?.StationID ?? {};
    const { CurrentView ,FromDate, ToDate } = PeriodicModel;

    let weekName = "";
    let obj = {
      1: "Week - 1",
      8: "Week - 2",
      15: "Week - 3",
      22: "Week - 4",
    };
    const DisplayFromDate = moment(FromDate).format("DD-MM-YYYY");
    const DisplayToDate = moment(FromDate).add(6, "day").format("DD-MM-YYYY");
    const WeekID = moment(FromDate).isoWeek();

    const startOfMonth = moment(FromDate).startOf('month'); // First day of the month

// Calculate the week number within the month
    const weekOfMonth = Math.ceil(moment(FromDate).diff(startOfMonth, 'days') / 7);

    if (CurrentView == "week") {
      return `${DisplayFromDate} to ${DisplayToDate} - (Week - ${weekOfMonth})`;
    }

    if (CurrentView != "month") {
      weekName = obj[new Date(cusDate).getDate()] ?? "Week - 1";
    }

    // var stations = GetLoginStationList();
    // let StationObj =
    //   stations.find((ele) => ele.value == stationID.value) ?? stations[0];
    // let StationCode = StationObj.label ?? "";
    // StationCode = StationCode ? " - " + StationCode : StationCode;
    // setPeriodicModel((pre) => ({
    //   ...pre,
    //   StationID: StationObj ?? {},
    // }));

    return (
      moment(cusDate).format("DD-MMMM-yyyy") +
      ` ${weekName.length > 0 ? "- ( " + weekName + " )" : ""}`
    );
  };

  const onNavigate = (type) => {
    const { CurrentView } = PeriodicModel;
    let { DurationType, addCount } =
      MaintenanceDuration[CurrentView ?? "month"];

    let CurrentDate = getYMDdate();
    if (PeriodicModel?.FromDate) {
      CurrentDate = PeriodicModel?.FromDate;
    }
    if (CurrentView !== "month") {
      new Date(CurrentDate).getDate();
    }
    if ("monthly" == CurrentView) {
      return onNavigateForMonth(type);
    }
    if ("quaterly" == CurrentView) {
      return onNavigateForQuarter(type);
    }
    if ("halfYearly" == CurrentView) {
      return onNavigateForHalfYearly(type);
    }
    if ("yearly" == CurrentView) {
      return onNavigateForYearly(type);
    }
    if ("week" == CurrentView) {
      return onNavigateForWeekly(type);
    }

    var dLst = CurrentDate.split("-");
    var newD = new Date(dLst[0], dLst[1] - 1, dLst[2]);
    switch (type) {
      case Navigate.PREVIOUS:
        newD = moment(newD).add(-addCount, DurationType);

        break;
      case Navigate.NEXT:
        newD = moment(newD).add(addCount, DurationType);
        break;
      default:
        if (CurrentView == "month") newD = new Date();
        else {
          newD = GetCurrentWeek();
        }
        break;
    }

    setPeriodicModel((pre) => ({
      ...pre,
      FromDate: getYMDdate(newD),
      CalSrtDate: getYMDdate(newD),
    }));
    return newD;
  };

  const onNavigateForYearly = (type) => {
    var curDate = new Date();
    let YearMonth = MonthConfigObj.find(
      (ele) => ele.StatusName == "Yearly_EndMonth"
    );
    if (YearMonth) {
      const { Month } = YearMonth;
      curDate = new Date(curDate.getFullYear(), Month - 1, 1);
    }
    let Quarter_month = PeriodicModel?.FromDate;
    let Quarter_Week = PeriodicModel?.CurrentWeek;
    if (!Quarter_month) {
      setPeriodicModel((pre) => ({
        ...pre,
        FromDate: curDate,
      }));
      Quarter_month = curDate;
    }
    if (!Quarter_Week) {
      setPeriodicModel((pre) => ({
        ...pre,
        CurrentWeek: 1,
      }));
      Quarter_Week = 1;
    }
    var d = Quarter_month;
    var e = Quarter_Week;

    var newD = new Date(d);
    switch (type) {
      case Navigate.PREVIOUS:
        if (e == 1) {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: 4,
          }));
          let year = newD.getFullYear();
          let datePrev = new Date(year - 1, newD.getMonth(), 22);
          newD = datePrev;
        } else {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: e - 1,
          }));
          newD.setDate(newD.getDate() - 7);
        }
        setTitle(GetTitle());
        break;

      case Navigate.NEXT:
        if (e == 4) {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: 1,
          }));
          let dateNext = new Date(newD.getFullYear() + 1, newD.getMonth(), 1);
          newD = dateNext;
        } else {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: e + 1,
          }));
          newD.setDate(newD.getDate() + 7);
        }
        break;

      case Navigate.TODAY:
        // setPeriodicModel((pre) => ({
        //   ...pre,
        //   CurrentWeek: 1,
        // }));
        // setTitle(GetTitle());
        // newD = new Date(new Date().getFullYear(), newD.getMonth(), 1);

        let WeeKObj = {
          1: 1,
          8: 2,
          15: 3,
          22: 4,
        };
        if (MonthConfigObj) {
          const { CurrentWeek } = PeriodicModel;
          let StartDate = findKeyByValue(WeeKObj, CurrentWeek) || 1;
          newD = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            StartDate
          );
          newD = YearlyMOnthForPM(newD, MonthConfigObj);
          let tempCurrentWeek = WeeKObj[newD.getDate()] || 1;
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: tempCurrentWeek,
          }));
          setTitle(GetTitle());
        }

        break;

      default:
        var dates = new Date(newD.getFullYear(), newD.getMonth(), 1);
        localStorage.setItem("quaterMonth", getYMDdate(dates));
        newD = dates;
        break;
    }
    setPeriodicModel((pre) => ({
      ...pre,
      FromDate: getYMDdate(newD),
    }));
    return getYMDdate(newD);
  };

  const onNavigateForQuarter = (type) => {
    debugger;
    let { Date: curDate } = QuarterMOnthForPM(new Date(), MonthConfigObj);
    let Quarter_month = PeriodicModel?.FromDate;
    let Quarter_Week = PeriodicModel?.CurrentWeek;
    if (!Quarter_month) {
      setPeriodicModel((pre) => ({
        ...pre,
        FromDate: curDate,
      }));
      Quarter_month = curDate;
    }
    if (!Quarter_Week) {
      setPeriodicModel((pre) => ({
        ...pre,
        CurrentWeek: 1,
      }));
      Quarter_Week = 1;
    }
    var d = Quarter_month;
    var e = Quarter_Week;

    var dLst = d.split("-");
    var newD = new Date(d);
    switch (type) {
      case Navigate.PREVIOUS:
        if (e == 1) {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: 4,
          }));
          let year = newD.getFullYear();
          if (newD.getMonth() - 2 == 11) {
            year = year - 1;
          }
          let datePrev = new Date(year, newD.getMonth() - 3, 22);
          let { Date: TempDatePrev } = QuarterMOnthForPM(
            datePrev,
            MonthConfigObj
          );
          newD = TempDatePrev;
        } else {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: e - 1,
          }));
          newD.setDate(newD.getDate() - 7);
        }
        setTitle(GetTitle());
        break;

      case Navigate.NEXT:
        if (e == 4) {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: 1,
          }));
          debugger;
          let dateNext = new Date(newD.getFullYear(), newD.getMonth() + 3, 1);
          let { Date: TempDatePrev } = QuarterMOnthForPM(
            dateNext,
            MonthConfigObj
          );
          newD = TempDatePrev;
        } else {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: e + 1,
          }));
          newD.setDate(newD.getDate() + 7);
        }
        break;

      case Navigate.TODAY:
        let WeeKObj = {
          1: 1,
          8: 2,
          15: 3,
          22: 4,
        };

        if (MonthConfigObj) {
          const { CurrentWeek } = PeriodicModel;
          let StartDate = findKeyByValue(WeeKObj, CurrentWeek) || 1;
          newD = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            StartDate
          );
          let { Date: TempDatePrev } = QuarterMOnthForPM(newD, MonthConfigObj);
          newD = TempDatePrev;
          let tempCurrentWeek = WeeKObj[newD.getDate()] || 1;
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: tempCurrentWeek,
          }));
          setTitle(GetTitle());
        }
        break;
      default:
        var dates = new Date(newD.getFullYear(), newD.getMonth(), 1);
        localStorage.setItem("quaterMonth", getYMDdate(dates));
        newD = dates;
        break;
    }
    setPeriodicModel((pre) => ({
      ...pre,
      FromDate: getYMDdate(newD),
      CalSrtDate: getYMDdate(newD),
    }));
    return getYMDdate(newD);
  };

  const onNavigateForHalfYearly = (type) => {
    let { Date: curDate } = HalfYearlyMOnthForPM(new Date(), MonthConfigObj);
    let Quarter_month = PeriodicModel?.FromDate;
    let Quarter_Week = PeriodicModel?.CurrentWeek;
    if (!Quarter_month) {
      setPeriodicModel((pre) => ({
        ...pre,
        FromDate: curDate,
        CalSrtDate: getYMDdate(newD),
      }));
      Quarter_month = curDate;
    }
    if (!Quarter_Week) {
      setPeriodicModel((pre) => ({
        ...pre,
        CurrentWeek: 1,
      }));
      Quarter_Week = 1;
    }
    var d = Quarter_month;
    var e = Quarter_Week;
    var newD = new Date(d);
    switch (type) {
      case Navigate.PREVIOUS:
        if (e == 1) {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: 4,
          }));
          newD.setMonth(newD.getMonth() - 6);
          let datePrev = new Date(newD.getFullYear(), newD.getMonth() + 1, 22);
          let { Date: TempCurDate } = HalfYearlyMOnthForPM(
            datePrev,
            MonthConfigObj
          );
          datePrev = TempCurDate;
          newD = datePrev;
        } else {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: e - 1,
          }));
          newD.setDate(newD.getDate() - 7);
        }
        setTitle(GetTitle());
        break;

      case Navigate.NEXT:
        if (e == 4) {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: 1,
          }));
          newD.setMonth(newD.getMonth() + 6);

          let dateNext = new Date(newD.getFullYear(), newD.getMonth() + 1, 1);
          let { Date: TempCurDate } = HalfYearlyMOnthForPM(
            dateNext,
            MonthConfigObj
          );
          dateNext = TempCurDate;
          newD = dateNext;
        } else {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: e + 1,
          }));
          newD.setDate(newD.getDate() + 7);
        }
        break;

      case Navigate.TODAY:
        let WeeKObj = {
          1: 1,
          8: 2,
          15: 3,
          22: 4,
        };

        if (MonthConfigObj) {
          const { CurrentWeek } = PeriodicModel;
          let StartDate = findKeyByValue(WeeKObj, CurrentWeek) || 1;
          newD = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            StartDate
          );
          let { Date: TempCurDate } = HalfYearlyMOnthForPM(
            newD,
            MonthConfigObj
          );
          newD = TempCurDate;
          let tempCurrentWeek = WeeKObj[newD.getDate()] || 1;
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: tempCurrentWeek,
          }));
          setTitle(GetTitle());
        }

        break;

      default:
        var dates = new Date(newD.getFullYear(), newD.getMonth(), 1);
        localStorage.setItem("quaterMonth", getYMDdate(dates));
        newD = dates;
        break;
    }
    setPeriodicModel((pre) => ({
      ...pre,
      FromDate: getYMDdate(newD),
    }));
    return getYMDdate(newD);
  };

  const onNavigateForMonth = (type) => {
    var curDate = new Date();
    curDate.setDate(1);
    let Quarter_month = PeriodicModel?.FromDate;
    let Quarter_Week = PeriodicModel?.CurrentWeek;
    if (!Quarter_month) {
      setPeriodicModel((pre) => ({
        ...pre,
        FromDate: curDate,
      }));
      Quarter_month = curDate;
    }
    if (!Quarter_Week) {
      setPeriodicModel((pre) => ({
        ...pre,
        CurrentWeek: 1,
      }));
      Quarter_Week = 1;
    }
    var d = Quarter_month;
    var e = Quarter_Week;

    var newD = new Date(d);
    switch (type) {
      case Navigate.PREVIOUS:
        if (e == 1) {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: 4,
          }));
          let year = newD.getFullYear();
          if (newD.getMonth() - 2 == 11) {
            year = year - 1;
          }
          let datePrev = new Date(year, newD.getMonth() - 1, 22);
          newD = datePrev;
        } else {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: e - 1,
          }));
          newD.setDate(newD.getDate() - 7);
        }
        setTitle(GetTitle());
        break;

      case Navigate.NEXT:
        if (e == 4) {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: 1,
          }));
          let dateNext = new Date(newD.getFullYear(), newD.getMonth() + 1, 1);
          newD = dateNext;
        } else {
          setPeriodicModel((pre) => ({
            ...pre,
            CurrentWeek: e + 1,
          }));
          newD.setDate(newD.getDate() + 7);
        }
        break;

      case Navigate.TODAY:
        setPeriodicModel((pre) => ({
          ...pre,
          CurrentWeek: 1,
        }));
        setTitle(GetTitle());
        newD = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        break;

      default:
        break;
    }
    setPeriodicModel((pre) => ({
      ...pre,
      FromDate: getYMDdate(newD),
      CalSrtDate: getYMDdate(newD),
    }));
    return getYMDdate(newD);
  };
  const onNavigateForWeekly = (type) => {
    debugger;
    var curDate = moment().startOf("week").format("YYYY-MM-DD");
    let Quarter_month = PeriodicModel?.FromDate;
    let Quarter_Week = PeriodicModel?.CurrentWeek;
    if (!Quarter_month) {
      setPeriodicModel((pre) => ({
        ...pre,
        FromDate: curDate,
      }));
      Quarter_month = curDate;
    }
    var d = Quarter_month;

    var newD = new Date(d);
    switch (type) {
      case Navigate.PREVIOUS:
        newD.setDate(newD.getDate() - 7);
        break;

      case Navigate.NEXT:
        newD.setDate(newD.getDate() + 7);
        break;

      case Navigate.TODAY:
        newD = moment().startOf("week");
        break;

      default:
        break;
    }
    setPeriodicModel((pre) => ({
      ...pre,
      FromDate: getYMDdate(newD),
      CalSrtDate: getYMDdate(newD),
    }));
    return getYMDdate(newD);
  };
  const getCurrentSchedule = () => {
    let Obj = {
      month: "Daily",
      monthly: "Monthly",
      quaterly: "Quarterly",
      halfYearly: "Half-Yearly",
      yearly: "Yearly",
      week: "Weekly",
    };

    if (PeriodicModel.CurrentView) {
      let CurrentView = Obj[PeriodicModel.CurrentView];
      let label = $(
        `<button class="shattered-glass-button m-l-15">${CurrentView}</button>`
      );
      return label;
    }
  };

  const checkAnyOneSelected = () => {
    let tableArray = $("#periodic-daily").DataTable().rows().data().toArray();
    if (tableArray?.length) {
      let IsAssigned =
        tableArray.some((ele) => ele.IsAssigned) &&
        PeriodicModel.AssignedEmployee > 0;
      setPeriodicModel((pre) => ({
        ...pre,
        IsAssigned,
      }));
    }
  };

  const getEmployeeList = async () => {
    const { DepartmentID, StationID, FromDate, CurrentWeek, CurrentView } =
      PeriodicModel;

    let { ToDate } = GetToDateAndWeek(CurrentView, CurrentWeek, FromDate);
    if (DepartmentID && StationID?.value && FromDate && ToDate) {
      await PeriodicMaintenance_GetEmployeeWithEquipmentDetails(
        DepartmentID,
        0,
        StationID?.value,
        FromDate,
        ToDate
      )
        .then((res) => {
          if (res?.data?.data) {
            const { employeeList } = res.data.data;
            setDropdownModel((pre) => ({
              ...pre,
              EmployeeList: employeeList,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const SavePeriodicAssign = async () => {
    const {
      DepartmentID,
      EquipmentID,
      StationID,
      FromDate,
      CurrentView,
      CurrentWeek,
    } = PeriodicModel;

    let tableArray = $("#periodic-daily").DataTable().rows().data().toArray();

    let SelectedData = tableArray
      ?.filter((ele) => ele.IsAssigned)
      ?.map((ele) => {
        return {
          EmployeeID: PeriodicModel.AssignedEmployee,
          EquipmentID: ele.EquipmentID,
        };
      });

    if (SelectedData?.length) {
      let { ScheduleType, ToDate, week } = GetToDateAndWeek(
        CurrentView,
        CurrentWeek,
        FromDate
      );
      const obj = {
        DepartmentID,
        EquipmentID: 0,
        StationID: StationID?.value,
        FromDate,
        ToDate: ToDate,
        PeriodicAssignList: JSON.stringify(SelectedData),
        WeekID: week,
        ScheduleType,
        SavedBy: GetLoginUserID(),
        Type: 2,
      };
      showLoading();
      await PeriodicMaintenance_AssignEmployee(obj)
        .then((res) => {
          hideLoading();
          if (res?.data?.isSuccess) {
            showSaveSuccess();
          } else {
            showSaveError();
          }
          getJobs();
        })
        .catch((err) => {
          hideLoading();
          console.log(err);
          showSaveError();
        });

      return true;
    }
  };

  const SearchPre = () => {
    return `<span class='CalenderDateTitle'>${title}</span>`;
  };

  const AddPlugin = () => {
    $("#selectall").on("change", function (e) {
      $(".SelectedEquip").prop("checked", e.target.checked);
      let tableArray = $("#periodic-daily").DataTable().rows().data().toArray();
      tableArray.forEach((ele) => (ele.IsAssigned = e.target.checked));
      checkAnyOneSelected();
    });
  };

  const HandleChange = (e, cusName) => {
    if (!cusName) {
      const { value, name } = e.target;
      setPeriodicModel((pre) => ({
        ...pre,
        [name]: value,
      }));
      cusName = name;
    } else {
      setPeriodicModel((pre) => ({
        ...pre,
        [cusName]: e,
      }));
    }

    if (cusName == "StationID") {
      handleGlobalStationName(e?.label ?? "");
    }

    if (["StationTypeID", "DepartmentID"].includes(cusName)) {
      const { value } = e.target;

      const StationTypeID =
        cusName == "StationTypeID" ? value : PeriodicModel.StationTypeID;
      const DepartmentID =
        cusName == "DepartmentID" ? value : PeriodicModel.DepartmentID;

      // const fineDept = DropdownModel?.DepartmentList?.find(
      //   (ele) => ele.value == DepartmentID
      // );
      const finedType = DropdownModel?.StationTypes?.find(
        (ele) => ele.value == StationTypeID
      );

      if (DepartmentID && finedType) {
        const FilStations = DropdownModel?.FilterStationList.filter(
          (ele) =>
            ele.CorridorID == finedType.value &&
            ele.DepartmentID == DepartmentID
        );

        setDropdownModel((pre) => ({
          ...pre,
          StationList: FilStations,
        }));

        setPeriodicModel((pre) => ({
          ...pre,
          StationID: FilStations?.[0] ?? {},
        }));

        handleGlobalStationName(FilStations?.[0]?.label ?? "");
      } else {
        setDropdownModel((pre) => ({
          ...pre,
          StationList: [],
        }));

        setPeriodicModel((pre) => ({
          ...pre,
          StationID: {},
        }));
        handleGlobalStationName("");
      }
    }
  };

  return (
    <div className="card ">
      <div className="card-body">
        <BootrapModel
          Id={"Description"}
          CloseID="Descriptionclose"
          ModelTitle={`Job Description`}
          modelSize={"modal-md"}
          module={<DescriptionList maintenanceTypeID={1}></DescriptionList>}
        >
          {" "}
        </BootrapModel>
        <Toolbar />

        <>
          <HtmlTable
            columnList={columnNames}
            rowList={rowList ?? []}
            id={"periodic-daily"}
            SearchPrependHtml={SearchPre}
            ShowLengthPostponedHtml={getCurrentSchedule}
            addedPlugin={AddPlugin}
          />
          <BootrapModel
            Id={"DownLoadFiles"}
            CloseID="DownLoadFilesClose"
            ModelTitle={`Periodic Maintenance - Download Files`}
            modelSize={"modal-xl"}
            module={
              <DownloadModel
                Flag={Flags}
                DeleteAttachment={DownloadFiles}
                FileDatas={FileDatas}
                DownLoadRefresh={DownLoadRefresh}
              ></DownloadModel>
            }
          >
            {" "}
          </BootrapModel>
          <BootrapModel
            Id={"PeriodicJobAssign"}
            CloseID="PeriodicJobAssignClose"
            ModelTitle={`Periodic Job Assign`}
            modelSize={"modal-xl"}
            closepopup={() => {
              getJobs();
            }}
            module={<PeriodicJobAssign></PeriodicJobAssign>}
          >
            {" "}
          </BootrapModel>

          <BootrapModel
            Id={"FileViewModel"}
            CloseID="closeFileViewModel"
            ModelTitle={`File Viewer`}
            modelSize={"modal-xl"}
            module={
              <ReactFileViewer
                fileBlob={PeriodicModel?.FileBlob}
                fileName={PeriodicModel?.FileName}
              />
            }
          >
            {" "}
          </BootrapModel>
        </>
      </div>
    </div>
  );
};

DailyCustomView.propTypes = {
  date: localStorage.getItem("dailyDate"),
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

DailyCustomView.range = (date, { localizer }) => {
  const start = date;
  const end = localizer.add(start, 2, "day");

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

DailyCustomView.title = (date, { localizer }) => {
  return "";
};
