import moment from "moment";
import React, { useMemo, useContext, useEffect, useState, useRef } from "react";
import { Views, momentLocalizer, Calendar } from "react-big-calendar";
import {
  Decrypt,
  GetCurrentWeek,
  firstAndLastDateOfMonth,
  getYMDdate,
} from "../../services/CommonService";
import { AppContext } from "../../services/ContextProvider";
import { useNavigate } from "react-router";
import { showWarningMsg } from "../../components";
import { CheckList_GetStatusUsingID } from "./CheckListService";
import { Dropdown } from "../../components";
import Global from "../../services/Global";

export default function CheckListCalenderView() {
  const navigate = useNavigate();
  const {
    TypeDetails,
    handleGlobalStationName,
    getCookies,
    HandleTypeDetails,
    showLoading,
    hideLoading,
  } = useContext(AppContext);

  const localizer = momentLocalizer(moment);
  const { defaultDate } = useMemo(
    () => ({
      defaultDate: TypeDetails.CheckDate,
    }),
    []
  );
  const [PendingColors, SetPendingColors] = useState([]);
  // const [StationID, setStationID] = useState("");
  const [StationList, setStationList] = useState([]);
  const CalenderRef = useRef(null);

  const handleSlotSelect = (slotInfo) => {
    const targetDate =
      TypeDetails.ScheduleType === 43
        ? getYMDdate(slotInfo.start)
        : GetCurrentWeek(slotInfo.start);

    if (targetDate > getYMDdate(new Date())) {
      showWarningMsg("You won't be able to enter values in the future .");
    } else {
      HandleTypeDetails(
        "CheckDate",
        TypeDetails.ScheduleType === 43
          ? getYMDdate(slotInfo.start)
          : GetCurrentWeek(slotInfo.start)
      );
      navigate("/CheckListDailyCalender");
    }
  };

  const handleViewSelect = (event) => {
    console.log(event,"event");
    
    let ScheduleType = event == "week" ? 44 : 43;
    let CheckDate = event == "week" ? GetCurrentWeek() : getYMDdate(new Date());
    HandleTypeDetails("ScheduleType", ScheduleType);
    HandleTypeDetails("CheckDate", CheckDate);
  };

  useEffect(() => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    let ignoredStation = ["stc", "str", "swa"];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });
    setStationList(FinalStationList ?? []);

    handleGlobalStationName(FinalStationList[0]?.key ?? "");

    HandleTypeDetails("ScheduleType", 43);
    HandleTypeDetails("CheckDate", getYMDdate(new Date()));
  }, []);

  useEffect(() => {
    let currentView = TypeDetails.ScheduleType == 44 ? "week" : "month";

    NavigateTab(new Date(), currentView);
  }, [TypeDetails.ScheduleType, TypeDetails.StationID]);

  function getPendingDates(
    StartDate = firstAndLastDateOfMonth().firstDay,
    EndDate = firstAndLastDateOfMonth().lastDay
  ) {
    showLoading();
    if (TypeDetails.StationID > 0) {
      CheckList_GetStatusUsingID(
        TypeDetails.StationID,
        TypeDetails.ScheduleType,
        StartDate,
        EndDate
      )
        .then((res) => {
          hideLoading();
          if (res?.data?.data?.length) {
            SetPendingColors(res.data.data);
          } else {
          }
        })
        .catch((err) => {
          hideLoading();
          console.log(err);
        });
    } else {
      SetPendingColors([]);
    }
  }

  const calendarStyle = (date) => {
    if (PendingColors?.length) {
      let deserveColor = PendingColors.find((x) => {
        return getYMDdate(x.CheckDate) == getYMDdate(date);
      });
      let style = {
        backgroundColor: "",
        margin: 0,
        padding: 0,
      };
      if (deserveColor) {
        style.backgroundColor = deserveColor.Color;
      }
      // const today = new Date();
      // const startOfWeek = moment(today).startOf('week').toDate();
      // const endOfWeek = moment(today).endOf('week').toDate();
      // if (date >= startOfWeek && date <= endOfWeek) {
      //   style.backgroundColor = "lightblue";
      // }
      return { style };
    } else {
      return {
        style: {
          backgroundColor: "",
          margin: 0,
          padding: 0,
        },
      };
    }
  };

  function NavigateTab(date = new Date(), view = "month") {
     
    HandleTypeDetails("CheckDate", getYMDdate(date));
    let Year = date.getFullYear();

    let Month = date.getMonth() + 1;

    if (view == "month") {
      const { firstDay, lastDay } = firstAndLastDateOfMonth(Year, Month);
      getPendingDates(firstDay, lastDay);
    } else {
      debugger;
      const startOfWeek = moment(date).startOf("week").toDate();
      const endOfWeek = moment(date).endOf("week").toDate();
      const StartDate = getYMDdate(startOfWeek);
      const EndDate = getYMDdate(endOfWeek);
      getPendingDates(StartDate, EndDate);
    }
  }
  function HandleChange(e) {
    const { value, name } = e.target;
    HandleTypeDetails(name, value);
    let CurrentStation = StationList.find((x) => x.value == value);
    handleGlobalStationName(CurrentStation?.key ?? "");
  }

  return (
    <>
      <div
        className="col-md-1"
        style={{
          position: "absolute",
          right: "16%",
          marginBottom: "26px",
        }}
      >
        <div className="form-group">
          <Dropdown
            data={StationList}
            placeholder="Select"
            name={"StationID"}
            HandleChange={HandleChange}
            value={TypeDetails.StationID}
          />
        </div>
      </div>

      <div className="height600">
        <Calendar
          ref={CalenderRef}
          style={{ height: 500 }}
          date={new Date(TypeDetails.CheckDate)}
          startAccessor="start"
          endAccessor="end"
          views={{
            month: true,
            week: true,
          }}
          dayPropGetter={calendarStyle}
          onNavigate={NavigateTab}
          onView={handleViewSelect}
          // view={TypeDetails.ScheduleType == 43 ? "month" : "week"}
          max={new Date()}
          selectable={true}
          onSelectSlot={handleSlotSelect}
          localizer={localizer}
        />
      </div>
    </>
  );
}
