import { IoMdCheckmark } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const BreakDownDetailsView = ({
  DetailModel,
  UsedSpareModel,
  RemarksModel,
  TabID
}) => {
  if (!DetailModel) {
    return <div>No details available</div>;
  }

  return (
    <div>
      <div className="row">
        {typeof DetailModel === "object" &&
          Object.entries(DetailModel).map(([key, value]) => (
            <div className="col-md-3" key={key}>
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">{key}</h5>
                  {/* Null check for each value */}
                  <p className="card-text">
                    {value !== null && value !== undefined ? value : "N/A"}
                  </p>
                </div>
              </div>
            </div>
          ))}

        {Array.isArray(RemarksModel) && RemarksModel.length > 0 ? (
          RemarksModel.map((remark, index) => (
            <div className="col-md-3" key={index}>
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">{remark.RemarksTitle}</h5>
                  <p className="card-text">
                    {remark.Remarks !== null && remark.Remarks !== undefined
                      ? remark.Remarks
                      : "N/A"}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-md-12">
            <p>No remarks available.</p>
          </div>
        )}
      </div>
      <div>
        <ul>
          <table
            id="SpareDetailes"
            style={{ marginBottom: "3px" }}
            className="table table-striped custom-table mt-3"
          >
            <thead>
              <tr>
                <th>Spare Name</th>
                {/* <th>Part No</th> */}
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody id="tbl1">
              <>
                {UsedSpareModel != null && UsedSpareModel.length > 0 ? (
                  UsedSpareModel.map((x) => {
                    return (
                      <tr>
                        <td> {x.spareName} </td>
                        {/* <td>{x.partNo}</td> */}
                        <td>{x.quantity}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center" colspan="6">
                      No Data Found
                    </td>
                  </tr>
                )}
              </>
            </tbody>
          </table>
        </ul>
      </div>
      <div className="row justify-content-end">
      <div className="col-sm-6 mb-2 text-end">
        <button
          className="btn btn-success m-l-5"
          data-bs-toggle="modal"
          data-bs-target={`#Approve_${TabID}`}
          title="Reject"
        >
          <span>
            <IoMdCheckmark /> Approve
          </span>
        </button>

        <button
          className="btn btn-danger m-l-5"
          title="Reject"
          data-bs-toggle="modal"
          data-bs-target={`#Rejection_${TabID}`}
        >
          <span>
            <IoClose /> Reject
          </span>
        </button>
      </div>
      </div>
    </div>
  );
};

export default BreakDownDetailsView;
