import { useEffect, useState, useContext } from "react";
import $ from "jquery";
import {
  AuditingProcess_GetGridData,
  AuditingProcess_GetDetailsByID,
  AuditingProcess_ApproveReject,
  AuditingProcess_Getdropdown,
} from "./AuditingProcessService.js";
import BreakDownDetailsView from "./BreakDownDetailsView.js";
import { DownloadModel } from "../BreakDownMaintenance/DownloadModel";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService.js";
import { PeriodicMaintenance_GetEquipmentDetailForJobSave } from "../PeriodicMaintenance/PeriodicService.js";
import { GetLoginUserID, Decrypt } from "./../../services/CommonService";
import { useNavigate } from "react-router";
import {
  showWarningMsg,
  showSaveSuccess,
  Dropdown,
  HtmlTable,
  showSaveError,
  BootrapModel,
} from "../../components";
import { WithSelectDropDown } from "../../components/WithSelectDropDown";
import { AppContext } from "../../services/ContextProvider";
import Global, { COOKIES } from "../../services/Global";

export default function AuditingProcess({ TabID = 0, DepartmentID }) {
  const _breakdownService = new BreakdownMaintenanceService();
  const [GridModel, setGridModel] = useState([]);
  const [DetailModel, SetDetailModel] = useState({});
  const [RemarksModel, SetRemarksModel] = useState({});
  const [UsedSpare, SetUsedSpare] = useState({});
  const [FileData, setFileData] = useState([]);
  const [selectedModel, setselectedModel] = useState([]);
  const [header, setheader] = useState("");
  const navigate = useNavigate();
  const { getCookies } = useContext(AppContext);

  useEffect(() => {
    initGrid();
  }, [TabID, DepartmentID]);
  const handleRefresh = () => {
    initGrid();
  };

  const columnNames = [
    {
      title: "TaskTypeID",
      visible: false,
      data: "PeriodicCheckListMaintenanceID",
    },
    { title: "Department", data: "DepartmentName" },
    { title: "Station/Section Code", data: "StationCode" },
    {
      title: "Maintenance Type",
      data: "Type",
      createdCell: (td, cellData, rowData, row, col) => {
        var dd = `<div class="action-label">
<a class="btn btn-white btn-sm "  href="javascript:void(0);" style="font-size: 10px;padding: 1px;border-radius: 50px 20px !important;min-width: auto;    background: #f62d51;
border: 1px solid #f62d51; color: #fff">
Cyclic Check
</a>
</div>`;

        $(td).html(cellData);
        if (rowData.ISCyclicCheck == 1) {
          $(td).append(dd);
        }
      },
    },
    { title: "Equipment Short Name", data: "EquipmentShortName" },
    { title: "Schedule Type", data: "MaintenanceTypeName" },
    { title: "Approver Name", data: "FirstName" },
    { title: "Approver Remarks", data: "Remarks" },
    {
      title: "View Details",
      data: "PeriodicCheckListMaintenanceID",
      // visible:
      //     getPrivilege().includes("NONREVENUECONFIGURATIONEDIT") ||
      //     getPrivilege().includes("NONREVENUECONFIGURATIONDELETE"),
      createdCell: (td, cellData, rowData, row, col) => {
        var eye = "";
        var b = "";
        var c = "";

        // if (getPrivilege().includes("NONREVENUECONFIGURATIONEDIT")) {
        let IsBMType = rowData.Type == "Breakdown Maintenance";
        let ISPmType = rowData.Type == "Preventive Maintenance";
        if (IsBMType) {
          eye =
            $(`<button class='btn icon-btn' title='View Details' data-bs-toggle="modal" data-bs-target="#BreakDown_${TabID}">
                <i class='fa fa-eye' style='margin-right: 10px;'></i>
            </button>`).on("click", function () {
              getDetailsID(rowData);

              setselectedModel(rowData);
              setheader(rowData.Type + " " + rowData.EquipmentShortName);
            });
        } else if (ISPmType) {
          eye = $(`<button class='btn icon-btn' title='View Details'>
            <i class='fa fa-eye' style='margin-right: 10px;'></i>
        </button>`).on("click", function () {
            const date = new Date(rowData.FromDate);
            const FromDate = date.toISOString().split("T")[0];
            const toDate = new Date(rowData.ToDate);
            const ToDate = toDate.toISOString().split("T")[0];
            EditPeriodicSchedule(
              rowData.PeriodicCheckListMaintenanceID,
              FromDate,
              ToDate,
              rowData
            );
            // EditPeriodicSchedule(1012, "2024-05-01")
          });
        }
        // }
        let IsDownloadEnable = rowData.DownloadFlag == 1;
        if (IsDownloadEnable) {
          b =
            $(`<button class='btn icon-btn text-center'data-bs-toggle="modal" data-bs-target="#DownLoadFiles_${TabID}" title='Download'  >
                  <i class='fa fa-download' style='margin-right: 10px;'></i>
                  </button>`).on("click", function () {
              DownloadFiles(
                rowData.PeriodicCheckListMaintenanceID,
                0,
                IsBMType ? 8 : 7
              );
            });
        } else {
          b =
            $(`<button class='btn icon-btn text-center' disabled title='Download'  >
                    <i class='fa fa-download' style='margin-right: 10px;'></i>
                    </button>`);
        }
        // if (IsBMType) {
        //   c =
        //     $(`<button class='btn icon-btn text-center' title='Selfie Download'    >
        //     <i class='fa fa-file-image-o mr-5'></i>
        //           </button>`).on("click", function () {
        //       // SetIsView(true)
        //       TicketDownloadFiles(rowData.PeriodicCheckListMaintenanceID);
        //       // setFlag(2);
        //     });
        // }

        $(td).html(eye);
        $(td).append(b);
        $(td).append(c);
      },
    },
    {
      title: "Submit",
      data: "PeriodicCheckListMaintenanceID",
      createdCell: (td, cellData, rowData, row, col) => {
        let btn = $(`
    <button type="button" class="btn colorful-btn m-r-10" data-bs-toggle="modal" data-bs-target="#Approve_${TabID}" title="Approve">
    <i class="fa fa-thumbs-o-up"></i>
    </button>`).on("click", function () {
          setselectedModel(rowData);
          setheader(rowData.Type + " " + rowData.EquipmentShortName);
          // approveAction(rowData.PeriodicCheckListMaintenanceID, rowData.Type, 3);
        });

        let RejectBtn = $(`
    <button type="button" class="btn icon-btn red-btn" data-bs-toggle="modal" data-bs-target="#Rejection_${TabID}" title="Reject">
        <i class="fa fa-times"></i>
    </button>`).on("click", function () {
          setheader(rowData.Type + " " + rowData.EquipmentShortName);
          setselectedModel(rowData);
        });

        $(td).html(btn);
        $(td).append(RejectBtn);
      },
    },
  ];

  const initGrid = async () => {
    const RoleID = getCookies(Global.COOKIES.UserRoleID);
    // const RoleID = '4bdb1c0b-9b3f-4513-a8dd-34181701e300';

    await AuditingProcess_GetGridData(RoleID, DepartmentID).then((res) => {
      if (res?.data?.data) {
        const { auditingList, manualList } = res?.data?.data;

        if (TabID == 0) {
          if (auditingList?.length) {
            setGridModel(auditingList);
          } else {
            setGridModel([]);
          }
        } else if (TabID == 1) {
          if (manualList?.length) {
            setGridModel(manualList);
          } else {
            setGridModel([]);
          }
        }
      } else {
        setGridModel([]);
      }
    });
  };
  const GetDetailsByID = async (Type, ID) => {
    try {
      const res = await AuditingProcess_GetDetailsByID(Type, ID);

      if (
        res != null &&
        res.data.data.detailsByID != null &&
        res.data.data.detailsByID.length > 0
      ) {
        SetDetailModel(...res.data.data.detailsByID);
        SetUsedSpare(res.data.data.usedSpareList);
        SetRemarksModel(res.data.data.remarksList);
      } else {
        SetDetailModel([]);
        SetRemarksModel([]);
      }
    } catch (error) {
      SetDetailModel([]);
    }
  };
  const EditPeriodicSchedule = async (
    PeriodicCheckListMaintenanceID,
    FromDate,
    ToDate,
    rowData
  ) => {
    debugger
    let EmployeeID = GetLoginUserID();

    await PeriodicMaintenance_GetEquipmentDetailForJobSave(
      PeriodicCheckListMaintenanceID,
      EmployeeID
    )
      .then((res) => {
        console.log(res.data,'Aditingprocess')
        if (res?.data?.data?.length) { 
          const equipmentShortName = rowData?.EquipmentShortName?.toUpperCase()?.replaceAll(" ", "");
          if (
            equipmentShortName == "ICF-1-OCEFR-V-(ICF-1O/CEFRELAY)-(OVERCURRENTANDEARTHFAULTRELAY110&33KV)"
           
          ) {
            navigate("/OvrCrt_EarthFaultRelay_110_33_View", {
              state: {
                PeriodicCheckListMaintenanceID,
                FromDate,
                EndDate: ToDate,
                EquipmentDetails: res.data.data,
                selectedModel: rowData,
                header: rowData.Type + " " + rowData.EquipmentShortName,
              },
            });
          }
          else{
            navigate("/Periodic-Detail", {
              state: {
                PeriodicCheckListMaintenanceID,
                FromDate,
                EndDate: ToDate,
                EquipmentDetails: res.data.data,
                selectedModel: rowData,
                header: rowData.Type + " " + rowData.EquipmentShortName,
              },
            });
          }
          
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDetailsID = (rowdata) => {
    GetDetailsByID(rowdata.Type, rowdata.PeriodicCheckListMaintenanceID);
  };

  const DownloadFiles = (ticketID, AttachmetID, Flag) => {
    _breakdownService
      .GetFilesUsingTicketID(ticketID, AttachmetID, Flag)
      .then((res) => {
        setFileData(res.data);
      })
      .catch((err) => {
        setFileData([]);
        console.log(err);
      });
  };

  const RejectionDetails = (selectedModel, handleRefresh) => {
    const [DropdownModel, setdropdownModel] = useState([]);
    const [approveModel, setapproveModel] = useState({
      level: 0,
      Remarks: "",
    });

    useEffect(() => {
      DropDown();

      function DropDown() {
        AuditingProcess_Getdropdown().then((res) => {
          if (
            res != null &&
            res.data.data.dropdownList != null &&
            res.data.data.dropdownList.length > 0
          ) {
            setdropdownModel(res.data.data.dropdownList);
          }
        });
      }
    }, []);

    const CommonHandleChange = (e) => {
      const { name, value } = e.target;
      setapproveModel((pre) => ({
        ...pre,
        [name]: value,
      }));
    };
    const rejectAction = (e) => {
      e.preventDefault();
      if (selectedModel != null) {
        if (approveModel.level != 0) {
          let Obj = selectedModel.selectedModel;
          let model = {};
          model.ModuleType = Obj.Type;
          model.ID = Obj.PeriodicCheckListMaintenanceID;
          model.Status = Obj.Type == "Preventive Maintenance" ? 4 : 105;
          model.RejectionStatus = approveModel.level;
          model.Remarks = approveModel.Remarks;
          model.SavedBy = GetLoginUserID();
          model.StationID = Obj.StationID;
          model.RoleID = getCookies(Global.COOKIES.UserRoleID);

          AuditingProcess_ApproveReject(model)
            .then((response) => {
              const { data } = response?.data;
              if (data == "-1" || data == null) {
                showSaveError();
                handleRefresh();
              } else {
                showSaveSuccess();
              }
              $(`#closeRejection_${TabID}`).click();
            })
            .catch((err) => {
              console.log(err);
              $(`#closeRejection_${TabID}`).click();
            });
        } else {
          showWarningMsg("Please select Rejection level");
        }
      }
    };

    return (
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="stationCode">
              Risk levels<span className="text-danger"> *</span>{" "}
            </label>
            <WithSelectDropDown
              data={DropdownModel}
              placeholder="Select"
              name={"level"}
              HandleChange={CommonHandleChange}
              value={approveModel.level}
              className=""
            />
            {/* <span className="text-danger"> {errors.DepartmentID} </span> */}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="remarks">
              Remarks
            </label>
            <textarea
              name="Remarks"
              className="form-control"
              id="remarks"
              value={approveModel.Remarks}
              onChange={CommonHandleChange}
              rows="3"
              placeholder="Enter remarks here..."
            ></textarea>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-danger"
              type="submit"
              onClick={rejectAction}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ApproveDetails = (selectedModel, handleRefresh) => {
    const [approverRemarks, setapproverRemarks] = useState("");

    const appproveAction = () => {
      if (selectedModel != null) {
        let Obj = selectedModel.selectedModel;
        let model = {};
        model.ModuleType = Obj.Type;
        model.ID = Obj.PeriodicCheckListMaintenanceID;
        model.Status = Obj.Type == "Preventive Maintenance" ? 3 : 113;
        model.Remarks = approverRemarks.value;
        model.SavedBy = GetLoginUserID();
        model.StationID = Obj.StationID;
        model.RoleID = getCookies(Global.COOKIES.UserRoleID);

        AuditingProcess_ApproveReject(model)
          .then((response) => {
            const { data } = response?.data;
            if (data == "-1" || data == null) {
              showSaveError();
              handleRefresh();
            } else {
              showSaveSuccess();
            }
            $(`#closeApproval_${TabID}`).click();
          })
          .catch((err) => {
            console.log(err);
            $(`#closeApproval_${TabID}`).click();
          });
      }
    };

    const CommonHandleChange = (e) => {
      const { value } = e.target;
      setapproverRemarks(() => ({
        value,
      }));
    };

    return (
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="remarks">
              Remarks
            </label>
            <textarea
              className="form-control"
              id="remarks"
              rows="3"
              placeholder="Enter remarks here..."
              onChange={CommonHandleChange}
            ></textarea>
          </div>
        </div>
        <div className="col-md-6 d-flex justify-content-between align-items-center">
          <button className="btn btn-success" onClick={appproveAction}>
            Approve
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <BootrapModel
        Id={`BreakDown_${TabID}`}
        modelSize={"modal-xl"}
        ModelTitle={"Breakdown Maintenance Details"}
        module={
          <BreakDownDetailsView
            DetailModel={DetailModel}
            UsedSpareModel={UsedSpare}
            RemarksModel={RemarksModel}
            TabID={TabID}
          />
        }
        closepopup={handleRefresh}
      />
      <BootrapModel
        Id={`Rejection_${TabID}`}
        modelSize={"modal-xs"}
        CloseID={`closeRejection_${TabID}`}
        ModelTitle={header}
        module={
          <RejectionDetails
            handleRefresh={handleRefresh}
            selectedModel={selectedModel}
          />
        }
        closepopup={handleRefresh}
      />
      <BootrapModel
        Id={`Approve_${TabID}`}
        modelSize={"modal-xs"}
        ModelTitle={header}
        CloseID={`closeApproval_${TabID}`}
        module={
          <ApproveDetails
            handleRefresh={handleRefresh}
            selectedModel={selectedModel}
          />
        }
        closepopup={handleRefresh}
      />
      <BootrapModel
        Id={`DownLoadFiles_${TabID}`}
        ModelTitle={"Download Attachments"}
        modelSize={"modal-xl"}
        module={
          <DownloadModel
            Flag={2}
            FileDatas={FileData}
            ImageID={`SystemAuditImgID_${TabID}`}
          ></DownloadModel>
        }
      >
        {" "}
      </BootrapModel>

      <HtmlTable
        columnList={columnNames}
        rowList={GridModel}
        id={`ConfigureList_${TabID}`}
      />
    </div>
  );
}
