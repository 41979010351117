import { createContext, useState } from "react";
import { useCookies } from "react-cookie";
import {
  Encrypt,
  Decrypt,
  getYMDdate,
  GetCurrentWeek,
  makeUnique,
} from "./CommonService";
import { COOKIES } from "./Global";
import { GetNumberOfDaysInSec } from "./CommonService";
import Global from "../services/Global";
import { useContext } from "react";

export const AppContext = createContext({
  getCookies: (name) => {},
  setCookies: (name, data) => {},
  removeCookies: (name) => {},
  loadingStatus: false,
  showLoading: () => {},
  hideLoading: () => {},
  currentMasterTab: 0,
  handleMasterTab: (tabNo) => {},
  PeriodicEditData: {},
  handlePeriodicEditData: (data) => {},
  refreshCal: false,
  handleCal: () => {},
  calRefresh: false,
  handleCalRefresh: () => {},
  initialBreakdownTabID: 0,
  handleBreakdownTab: () => {},
  GlobalStationName: "",
  GlobalCorridor: false,
  handleGlobalStationName: () => {},
  handleGlobalCorridor: () => {},
  handleInitialScheduleType: () => {},
  CheckListDate: "",
  InitialScheduleType: "",
  TypeDetails: {},
  HandleTypeDetails: {},
  SelectedRowList: [],
  setSelectedRowList: () => {},
  PeriodicModel: {},
  setPeriodicModel: {},
  HandleSafetyMeasurement: () => {},
  SafetyMeasurementModel: {},
  PerformanceDropdownList: {},
  setPerformanceDropdownList: () => {},
  DepartmentConfigList: [],
  setDepartmentConfigList: () => {},
  GetLoginStationList: () => {},
  GetLoginStationTypes: () => {},
  CommonLogout: () => {},
  NotificationModel: {},
  setNotificationModel: () => {},
  GetLoginStationBaseOnDep: () => {},
  rowData: {},
  setRowData: () => {},
  BreakDownFilterModel: {},
  setBreakDownFilterModel: () => {},
});

export const AppContextProvider = ({ children }) => {
  const [cookies, setCookies, removeCookie] = useCookies([
    COOKIES.Jwt,
    COOKIES.PeriodicStationID,
    COOKIES.modulePrivileage,
    COOKIES.EmployeeID,
  ]);

  const [rowData, setRowData] = useState({});

  const GetCookies = (name) => {
    if (
      name == COOKIES.Stations ||
      name == COOKIES.GLOBAL_STATION ||
      name == Global.COOKIES.DepartmentStations
    ) {
      return localStorage.getItem(name);
    }
    var value = cookies[`${name}`];

    if (value != null) {
      return value;
    } else {
      return null;
    }
  };

  const SetCookies = (name, data, expiryDate = GetNumberOfDaysInSec(1)) => {
    if (
      name == COOKIES.Stations ||
      name == COOKIES.GLOBAL_STATION ||
      name == Global.COOKIES.DepartmentStations
    ) {
      localStorage.setItem(name, data);
      return;
    }
    // var encrptedData = Encrypt(data);
    setCookies(name, data, { path: "/", maxAge: expiryDate });
  };

  const RemoveCookies = (name) => removeCookie(name);

  {
    /* For Loader */
  }
  const [showLoading, setShowLoading] = useState(false);

  const handleShowLoading = () => setShowLoading(true);
  const handleHideLoading = () => setShowLoading(false);

  {
    /* Master tab */
  }
  const [masterTab, setMasterTab] = useState(0);

  const handleMasterTabs = (tabNo) => {
    setCookies("ID", tabNo);
    setMasterTab(tabNo);
  };

  // BreakDown Tab

  const [BreakdownTab, setBreakdownTab] = useState(0);

  const handleBreakdownTab = (tabNo) => {
    setCookies("BreakdownTab", tabNo);
    setBreakdownTab(tabNo);
  };

  // Global Station Name
  const [GlobalStationName, setGlobalStationName] = useState("");

  const handleGlobalStationName = (StationName = "") => {
    setCookies("GlobalStationName", StationName);

    let CurrentStationList = Decrypt(GetCookies(Global.COOKIES.Stations));
    if (CurrentStationList?.length) {
      let StationList = CurrentStationList.map((element) => {
        return element.StationCode;
      });
      StationName = StationList == StationName ? "All Stations" : StationName;
    }

    setGlobalStationName(StationName);
  };

  // Global Corridor Name
  const [Corridor, setCorridor] = useState(
    GetCookies(Global.COOKIES.corridorID)
  );

  const handleGlobalCorridor = (event) => {
    let GlobalStationList = Decrypt(GetCookies(Global.COOKIES.GLOBAL_STATION));
    let CorridorID = Corridor == 2 ? 1 : 2;
    if (GlobalStationList?.length) {
      let stationList = GlobalStationList.filter((x) => {
        return x.CorridorType == CorridorID;
      });

      SetCookies(Global.COOKIES.Stations, Encrypt(stationList));
      SetCookies(Global.COOKIES.corridorID, CorridorID);
      setCorridor(CorridorID);
      window.location.reload();
    }
  };

  //for Checks Date
  const [CheckDate, setCheckDate] = useState(getYMDdate(new Date()));

  const [InitialScheduleType, setInitialScheduleType] = useState(43);
  const InitialDailyDetails = {
    View: "day",
    ScheduleType: 43,
    IncrementNumber: 1,
    CheckDate: getYMDdate(new Date()),
    StationID: getFirstStationID() ?? 0,
  };
  const InitialWeeklyDetails = {
    View: "week",
    ScheduleType: 44,
    IncrementNumber: 7,
    CheckDate: GetCurrentWeek(),
    StationID: getFirstStationID() ?? 0,
  };

  const [TypeDetails, setTypeDetails] = useState(InitialDailyDetails);
  const handleInitialScheduleType = (Type) => {
    setInitialScheduleType(Type);
    let typeDetails = Type == 43 ? InitialDailyDetails : InitialWeeklyDetails;
    setTypeDetails(typeDetails);
  };

  function getFirstStationID() {
    const userStationsList = Decrypt(GetCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    let ignoredStation = ["stc", "str", "swa"];
    if (userStationsList?.length) {
      let filterStation = userStationsList.filter((x) => {
        return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
      });
      FinalStationList = filterStation.map((data) => {
        return { value: data.StationID, key: data.StationCode };
      });
      return FinalStationList[0]?.value ?? 0;
    }
    return 0;
  }

  // Periodic

  const [PeriodicModel, setPeriodicModel] = useState({
    EquipmentID: 0,
    JobScheduleID: 0,
    FromDate: "",
    EndDate: "",
    MaintenanceTypeID: "",
    PWTDate: "",
    Location: "",
    PWTNumber: "",
    IsChecked: true,
    StationID: 0,
    EquipmentDetailId: {},
    FileObj: [],
    ApprovalRemarks: "",
    OverAllRemarksList: [],
    IsJobAssigner: false,
    AllClosed: false,
  });

  const [SelectedRowList, setSelectedRowList] = useState([]);

  const HandlePeriodicModel = (obj = {}) => {
    setPeriodicModel((pre) => ({
      ...pre,
      ...obj,
    }));
  };

  const [SafetyMeasurementModel, setSafetyMeasurementModel] = useState({
    Heading: "",
    SubHeading: "",
    SafetyMeasures: "",
  });

  const HandleSafetyMeasurement = (obj) => {
    setSafetyMeasurementModel((pre) => ({
      ...pre,
      ...obj,
    }));
  };

  /**
   * Handles type details and updates the state.
   *
   * @param {string} name - The name of the type detail.
   * @param {any} value - The value of the type detail.
   * @returns {void}
   */
  const HandleTypeDetails = (name, value) => {
    setTypeDetails((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  // Performence Evaluation

  const [PerformanceDropdownList, setPerformanceDropdownList] = useState({
    StationList: [],
    StationID: 0,
    StartDate: "",
    CurrentYear: new Date().getFullYear(),
  });

  //  Department Config

  const [DepartmentConfigList, setDepartmentConfigList] = useState([]);

  const [editData, setEditData] = useState({});

  const handleEditData = (data) => setEditData(data);

  const [refreshCal, setRefreshCal] = useState(false);

  const handleCal = () => setRefreshCal(!refreshCal);

  const [calRefresh, setCalRefresh] = useState(false);

  const handleCalRefresh = () => setCalRefresh(!calRefresh);

  const GetLoginStationList = () => {
    const userStationsList = Decrypt(GetCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    FinalStationList = userStationsList?.map((data) => {
      const { StationCode, StationID, Type, IsSection, CorridorID } = data;
      return {
        value: StationID,
        label: StationCode,
        Type,
        IsSection,
        CorridorID,
      };
    });
    handleGlobalStationName(FinalStationList[0]?.label ?? "");
    return FinalStationList;
  };

  const GetLoginStationBaseOnDep = () => {
    const userStationsList = Decrypt(
      GetCookies(Global.COOKIES.DepartmentStations)
    );
    let FinalStationList = [];
    if (userStationsList?.length) {
      FinalStationList = userStationsList?.map((data) => {
        const {
          StationCode,
          StationID,
          Type,
          IsSection,
          CorridorID,
          DepartmentID,
        } = data;
        return {
          value: StationID,
          label: StationCode,
          Type,
          IsSection,
          CorridorID,
          DepartmentID,
        };
      });
      handleGlobalStationName(FinalStationList[0]?.label ?? "");
      return FinalStationList;
    }
    return [];
  };

  const [NotificationModel, setNotificationModel] = useState({
    GeneralNotification: 0,
    PeriodicNotification: 0,
    BreakdownNotification: 0,
    CheckListNotification: 0,
    AlarmNotification: 0,
    DailyEleListNotification: 0,
  });

  const GetLoginStationTypes = () => {
    const userStationsList = Decrypt(GetCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    FinalStationList = userStationsList?.map((data) => {
      const { CorridorID, CorridorName } = data;
      return { value: CorridorID, label: CorridorName, key: CorridorName };
    });
    return makeUnique(FinalStationList, ["value", "label"]);
  };

  const CommonLogout = () => {
    RemoveCookies(Global.COOKIES.Jwt);
    RemoveCookies(Global.COOKIES.modulePrivileage);
    RemoveCookies(Global.COOKIES.UserName);
    RemoveCookies(Global.COOKIES.Password);
    RemoveCookies(Global.COOKIES.UserGoodID);
    RemoveCookies(Global.COOKIES.UserDetails);
    RemoveCookies(Global.COOKIES.EmployeeID);
    RemoveCookies(Global.COOKIES.PeriodicStationID);
    RemoveCookies(Global.COOKIES.Stations);
    RemoveCookies(Global.COOKIES.GLOBAL_STATION);
    RemoveCookies(Global.COOKIES.corridorID);
    RemoveCookies(Global.COOKIES.CorridorChangePrivilege);
    RemoveCookies(Global.COOKIES.Departments);
    RemoveCookies(Global.COOKIES.Role);
    RemoveCookies(Global.COOKIES.UserRoleID);
    RemoveCookies(Global.COOKIES.IsTvsvac);
    RemoveCookies(Global.COOKIES.Departments);
    RemoveCookies("BAPPP");
    localStorage.clear();
  };

  const [BreakDownFilterModel, setBreakDownFilterModel] = useState({
    DepartmentID: 0,
    CorridorID: 0,
    StationID: 0,
  });

  const contextValue = {
    getCookies: GetCookies,
    setCookies: SetCookies,
    removeCookies: RemoveCookies,
    CommonLogout,
    loadingStatus: showLoading,
    showLoading: handleShowLoading,
    hideLoading: handleHideLoading,
    currentMasterTab: masterTab,
    initialBreakdownTabID: BreakdownTab,
    handleMasterTab: handleMasterTabs,
    handleBreakdownTab: handleBreakdownTab,
    PeriodicEditData: editData,
    handlePeriodicEditData: handleEditData,
    refreshCal: refreshCal,
    handleCal: handleCal,
    cookies: cookies,
    calRefresh: calRefresh,
    handleCalRefresh: handleCalRefresh,
    GlobalStationName: GlobalStationName,
    GlobalCorridor: Corridor,
    CheckListDate: CheckDate,
    handleGlobalStationName: handleGlobalStationName,
    handleGlobalCorridor: handleGlobalCorridor,
    handleInitialScheduleType: handleInitialScheduleType,
    InitialScheduleType: InitialScheduleType,
    TypeDetails: TypeDetails,
    HandleTypeDetails: HandleTypeDetails,
    SelectedRowList: SelectedRowList,
    setSelectedRowList: setSelectedRowList,
    PeriodicModel: PeriodicModel,
    setPeriodicModel: HandlePeriodicModel,
    HandleSafetyMeasurement: HandleSafetyMeasurement,
    SafetyMeasurementModel: SafetyMeasurementModel,
    PerformanceDropdownList,
    setPerformanceDropdownList,
    DepartmentConfigList,
    setDepartmentConfigList,
    GetLoginStationList,
    GetLoginStationTypes,
    NotificationModel,
    setNotificationModel,
    GetLoginStationBaseOnDep,
    rowData, // Add rowData here
    setRowData,
    BreakDownFilterModel,
    setBreakDownFilterModel,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export function DefaultAppContextValues() {
  const ContextValue = useContext(AppContext);
  return ContextValue;
}
