import React, { useEffect, useState } from "react";
import "./RCAReportCss.css";
import jsPDF from "jspdf";
import { useLocation, useNavigate } from "react-router";
import {
  DownloadForRCAReport,
  getRCAReport,
} from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { FullFormatDate } from "../../services/CommonService";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { RiArrowGoBackFill } from "react-icons/ri";
import JSZip from "jszip";
import { DefaultAppContextValues } from "../../services/ContextProvider";

function RCAPdfReport() {
  const navigate = new useNavigate();
  const location = useLocation();
  const [RCAReportModel, setRCAReportModel] = useState({
    stationCode: "",
    departmentName: "",
    corridorName: "",
    equipmentCode: "",
    dateTimeIncident: "",
    dateTimeClosure: "",
    descriptionofincident: "",
    lastMaintenanceDate: "",
    timeSequenceOfIncidents: "",
    probableCauseOfFailure: "",
    correctiveAction: "",
    detailsOfFaultyComponents: "",
    detailsOfNewReplacedComponents: "",
    detailsofIncident: "",
    lastMaintenanceDetails: [],
    images: "",
  });
  const { showLoading, hideLoading } = DefaultAppContextValues();

  useEffect(() => {
    if (location.state) {
      const { TicketID } = location.state;
      if (!TicketID) {
        navigate("/Error404");
      } else {
        getDetailsUsingId(TicketID);
      }
    } else {
      navigate("/Error404");
    }
  }, []);

  const getDetailsUsingId = async (TicketID) => {
    await getRCAReport(TicketID)
      .then((res) => {
        if (res?.data) {
           ;
          const { lastMaintenanceDetails, reportDetails } = res.data;
          const { detailsofIncident, timeSequenceOfIncidents } =
            reportDetails[0];
          const Obj = reportDetails[0];
          Obj.lastMaintenanceDetails = lastMaintenanceDetails;
          Obj.timeSequenceOfIncidents = JSON.parse(timeSequenceOfIncidents);
          Obj.detailsofIncident = JSON.parse(detailsofIncident);
          setRCAReportModel(Obj);
        } else {
          setRCAReportModel({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DynamicTable = ({ DataList = [] }) => {
    return (
      <table
        className=""
        cellspacing="0"
        cellpadding="3"
        border="0"
        width="100%"
      >
        <thead>
          <tr>
            {DataList.length > 0 &&
              DataList[0].cells.map((cell, colIndex) => (
                <th
                  key={`header-${colIndex}`}
                  className="ReportTableHeader"
                  align="middle"
                  valign="middle"
                  width="5%"
                  nowrap
                  style={{ border: "1.5px solid #232323" }}
                >
                  {cell}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {DataList.length > 0 &&
            DataList.slice(1).map((row, rowIndex) => (
              <tr key={row.id}>
                {row.cells.map((cell, colIndex) => (
                  <td
                    key={`${row.id}-${colIndex}`}
                    className=""
                    style={{ border: "1.5px solid #232323" }}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  function getBase64Image(url, callback) {
    callback(url);
  }

  function ConvertHTMLFileToPDF() {
    showLoading();
    const PageMargin = 25; // Margin from the edge of the page
    const margin = 50; // Margin from the edge of the page

    var doc = new jsPDF("l", "mm", [1200, 1210]);
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    var pdfjs = document.querySelector("#RCAPDFREPORT");

    doc.html(pdfjs, {
      callback: async function (doc) {

        let images = [];
        if (RCAReportModel?.images?.length) {
          await DownloadForRCAReport(RCAReportModel?.images)
            .then(async (response) => {
              const zip = await JSZip.loadAsync(response.data);
              for (const [relativePath, zipEntry] of Object.entries(
                zip.files
              )) {
                if (zipEntry.name.match(/\.(jpg|jpeg|png)$/i)) {
                  const blob = await zipEntry.async("blob");
                  const dataURL = await blobToDataURL(blob);
                  images.push(dataURL);
                }
              }
            })
            .catch((err) => {
              console.log(err);
              hideLoading();
            });
        }
        let currentY =
          doc.internal.getNumberOfPages() > 1
            ? margin
            : doc.internal.pageSize.height - margin;
        let imageHeight = 300; // Height of each image
        let imageWidth = 500; // Width of each image

        function addImagesSequentially(index) {
          if (index < images.length) {
            getBase64Image(images[index], function (base64Image) {
              // Check if the image fits on the current page; if not, add a new page
              if (currentY + imageHeight > pageHeight - margin) {
                doc.addPage();
                currentY = margin;
                doc.rect(
                  PageMargin,
                  PageMargin,
                  pageWidth - 2 * PageMargin,
                  pageHeight - 2 * PageMargin
                );
              }

              // Add the image at the current position
              doc.addImage(
                base64Image,
                "JPEG",
                index % 2 == 0 ? margin : 600,
                currentY,
                imageWidth,
                imageHeight
              );

              // Move to the position for the next image
              currentY += index % 2 == 0 ? 0 : imageHeight + 10; // Add 10 units of space between images

              // Add the next image
              addImagesSequentially(index + 1);
            });
          } else {
             
            doc.page=1; // use this as a counter.

   
            doc.save(
              `Root Cause Analysis Report (RCAR) - ${RCAReportModel?.ticketNo}.pdf`
            );
            hideLoading(); // Save the PDF after adding all images
          }
        }

        // Start adding images from the first one
        addImagesSequentially(0);
      },
      x: margin,
      y: margin,
    });

    // Draw a border around the content area
    doc.rect(
      PageMargin,
      PageMargin,
      pageWidth - 2 * PageMargin,
      pageHeight - 2 * PageMargin
    );
  }

  function blobToDataURL(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL("image/jpeg"));
        };
        img.src = reader.result;
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  function addFooter(doc, footerText) {
    const pageHeight = doc.internal.pageSize.height;
    const footerPositionY = pageHeight - 10; // Adjust based on your footer height

    doc.text(footerText, 10, footerPositionY);
  }

  return (
    <>
      {" "}
      <div className="justify-content-end row">
        <div className="col-6" style={{ position: "absolute" }}>
          <button
            className="btn btn-secondary float-end m-r-5"
            onClick={() => navigate("/RcaReport")}
          >
            Go Back
            <RiArrowGoBackFill />
          </button>
          <button
            className="btn btn-primary float-end m-r-5"
            onClick={() => ConvertHTMLFileToPDF()}
            title="Download"
          >
            <FaArrowAltCircleDown />
          </button>
        </div>
      </div>
      <table
        id="RCAPDFREPORT"
        cellspacing="0"
        cellpadding="0"
        width="1020px"
        border="0"
      >
        <tr>
          <td align="center">
            <br />
            <table
              style={{
                border: "1 solid Orange",
                cellpadding: "0",
                cellspacing: "0",
                width: "1020px",
              }}
            >
              <tr style={{ height: "40px" }}>
                <td align="left" valign="middle" nowrap>
                  <h3 style={{ margin: 0, padding: 0 }}>
                    <b>
                      Root Cause Analysis Report (RCAR) -{" "}
                      {RCAReportModel?.ticketNo}{" "}
                    </b>
                  </h3>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <table
                    cellspacing="0"
                    cellpadding="3"
                    border="0"
                    width="100%"
                  >
                    <tr>
                      <td align="left" valign="middle" width="10px" nowrap>
                        Department:
                      </td>
                      <td align="left" valign="middle" nowrap>
                        {RCAReportModel.departmentName}&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" width="5%" nowrap>
                        Corridor:
                      </td>
                      <td align="left" valign="middle" nowrap>
                        {RCAReportModel.corridorName}&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" width="5%" nowrap>
                        Station/Section:
                      </td>
                      <td align="left" valign="middle">
                        {RCAReportModel.stationCode}&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" width="5%" nowrap>
                        Date and Time of Incident:
                      </td>
                      <td align="left" valign="middle">
                        {FullFormatDate(RCAReportModel.dateTimeIncident)}&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" width="5%" nowrap>
                        Date and Time of Closure:
                      </td>
                      <td align="left" valign="middle">
                        {FullFormatDate(RCAReportModel.dateTimeClosure)}&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" width="5%" nowrap>
                        Equipment Details:
                      </td>
                      <td align="left" valign="middle">
                        {RCAReportModel.equipmentCode}&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" width="5%" nowrap>
                        <br />
                        <h4>
                          <b> Last Maintenance Carried Out:</b>
                        </h4>
                      </td>
                    </tr>
                    {RCAReportModel?.lastMaintenanceDetails?.length
                      ? RCAReportModel.lastMaintenanceDetails?.map((ele) => {
                          return (
                            <tr>
                              <td
                                align="left"
                                valign="middle"
                                width="5%"
                                nowrap
                              >
                                {ele.MaintenanceTypeName}
                              </td>
                              <td
                                align="left"
                                valign="middle"
                                width="5%"
                                nowrap
                              >
                                {ele.LastMaintenanceDate}
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                    <tr>
                      <td colspan="2">
                        <br />
                        <h4>
                          {" "}
                          <b>Details of Incident: </b>
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <DynamicTable
                        DataList={RCAReportModel.detailsofIncident}
                        title="Details of Incident"
                      />
                    </tr>
                    <tr>
                      <td align="left" valign="middle" width="5%" nowrap>
                        <br />
                        Incident brief:
                      </td>
                      <td align="left" valign="middle">
                        <br />
                        {RCAReportModel?.descriptionofincident}&nbsp;
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2">
                        <br />
                        <h4>
                          {" "}
                          <b>Time Sequence of Incidents: </b>
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <DynamicTable
                        DataList={RCAReportModel?.timeSequenceOfIncidents}
                        title="Details of Incident"
                      />
                    </tr>
                    <tr>
                      <td align="left" valign="middle" width="5%" nowrap>
                        <br />
                        Probable Cause of Failure:
                      </td>
                      <td align="left" valign="middle">
                        <br />
                        {RCAReportModel?.probableCauseOfFailure}&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" width="5%" nowrap>
                        Details of Faulty Components:
                      </td>
                      <td align="left" valign="middle">
                        {RCAReportModel?.detailsOfFaultyComponents}&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="middle" width="5%" nowrap>
                        Details of New Replaced Components:
                      </td>
                      <td align="left" valign="middle">
                        {RCAReportModel?.detailsOfNewReplacedComponents}&nbsp;
                      </td>
                    </tr>
                  </table>
                  <br />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </>
  );
}

export default RCAPdfReport;
