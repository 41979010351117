import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
function TreeTable({
  columnList,
  rowList,
  id,
  childColumns,
  ChildApiFunction = () => [],
  SearchPrependHtml = null,
  SearchPostponedHtml = null,
  count = 10,
}) {
  const [isTable, setIsTable] = useState(true);
  const tableName = id;
  const tableRef = useRef();
  useEffect(() => {
    if (columnList != null && columnList.length > 0) {
      if (rowList == null || rowList == [] || rowList?.length >= 0) {
        setDataTable();
        setIsTable(true);
      } else if (rowList.length != columnList.length) {
        setIsTable(false);
      }
    }
    // $(".sorting").trigger("click");
  }, [rowList?.length, rowList, isTable, JSON.stringify(columnList)]);

  const setDataTable = () => {
    //set data table
    const table = $(`#${tableName}`).DataTable({
      data: rowList != null ? rowList : [],
      columns: columnList,
      destroy: true,
      searching: true,
      paging: true,
      scrollX: true,
      stateSave: true,
      autoWidth: true,
    });

    // Add event listener for opening and closing details
    $(`#${tableName} tbody`).on("click", "td.dt-control",async function () {
      var tr = $(this).closest("tr");
      var row = table.row(tr);
      var ChildRowID = row.index();
      var data = table.row($(this).closest("tr")).data();

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass("shown");
      } else {
        let RowList = []
        if (data) {
           RowList =await ChildApiFunction(data, tr, table);
           
        }

        if (RowList?.length) {
          row.child(ChildTable(ChildRowID)).show();
          childTableCreation(RowList, childColumns, ChildRowID);
        } else {
          row.child(ChildTable(ChildRowID)).show();
          childTableCreation([], childColumns, ChildRowID);
        }

        tr.addClass("shown");
      }
    });
    if (SearchPrependHtml) {
      $(`#${tableName}_filter`).prepend(SearchPrependHtml());
    }

    if (SearchPostponedHtml) {
      $(`#${tableName}_filter`).append(SearchPostponedHtml());
    }

    return function () {
      table.destroy();
    };
  };

  const childTableCreation = (rowList, childColumns, ChildRowID) => {

    const Child = $(`#child-${ChildRowID}`).DataTable({
      data: rowList,
      columns: childColumns,
      destroy: true,
      searching: false,
      paging: false,
      bInfo: false,
      scrollX: true,
      autoWidth: true,
    });

    return () => {
      Child.destroy();
    };
  };

  return (
    <>
      <div className="table-responsive" style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-sm-12 mt-3">
            {isTable ? (
              <table
                className="table table-striped no-footer display"
                id={tableName}
                ref={tableRef}
                data-page-length={count ? count : "10"}
                width="100%"
              ></table>
            ) : (
              /* No of coulms and no of rows must be equal*/
              <div className="row col-12 text-center mb-2">
                <p className="text-muted">Table not found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TreeTable;

const ChildTable = (tableIndex) => {
  return `
      <table id="child-${tableIndex}" class="table table-striped no-footer display collapse-table" style="width: 100%; margin:auto;text-align: center; vertical-align: middle;">
      </table>`;
};

