import React from "react";
import {
  GetLoginUserID,
  Decrypt,
  getYMDdate,
  DownloadIcon,
  DeepCopy,
  tableLayout,
} from "../../services/CommonService";
import { ReactMultiSelect } from "../../components/MultiSelect/ReactMultiSelect";
import { useState, useEffect, useRef, useContext } from "react";
import $, { data } from "jquery";
import MasterDetailService from "../master/MasterDetailService";
import makeAnimated from "react-select/animated";
import { PeriodicService } from "../periodic/PeriodicService";
import { AppContext } from "../../services/ContextProvider";
import { Dropdown } from "../../components/Dropdown";
import Global from "../../services/Global";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ChillerMonitoringHtmlTable } from "../../components/ChillerMonitoringHtmlTable.js";
import {
  ChillerMonitoring_GetChillerMonitoringReportData,
  ChillerMonitoring_GetEquipmentCode,
} from "../ManualLog/DailyElectricalPanelservice.js";
import { logo } from "../Image.js";
const XLSX = require("xlsx");
const _periodicServices = new PeriodicService();
const initialEquipmentState = {
  RoomID: [],
  StationName: "",
  StationID: "",
  IsDeleted: false,
  checkAllAttended: 0,
  CurrentDate: getYMDdate(new Date()),
  checkAllApproved: false,
  checkAllRejected: false,
  EquipmentLocation: "",
  OffStartTime: "",
  IsChillerMonitorAllCheck: 0,
  Chiller1: "",
  Chiller2: "",
  Chiller3: "",
  MDB_1: "",
  MDB_2: "",
  OnTime: '',
  OffTime: '',
  TVS_Consumption: "",
  VAC_Consumption: "",
};

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const ChillerMonitoringReport = () => {
  const { showLoading, hideLoading, getCookies, handleGlobalStationName } =
    useContext(AppContext);

  const [StationList, setStationList] = useState([]);
  const [StationID, SetStationID] = useState([]);
  const [rowList, setData] = useState([]);
  const [FromDate, SetfromDate] = useState("");
  const [ToDate, SetToDate] = useState("");
  const [periodicTypeList, setPeriodicTypeList] = useState([]);
  const [EqipmentList, setEquipmentList] = useState([]);
  const [EqipmentID, setEquipmentID] = useState(0);
  const [stationIds, setStationIds] = useState("");
  const [EquipmentLocationList, setEquipmentLocationList] = useState([]);
  const [TimeandHoursList, setTimeandHoursList] = useState([]);

  const [EquipmentTagNumberList, setEquipmentTagNumberList] = useState([]);
  const [RoomModel, setRoomModel] = useState(initialEquipmentState);
  const [MasterDetails, setMasterDetails] = useState([]);

  const [periodicType, setPeriodicType] = useState(1);

  const [ChillerMonitoringDetailList, setChillerMonitoringDetailList] =
    useState([]);
  $.DataTable = require("datatables.net");

  const columnNames = [
    {
      title: `<p style="text-align: center;">S.NO</p>`,
      data: "ChillerMonitoringDWNID",
    },
    {
      title: `<p style="text-align: center;">Description</p>`,
      data: "ChillerName",
    },

    {
      title: `<p style="text-align: center;">Min/ Max Level</p>`,
      data: "MaxMinLevel",
    },
    {
      title: `<p style="text-align: center;">Units</p>`,
      data: "Units",
    },



    // {
    //   title: `<p style="text-align: center;">0.00</p>`,
    //   data: "Time_0_Start",
    //   // className: checkColVisible(min, max, 1) ? "hide" : "",
    // },
    // {
    //   title: `<p style="text-align: center;">1.00</p>`,
    //   data: "Time_0_End",

    //   // className: checkColVisible(min, max, 1) ? "hide" : "",
    // },
    // {
    //   title: `<p style="text-align: center;">2.00</p>`,
    //   data: "Time_2_Start",
    //   // className: checkColVisible(min, max, 2) ? "hide" : "",
    // },
    // {
    //   title: `<p style="text-align: center;">3.00</p>`,
    //   data: "Time_2_End",
    //   // className: checkColVisible(min, max, 2) ? "hide" : "",
    // },
    {
      title: `<p style="text-align: center;">4.00</p>`,
      data: "Time_4_Start",
      width: "200px",
    },
    // {
    //   title: `<p style="text-align: center;">5.00</p>`,
    //   data: "Time_4_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">6.00</p>`,
      data: "Time_6_Start",
    },
    // {
    //   title: `<p style="text-align: center;">7.00</p>`,
    //   data: "Time_6_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">8.00</p>`,
      data: "Time_8_Start",
    },
    // {
    //   title: `<p style="text-align: center;">9.00</p>`,
    //   data: "Time_8_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">10.00</span></p>`,
      data: "Time_10_Start",
    },
    // {
    //   title: `<p style="text-align: center;">11.00</span></p>`,
    //   data: "Time_10_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">12.00</p>`,
      data: "Time_12_Start",
    },
    // {
    //   title: `<p style="text-align: center;">13.00</p>`,
    //   data: "Time_12_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">14.00</p>`,
      data: "Time_14_Start",
    },
    // {
    //   title: `<p style="text-align: center;">15.00</p>`,
    //   data: "Time_14_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">16.00</p>`,
      data: "Time_16_Start",
    },
    // {
    //   title: `<p style="text-align: center;">17.00</p>`,
    //   data: "Time_16_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">18.00</p>`,
      data: "Time_18_Start",
    },
    // {
    //   title: `<p style="text-align: center;">19.00</p>`,
    //   data: "Time_18_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;">20.00</p>`,
      data: "Time_20_Start",
    },
    // {
    //   title: `<p style="text-align: center;">21.00</p>`,
    //   data: "Time_20_End",
    //   className: "hide",
    // },
    {
      title: `<p style="text-align: center;"> 22.00</p>`,
      data: "Time_22_Start",
    },
    // {
    //   title: `ChillerMonitoringDate`,
    //   data: "Units",
    //   visible: false,
    // },
    {
      title: `ChillerMonitoringDate`,
      data: "ChillerMonitoringDate",
      visible: false,
    },
    {
      title: `EquipmentCode`,
      data: "EquipmentCode",
      visible: false,
    },
  ];

  const [isTable, setIsTable] = useState(false);
  let offStartTimedefault = 0;
  let offEndTimedefault = 4;
  useEffect(() => {
    Report_GetJobEquipmentList();
    getType();
  }, []);

  useEffect(() => {
    initialDropDown();
    SetfromDate(getYMDdate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),);
    SetToDate(getYMDdate(new Date()));
  }, []);

  useEffect(() => {
    initialDropDownEquipmentCode();
  }, [RoomModel.StationID]);

  useEffect(() => {
    getDailyElectricalDetails();
  }, [
    RoomModel.StationID,
    RoomModel.EquipmentDetailID,
    RoomModel.EquipmentDetailIDs,
    FromDate,
    // ListPageRender,
  ]);

  const getDailyElectricalDetails = async () => {
    if (RoomModel.StationID > 0 && RoomModel.EquipmentDetailID?.value) {
      showLoading();

      await ChillerMonitoring_GetChillerMonitoringReportData(
        RoomModel.StationID,
        RoomModel.EquipmentDetailID.value,
        FromDate, ToDate
      )
        .then((res) => {
          //
          if (res?.data) {
            if (res.data.data.offStartTimeSelected?.length) {
              setRoomModel((pre) => ({
                ...pre,
                OffStartTime: res.data.data.offStartTimeSelected[0],
              }));
            } else {
              setRoomModel((pre) => ({
                ...pre,
                OffStartTime: "",
              }));
            }
            if (res.data.data.offEndTimeSelected?.length) {
              setRoomModel((pre) => ({
                ...pre,
                OffEndTime: res.data.data.offEndTimeSelected[0],
              }));
            } else {
              setRoomModel((pre) => ({
                ...pre,
                OffEndTime: "",
              }));
            }

            if (res.data.data.chillerMonitoringDet?.length) {
              setChillerMonitoringDetailList([
                ...res.data.data.chillerMonitoringDet,
              ]);
            } else {
              setChillerMonitoringDetailList([]);
            }
            if (res.data.data?.chillerMonitoringMST != null) {
              setRoomModel((pre) => ({
                ...pre,
                checkAllAttended:
                  res.data.data.chillerMonitoringMST[0].ChillerMonitoringStatus,
                IsChillerMonitorAllCheck:
                  res.data.data.chillerMonitoringMST[0]
                    .IsChillerMonitorAllCheck,
                Chiller1: res.data.data.chillerMonitoringMST[0].Chiller_1,
                Chiller2: res.data.data.chillerMonitoringMST[0].Chiller_2,
                Chiller3: res.data.data.chillerMonitoringMST[0].Chiller_3,
                StationName: res.data.data.chillerMonitoringMST[0].StationName,
                MDB_1: res.data.data.chillerMonitoringMST[0].MDB_1,
                MDB_2: res.data.data.chillerMonitoringMST[0].MDB_2,
                OnTime: res.data.data.chillerMonitoringMST[0].OnTime,
                OffTime: res.data.data.chillerMonitoringMST[0].OffTime,
                TVS_Consumption:
                  res.data.data.chillerMonitoringMST[0].TVS_Consumption,
                VAC_Consumption:
                  res.data.data.chillerMonitoringMST[0].VAC_Consumption,
              }));
              setMasterDetails((pre) => ({
                ...pre,
                masterDetails: res.data.data.chillerMonitoringMST,
              }));
            } else {
              setRoomModel((pre) => ({
                ...pre,
                checkAllAttended: 0,
                Chiller1: "",
                Chiller2: "",
                Chiller3: "",
                StationName: "",
                MDB_1: "",
                MDB_2: "",
                VAC_Consumption: "",
                TVS_Consumption: "",
                OffTime: '',
                OnTime: '',
                IsChillerMonitorAllCheck: 0,
              }));
            }

            if (res.data.data.equipmentLocationSelected?.length) {
              setRoomModel((pre) => ({
                ...pre,
                EquipmentLocation:
                  res.data.data.equipmentLocationSelected[0].label,
              }));
            } else {
              setRoomModel((pre) => ({
                ...pre,
                EquipmentLocation: "",
              }));
            }
            if (res.data.data.isoDocumentName?.length) {
              setRoomModel((pre) => ({
                ...pre,
                ISODocumentNo: res.data.data.isoDocumentName[0].DocumentNo,
              }));
            } else {
              setRoomModel((pre) => ({
                ...pre,
                ISODocumentNo: "",
              }));
            }
          } else {
            setRoomModel((pre) => ({
              ...pre,
              OffStartTime: "",
              OffEndTime: "",
              ISODocumentNo: "",
              checkAllAttended: 0,
              IsChillerMonitorAllCheck: 0,
              EquipmentLocation: "",
              EquipmentDetailID: "",
            }));

            setChillerMonitoringDetailList([]);
          }

          hideLoading();
        })
        .catch((err) => {
          hideLoading();
          console.log(err);
        });
    }
  };

  const initialDropDownEquipmentCode = async () => {
    if (RoomModel.StationID > 0) {
      await ChillerMonitoring_GetEquipmentCode(RoomModel.StationID)
        .then((res) => {
          if (res?.data) {
            if (res.data.data.chillerTagList?.length) {
              // var list = { value: '0', label: 'All' }
              // list.push(res.data.data.chillerTagList);
              setEquipmentTagNumberList([{ value: '0', label: 'All' }, ...res.data.data.chillerTagList]);

              // setEquipmentTagNumberList([...res.data.data.chillerTagList]);

              if (res.data.data.chillerTagList?.length) {
                setRoomModel((pre) => ({
                  ...pre,

                  EquipmentDetailID: { value: '0', label: 'All' },
                }));
              } else {
                setRoomModel((pre) => ({
                  ...pre,
                  EquipmentDetailID: "",
                }));
              }
            } else {
              setEquipmentTagNumberList([]);
            }
            if (res.data.data.equipmentLocationList?.length) {
              setEquipmentLocationList([
                ...res.data.data.equipmentLocationList,
              ]);
            } else {
              setEquipmentLocationList([]);
            }
            if (res.data.data.timeandHoursList?.length) {
              setTimeandHoursList([...res.data.data.timeandHoursList]);
            } else {
              setTimeandHoursList([]);
            }
          } else {
            setRoomModel((pre) => ({
              ...pre,
              EquipmentDetailID: "",
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const initialDropDown = async () => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    let ignoredStation = ["stc", "str", "swa"];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });

    setStationList([...FinalStationList]);

    setRoomModel((pre) => ({
      ...pre,
      StationID: FinalStationList[0]?.value ?? 0,
      CurrentDate: getYMDdate(new Date()),
    }));
    handleGlobalStationName(FinalStationList[0]?.key ?? "");
  };

  const HandleChange = (e) => {
    let StationLabel = e.map((x) => x.label).join();
    handleGlobalStationName(StationLabel);
    SetStationID(e);
  };

  useEffect(() => {
    var stationIds = StationID.map((x) => {
      return x.value;
    });

    if (FromDate != null) {
      $("#ToDate").attr("min", FromDate);
    }

    setStationIds(stationIds);
    return () => {
      setData([]);
    };
  }, [StationID, FromDate, periodicType, RoomModel.EquipmentDetailID]);

  const Report_GetJobEquipmentList = () => {
    _periodicServices
      .Report_GetJobEquipmentList()
      .then((data) => {
        var datas = [];
        if (data != null && data.data != null && data.data[0].length > 0) {
          data.data[0].map((c, i) => {
            // var dataLst = [];
            datas.push({ key: c.EquipmentShortName, value: c.EquipmentID });
          });
          if (datas.length > 0) {
            datas.push({ key: "All Equipments", value: -1 });
          }
          setEquipmentList(datas);
          setIsTable(true);
          // hideLoading();
        } else {
          setEquipmentList([]);
          // hideLoading();
        }
        if (datas.length > 0) {
          setEquipmentID(datas[0].value);
        }
      })
      .catch(
        (err) => console.log(err)
        // hideLoading()
      );
  };
  const getType = () => {
    var dataLst = [];
    dataLst.push({ key: "Daily", value: 1 });
    dataLst.push({ key: "Monthly", value: 2 });
    dataLst.push({ key: "Quarterly", value: 3 });
    dataLst.push({ key: "Half-Yearly", value: 4 });
    dataLst.push({ key: "Yearly", value: 5 });
    dataLst.push({ key: "All Type", value: -1 });

    setPeriodicTypeList(dataLst);
  };

  const CommonHandleChange = (event, name) => {
    if (Object.hasOwn(event, "target")) {
      const { name, value } = event.target;

      setRoomModel((PreviousState) => ({
        ...PreviousState,
        [name]: value,
      }));
      if (name == "StationID") {
        setRoomModel((pre) => ({
          ...pre,
          EquipmentDetailID: "",
          EquipmentLocation: "",
          OffStartTime: "",
          OffEndTime: "",
        }));
        setChillerMonitoringDetailList([]);

        let Station = StationList.find((x) => x.value == value);
        handleGlobalStationName(Station?.key ?? "");
      }
    } else {
      if (name === "OffEndTime") {
        const hours = event.label.split(".")[0];
        offEndTimedefault = Number(hours);
        for (let i = offStartTimedefault; i <= offEndTimedefault; i++) {
          //  offoverallTimedefault.push(i);
        }
        //alert(offoverallTimedefault);
      }
      if (name === "OffStartTime") {
        const hours = event.label.split(":")[0];
        offStartTimedefault = hours;
      }

      setRoomModel((PreviousState) => ({
        ...PreviousState,
        [name]: event,
      }));
    }
  };
  // const PDfCustomization = (doc) => {
  //   const table = DeepCopy(doc.content[1]);
  //   let array = JSON.parse(JSON.stringify(doc.content[1].table.body));
  //   let header = DeepCopy(doc.content[1].table.body[0]);
  //   let isWidth = false;
  //   if (header.length >= 10) {
  //     isWidth = true;
  //   }
  //   debugger;

  //   array = array.slice(1);
  //   let listsByDate = {};
  //   // Group items by date
  //   array.forEach(function (item) {
  //     let dateCheck = item[item.length - 1].text; // Assuming index 8 contains the dateCheck text field
  //     if (!listsByDate[dateCheck]) {
  //       listsByDate[dateCheck] = [DeepCopy(header)]; // Create a new list if it doesn't exist
  //     }
  //     listsByDate[dateCheck].push(item);
  //   });

  //   let mainList = Object.keys(listsByDate).map(function (dateCheck) {
  //     return {
  //       date: dateCheck,
  //       // items: listsByDate[dateCheck],
  //       items: listsByDate[dateCheck].map(function (item) {
  //         return item.slice(0, -1); // Remove the last index from each item array
  //       }),
  //     };
  //   });

  //   console.log("mainList", mainList);
  //   debugger;

  //   for (let i = 1, j = 0; i < mainList.length * 3; i = i + 3, j++) {
  //     let TechnicianRow = [];
  //     let EngineerRow = [];

  //     let copiedTable = DeepCopy(table);
  //     copiedTable.headerRows = 1;
  //     // doc.content[i] = addStartHeader();
  //     // doc.content[i] = addDateandStation(formatDate(mainList[j].date));
  //     // doc.header = addStartHeader();
  //     copiedTable.layout = tableLayout();
  //     copiedTable.table.body = mainList[j].items;
  //     doc.content.push(copiedTable);

  //     // let tableLength = headerArray.length;
  //     copiedTable.table.body.forEach((ele, index) => {
  //       if (index != 0) ele[0].text = index;
  //     });

  //     console.log(copiedTable.table.body);
  //     TechnicianRow.push({
  //       text: `Name of the Shift Technician : ${ChillerMonitoringDetailList?.[0]?.ShiftTechnician ?? ""}`,
  //       colSpan: 3,
  //     });
  //     EngineerRow.push({
  //       text: 'Name of the Shift Approver/Engineer',
  //       colSpan: 2,
  //     });

  //     var dynamicText = [
  //       ``,
  //       ``,
  //       `VAC Consumption : ${RoomModel.VAC_Consumption}`,
  //       "",
  //       `TVS Consumption: ${RoomModel.TVS_Consumption}`,
  //       "",
  //       `Chiller-1 ${RoomModel.Chiller1}`,
  //       `Chiller-2 ${RoomModel.Chiller2}`,
  //       `Chiller-3 ${RoomModel.Chiller3}`,
  //       `MDB-1 ${RoomModel.MDB_1}`,
  //       `MDB-2 ${RoomModel.MDB_2}`,
  //       `OnTime :  ${Number(RoomModel.OnTime).toFixed(2)}`,
  //       `OffTime :  ${Number(RoomModel.OffTime).toFixed(2)}`,
  //       ``,
  //     ];
  //     var dynamicColumn = [
  //       2,
  //       2,
  //       2,
  //       2,
  //       2,
  //       1,
  //       1,
  //       1,
  //       1,
  //       1,
  //       ,
  //       1,
  //       1,
  //       1,
  //       1,
  //       3,
  //       3,
  //       3,
  //       3,
  //       3,
  //       3,
  //       3,
  //       2,
  //       2,
  //       3,
  //       2,
  //       3,
  //       3,
  //       3,
  //       3,
  //       3,
  //       3,
  //       3,
  //       3,
  //       3,
  //     ];
  //     let count = 0;
  //     // debugger;
  //     let headerRow = [...doc.content[1].table.body[0]];
  //     headerRow = headerRow.slice(1);
  //     headerRow?.forEach(() => {
  //       TechnicianRow.push({
  //         colSpan: dynamicColumn[count],
  //         text: dynamicText[count] || "",
  //       });
  //       EngineerRow.push({
  //         colSpan: 12,
  //         alignment: "center",
  //         text: ChillerMonitoringDetailList?.[0]?.ShiftEngineer ?? "",
  //       });
  //       count++; // Increment count
  //     });
  //     doc.content[1].table.body.push(TechnicianRow);
  //     doc.content[1].table.body.push(EngineerRow);
  //     doc.content[1].table.widths = "*";
  //     doc.content[1].layout = {
  //       padding: 10, // Add padding for the entire table
  //     };
  //     if (j !== mainList.length - 1) {
  //       doc.content.push({ text: "", pageBreak: "before" });
  //     }
  //   }

  //   console.log(table);




  // };

  function formatDate(datetimeString) {
    const date = new Date(datetimeString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  const PDfCustomization = (doc) => {
    let array = JSON.parse(JSON.stringify(doc.content[1].table.body));
    let header = DeepCopy(doc.content[1].table.body[0]);

    array = array.slice(1);
    let listsByDate = {};
    debugger;

    // Group items by date
    array.forEach(function (item) {
      let dateCheck = item[item.length - 1].text + ',' + item[item.length - 2].text; // Assuming index 8 contains the dateCheck text field
      if (!listsByDate[dateCheck]) {
        listsByDate[dateCheck] = [DeepCopy(header)]; // Create a new list if it doesn't exist
      }
      listsByDate[dateCheck].push(item);
    });
    // 
    // Convert the object of lists into an array
    let mainList = Object.keys(listsByDate).map(function (dateCheck) {
      let date = dateCheck.split(",")[1];
      let code = dateCheck.split(",")[0];
      return {
        date: date,
        codes: code,
        // items: listsByDate[dateCheck],
        items: listsByDate[dateCheck].map(function (item) {
          return item.slice(0, -2); // Remove the last index from each item array
        }),
      };
    });

    let addDateandStation = (date, code, onTime, offTime) => {
      return {
        columns: [
          {
            text: `Station Name: ${RoomModel?.StationName ?? ''}`,
            alignment: "left",
            margin: [0, 0, 0, 6],
          },
          {
            text: `Running Chiller : ${code}`,
            alignment: "center",
            margin: [0, 0, 0, 6],
          },
          {
            text: `On Time: ${Number(onTime).toFixed(2)}, Off Time: ${Number(offTime).toFixed(2)}`,
            alignment: "right",
            margin: [0, 0, 0, 6],
          },
          {
            text: `Date: ${date}`,
            alignment: "right",
            margin: [0, 0, 0, 6],
          },
        ],
      };
    };
    // let addOntimeOfftime = (onTime, offTime) => {
    //   return {
    //     columns: [
    //       {
    //         text: `On Time: ${Number(onTime).toFixed(2)}, Off Time: ${Number(offTime).toFixed(2)}`,
    //         alignment: "right",
    //         margin: [0, 0, 0, 6],
    //       },
    //     ],
    //   };
    // };

    // let addStartHeader = () => [
    //   {
    //     columns: [
    //       {
    //         table: {
    //           widths: [440, 440, 250],
    //           layout: {
    //             vLineWidth: 0.5,
    //             hLineWidth: 0.5,
    //           },

    //           body: [
    //             [
    //               {
    //                 text: `Doc no: ${RoomModel?.ISODocumentNo ?? ""} \n Rev: 01`,
    //                 alignment: "left",
    //                 margin: [0, 20, 0, 0],
    //               },
    //               {
    //                 text: `Daily Chiller Monitoring`,
    //                 // rowSpan: 2,
    //                 alignment: "center",
    //                 margin: [0, 23, 0, 0],
    //               },
    //               {
    //                 image: logo,
    //                 // rowSpan: 2,
    //                 alignment: "center",
    //                 width: 50,
    //                 margin: [0, 5, 0, 5],
    //               },
    //             ],
    //           ],
    //         },
    //         margin: [18, 10, 0, 0],
    //       },
    //     ],
    //   },
    // ];

    const table = DeepCopy(doc.content[1]);
    let n = 0;
    doc.header = function (page, pages) { };
    for (let i = 1, j = 0; i < mainList.length * 3; i = i + 3, j++) {
      let TechnicianRow = [];
      let EngineerRow = [];
      var list = ChillerMonitoringDetailList.find((e) => e.ChillerMonitoringDate == mainList[j].date);
      var masterlist = MasterDetails.masterDetails.find((e) => e.ChillerMonitoringDate == mainList[j].date && e.EquipmentCode.trim() == mainList[j].codes.trim());
      let copiedTable = DeepCopy(table);
      copiedTable.headerRows = 1;
      debugger;
      doc.content[i] = addDateandStation(formatDate(mainList[j].date), masterlist.EquipmentCode, masterlist.OnTime, masterlist.OffTime);
      // doc.header = addStartHeader();
      copiedTable.layout = tableLayout();
      copiedTable.table.body = mainList[j].items;
      // doc.content.push(addOntimeOfftime())
      doc.content.push(copiedTable);
      copiedTable.table.widths = [30, 120, 60, 40, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60];

      // let tableLength = headerArray.length;
      copiedTable.table.body.forEach((ele, index) => {
        if (index != 0) ele[0].text = index;
      });

      let count = 0;
      // copiedTable.body = [headerArray, ...copiedTable.table.body];
      let headerArray = copiedTable.table.body[0];

      TechnicianRow.push({
        text: "Name of the Shift Technician",
        colSpan: 2,
      });

      EngineerRow.push({
        text: "Name of the Shift Approver/Engineer",
        colSpan: 2,
      });


      list = DeepCopy(list);
      // let count = 0;

      headerArray.forEach((ele, index) => {
        if (index !== 0) {
          TechnicianRow.push({
            colSpan: headerArray.length - 2,
            text: list.ShiftTechnician,
            alignment: "center",
          });
          EngineerRow.push({
            colSpan: headerArray.length - 2,
            alignment: "center",
            text: list.ShiftEngineer,
          });
          count++; // Increment count
        }
      });

      var dynamicText = [
        `VAC Consumption : ${masterlist.VAC_Consumption}`,
        "",
        `TVS Consumption: ${masterlist.TVS_Consumption}`,
        "",
        `Chiller-1 ${masterlist.Chiller_1}`,
        `Chiller-2 ${masterlist.Chiller_2}`,
        `Chiller-3 ${masterlist.Chiller_3}`,
        `MDB-1 ${masterlist.MDB_1}`,
        `MDB-2 ${masterlist.MDB_2}`,
        ``,
        ``,
        ``,
        ``,
        '',
      ];

      //doc.content[1].table.body.push(mergedRows);
      copiedTable.table.body.push(dynamicText);
      copiedTable.table.body.push(TechnicianRow);
      copiedTable.table.body.push(EngineerRow);

      if (j !== mainList.length - 1) {
        doc.content.push({ text: "", pageBreak: "before" });
      }


      // doc.content
    }

  };

  return (
    <>
      <div className="page-header mb-1">
        <div className="row">
          <div className="col-md-2 mt-2">
            <h4 className="page-title">Chiller Monitoring Report</h4>
          </div>

          <div className="col-md-2">
            <label className="col-form-label">Stations</label>

            <Dropdown
              data={StationList}
              placeholder="Select"
              name={"StationID"}
              HandleChange={CommonHandleChange}
              value={RoomModel.StationID}
              className=""
            />
          </div>

          <div className="col-md-2">
            <label className="col-form-label" for="empCode">
              Chiller No <span className="text-danger"> *</span>{" "}
            </label>{" "}
            <ReactMultiSelect
              options={EquipmentTagNumberList}
              value={RoomModel.EquipmentDetailID}
              onChange={(e) => CommonHandleChange(e, "EquipmentDetailID")}
              labelledBy="Select"
            />
          </div>

          <div className="col-md-2">
            <label className="col-form-label">Date</label>

            <input
              type="date"
              className="form-control"
              id="FromDate"
              defaultValue={FromDate}
              onChange={(e) => {
                SetfromDate(e.target.value);
              }}
            />
          </div>
          <div className="col-md-2">
            <label className="col-form-label">Date</label>

            <input
              type="date"
              className="form-control"
              id="FromDate"
              defaultValue={ToDate}
              onChange={(e) => {
                SetToDate(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="card card-body">
        <div className="">
          {isTable ? (
            <>
              <ChillerMonitoringHtmlTable
                IsASC
                IsShowPDF
                title="Chiller Monitoring Report"
                rowList={ChillerMonitoringDetailList}
                columnList={columnNames}
                id={`ChillerMonitoringReport`}
                FromDate={FromDate}
                StationName={RoomModel?.StationName ?? ""}
                EquipmentName={RoomModel?.EquipmentDetailID?.label ?? ""}
                pdfHeading={`Daily Chiller Monitoring`}
                PDfCustomization={PDfCustomization}
                DocumentNo={RoomModel?.ISODocumentNo ?? ""}
                landscape
              // paging={false}
              />
            </>
          ) : (
            <>
              <div className="text-center" style={{ margin: "10rem" }}>
                <div className="loader-Wrapper">
                  <div className="Customloader-div">
                    <span className="loader"></span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
