import React, { useEffect, useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-big-calendar";
import { useNavigate } from "react-router";
import {
  getYMDdate,
  getUserDetails,
  Week5_render,
  determineWeekColor,
  determineWeekColorForQuarter,
  addDays,
  QuarterMOnthForPM,
  generateQuarters,
} from "../../services/CommonService";
import { AppContext } from "./../../services/ContextProvider";
import { COOKIES } from "../../services/Global";
import { PeriodicWeekData, checkDate, checkMonth } from "./PeriodicWeekData";
import { PeriodicService } from "./PeriodicService";
import { PeriodicSetQuerterMOnthDate } from "../../services/CommonService";
import { ContextValues } from "./PeriodicContextProvider";
import CommonWeekCalender from "./CommonWeekCalender";

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function CalenderQuaterView({
  date,
  localizer,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(), "day"),
  ...props
}) {
  const { MonthConfigObj } = ContextValues();

  const StartMonth =
    MonthConfigObj.find((ele) => "86" == ele.ScheduleType)?.Month ?? 0;

  const MonthList = generateQuarters(StartMonth)?.map((ele) => ele[0]);

  const [header, setHeader] = useState([]);
  const { getCookies, showLoading, hideLoading } = useContext(AppContext);

  const { PeriodicModel, setPeriodicModel } = ContextValues();

  useEffect(() => {
    const { Date: NewDate, NeedToCall } = QuarterMOnthForPM(
      date,
      MonthConfigObj
    );
    var curMonth = NewDate.getMonth() + 1;
    var curYear = NewDate.getFullYear();
    var finalDate = NewDate;

    let finalMonth = finalDate.getMonth() + 1;
    let list = [];

    const MonthList = generateQuarters(StartMonth);
    let CurrentQuarters = MonthList.find((ele) => ele.includes(finalMonth));

    if (CurrentQuarters?.length) {
      CurrentQuarters.forEach((ele) => {
        list.push(Months[ele - 1]);
      });
    }

    setHeader(list);
  }, [date, JSON.stringify(MonthConfigObj), PeriodicModel?.CurrentView]);

  const currRange = useMemo(() => {
    const { Date, NeedToCall } = QuarterMOnthForPM(date, MonthConfigObj);

    if (localStorage.getItem("quaterMonth") == null) {
      localStorage.setItem("quaterMonth", getYMDdate(Date));
    }

    return CalenderQuaterView.range(Date, { localizer });
  }, [date, localizer]);

  let week5 = PeriodicWeekData(currRange[0]);

  let week1 = currRange.slice(0, 7);
  let week2 = currRange.slice(7, 14);
  let week3 = currRange.slice(14, 21);
  let week4 = currRange.slice(21, 28);

  let weekLst = [week1, week2, week3, week4, week5];

  return (
    <>
      <div className="card p-1 m-0">
        <ul
          className="nav nav-tabs nav-tabs-solid nav-justified"
          style={{ height: 40 }}
        >
          {header != null && header.length > 0 ? (
            header.map((v, i) => {
              if (i == 1) {
                var id = "#solid-justified-tab" + (i + 1);
                return (
                  <li className="nav-item">
                    <a className="nav-link active">{v}</a>
                  </li>
                );
              } else {
                var id = "#solid-justified-tab" + (i + 1);
                return (
                  <li className="nav-item">
                    <a className="nav-link">{v}</a>
                  </li>
                );
              }
            })
          ) : (
            <></>
          )}
        </ul>
      </div>
      <CommonWeekCalender weekLst={weekLst}></CommonWeekCalender>
    </>
  );
}

CalenderQuaterView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

CalenderQuaterView.title = (date, { localizer }) => {
  return "";
};

CalenderQuaterView.range = (date = new Date(), { localizer }) => {
  const start = new Date(date.getFullYear(), date.getMonth(), 1);
  const end = localizer.add(
    start,
    new Date(date.getFullYear(), date.getMonth(), 0).getDate(),
    "day"
  );
  let current = start;
  const range = [];
  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }
  return range;
};

CalenderQuaterView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -3, "month");

    case Navigate.NEXT:
      return localizer.add(date, 3, "month");

    default:
      return date;
  }
};
