import { toDate } from "date-fns";
import Axios from "../../services/AxiosService";

const axios = new Axios();

export default class BillingSheetServices {

    GetEquipmentShortNameList(){
        return axios.get("BillingSheet/Get_EquipmentShortNameList");
    }
    BillingSheetCheckList_Save(data){
        return axios.post("BillingSheet/BillingSheetCheckList_Save",data)
    }
    BillingSheet_GetMaintenanceDetails(fromDate,toDate){
        return axios.get(`BillingSheet/BillingSheet_GetMaintenanceDetails?fromDate=${fromDate}&toDate=${toDate}`)
    }
    BillingSheet_GetJobApprovedStationList(checkListId,fromDate,toDate){
        return axios.get(`BillingSheet/BillingSheet_GetJobApprovedStationList?checkListId=${checkListId}&fromDate=${fromDate}&toDate=${toDate}`)
    }
    BillingSheet_SaveMaintenanceDetails(data){
        return axios.post("BillingSheet/BillingSheet_SaveMaintenanceDetails",data);
    }
    BillingSheet_ReportDetails(fromDate,toDate){
        return axios.get(`BillingSheet/BillingSheet_ReportDetails?fromDate=${fromDate}&toDate=${toDate}`)
    }
    BillingSheet_Get_MaintaneceId(checklistId,fromDate, toDate){
        return axios.get(`BillingSheet/BillingSheet_Get_MaintaneceId?checklistId=${checklistId}&fromDate=${fromDate}&toDate=${toDate}`)
    }
    BillingCheckList_GetById(checkListId){
        return axios.get(`BillingSheet/BillingCheckList_GetById?checkListId=${checkListId}`)
    }
}
