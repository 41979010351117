import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { DefaultAppContextValues } from "../../../services/ContextProvider";
import {
  DeepCopy,
  getL0RoleID,
  GetLoginUserID,
  getUserDetails,
  validateModel,
} from "../../../services/CommonService";
import { useLocation, useNavigate } from "react-router";
import {
  PeriodicMaintenance_GetPM_Annexure_DetailsUsingID,
  PeriodicMaintenance_Save_PM_Annexure,
} from "../PeriodicService";
import { RiArrowGoBackLine } from "react-icons/ri";
import swal from "sweetalert";
import { showErrorMsg, showSaveSuccess } from "../../../components";

const PeriodicMonthlyAnnexureForm = () => {
  const [formStates, setFormStates] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const { showLoading, hideLoading } = DefaultAppContextValues();
  const [Model, setModel] = useState({
    IsAllClosed: false,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const {
    PeriodicCheckListMaintenanceID = 0,
    EquipmentDetailID = 0,
    PeriodicDetailID = 0,
  } = location?.state ?? {};

  const GetDescriptionByID = () => {
    if (
      (PeriodicCheckListMaintenanceID && EquipmentDetailID, PeriodicDetailID)
    ) {
      showLoading();
      PeriodicMaintenance_GetPM_Annexure_DetailsUsingID(
        PeriodicCheckListMaintenanceID,
        EquipmentDetailID,
        GetLoginUserID(),
        PeriodicDetailID
      )
        .then((res) => {
          hideLoading();
          if (res?.data?.data?.length) {
            setFormStates(res?.data?.data);
          }
        })
        .catch((err) => {
          hideLoading();
        });
    } else {
      navigate("/Error404");
    }
  };

  useEffect(() => {
    GetDescriptionByID();
  }, [PeriodicCheckListMaintenanceID, EquipmentDetailID]);
  const Header = ({ title }) => (
    <div className="row align-items-center mb-4">
      <div className="col-md-7">
        <h3 className="page-title">{title}</h3>
      </div>
    </div>
  );

  const handleChange = (e, key, fieldName) => {
    const updatedFormStates = [...formStates];
    updatedFormStates[key] = {
      ...updatedFormStates[key],
      [fieldName || e.target.name]: e.target.value,
    };
    setFormStates(updatedFormStates);
  };

  const handleFormSubmit = async (key = 0) => {
    const AllData = DeepCopy(formStates);
    const formData = AllData[key];
    const ValidateField = {
      BatteryCurrent: "Battery Current",
      VoltageB: "VoltageB",
      VoltageG: "VoltageG",
      VoltageR: "VoltageR",
      LoadCurrent: "Load Current",
      Time: "Time",
      TotalVoltage: "Total Voltage",
      // M1: "M1",
      // M2: "M2",
      // M3: "M3",
      // M4: "M4",
      // M5: "M5",
      // M6: "M6",
      // M7: "M7",
      // M8: "M8",
      // M9: "M9",
      // M10: "M10",
      // M11: "M11",
      // M12: "M12",
      // M13: "M13",
      // M14: "M14",
      // M15: "M15",
      // M16: "M16",
      // M17: "M17",
      // M18: "M18",
      // M19: "M19",
      // M20: "M20",
      // M21: "M21",
      // M22: "M22",
      // M23: "M23",
      // M24: "M24",
      // Remarks: "Remarks",
    };
    const validationErrors = validateModel(formData, ValidateField);
    if (validationErrors) {
      formData.Error = validationErrors;
      setFormStates(AllData);
      return;
    }

    try {
      const obj = {
        ...formData,
        PeriodicDetailID,
        SavedBy: GetLoginUserID(),
      };

      await PeriodicMaintenance_Save_PM_Annexure(obj)
        .then((response) => {
          if (response != null && response.data != null) {
            showSaveSuccess();
            GetDescriptionByID();
            return response.data;
          }
          return null;
        })
        .catch((err) => {
          hideLoading();
          showErrorMsg();
          GetDescriptionByID();
          console.log(err);
        });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const isAbleToEdit = () => {
    return !(getL0RoleID() == getUserDetails().RoleID);
  };

  return (
    <div className="mt-3">
      <div className="row align-items-center">
        <div className="row justify-content-between">
          <div className="col-8 ">
            {" "}
            <h3> Telecom - Monthly Preventive Maintenance CheckList </h3>
          </div>
          <div className="col-4 mb-2 text-end">
            <button
              className="btn btn-secondary"
              style={{ backgroundColor: "#b3b3b3", borderColor: "#b3b3b3" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <RiArrowGoBackLine /> Go Back
            </button>
          </div>
        </div>
      </div>

      <Accordion
        allowZeroExpanded={true}
        allowMultipleExpanded
        preExpanded={[0]}
      >
        {formStates.map((item, key) => {
          const { Error: errors = {} } = item;

          return (
            <AccordionItem key={key} uuid={key}>
              <AccordionItemHeading>
                <AccordionItemButton>{item.Description}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="row mb-3">
                      <div className="col-md-12 bg-warning align-content-center text-center">
                        {item.ComponentName}
                      </div>
                    </div>

                    <div className="main d-flex w-100 gap-2">
                      <div className="left" style={{ flex: "0 0 70%" }}>
                        <div className="mb-3 d-flex align-items-center">
                          <label className="me-3">Input Voltage:</label>

                          <div className="row ">
                            <div className=" col-3">
                              <label className="form-label">R</label>
                              <span className="text-danger"> *</span>
                              <input
                                className="form-control"
                                type="number"
                                name="VoltageR"
                                value={item?.VoltageR}
                                disabled={isAbleToEdit()}
                                onChange={(e) => handleChange(e, key)}
                              />
                              {errors?.VoltageR && (
                                <span className="text-danger">
                                  {errors?.VoltageR}
                                </span>
                              )}
                            </div>
                            <div className=" col-3">
                              <label className="form-label">B</label>
                              <span className="text-danger"> *</span>
                              <input
                                className="form-control"
                                type="number"
                                name="VoltageB"
                                value={item.VoltageB}
                                disabled={isAbleToEdit()}
                                onChange={(e) => handleChange(e, key)}
                              />
                              {errors?.VoltageB && (
                                <span className="text-danger">
                                  {errors?.VoltageB}
                                </span>
                              )}
                            </div>
                            <div className=" col-3">
                              <label className="form-label">G</label>
                              <span className="text-danger"> *</span>
                              <input
                                disabled={isAbleToEdit()}
                                className="form-control"
                                type="number"
                                name="VoltageG"
                                value={item.VoltageG}
                                onChange={(e) => handleChange(e, key)}
                              />
                              {errors?.VoltageG && (
                                <span className="text-danger">
                                  {errors?.VoltageG}
                                </span>
                              )}
                            </div>

                            <div className=" col-3">
                              <label className="form-label">Time</label>
                              <span className="text-danger"> *</span>
                              <input
                                disabled={isAbleToEdit()}
                                className="form-control"
                                type="datetime-local"
                                name="Time"
                                value={item.Time}
                                onChange={(e) => handleChange(e, key)}
                              />
                              {errors?.Time && (
                                <span className="text-danger">
                                  {errors?.Time}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <h5 className="mt-4">
                          i. Measurement of individual cell voltage
                        </h5>

                        <table className="table">
                          <tbody>
                            {[...Array(6)].map((_, rowIndex) => (
                              <tr key={rowIndex}>
                                {Array(4)
                                  .fill(0)
                                  .map((_, colIndex) => {
                                    const measurementIndex =
                                      rowIndex * 4 + colIndex + 1;
                                    const MError =
                                      errors[`M${measurementIndex}`];

                                    return (
                                      <>
                                        <td key={`label-${measurementIndex}`}>
                                          {measurementIndex}
                                        </td>

                                        <td key={`input-${measurementIndex}`}>
                                          <input
                                            className={`form-control ${
                                              MError ? "border-danger" : ""
                                            } `}
                                            type="number"
                                            disabled={isAbleToEdit()}
                                            name={`M${measurementIndex}`}
                                            value={
                                              item[`M${measurementIndex}`] || ""
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                key,
                                                `M${measurementIndex}`
                                              )
                                            }
                                          />
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="d-flex flex-wrap mt-3">
                          <div className="d-flex flex-column me-2 mb-2">
                            <label className="form-label">
                              Total Voltage{" "}
                              <span className="text-danger"> *</span>
                            </label>

                            <input
                              className="form-control w-100"
                              type="text"
                              name="TotalVoltage"
                              value={item.TotalVoltage}
                              disabled={isAbleToEdit()}
                              onChange={(e) => handleChange(e, key)}
                            />
                            {errors?.TotalVoltage && (
                              <span className="text-danger">
                                {errors?.TotalVoltage}
                              </span>
                            )}
                          </div>
                          <div className="d-flex flex-column me-2 mb-2">
                            <label className="form-label">
                              Load Current{" "}
                              <span className="text-danger"> *</span>
                            </label>

                            <input
                              className="form-control w-100"
                              type="text"
                              name="LoadCurrent"
                              value={item.LoadCurrent}
                              disabled={isAbleToEdit()}
                              onChange={(e) => handleChange(e, key)}
                            />
                            {errors?.LoadCurrent && (
                              <span className="text-danger">
                                {errors?.LoadCurrent}
                              </span>
                            )}
                          </div>
                          <div className="d-flex flex-column me-2 mb-2">
                            <label className="form-label">
                              Battery Current{" "}
                              <span className="text-danger"> *</span>
                            </label>

                            <input
                              className="form-control w-100"
                              type="text"
                              name="BatteryCurrent"
                              value={item.BatteryCurrent}
                              disabled={isAbleToEdit()}
                              onChange={(e) => handleChange(e, key)}
                            />
                            {errors?.BatteryCurrent && (
                              <span className="text-danger">
                                {errors?.BatteryCurrent}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="right d-flex align-items-stretch"
                        style={{ flex: "0 0 30%" }}
                      >
                        <div className="remarks w-100">
                          <textarea
                            className="w-100 h-100 form-control"
                            style={{ resize: "none" }}
                            name="Remarks"
                            value={item.Remarks}
                            disabled={isAbleToEdit()}
                            onChange={(e) => handleChange(e, key)}
                          ></textarea>
                          {errors?.Remarks && (
                            <span className="text-danger">
                              {errors?.Remarks}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      {!isAbleToEdit() && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => handleFormSubmit(key)}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
};

export default PeriodicMonthlyAnnexureForm;
