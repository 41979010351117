import React, { useCallback, useContext, useEffect, useState } from "react";
import TreeTable from "../../components/TreeTable/TreeTable";
import {
  BootrapModel,
  ReactMultiSelect,
  showErrorMsg,
  showSuccessMsg,
  showWarningMsg,
} from "../../components";
import BillingSheetSaveDetail from "./BillingSheetSaveDetail";
import BillingSheetServices from "./BillingSheetServices";
import $ from "jquery";
import { toast } from "react-toastify";
import { FilterDropdown } from "../../components/FilterDropdown";
import { toDate } from "date-fns";
import { Link } from "react-router-dom";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { AppContext } from "../../services/ContextProvider";
import {
  getPrivilege,
  Decrypt,
  getYMDdate,
  GetLoginUserID,
  getUserDetails,
  addDays,
} from "../../services/CommonService";
import { COOKIES } from "../../services/Global";
import { DownloadModel } from "../BreakDownMaintenance/DownloadModel";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";
import swal from "sweetalert";
export default function BillingSheet() {
  const _billServc = new BillingSheetServices();
  const [BillingSheetList, setBillingSheetList] = useState([]);
  const [BillingSheetDetails, setBillingSheetDetails] = useState([]);
  const [Year, setYear] = useState(new Date().getFullYear());
  const [Quarter, setQuarter] = useState("Nov-Jan(Q1)");
  const [dateRange, setDateRange] = useState({
    fromDate: `${Year}-11-01`,
    toDate: `${parseInt(Year) + 1}-01-31`,
  });
  const [TotalAmount, setTotalAmount] = useState();
  const [isHandle, setIsHandle] = useState(false);
  const _breakdownService = new BreakdownMaintenanceService();
  const [FileData, setFileData] = useState([]);
  const initialBillingState = {
    checkAllAttended: false,
    checkAllApproved: false,
    checkAllRejected: false,
  };
  const [BillingSheetModel, setBillingSheetModel] =
    useState(initialBillingState);
  useEffect(() => {
    ParentDataFunction();
  }, [dateRange]);
  const {
    getCookies,
    showLoading,
    hideLoading,
    handleGlobalStationName,
    PerformanceDropdownList,
    setPerformanceDropdownList,
  } = useContext(AppContext);
  const [AttendanceCount, setAttendanceCount] = useState(0);
  const columns = [
    {
      className: "dt-control",
      orderable: true,
      data: "",
      defaultContent: "",
      createdCell: function (td, cellData, rowData) {
        const hideIcon = rowData.EquipmentShortName === "OTHERS";

        if (hideIcon) {
          $(td).css("visibility", "hidden");
        }
      },
    },
    { title: "CheckListId", data: "CheckListId", visible: false },
    { title: "Checklist Number", data: "CheckListNumber" },
    { title: "Checklist Description", data: "CheckListDescription" },
    {
      title: "Total No's",
      data: "TotalNo",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";
        const historyCard = rowData.IsHistoryCard === 1;
        const { checkAllApproved, checkAllAttended } = BillingSheetModel;
        let IsDisable =
          IsAllowToEnterData() && !checkAllApproved ? "" : "disabled";
        const inputElement = $(
          `<input type="number" class="form-control" ${IsDisable} name="TotalNo" value="${
            cellData || 0
          }" data-id="${rowData.CheckListId}" data-field="TotalNo"/>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = parseFloat(e.target.value);

          if (!isNaN(newValue)) {
            rowData.TotalNo = newValue;

            const maxMarksWeightage = rowData.MaxMarksWeightage || 0;
            const totalCompletedChecklist =
              rowData.TotalCompletedChecklist || 0;

            if (newValue > 0) {
              rowData.MarksObtained =
                (totalCompletedChecklist / newValue) * maxMarksWeightage;
            } else {
              rowData.MarksObtained = 0;
            }

            const marksObtainedInput = $(td)
              .closest("tr")
              .find('input[name="MarksObtained"]');
            marksObtainedInput.val(rowData.MarksObtained.toFixed(2));

            handleInputChange(rowData);
          }
        });
        if (hideCondition) {
          $(td).css("visibility", "hidden");
        }
        if (historyCard) {
          $(td).html(inputElement);
        }
      },
    },
    {
      title: "Total Completed Checklist",
      data: "TotalCompletedChecklist",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";
        const historyCard = rowData.IsHistoryCard === 1;
        const { checkAllApproved, checkAllAttended } = BillingSheetModel;
        let IsDisable =
          IsAllowToEnterData() && !checkAllApproved ? "" : "disabled";
        const inputElement = $(
          `<input type="number" class="form-control" ${IsDisable} name="TotalCompletedChecklist" value="${
            cellData || 0
          }" data-id="${
            rowData.CheckListId
          }" data-field="TotalCompletedChecklist"/>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = parseFloat(e.target.value);

          if (!isNaN(newValue)) {
            rowData.TotalCompletedChecklist = newValue;

            const totalNos = rowData.TotalNo || 0;
            const maxMarksWeightage = rowData.MaxMarksWeightage || 0;

            if (totalNos > 0) {
              rowData.MarksObtained = (newValue / totalNos) * maxMarksWeightage;
            } else {
              rowData.MarksObtained = 0;
            }

            const marksObtainedInput = $(td)
              .closest("tr")
              .find('input[name="MarksObtained"]');
            marksObtainedInput.val(rowData.MarksObtained.toFixed(2));

            handleInputChange(rowData);
          }
        });
        if (hideCondition) {
          $(td).css("visibility", "hidden");
        }
        if (historyCard) {
          $(td).html(inputElement);
        }
      },
    },

    {
      title: "Max Marks Weightage (%)",
      data: "MaxMarksWeightage",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";
        const { checkAllApproved, checkAllAttended } = BillingSheetModel;
        let IsDisable =
          IsAllowToEnterData() && !checkAllApproved ? "" : "disabled";
        const inputElement = $(
          `<input type="number" class="form-control" name ="MaxMarksWeightage" ${IsDisable} value="${cellData.toFixed(
            2
          )}" data-id="${rowData.CheckListId}" data-field="MaxMarksWeightage"/>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = parseFloat(e.target.value);

          if (!isNaN(newValue)) {
            rowData.MaxMarksWeightage = newValue;

            // Recalculate MarksObtained whenever MaxMarksWeightage is changed
            const totalNos = rowData.TotalNo || 0;
            const newValueCompletedChecklist =
              rowData.TotalCompletedChecklist || 0;

            if (totalNos > 0) {
              rowData.MarksObtained =
                (newValueCompletedChecklist / totalNos) * newValue;
            } else {
              rowData.MarksObtained = 0;
            }

            const marksObtainedInput = $(td)
              .closest("tr")
              .find('input[name="MarksObtained"]');
            marksObtainedInput.val(rowData.MarksObtained.toFixed(2));

            handleInputChange(rowData);
          }
        });

        if (hideCondition) {
          $(td).css("visibility", "hidden");
        } else {
          $(td).html(inputElement);
        }
      },
    },
    {
      title: "Marks Obtained",
      data: "MarksObtained",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";
        const { checkAllApproved, checkAllAttended } = BillingSheetModel;
        let IsDisable =
          IsAllowToEnterData() && !checkAllApproved ? "" : "disabled";
        if (cellData <= 0) {
          const newValue = rowData.TotalCompletedChecklist || 0;
          const totalNos = rowData.TotalNo || 0;
          const maxMarksWeightage = parseFloat(rowData.MaxMarksWeightage) || 0;

          if (totalNos > 0) {
            rowData.MarksObtained = (newValue / totalNos) * maxMarksWeightage;
          } else {
            rowData.MarksObtained = 0;
          }
        }

        const inputElement = $(
          `<input type="number" class="form-control" ${IsDisable} name="MarksObtained" value="${rowData.MarksObtained.toFixed(
            2
          )}" data-id="${rowData.CheckListId}" data-field="MarksObtained"/>`
        ).on("change", function (e) {
          e.preventDefault();
          let updatedValue = parseFloat(e.target.value);

          rowData.MarksObtained = updatedValue;
          handleInputChange(rowData);
        });

        if (hideCondition) {
          $(td).css("visibility", "hidden");
        } else {
          $(td).html(inputElement);
        }
      },
    },
    {
      title: "Rate as per Contract (Rs)",
      data: "RateAsPerContract",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";
        const { checkAllApproved, checkAllAttended } = BillingSheetModel;
        let IsDisable =
          IsAllowToEnterData() && !checkAllApproved ? "" : "disabled";
        const inputElement = $(
          `<input type="number" class="form-control" name="RateAsPerContract" value="${cellData}" ${IsDisable} data-id="${rowData.CheckListId}" data-field="RateAsPerContract"/>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = parseFloat(e.target.value);

          if (!isNaN(newValue)) {
            rowData.RateAsPerContract = newValue;

            handleInputChange(rowData);
          }
        });

        if (hideCondition) {
          $(td).css("visibility", "hidden");
        } else {
          $(td).html(inputElement);
        }
      },
    },
    {
      title: "Amount",
      data: "Amount",
      createdCell: function (td, cellData, rowData) {
        const { checkAllApproved, checkAllAttended } = BillingSheetModel;
        let IsDisable =
          IsAllowToEnterData() && !checkAllApproved ? "" : "disabled";
        const inputElement = $(
          `<input type="number" class="form-control" name="Amount" ${IsDisable} value="${cellData}" data-id="${rowData.CheckListId}" data-field="Amount"/>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = parseFloat(e.target.value);

          if (!isNaN(newValue)) {
            rowData.Amount = newValue;

            handleInputChange(rowData);
          }
        });

        $(td).html(inputElement);
      },
    },
    {
      title: "Max Amount (Rs)",
      data: "MaxAmount",
      createdCell: function (td, cellData, rowData) {
        const { checkAllApproved, checkAllAttended } = BillingSheetModel;
        let IsDisable =
          IsAllowToEnterData() && !checkAllApproved ? "" : "disabled";
        const inputElement = $(
          `<input type="number" class="form-control" name="MaxAmount" value="${cellData}" ${IsDisable} data-id="${rowData.CheckListId}" data-field="MaxAmount"/>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = parseFloat(e.target.value);

          if (!isNaN(newValue)) {
            rowData.MaxAmount = newValue;

            handleInputChange(rowData);
          }
        });

        $(td).html(inputElement);
      },
    },

    {
      title: "Remarks of Vendor-L1",
      data: "VendorRemarks",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";
        const { checkAllApproved, checkAllAttended } = BillingSheetModel;
        let IsDisable =
          IsAllowToEnterData() && !checkAllApproved ? "" : "disabled";
        const inputElement = $(
          `<textarea  type="text" class="form-control" name ="VendorRemarks" data-id="${rowData.CheckListId}" ${IsDisable} data-field="VendorRemarks">${cellData}</textarea>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = e.target.value;

          rowData.VendorRemarks = newValue;

          handleInputChange(rowData);
        });

        if (hideCondition) {
          $(td).css("visibility", "hidden");
        } else {
          $(td).html(inputElement);
        }
      },
    },
    {
      title: "Remarks of CMRL-L2",
      data: "CMRLRemarks",
      createdCell: function (td, cellData, rowData) {
        const hideCondition = rowData.EquipmentShortName === "OTHERS";
        const { checkAllApproved, checkAllAttended } = BillingSheetModel;

        let IsDisable =
          (getUserDetails().RoleID == COOKIES.CMRL_L2ID) && !checkAllApproved ? "" : "disabled";
        const inputElement = $(
          `<textarea  type="text" class="form-control" name="CMRLRemarks" data-id="${rowData.CheckListId}" ${IsDisable} data-field="CMRLRemarks">${cellData}</textarea>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = e.target.value;

          rowData.CMRLRemarks = newValue;

          handleInputChange(rowData);
        });

        if (hideCondition) {
          $(td).css("visibility", "hidden");
        } else {
          $(td).html(inputElement);
        }
      },
    },
    {
      title: "Attachment",
      data: "CheckListId",
      width: "40px",

      createdCell: function (td, cellData, rowData, row, col) {
        let normalDiv = $('<div class="file-upload-div "> </div>');
        const { checkAllApproved, checkAllAttended } = BillingSheetModel;
         let IsDisable = (!checkAllApproved) ? "" : "disabled";
        let UploadIcon = $(
          `  <i class="fa fa-upload col-6 ${IsDisable}" style="font-size: 17px;"   aria-hidden="true"></i>`
        ).on("click", function (e) {
          var parentElement = $(e.target).closest(".file-upload-div");
          parentElement.find(".file-input").click();
        });
        var inputElement = $(`
          <input type="file" class="form-control hide file-input"  ${IsDisable} multiple value="${cellData}"/>`).on(
          "change",
          async function (e) {
            e.preventDefault();

            const formData = new FormData();
            $.each(e.target.files, function (i, file) {
              formData.append("files", file);
            });
            if (e.target?.files.length > 0) {
              let returnValue = await fileUpload(formData);
              if (returnValue) {
                rowData.Upload = JSON.stringify(returnValue);
                handleInputChange(rowData);
              }
            } else {
              rowData.Upload = "";
            }
          }
        );
        let IsDownloadEnable = rowData.DownloadFlag;
        let DownloadIcon = "";
        if (IsDownloadEnable) {
          DownloadIcon =
            $(`<button class='btn icon-btn text-center'data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'  >
          <i class='fa fa-download m-r-5'></i>
          </button>`).on("click", function () {
              _billServc
                .BillingSheet_Get_MaintaneceId(
                  rowData.CheckListId,
                  dateRange.fromDate,
                  dateRange.toDate
                )
                .then((response) => {
                  const maintenanceId =
                    response?.data?.data[0]?.BillingSheetMaintaneceId ?? 0;

                  DownloadFiles(maintenanceId, 0, 10);
                })
                .catch((error) => {
                  console.error(
                    "Error fetching BillingSheetMaintaneceId:",
                    error
                  );
                });
            });
        } else {
          DownloadIcon =
            $(`<button class='btn icon-btn text-center' disabled title='Download'  >
        <i class='fa fa-download m-r-5'></i>
        </button>`);
        }
        // if (IsAllowToEnterData()) {
        normalDiv.append(UploadIcon);
        //}
        normalDiv.append(inputElement);
        normalDiv.append(DownloadIcon);
        $(td).html(normalDiv);
      },
    },
    {
      title: "Status",
      data: "StatusName",
      width: "60px",
      createdCell: function (td, cellData, rowData, row, col) {
        if (rowData.EquipmentShortName === "OTHERS") {
          $(td).html("");
        } else {
          let span = $(`<span class="StatusCls"></span>`);
          span.append(GetPeriodicStatus(cellData));
          $(td).html(span);
        }
      },
    },
    {
      title: "Option",
      data: "CheckListId",
      visible: getPrivilege().includes("BILLINGSHEETEDIT"),

      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        if (getPrivilege().includes("BILLINGSHEETEDIT")) {
          b =
            $(`<button class='btn icon-btn' title='Edit' data-bs-toggle='modal'
          data-bs-target="#AddBillingSheetCheckList">
          <i class='fa fa-pencil m-r-5'></i>
        </button>`).on("click", function () {
              CheckListDetailGetByID(rowData.CheckListId);
            });
        }
        $(td).html(b);
      },
    },
  ];

  const ChildDataColumns = [
    { title: "CheckListId", data: "CheckListId", visible: false },
    { title: "Station Name", data: "StationCode" },
    {
      title: "",
      data: "StatusName",
      createdCell: function (td, cellData, rowData) {
        if (rowData.IsAttendance === 1 && AttendanceCount > 0) {
          cellData = "Approved";
        }

        const content =
          cellData === "Approved"
            ? '<span style="background-color: green; color: white; padding: 3px 6px; border-radius: 4px;">Completed</span>'
            : '<span style="background-color: red; color: white; padding: 3px 6px; border-radius: 4px;">InComplete</span>';
        if (rowData.IsHistoryCard == 1) {
          content = "";
        }
        $(td).html(content);
      },
    },
  ];

  function IsAllowToEnterData() {
    return getUserDetails().RoleID == COOKIES.EnggRoleID;
  }
  const DownloadFiles = (ticketID, AttachmetID, Flag) => {
    _breakdownService
      .GetFilesUsingTicketID(ticketID, AttachmetID, Flag)
      .then((res) => {
        setFileData(res.data);
      })
      .catch((err) => {
        setFileData([]);
        console.log(err);
      });
  };
  const CheckListDetailGetByID = (Id) => {
    _billServc
      .BillingCheckList_GetById(Id)
      .then((res) => {
        if (res?.data?.data?.length) {
          setBillingSheetDetails(res?.data?.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fileUpload = async (formData) => {
    showLoading();
    return await _breakdownService
      .FileUpload(formData)
      .then((res) => {
        hideLoading();
        showSuccessMsg("Files Uploaded SuccessFully.");
        return res;
      })
      .catch((err) => {
        hideLoading();
        showErrorMsg("Something Went Wrong! Please Try Again.");

        console.log(err);
        return 0;
      });
  };
  const ParentDataFunction = async () => {
    try {
      var FromDate = dateRange.fromDate || `${Year}-11-01`;
      var ToDate = dateRange.toDate || `${parseInt(Year) + 1}-01-31`;

      const parentData = await _billServc.BillingSheet_GetMaintenanceDetails(
        FromDate,
        ToDate
      );

      if (parentData?.data?.data?.billingSheetCheckList?.length) {
        const { billingSheetCheckList, attendance } = parentData?.data?.data;
        let checkAllAttended = billingSheetCheckList
          .slice(0,-1)
          .every((x) => x.StatusName === "Completed");

        let checkAllApproved = billingSheetCheckList
          .slice(0,-1)
          .every((x) => x.StatusName == "Approved");
        let checkAllRejected = billingSheetCheckList
          .slice(0,-1)
          .every((x) => x.StatusName == "Rejected");
        setAttendanceCount(attendance?.length);
        setBillingSheetModel((pre) => ({
          ...pre,
          checkAllApproved: checkAllApproved,
          checkAllAttended: checkAllAttended,
          checkAllRejected: checkAllRejected,
        }));
      }

      const updatedParentData = await Promise.all(
        parentData?.data?.data?.billingSheetCheckList.map(
          async (parentItem) => {
            const childData = await ChildDataFunction({
              CheckListId: parentItem.CheckListId,
            });

            var totalRow = childData?.length;
            var approvedCount = childData.filter(
              (item) => item.StatusName === "Approved"
            ).length;

            const IsCount = parentData?.data?.data?.attendance?.length;
            if (parentItem.IsAttendance === 1 && IsCount > 0) {
              childData.forEach((item) => {
                if (item.StatusName !== "Approved") {
                  item.StatusName = "Approved";
                }
              });
              approvedCount = childData.length;
            }

            return {
              ...parentItem,
              TotalNo:
                parentItem.TotalNo >= totalRow ? parentItem.TotalNo : totalRow,
              TotalCompletedChecklist:
                parentItem.TotalCompletedChecklist >= approvedCount
                  ? parentItem.TotalCompletedChecklist
                  : approvedCount,
            };
          }
        )
      );

      const totalAmount = updatedParentData.reduce((sum, item) => {
        if (item.EquipmentShortName === "OTHERS") {
          return (sum -= item.Amount);
        }
        return (sum += item.Amount);
      }, 0);

      setTotalAmount(totalAmount);

      setBillingSheetList(updatedParentData);
    } catch (error) {
      console.log(error);
    }
  };

  const ChildDataFunction = async ({ CheckListId }) => {
    try {
      var FromDate = dateRange.fromDate || `${Year}-11-01`;
      var ToDate = dateRange.toDate || `${parseInt(Year) + 1}-01-31`;

      const response = await _billServc.BillingSheet_GetJobApprovedStationList(
        CheckListId,
        FromDate,
        ToDate
      );
      return response?.data?.data || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleInputChange = async (obj, Status = 132) => {
    try {
      debugger;
      console.log(obj, "OBj");
      obj.Status = Status;
      obj.fromDate = dateRange.fromDate || `${Year}-11-01`;
      obj.toDate = dateRange.toDate || `${parseInt(Year) + 1}-01-31`;
      obj.CreatedBy = GetLoginUserID();
      const SaveDetails = await _billServc.BillingSheet_SaveMaintenanceDetails(
        obj
      );

      if (SaveDetails.data > 0) {
        ParentDataFunction();
        //toast.success("Saved Successfully");
      }
    } catch (error) {
      toast.error("Save Failed");
    }
  };
  const handleQuarterChange = (event) => {
    const selectedQuarter = event.target.value;
    setQuarter(selectedQuarter);
    updateDateRange(selectedQuarter, Year);
  };

  const handleYearChange = (selectedYear) => {
    setYear(selectedYear);
    updateDateRange(Quarter, selectedYear);
  };

  const updateDateRange = (quarter, year) => {
    let fromDate = "";
    let toDate = "";

    switch (quarter) {
      case "Nov-Jan(Q1)":
        fromDate = `${year}-11-01`;
        toDate = `${parseInt(year) + 1}-01-31`;
        break;
      case "Feb-Apr(Q2)":
        fromDate = `${year}-02-01`;
        toDate = `${year}-04-30`;
        break;
      case "May-Jul(Q3)":
        fromDate = `${year}-05-01`;
        toDate = `${year}-07-31`;
        break;
      case "Aug-Oct(Q4)":
        fromDate = `${year}-08-01`;
        toDate = `${year}-10-31`;
        break;
      default:
        break;
    }

    setDateRange({ fromDate, toDate });
  };

  function ApprovedBtn() {
    let tableValues = $("#BillingTable").DataTable().table().data().toArray();
    let currentDate = getYMDdate(new Date());
    let Q1 = `${parseInt(Year) + 1}-01-31`;
    let Q2 = `${Year}-04-30`;
    let Q3 = `${Year}-07-31`;
    let Q4 = `${Year}-10-31`;
    //if(currentDate == Q1 || currentDate==Q2 || currentDate==Q3 || currentDate==Q4){
    let ApproveBtn = $(
      `<button  class="btn btn-success disabled"
          >  Approve </button>  `
    ).on("click", async function (e) {
      e.preventDefault();
      if (tableValues?.length) {
        let rowData = tableValues[0];
        handleInputChange(rowData, 133);
        ParentDataFunction();
      }
      //  Submit_SaveCheckListDetails(46);
    });
    let RejectBTn = $(
      `<button  class="btn btn-danger m-l-15 m-r-10 disabled"
          >  Reject </button>  `
    ).on("click", async function (e) {
      e.preventDefault();

      if (tableValues?.length) {
        let rowData = tableValues[0];
        handleInputChange(rowData, 134);
        ParentDataFunction();
      }
    });

    let Fragment = $(`<span></span>`);

    if (getUserDetails().RoleID !== COOKIES.EnggRoleID) {
      Fragment.append(ApproveBtn);
      Fragment.append(RejectBTn);
    }
    const { checkAllAttended, checkAllApproved, checkAllRejected } =
      BillingSheetModel;

    if (checkAllAttended && getUserDetails().RoleID == COOKIES.CMRL_L2ID) {
      ApproveBtn.removeClass("disabled");
      RejectBTn.removeClass("disabled");
    }

    if (
      ((checkAllAttended || checkAllApproved || checkAllRejected) &&
        getUserDetails().RoleID != COOKIES.CMRL_L2ID &&
        getPrivilege().includes("BILLINGSHEETAPPROVAL")) ||
      checkAllApproved
    ) {
      RejectBTn.removeClass("disabled");
    }

    if (
      ((checkAllAttended || checkAllApproved || checkAllRejected) &&
        getUserDetails().RoleID != COOKIES.CMRL_L2ID &&
        getPrivilege().includes("BILLINGSHEETAPPROVAL")) ||
      checkAllRejected
    ) {
      ApproveBtn.removeClass("disabled");
    }

    if (
      (checkAllAttended || checkAllApproved || checkAllRejected) &&
      getUserDetails().RoleID != COOKIES.CMRL_L2ID &&
      getPrivilege().includes("BILLINGSHEETAPPROVAL") &&
      checkAllAttended
    ) {
      ApproveBtn.removeClass("disabled");
    }

    return Fragment;
    //}else{
    //return `<span></span>`
    //}
  }
  function SubmitBtn() {
    let tableValues = $("#BillingTable").DataTable().table().data().toArray();
    let SendForApprovelBtn = $(
      `<button id="submitButton" class="btn btn-primary m-l-15" >  Submit </button> `
    ).on("click", async function (e) {
      e.preventDefault();
      debugger;
      let everyapprove = tableValues
        .slice(0,-1)
        .every((x) => x.StatusName === "Completed");
         console.log(everyapprove,"everyapprove");
      if (tableValues?.length && everyapprove) {
        let rowData = tableValues[0];
        swal({
          title: "Are you sure?",
          text: `The selected date is not the last date of the quarter. Do you still want to submit?`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((IsSubmit) => {
          if (IsSubmit) {
            showSuccessMsg("Save Successfully");
            handleInputChange(rowData);
            ParentDataFunction();
          }
        });
      } else {
        showWarningMsg("Please completed all checklist.");
      }
    });
    const { checkAllAttended, checkAllApproved, checkAllRejected } =
      BillingSheetModel;
    let Fragment = $(`<span ></span>`);
    if (getUserDetails().RoleID === COOKIES.EnggRoleID) {
      Fragment.append(SendForApprovelBtn);
    }
    if (getUserDetails().RoleID === COOKIES.EnggRoleID && checkAllApproved) {
      SendForApprovelBtn.addClass("disabled");
    }
    if (getUserDetails().RoleID === COOKIES.EnggRoleID && checkAllRejected) {
      SendForApprovelBtn.removeClass("disabled");
    }
    return Fragment;
  }

  return (
    <div>
      <div className="page-header mb-3">
        <div className="row justify-content-between">
          <div className="col-6">
            <h3 className="page-title">Billing Sheet</h3>
          </div>
          <div className="col-6" style={{ width: "11%" }}>
            {BillingSheetModel.checkAllApproved ||
            BillingSheetModel.checkAllRejected ? (
              <Link to="/BillingSheetReportExl">
                {" "}
                <button className="btn btn-secondary">Go to Report</button>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <label className="form-check-label">Select</label>
          <div>
            <select
              onChange={handleQuarterChange}
              value={Quarter}
              className="form-select"
            >
              <option>Nov-Jan(Q1)</option>
              <option>Feb-Apr(Q2)</option>
              <option>May-Jul(Q3)</option>
              <option>Aug-Oct(Q4)</option>
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <label className="form-check-label">Year</label>
          <FilterDropdown
            code={"Year"}
            name={"Year"}
            onChange={(e) => handleYearChange(e.target.value)}
            value={Year}
          />
        </div>
        <div className="col-md-3">
          <label className="form-check-label">Total Amount</label>
          <input
            type="number"
            className="form-control"
            value={TotalAmount}
            disabled
          />
        </div>
        {getPrivilege().includes("BILLINGSHEETCREATE") ? (
          <div className="col-3">
            <button
              type="button"
              className="btn add-btn"
              data-bs-toggle="modal"
              data-bs-target="#AddBillingSheetCheckList"
              style={{ marginTop: "20px" }}
            >
              <i className="fa fa-plus"></i> Add
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="card card-body mt-3">
        <TreeTable
          columnList={columns}
          rowList={BillingSheetList}
          id={"BillingTable"}
          title={"Billing Sheet"}
          childColumns={ChildDataColumns}
          ChildApiFunction={ChildDataFunction}
          SearchPrependHtml={ApprovedBtn}
          SearchPostponedHtml={SubmitBtn}
          isAsc
        />
      </div>
      <BootrapModel
        Id={"AddBillingSheetCheckList"}
        CloseID="CloseBillingSheetCheckList"
        ModelTitle={`BillingSheet CheckList Creation`}
        modelSize={"modal-xl"}
        closepopup={() => {
          ParentDataFunction();
          setBillingSheetDetails([]);
        }}
        module={
          <BillingSheetSaveDetail BillingSheetList={BillingSheetDetails} />
        }
      />

      <BootrapModel
        Id={"DownLoadFiles"}
        CloseID="DownLoadFilesClose"
        ModelTitle={"Download Attachments"}
        modelSize={"modal-xl"}
        module={
          <DownloadModel
            Flag={5}
            DeleteAttachment={DownloadFiles}
            FileDatas={FileData}
            // DownLoadRefresh={DownLoadRefresh}
          ></DownloadModel>
        }
      >
        {" "}
      </BootrapModel>
    </div>
  );
}
