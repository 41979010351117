import { useEffect, useState, useContext } from "react";
import { PageHeader } from "../../components/PageHeader";
import {
  Dropdown,
  ReactMultiSelect,
  CheckListHtmlTable,
} from "../../components";
import { AppContext } from "../../services/ContextProvider";
import Global, { COOKIES } from "../../services/Global";
import $ from "jquery";
import {
  getPrivilege,
  Decrypt,
  getScheduleType,
} from "../../services/CommonService";
import { logo } from "../Image";
import moment from "moment";
import {
  PerformanceEvaluation_DropDownDetails,
  PerformanceEvaluation_ReportGetByID,
} from "./PerformanceEvaluationService";
import { makeUnique } from "../../services/CommonService";
export default function PerformanceEvaluationReport() {
  const todayDate = new Date();
  const initialEquipmentState = {
    PerfomanceID: [],
    EquipmentID: {},
    StationID: "",
    StationName: "",
    DescriptionName: "",
    Unit: "",
    OrderNo: 0,
    IsDeleted: false,
    FromDate: "",
    ToDate: "",
    YearID: {},
    QuarterID: {},
    ScheduleTypeIsDisabled: false
  };
  const { getCookies, showLoading, hideLoading, handleGlobalStationName } =
    useContext(AppContext);
  const [PerformanceModel, setPerformanceModel] = useState(
    initialEquipmentState
  );
  const [EquipmentTagNumberList, setEquipmentTagNumberList] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [RowList, setRowList] = useState([]);
  const [TopHeaderColumns, setTopHeaderColumns] = useState([]);

  const [StationList, setStationList] = useState([]);
  const [YearList, setYearList] = useState([]);
  const [QuarterList, setQuarterList] = useState([
    { label: "Q1 - (Nov - Jan)", Value: 75 },
    { label: "Q2 - (Feb - Apr)", Value: 76 },
    { label: "Q3 - (May - Jul)", Value: 77 },
    { label: "Q4 - (Aug - Oct)", Value: 78 },
  ]);
  const [DesList, setDesList] = useState([]);

  useEffect(() => {
    initDropDown();
  }, []);
  useEffect(() => {
    initialEquipmentCode();
  }, [PerformanceModel.StationID]);
  useEffect(() => {
    GetDescriptionByID();
  }, [JSON.stringify(PerformanceModel)]);

  const initDropDown = async () => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    let ignoredStation = ["stc", "str", "swa"];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });

    setStationList([...FinalStationList]);

    const CurrentYear = new Date().getFullYear();
    const StartYear = 2022;
    const ListOfYear = [
      ...Array.from({ length: CurrentYear - 2021 }, (_, index) => ({
        label: StartYear + index,
        Value: StartYear + index,
      })),
    ].reverse();
    setYearList(ListOfYear);
    let CurrentQuarter = QuarterList.find(
      (ele) => ele.Value == getScheduleType()
    );

    setPerformanceModel((pre) => ({
      ...pre,
      StationID: FinalStationList[0]?.value ?? 0,
      YearID: ListOfYear[0] ?? {},
      QuarterID: CurrentQuarter,
      StationName: FinalStationList[0]?.key,
    }));
    handleGlobalStationName(FinalStationList[0]?.key ?? "");
  };
  const initialEquipmentCode = async () => {
    if (PerformanceModel.StationID > 0) {
      await PerformanceEvaluation_DropDownDetails(
        PerformanceModel.StationID
      ).then((res) => {
        setEquipmentTagNumberList(res.data.data);
        setPerformanceModel((pre) => ({
          ...pre,
          EquipmentID: res.data.data[0],
        }));
      });
    }
  };
  const GetDescriptionByID = async () => {
    if (
      (PerformanceModel.StationID &&
        PerformanceModel.EquipmentID?.Value &&
        PerformanceModel.FromDate,
        PerformanceModel.YearID?.Value)
    ) {
      const { YearID, QuarterID } = PerformanceModel;

      await PerformanceEvaluation_ReportGetByID(
        PerformanceModel.StationID,
        PerformanceModel.EquipmentID.Value ?? 0,
        PerformanceModel.FromDate,
        PerformanceModel.ToDate,
        YearID.Value ?? 0,
        QuarterID.Value ?? 0
      )
        .then((res) => {
          if (res?.data?.data) {
            let columnsList = [];
            columnsList.push({
              title: " S.No",
              data: "RowID",
              width: "10px",
              // ClassName : "hide",
              // visible: false
            });
            columnsList.push({
              title: `<p style = 'width : 150px'> Description of Work<p/>`,
              data: "DescriptionName",
              width: "150px ",
            });

            const { descriptionList, equipmentList } = res.data.data;
            if (descriptionList?.length) {
              setDesList([...descriptionList])
              let uniqueList = makeUnique(equipmentList, [
                "EquipmentCode",
                "EquipmentDetailID",
              ]);
              if (
                uniqueList &&
                uniqueList[0].EquipmentCode &&
                uniqueList[0].EquipmentCode.includes("CT")
              ) {
                const { EquipmentDetailID, EquipmentCode } = uniqueList[0];

                columnsList.push({
                  title: `<p style = 'width : 150px'> Rated <p/>`,
                  data: `Rated_${EquipmentDetailID}`,
                  width: "150px ",
                });
              }
              let EquipmentHeaders = [];
              EquipmentHeaders.push({
                title: "",
                colspan: 2,
              });
              uniqueList &&
                uniqueList[0].EquipmentCode &&
                uniqueList[0].EquipmentCode.includes("AHU")
                ? uniqueList?.forEach((element, index) => {
                  const { EquipmentCode, EquipmentDetailID } = element;
                  EquipmentHeaders.push({
                    title: `${EquipmentCode}`,
                    colspan: 2,
                  });
                  columnsList.push({
                    title: "Rated",
                    data: `Rated_${EquipmentDetailID}`,
                  });
                  columnsList.push({
                    title: "Actual",
                    data: `Actual_${EquipmentDetailID}`,
                  });
                })
                : uniqueList?.forEach((element, index) => {
                  const { EquipmentCode, EquipmentDetailID } = element;
                  columnsList.push({
                    title: EquipmentCode,
                    data: `Actual_${EquipmentDetailID}`,
                  });
                });
              EquipmentHeaders.push({
                title: "",
                colspan: 1,
              });
              columnsList.push({
                title: "Remarks",
                data: "RemarksByTechnician",
                width: "60px",
              });
              descriptionList.forEach((element) => {
                let filList = equipmentList.filter(
                  (x) =>
                    x.PerformanceEvaluationID == element.PerformanceEvaluationID
                );
                filList.forEach((y) => {
                  let column_name = y.EquipmentDetailID.toString();
                  element[`Actual_${column_name}`] = y.ActualValue;
                  element[`Rated_${column_name}`] = y.RatedValue;
                });
              });

              let FinalRowObj = descriptionList.sort(
                (a, b) => a.RowID - b.RowID
              );
              setTopHeaderColumns(EquipmentHeaders);
              setColumnList([...columnsList]);
              setRowList([...FinalRowObj]);
              console.log(columnsList, FinalRowObj[0])
            } else {
              setColumnList([]);
              setRowList([]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setColumnList([]);
      setRowList([]);
    }
  };
  function CustomTopHeader(table) {
    let thead = $(table?.table()?.header());
    if (thead?.length) {
      let headerRow1 = $('<tr class="header-row">');
      TopHeaderColumns.forEach((column) => {
        headerRow1.append(
          `<th style="border-right: 2px solid silver; border-left: 1px solid silver;" >${column.title}</th>`
        );
      });
      let headerRow2 = $('<tr class="header-row">');
      TopHeaderColumns.forEach((data) => {
        if (!data?.title) {
          headerRow2.append(
            `<th colspan="${data.colspan}" style="text-align: center; border-right: 1px solid silver; border-left: 1px solid silver;"></th>`
          );
        } else {
          headerRow2.append(
            `<th colspan="${data.colspan}" style="text-align: center;  border-right: 1px solid silver; border-left: 1px solid silver;">${data.title}</th>`
          );
        }
      });
      thead.prepend(headerRow2);
    }
  }
  const CommonHandleChange = (event, name) => {
    if (Object.hasOwn(event, "target")) {
      const { name, value } = event.target;

      setPerformanceModel((PreviousState) => ({
        ...PreviousState,
        [name]: value,
      }));
      if (name == "StationID") {
        let Station = StationList.find((x) => x.value == value);

        handleGlobalStationName(Station?.key ?? "");
        setPerformanceModel((pre) => ({
          ...pre,
          StationName: Station?.key,
        }));
      } else if (name == "FromDate" || name == "ToDate") {

        setPerformanceModel((PreviousState) => ({
          ...PreviousState,
          ScheduleTypeIsDisabled: value ? true : false,
        }));
      }
    } else {
      setPerformanceModel((PreviousState) => ({
        ...PreviousState,
        [name]: event,
      }));
    }
  };
  const PDfCustomization = (doc) => {
    let TopHeader = JSON.parse(JSON.stringify(TopHeaderColumns));
    let TopHead = [];

    TopHeader.forEach((ele) => {
      let SampleEle = {
        text: ele.title,
        colSpan: ele.colspan,
      };
      TopHead.push(SampleEle);
      for (let i = 0; i < ele.colspan - 1; i++) {
        TopHead.push("");
      }
    });
    PerformanceModel?.EquipmentID?.Value == 2
      ? doc.content[1].table.body.unshift(TopHead)
      : (doc.content[1].table.widths = "*");
    doc["header"] = function (page, pages) {
      return {
        stack: [
          {
            columns: [
              {
                table: {
                  widths: [200, 200, 150],

                  body: [
                    [
                      {
                        text: `Document No : ${PerformanceModel?.EquipmentID?.DocumentNo ?? ''} \n Rev: 01`,
                        alignment: "left",
                        margin: [0, 20, 0, 0],
                      },
                      {
                        text: `Performance Evaluation of ${PerformanceModel?.EquipmentID?.label}`,
                        // rowSpan: 2,
                        alignment: "center",
                        margin: [0, 23, 0, 0],
                      },
                      {
                        image: logo,
                        // rowSpan: 2,
                        alignment: "center",
                        width: 50,
                        margin: [0, 5, 0, 5],
                      },
                    ],
                  ],
                },
                margin: [18, 10, 0, 0],
              },
            ],
          },

          {
            columns: [
              {
                text: `Station Name: ${PerformanceModel.StationName ?? ""}`,
                alignment: "left",
                margin: [18, 10, 0, 0],
              },
              {
                text: "Date :" + moment().format("DD-MM-YYYY"),
                alignment: "right",
                margin: [0, 10, 25, 0],
              },
            ],
          },
        ],
        columnGap: 10,
      };
    };
    doc.pageSize = "LEGAL";
    let TechnicianRow = [];
    let EngineerRow = [];

    TechnicianRow.push({
      text: "Name of the Shift Technician",
    });
    EngineerRow.push({
      text: "Name of the Shift Approver/Engineer",
    });
     ;
    console.log(DesList);
    let ShiftTechnician = DesList?.[0]?.ShiftTechnician;
    let ShiftEngineer = DesList?.[0]?.ShiftEngineer;

    let headerRow = [...doc.content[1].table.body[0]];
    headerRow = headerRow.slice(1); // Remove the first element (likely the index or serial number)
    headerRow.forEach(() => {
      TechnicianRow.push({
        text: ShiftTechnician,
        colSpan: headerRow.length,
        alignment: "center",
      });
      EngineerRow.push({
        text: ShiftEngineer,
        colSpan: headerRow.length,
        alignment: "center",
      });
    });


    doc.content[1].table.body.push(TechnicianRow);
    doc.content[1].table.body.push(EngineerRow);
  };

  return (
    <div>
      <PageHeader title={`Performance Evaluation Report`}> </PageHeader>
      <form id="ReportCheckList">
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Station Name
              </label>
              <Dropdown
                data={StationList}
                placeholder="Select"
                name={"StationID"}
                HandleChange={CommonHandleChange}
                value={PerformanceModel.StationID}
                className=""
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Equipment
              </label>
              <ReactMultiSelect
                options={EquipmentTagNumberList}
                placeholder="Select"
                value={PerformanceModel.EquipmentID}
                onChange={(e) => {
                  CommonHandleChange(e, "EquipmentID");
                }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Year
              </label>
              <ReactMultiSelect
                options={YearList}
                placeholder="Select"
                value={PerformanceModel.YearID}
                isDisabled={PerformanceModel.ScheduleTypeIsDisabled}
                onChange={(e) => {
                  CommonHandleChange(e, "YearID");
                }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="label">Quarter</label>
              <ReactMultiSelect
                options={QuarterList}
                placeholder="Select"
                value={PerformanceModel.QuarterID}
                isDisabled={PerformanceModel.ScheduleTypeIsDisabled}
                onChange={(e) => {
                  CommonHandleChange(e, "QuarterID");
                }}
              />
            </div>
          </div>

          <div className="col-2">
            <label className="label" for="">
              From Date{" "}
            </label>

            <input
              type="date"
              className="form-control"
              id="FromDate"
              name="FromDate"
              pattern="\d{4}-\d{2}-\d{2}"
              value={PerformanceModel.FromDate}
              onChange={(e) => {
                CommonHandleChange(e);
              }}
            />
          </div>
          <div className="col-2">
            <label className="label" for="">
              To Date{" "}
            </label>

            <input
              type="date"
              className="form-control"
              value={PerformanceModel.ToDate}
              id="ToDate"
              name="ToDate"
              onChange={(e) => {
                CommonHandleChange(e);
              }}
            />
          </div>
        </div>
      </form>
      {columnList?.length ? (
        <>
          <div style={{ marginTop: "-25px" }}>
            <CheckListHtmlTable
              IsASC
              IsShowPDF
              title="Performance Evaluation"
              rowList={RowList}
              columnList={columnList}
              // pdfHeading={`Performance Evaluation of ${PerformanceModel?.EquipmentID?.label}`}
              id={`ReportPerformanceTable`}
              StationName={PerformanceModel.StationName ?? ""}
              EquipmentName={PerformanceModel.EquipmentName ?? ""}
              AdditionalPlugin={
                PerformanceModel?.EquipmentID?.Value == 2
                  ? CustomTopHeader
                  : () => { }
              }
              PDfCustomization={PDfCustomization}
            // DocumentNo={PerformanceModel?.EquipmentID?.DocumentNo ?? ""}
            />
          </div>
        </>
      ) : (
        <div className="row col-12 text-center  mb-2">
          <p className="text-muted"></p>
        </div>
      )}
    </div>
  );
}
