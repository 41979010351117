import React from "react";
import {
  Dropdown,
  ReactMultiSelect,
  HtmlTable,
  showWarningMsg,
  showSaveSuccess,
  showErrorMsg,
  showSaveError,
  CheckListHtmlTable,
  PageHeaderTitle,
} from "../../components";
import {
  getPrivilege,
  Decrypt,
  getYMDdate,
  GetLoginUserID,
  getUserDetails,
  addDays,
  validateEmail,
  DeepCopy,
  tableLayout,
} from "../../services/CommonService";
import { useState, useEffect, useContext } from "react";
import { AppContext } from "../../services/ContextProvider";
import Global, { COOKIES } from "../../services/Global";
// import {
//   EquipmentRunning_DropDownDetails,
//   EquipmentEnergyConsumption_SaveConfig,
//   EquipmentEnergyConsumption_GetEquipmentDetails,
//   EquipmentEnergyConsumption_DetailsSaveConfig,
//   EquipmentEnergyConsumption_GetDocumentID,
// } from "./ManualLogService";
import $ from "jquery";
import swal from "sweetalert";
import moment from "moment";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";
import {
  EquipmentEnergyConsumption_DetailsSaveConfig,
  EquipmentEnergyConsumption_GetDocumentID,
  EquipmentEnergyConsumption_GetEquipmentDetails,
} from "../ManualLog/ManualLogService";
import { DataTableWithAjax } from "../../components/DataTableWithAjax/DataTableWithAjax";
import { DataTableReportAjax } from "../../components/DataTableReportAjax";
import { BASE_URL } from "../../env";
import { logo, SteringLogo } from "../Image";
import { RiTextWrap } from "react-icons/ri";

export default function CriticalEquipmentRoomTemperatureMonitoringReport() {
  const [StationList, setStationList] = useState([]);
  const { getCookies, showLoading, hideLoading, handleGlobalStationName } =
    useContext(AppContext);
  const [StationId, setStationId] = useState();
  const [fromDate, setFromDate] = useState(getYMDdate());
  const [documnetNo, setDocumentNo] = useState();
  //const [RowList, setRowList] = useState([]);
  const [stationCode, setStationCode] = useState();
  useEffect(() => {
    initialDropDown();

    return () => {
      setStationList([]);
    };
  }, []);
  const columnNames = [
    {
      title: "S.No",
      data: "RowID",
      // render: function (data, type, row, meta) {
      //   return meta.row + 1;
      // },
      // orderable: false,
    },
    { title: "Room Name", data: "RoomName" },
    {
      title: `UOM\n(Deg C)`,
      data: "UOM",
    },
    {
      title: "00.00 \n (DBT)",
      data: "Time_1",
    },
    {
      title: "03:00 \n (DBT)",
      data: "Time_2",
    },
    {
      title: "03:00 \n (WBT)",
      data: "Time_3",
    },
    {
      title: "06.00 \n (DBT)",
      data: "Time_4",
    },
    {
      title: "09:00 \n (DBT)",
      data: "Time_5",
    },
    {
      title: "12:00 \n (DBT)",
      data: "Time_6",
    },
    {
      title: "12.00\n(WBT)",
      data: "Time_7",
    },
    {
      title: "15:00\n(DBT)",
      data: "Time_8",
    },
    {
      title: `18:00\n(WBT)`,
      data: "Time_9",
    },
    {
      title: `21.00\n(DBT)`,
      data: "Time_10",
    },
    {
      title: `21:00\n(WBT)`,
      data: "Time_11",
    },
    {
      title: `24:00\n(DBT)`,
      data: "Time_12",
    },

    {
      title: `Status`,
      data: "StatusName",
    },
  ];

  const initialDropDown = async () => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    // let ignoredStation = ["stc", "str", "swa"];
    let ignoredStation = [""];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation?.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });


    await EquipmentEnergyConsumption_GetDocumentID()
      .then((res) => {
        if (res?.data?.data) {
          const { data } = res.data;
          let document = data.find(
            (x) =>
              x.ModuleName == "CRITICAL_EQUIPMENT_ROOM_TEMPERATURE_CHECKLIST"
          );
          setDocumentNo(document.DocumentNo);
          console.log(document.DocumentNo, "DocumentNo");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    handleGlobalStationName(FinalStationList[0]?.key ?? "");
    setStationId(FinalStationList[0]?.value ?? 0);
    setStationList([...FinalStationList]);
  };

  const CommonHandleChange = (event) => {
    if (Object.hasOwn(event, "target")) {
      const { name, value } = event.target;

      if (name == "StationID") {
        let currentStation = StationList.find((x) => x.value == value);
        handleGlobalStationName(currentStation?.key ?? "");
        setStationId(value);
        setStationCode(currentStation.key);
      }
      if (name == "FromDate") {
        setFromDate(value);
      }
    }
  };

  const PDfCustomization = (doc) => {
    let array = JSON.parse(JSON.stringify(doc.content[1].table.body));
    let header = DeepCopy(doc.content[1].table.body[0]);

    array = array.slice(1);
    let listsByDate = {};
    // Group items by date
    array.forEach(function (item) {
      let dateCheck = item[item.length - 1].text; // Assuming index 8 contains the dateCheck text field
      if (!listsByDate[dateCheck]) {
        listsByDate[dateCheck] = [DeepCopy(header)]; // Create a new list if it doesn't exist
      }
      listsByDate[dateCheck].push(item);
    });

    // Convert the object of lists into an array
    let mainList = Object.keys(listsByDate).map(function (dateCheck) {
      return {
        date: dateCheck,
        items: listsByDate[dateCheck].map(function (item) {
          return item
        }),
      };
    });

    let addDateandStation = (date) => {
      let SelectedStation = StationList?.find(
        (x) => x.value == StationId
      );
      return {
        columns: [
          {
            text: `Station Name: ${SelectedStation?.key ?? ''}`,
            alignment: "left",
            margin: [0, 10, 0, 6],
          },
          {
            text: "Date:" + moment(fromDate).format("DD-MM-YYYY"),
            alignment: "right",
            margin: [0, 10, 0, 6],
          },
        ],
      };
    };

    let addStartHeader = () => [
      {
        columns: [
          {
            table: {
              widths: [200, 200, 150], // Adjust widths as needed
              layout: {
                vLineWidth: 0.5,
                hLineWidth: 0.5,
              },
              body: [
                [
                  {
                    text: [
                      {
                        text: `Doc no: ${documnetNo}`,
                        alignment: "left",
                        margin: [0, 20, 0, 0],
                      },
                      {
                        text: `\nRev: 01`,
                        alignment: "left",
                        margin: [0, 0, 0, 0],
                      },
                    ],
                    //border: [false, false, false, false] // remove border if not needed
                  },
                  {
                    text: [
                      {
                        text: "CRITICAL EQUIPMENT ROOM TEMPERATURE MONITORING REPORT",
                        alignment: "center",
                        margin: [0, 23, 0, 0],
                      },
                    ],
                    //border: [false, false, false, false], // remove border if not needed
                  },
                  {
                    //alignment: "center",
                    margin: [0, 5, 0, 5],
                    columns: [
                      // {
                      //   image: SteringLogo, // Ensure the logo path is correct
                      //   width: 50,
                      //   margin: [0, 0, 0, 5],
                      // },
                      {
                        text: [
                          {
                            text: `CMRL –UG Stations(Phase – I)`,
                            alignment: "left",
                            margin: [0, 20, 0, 0],
                          },
                        ],
                        margin: [0, 12, 0, 0], // Adjust margins as needed
                        //border: [false, false, false, false] // remove border if not needed
                      },
                    ],
                  },
                ],
              ],
            },
            margin: [18, 10, 0, 0],
          },
        ],
      },
    ];
    doc.content[0] = ""
    const table = DeepCopy(doc.content[1]);
    let n = 0;

    doc.header = function (page, pages) { };
    for (let i = 1, j = 0; i < mainList.length * 3; i = i + 3, j++) {
      let TechnicianRow = [];
      let EngineerRow = [];

      let copiedTable = DeepCopy(table);
      copiedTable.headerRows = 1;
      doc.content[i] = addDateandStation(formatDate(mainList[j].date));
      doc.header = addStartHeader();
      copiedTable.layout = tableLayout();
      copiedTable.table.body = mainList[j].items;
      //copiedTable.table.widths = [30,80,80,80,80,80,80]
      doc.content.push(copiedTable);

      copiedTable.table.body.forEach((ele, index) => {
        if (index != 0) ele[0].text = index;
      });

      let headerArray = copiedTable.table.body[0];
      TechnicianRow.push({
        text: "Name of the Shift Technician",
        colSpan: 2,
      });

      EngineerRow.push({
        text: "Name of the Shift Engineer",
        colSpan: 2,
      });

      if (j !== mainList.length - 1) {
        doc.content.push({ text: "", pageBreak: "before" });
      }
    }
  };

  function formatDate(datetimeString) {
    return datetimeString.split("T")[0];
  }
  return (
    <div>
      <div class="page-header mb-3">
        <div class="row justify-content-between">
          <div class="col-12">
            <h3 class="page-title">
              Critical Equipment Room Temperature Monitoring Report
            </h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <div className="form-group">
            <label className="label" for="empCode">
              Station Name
            </label>
            <Dropdown
              data={StationList}
              placeholder="Select"
              name={"StationID"}
              HandleChange={CommonHandleChange}
              value={StationId}
              className=""
            />
          </div>
        </div>
        <div className="col-2">
          <label className="label" for="">
            From Date{" "}
          </label>

          <input
            type="date"
            className="form-control"
            id="FromDate"
            name="FromDate"
            pattern="\d{4}-\d{2}-\d{2}"
            value={fromDate}
            onChange={(e) => {
              CommonHandleChange(e);
            }}
            max={getYMDdate(new Date())}
          />
        </div>
      </div>
      <div className="card card-body">
        <DataTableReportAjax
          StationID={StationId ?? 0}
          FromDate={fromDate}
          columnList={columnNames}
          title="Critical Equipment Room Temperature Monitoring Report"
          url={`${window.BASE_URL}ManualLog/CriticalEquipmentRoomTemperatureMonitoring_Report`}
          id={"CriticalEquipmentRoomTemperatureMonitoringReport"}
          pdfHeading={`Critical Equipment Room Temperature Monitoring Report`}
          pageLength={10}
          PDFCustomization={PDfCustomization}
        />
      </div>
    </div>
  );
}
