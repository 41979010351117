import React, { Component } from "react";
import $ from "jquery";
import { BASE_URL } from "../../env";
import { UPLOAD_URL } from "../../env";
class PictureUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      picture: true,
      src: UPLOAD_URL + props.images,
      width: window.innerWidth,
    };
    this.renderPreview();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.setState({ src: UPLOAD_URL + this.props.images });
    //this.renderPreview();
  }

  //   componentWillMount() {
  //     // Perform the required
  //     // activity inside it
  //     console.log('inside willmount');
  //     alert("inside will mount"+this.props.images)
  //     this.renderPreview();
  // }

  componentDidUpdate(prv) {
    if (this.props.images != prv.images) {
      this.componentDidMount();
    }
    if (this.props.Refresh != prv.Refresh) {
      this.componentDidMount();
    }
    if (this.props.reset != prv.reset) {
      this.setState({ src: UPLOAD_URL + "default.jpg" });
    }
  }

  handlePictureSelected(event) {
    $("#hidFlag").val(1);
    var picture = event.target.files[0];
    var src = URL.createObjectURL(picture);

    this.setState({
      picture: picture,
      src: src,
    });
  }

  renderPreview() {
    if (this.state.src) {
      return (
        <a href="#" id="openDiv">
          <img
            id="ProfilePic"
            style={{ float: "right" }}
            //style={this.state.width  > 800 ? {float: 'right',marginTop:-51,marginRight:-100} : {marginLeft:100}}
            width="250px"
            height="220px"
            src={this.state.src}
          />
        </a>
      );
    }
  }

  upload() {
    var formData = new FormData();

    formData.append("file", this.state.picture);

    $.ajax({
      url: window.BASE_URL + "Employee/ImportFile/",
      async: false,
      method: "POST",
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      cache: false,
      contentType: false,
      processData: false,
      success: function (response) {
        $("#hidPath").val(response);
      },
    });
  }

  render() {
    return (
      <div id="divImg">
        <input
          type="file"
          id="fileUpload"
          style={{ display: "none" }}
          onChange={this.handlePictureSelected.bind(this)}
          accept="image/png, image/gif, image/jpeg"
        />

        <br />

        {this.renderPreview()}

        <button
          id="btnUpload"
          style={{ display: "none" }}
          onClick={this.upload.bind(this)}
        >
          Upload
        </button>
      </div>
    );
  }
}

export default PictureUploader;
