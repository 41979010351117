import React, { useEffect, useContext } from "react";
import {
  HalfYearlyMOnthForPM,
  QuarterMOnthForPM,
  YearlyMOnthForPM,
  generateQuarters,
  getYMDdate,
} from "../../services/CommonService";
import { PeriodicMaintenance_GetPendingColor } from "./PeriodicService";
import { Calendar, globalizeLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import globalize from "globalize";

import { Decrypt } from "../../services/CommonService";
import { useNavigate } from "react-router-dom";
import { AppContext } from "./../../services/ContextProvider";
import Global, { COOKIES, MaintenanceTypes } from "../../services/Global";

import CalendarMonthView from "./CalendarMonthView";
import CalenderQuaterView from "./CalenderQuaterView";
import CalendarHalfyearlyView from "./CalendarHalfyearlyView";
import CalenderYearlyView from "./CalenderYearlyView";
import { ContextValues } from "./PeriodicContextProvider";
import { Dropdown, ReactMultiSelect } from "../../components";
import moment from "moment";
// import CalendarWeeklyView from "./CalendarWeeklyView";
import { TbChecklist } from "react-icons/tb";

const localizer = globalizeLocalizer(globalize);

export const PeriodicMaintance = () => {
  const navigate = useNavigate();
  const {
    getCookies,
    handleGlobalStationName,
    showLoading,
    hideLoading,
    GetLoginStationTypes,
    GetLoginStationBaseOnDep,
    //  GetLoginStationList,
  } = useContext(AppContext);

  const {
    setPeriodicModel,
    PeriodicModel,
    DropdownModel,
    MonthConfigObj,
    setDropdownModel,
  } = ContextValues();

  useEffect(() => {
    getStationList();
    return () => { };
  }, []);

  useEffect(() => {
    const buttons = document.querySelectorAll("button"); // Select all buttons

    buttons.forEach((button) => {
      if (button.textContent === "Back") {
        button.style.float = "left";
      }
    });
    setPeriodicModel((pre) => ({
      ...pre,
      CalSrtDate: getYMDdate(),
    }));
  }, []);

  useEffect(() => {
    GetPeriodicPendingStatus();
  }, [
    PeriodicModel.DepartmentID,
    PeriodicModel.StationID?.value,
    PeriodicModel.CalSrtDate,
    PeriodicModel.CurrentView,
  ]);

  const GetPeriodicPendingStatus = async () => {
    const { CalSrtDate, StationID, DepartmentID, CurrentView, IsAPICallNeed } =
      PeriodicModel;

    let ScheduleType = MaintenanceTypes[CurrentView ?? "month"];
    let FromDate = "";
    let ToDate = "";

    if (ScheduleType == 1 || ScheduleType == 2) {
      // Monthly handling

      let ConDate = new Date(CalSrtDate);
      FromDate = new Date(ConDate.getFullYear(), ConDate.getMonth(), 1);
      ToDate = moment(FromDate).add("M", 1).add("d", -1).format("YYYY-MM-DD");
      FromDate = moment(FromDate).format("YYYY-MM-DD");
    } else if (ScheduleType == 3) {
      // Quarterly handling
      let ConDate = new Date(CalSrtDate);
      const { Date: NewDate, NeedToCall } = QuarterMOnthForPM(
        ConDate,
        MonthConfigObj
      );
      FromDate = NewDate;

      if (FromDate) {
        FromDate.setDate(1);
        FromDate = moment(FromDate).format("YYYY-MM-DD");
        ToDate = new Date(FromDate);
        ToDate = moment(ToDate).add("M", 1).add("d", -1).format("YYYY-MM-DD");
      }
    } else if (ScheduleType == 4) {
      // Half-yearly handling
      let ConDate = new Date(CalSrtDate);
      const { Date: TempDate } = HalfYearlyMOnthForPM(ConDate, MonthConfigObj);
      FromDate = TempDate;
      if (FromDate) {
        FromDate.setDate(1);
        FromDate = moment(FromDate).format("YYYY-MM-DD");
        ToDate = new Date(FromDate);
        ToDate = moment(ToDate).add("M", 1).add("d", -1).format("YYYY-MM-DD");
      }
    } else if (ScheduleType == 5) {
      // Yearly handling
      let ConDate = new Date(CalSrtDate);
      FromDate = YearlyMOnthForPM(ConDate, MonthConfigObj);
      if (FromDate) {
        FromDate.setDate(1);
        FromDate = moment(FromDate).format("YYYY-MM-DD");
        ToDate = new Date(FromDate);
        ToDate = moment(ToDate).add("M", 1).add("d", -1).format("YYYY-MM-DD");
      } else if (ScheduleType == 6) {
        // Weekly handling
        let ConDate = new Date(CalSrtDate);
        const dayOfWeek = ConDate.getDay(); // Get current day of the week (0 = Sunday, 1 = Monday, etc.)
        FromDate = new Date(ConDate);
        FromDate.setDate(ConDate.getDate() - dayOfWeek); // Set to the start of the week (Sunday)
        ToDate = new Date(FromDate);
        ToDate.setDate(FromDate.getDate() + 6); // Set to the end of the week (Saturday)
        FromDate = moment(FromDate).format("YYYY-MM-DD");
        ToDate = moment(ToDate).format("YYYY-MM-DD");
      }
    }
    // Call the API if all required data is available
    if (
      ScheduleType &&
      StationID?.value &&
      DepartmentID &&
      FromDate &&
      ToDate
    ) {
      showLoading();
      await PeriodicMaintenance_GetPendingColor(
        ScheduleType,
        DepartmentID,
        StationID?.value,
        FromDate,
        ToDate
      )
        .then((res) => {
          hideLoading();
          if (res?.data?.data) {
            setDropdownModel((pre) => ({
              ...pre,
              PendingDateList: res.data.data,
            }));
          } else {
            setDropdownModel((pre) => ({
              ...pre,
              PendingDateList: [],
            }));
          }
        })
        .catch((err) => {
          hideLoading();
          setDropdownModel((pre) => ({
            ...pre,
            PendingDateList: [],
          }));
        });
    }
  };

  const getStationList = () => {
    let stationList = GetLoginStationBaseOnDep();
    let stationTypes = GetLoginStationTypes();
    let DepartmentList = Decrypt(getCookies(Global.COOKIES.Departments));

    let Stations = [];
    let Types = [];
    let Department = [];

    if (stationList?.length) {
      stationList.map((data) => {
        Stations.push({
          key: data.label,
          ...data,
        });
      });

      stationTypes.map((data) => {
        Types.push({
          key: data.label,
          ...data,
        });
      });
    }

    if (DepartmentList?.length) {
      const itemsWithoutChildren = DepartmentList.filter(
        (item) => !DepartmentList.some((child) => child.parentId === item.id)
      );

      itemsWithoutChildren.map((data) => {
        Department.push({ value: data.id, key: data.name, Type: data.Type });
      });

      const { StationID, DepartmentID, StationTypeID } = PeriodicModel;

      let FilStations = [];
      if (DepartmentID && StationID?.value && StationTypeID) {
        let TempCrdID = Types.find((ele) => ele.value == StationTypeID);
        // let TempDeptID = Department.find(
        //   (ele) => ele.value == DepartmentID
        // );

        FilStations = Stations.filter(
          (ele) =>
            ele.CorridorID == TempCrdID.value &&
            ele.DepartmentID == DepartmentID
        );
      } else {
        FilStations = Stations.filter((ele) => {
          return (
            ele.CorridorID == Types[0].value &&
            ele.DepartmentID == Department[0].value
          );
        });
      }

      setDropdownModel((pre) => ({
        ...pre,
        StationList: FilStations,
        FilterStationList: Stations,
        StationTypes: Types,
        DepartmentList: Department,
        FilterDepartmentList: Department,
      }));

      setPeriodicModel((pre) => ({
        ...pre,
        DepartmentID:
          DepartmentID != 0 ? DepartmentID : Department?.[0].value ?? 0,
        StationTypeID:
          StationTypeID != null ? StationTypeID : Types?.[0].value ?? 0,
        StationID:
          Object.keys(StationID).length !== 0
            ? StationID
            : FilStations?.[0] ?? {},
      }));
      handleGlobalStationName(FilStations?.[0]?.label ?? "");
    }
  };

  const calendarStyle = (date) => {
    const { PendingDateList } = DropdownModel;
    if (PendingDateList?.length) {
      let deserveColor = PendingDateList.find((x) => {
        return getYMDdate(x.DateValue) == getYMDdate(date);
      });
      let style = {
        backgroundColor: "",
        margin: 0,
        padding: 0,
      };
      if (deserveColor) {
        style.backgroundColor = deserveColor.Color;
      }
      return { style };
    } else {
      return {
        style: {
          backgroundColor: "",
          margin: 0,
          padding: 0,
        },
      };
    }
  };

  const CustomNavigateDaily = (date, view, action) => {
    var newDate = date;
    if (view == "month") {
      // Insert your custom behavior here...
      if (localStorage.getItem(COOKIES.CalendarMonth) == null) {
        localStorage.setItem(COOKIES.CalendarMonth, getYMDdate(newDate));
      }
      // newDate = newDatelocalStorage.getItem(COOKIES.CalendarMonth)
      // If you still want the default behavior after your custom behavior:
      switch (action) {
        case "NEXT":
          newDate.setDate(newDate.getDate() + 1);

          break;
        case "PREV":
          newDate.setDate(newDate.getDate() - 1);
          break;
        // Handle other actions like 'TODAY' or view changes if needed
        default:
          newDate = new Date();
      }

      localStorage.setItem(COOKIES.CalendarMonth, getYMDdate(newDate));
      setPeriodicModel((pre) => ({
        ...pre,
        CalSrtDate: getYMDdate(newDate),
      }));
      return newDate; // Return the new date
    } else {
      setPeriodicModel((pre) => ({
        ...pre,
        CalSrtDate: getYMDdate(date),
      }));

      return date;
    }
  };

  const HandleChange = (e, cusName) => {
    if (!cusName) {
      const { value, name } = e.target;
      setPeriodicModel((pre) => ({
        ...pre,
        [name]: value,
      }));
      cusName = name;
    } else {
      setPeriodicModel((pre) => ({
        ...pre,
        [cusName]: e,
      }));
    }

    if (cusName == "StationID") {
      handleGlobalStationName(e.label ?? "");
    }

    if (["StationTypeID", "DepartmentID"].includes(cusName)) {
      const { value } = e.target;

      const StationTypeID =
        cusName == "StationTypeID" ? value : PeriodicModel.StationTypeID;
      const DepartmentID =
        cusName == "DepartmentID" ? value : PeriodicModel.DepartmentID;

      // const fineDept = DropdownModel?.DepartmentList?.find(
      //   (ele) => ele.value == DepartmentID
      // );
      const finedType = DropdownModel?.StationTypes?.find(
        (ele) => ele.value == StationTypeID
      );
      if (DepartmentID && finedType) {
        const FilStations = DropdownModel?.FilterStationList.filter(
          (ele) =>
            ele.CorridorID == finedType.value &&
            ele.DepartmentID == DepartmentID
        );

        setDropdownModel((pre) => ({
          ...pre,
          StationList: FilStations,
        }));

        setPeriodicModel((pre) => ({
          ...pre,
          StationID: FilStations?.[0] ?? {},
        }));
        handleGlobalStationName(FilStations?.[0]?.label ?? "");
      } else {
        setDropdownModel((pre) => ({
          ...pre,
          StationList: [],
        }));

        setPeriodicModel((pre) => ({
          ...pre,
          StationID: {},
        }));
        handleGlobalStationName("");
      }
    }
  };

  const isWeeklyRequired = () => {
    debugger;
    const DepartmentName =
      DropdownModel?.DepartmentList?.find(
        (ele) => ele.value == PeriodicModel?.DepartmentID
      )?.key?.toLowerCase() ?? "";
    return DepartmentName?.includes("telecom");
  };

  return (
    <>
      <div className="page-header mb-1">
        <div className="row">
          <div className="col-4">
            <h3 className="page-title">Periodic Maintenance</h3>
          </div>
          <div className="col-1 mb-2 text-end">
            <button
              className="btn btn-success"
              title="Go to OverAllJobs"
              style={{ backgroundColor: "#b3b3b3", borderColor: "#b3b3b3" }}
              onClick={() => {
                navigate("/Periodic-Job-Allocation");
              }}
            >
              <TbChecklist />
            </button>
          </div>
          <div className="col-3">
            <Dropdown
              data={DropdownModel?.DepartmentList ?? []}
              value={PeriodicModel.DepartmentID}
              placeholder={"Department"}
              name={"DepartmentID"}
              HandleChange={HandleChange}
            >
              {" "}
            </Dropdown>
          </div>
          <div className="col-2">
            <Dropdown
              data={DropdownModel?.StationTypes ?? []}
              value={PeriodicModel.StationTypeID}
              name={"StationTypeID"}
              placeholder={"Station Types"}
              HandleChange={HandleChange}
            >
              {" "}
            </Dropdown>
          </div>
          <div className="col-2">
            <ReactMultiSelect
              options={DropdownModel?.StationList ?? []}
              value={PeriodicModel.StationID}
              name={"StationID"}
              placeholder={"Stations"}
              onChange={(e) => HandleChange(e, "StationID")}
            >
              {" "}
            </ReactMultiSelect>
          </div>
        </div>
      </div>
      <div className="card card-body mt-1">
        {(() => {
          const calendarViews = {
            month: true,
            week: true,
            monthly: CalendarMonthView,
            quaterly: CalenderQuaterView,
            halfYearly: CalendarHalfyearlyView,
            yearly: CalenderYearlyView,
          };


          const calendarMessages = {
            month: "Daily",
            monthly: "Monthly",
            week: "Weekly",
            quaterly: "Quarterly",
            halfYearly: "Half-Yearly",
            yearly: "Yearly",
          };

          if (!isWeeklyRequired()) {
            delete calendarViews.week;
            delete calendarMessages.week;
          }

          return (
            <Calendar
              localizer={localizer}
              views={calendarViews}
              view={PeriodicModel?.CurrentView ?? "month"}
              onView={(view) => {
                setPeriodicModel((pre) => ({
                  ...pre,
                  CurrentView: view,
                }));
              }}
              messages={calendarMessages} // Use the dynamically constructed messages object
              defaultDate={new Date()}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              selectable={true}
              onSelectSlot={(e) => {
                debugger;

                let FromDate = getYMDdate(e.start);
                let ToDate = getYMDdate(e.start);

                if (PeriodicModel.CurrentView == "week") {
                  FromDate = moment(e.start)
                    .startOf("week")
                    .format("YYYY-MM-DD");
                  ToDate = moment(e.start).endOf("week").format("YYYY-MM-DD");
                }

                setPeriodicModel((pre) => ({
                  ...pre,
                  FromDate,
                  ToDate,
                }));
                navigate("/Department-DailyCalender");
              }}
              onNavigate={CustomNavigateDaily}
              dayPropGetter={calendarStyle}
            />
          );
        })()}
      </div>
    </>
  );
};
