import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/js/app";
import Global, { COOKIES } from "../services/Global";
import { AppContext } from "../services/ContextProvider";
import logoimage from "../assets/img/preodic.png";
import breakdown from "../assets/img/engineer.png";
import {
  getUserDetails,
  getPrivilege,
  Decrypt,
} from "./../services/CommonService";
import { PiRecycleBold } from "react-icons/pi";
import { BsBuildingGear } from "react-icons/bs";
import { TbLocationCog } from "react-icons/tb";

// import Helpdocument from '../assets/documents/CMMS_UserManual.pdf'

function Sidebar() {
  const {
    getCookies,
    setCookies,
    showLoading,
    hideLoading,
    cookies,
    handleBreakdownTab,
    tempModulePrivileage,
  } = useContext(AppContext);
  const [priviledge, setpriviledge] = useState("");
  const [StationList, setStationList] = useState("");
  const [IsTvsvac, setIsTvsvac] = useState(false);
  const [IsPh1, setIsPh1] = useState(false);

  //const priviledge = getCookies(Global.COOKIES.modulePrivileage);

  useEffect(() => {
    var ModulePrivileges =
      getCookies(Global.COOKIES.modulePrivileage) != null
        ? getCookies(Global.COOKIES.modulePrivileage)
        : "";
    const stations = Decrypt(getCookies(COOKIES.Stations)) ?? "";
    if (stations?.length) {
      const isValidStationList = stations?.every((element) =>
        [1, 2, 3].includes(element.StationID)
      );
      setIsPh1(isValidStationList);
    }

    setStationList(stations);
    let timeout = setTimeout(setpriviledge(ModulePrivileges), 3000);
    setIsTvsvac(getCookies(Global.COOKIES.IsTvsvac));
  }, [getPrivilege()]);

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            {priviledge.includes("DASHBOARDVIEW") && (
              <>
                {IsTvsvac === "false" ? (
                  <>
                    <li className="MaintabACT dashboardTab">
                      <Link to="/BreakdownDepartmentDashboard">
                        <i className="la la-dashboard"></i>{" "}
                        <span>Dashboard</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="MaintabACT dashboardTab">
                      <Link to="/dashboard">
                        <i className="la la-dashboard"></i>{" "}
                        <span> Dashboard</span>
                      </Link>
                    </li>
                  </>
                )}
              </>
            )}

            {priviledge.includes("USERVIEW") ||
            priviledge.includes("ASSOCIATEVIEW") ? (
              <li className="submenu MaintabACT">
                <Link to="#">
                  <i className="la la-users"></i> <span> Employee</span>{" "}
                  <span className="menu-arrow"></span>
                </Link>

                <ul style={{ display: "none" }}>
                  {priviledge.includes("ASSOCIATEVIEW") ? (
                    <li>
                      <Link to="/employee" className="subdrop">
                        Employee List
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("USERVIEW") ? (
                    <li>
                      <Link to="/user" className="subdrop">
                        User List
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              <li style={{ display: "none" }}>
                <Link to="#"></Link>
              </li>
            )}

            {priviledge.includes("EQUIPMENTDESIGNVIEW") &&
              IsTvsvac === "true" && (
                <li className="MaintabACT">
                  <Link to="/design-comm">
                    <i className="las la-microchip"></i>{" "}
                    <span> Equipment Design</span>
                  </Link>
                </li>
              )}
            {priviledge.includes("SPAREINVENTORYVIEW") && (
              <li className="MaintabACT">
                <Link to="/spare">
                  <i className="las la-toolbox"></i>{" "}
                  <span> Equipment Spare</span>
                </Link>
              </li>
            )}

            {priviledge.includes("EQUIPMENTPARAMETERVIEW") ? (
              <li className="MaintabACT">
                <Link to="/parameterdetails">
                  <i className="las la-weight"></i>{" "}
                  <span> Equipment Parameter</span>
                </Link>
              </li>
            ) : (
              ""
            )}

            {priviledge.includes("PERIODICMAINTENANCEVIEW") ? (
              <li className="submenu MaintabACT">
                <Link to="#">
                  <i className="la la-users"></i>{" "}
                  <span> Periodic Maintenance</span>{" "}
                  <span className="menu-arrow"></span>
                </Link>

                <ul style={{ display: "none" }}>
                  {priviledge.includes("CHECKLISTVIEW") &&
                  IsTvsvac === "true" &&
                  StationList.some(
                    (element) => ![1, 2, 3].includes(element.StationID)
                  ) ? (
                    <li>
                      <Link to="/CheckListCalenderView" className="subdrop">
                        Operation Checks
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {priviledge.includes("PERIODICMAINTENANCEVIEW") &&
                  IsTvsvac === "true" ? (
                    <li>
                      <Link
                        to="/periodic"
                        state={{ view: "month" }}
                        className="subdrop"
                      >
                        Maintenance Checklist
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("PERIODICMAINTENANCEVIEW") &&
                  IsTvsvac === "false" ? (
                    <li>
                      <Link
                        to="/Periodic-Department"
                        state={{ view: "month" }}
                        className="subdrop"
                      >
                        Maintenance Checklist
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("PERFORMANCEEVALUATIONVIEW") &&
                    IsTvsvac === "true" &&
                    StationList.some(
                      (element) => ![1, 2, 3].includes(element.StationID)
                    ) && (
                      <li className="MaintabACT">
                        <Link to="/PerformanceEvaluationCalenderView">
                          <span>Performance Evaluation </span>
                        </Link>
                      </li>
                    )}

                 
                </ul>
              </li>
            ) : (
              <li style={{ display: "none" }}>
                <Link to="#"></Link>
              </li>
            )}
            {priviledge.includes("BREAKDOWNMAINTENANCEVIEW") &&
              IsTvsvac === "true" && (
                <li className="MaintabACT">
                  <Link
                    to="/breakdownmaintenance"
                    onClick={() => handleBreakdownTab(0)}
                  >
                    <img src={breakdown} width="30" height="auto" alt="" />

                    <span> Breakdown Maintenance</span>
                  </Link>
                </li>
              )}
            {priviledge.includes("BREAKDOWNMAINTENANCEVIEW") &&
              IsTvsvac === "false" && (
                <li className="MaintabACT">
                  <Link
                    to="/BreakdownDepartmentIndex"
                    onClick={() => handleBreakdownTab(0)}
                  >
                    <img src={breakdown} width="30" height="auto" alt="" />

                    <span>Breakdown Maintenance</span>
                  </Link>
                </li>
              )}
            {IsTvsvac === "false" && (
              <li className="MaintabACT">
                <Link to="/AuditingProcess">
                  <i className="las la-wave-square"></i>{" "}
                  <span>Auditing Process</span>
                </Link>
              </li>
            )}

            {priviledge.includes("MANUALPARAMETERENTRYVIEW") &&
              IsTvsvac === "true" &&
              StationList.some((element) =>
                [1, 2, 3].includes(element.StationID)
              ) && (
                <li className="MaintabACT">
                  <Link to="/manualparameterEntry">
                    <i className="las la-clipboard-list"></i>{" "}
                    <span>Manual Parameter Entry</span>
                  </Link>
                </li>
              )}

            {priviledge.includes("MANUALLOGVIEW") && IsTvsvac === "true" && (
              <li className="MaintabACT">
                <Link to="/manualLog">
                  <i className="las la-project-diagram"></i>{" "}
                  <span>Manual Log Entry </span>
                </Link>
              </li>
            )}
            {priviledge.includes("REPORTVIEW") && IsTvsvac === "false" ? (
              <li>
                <Link to="/CyclicCheckIndex">
                  <i>
                    {" "}
                    <PiRecycleBold style={{ fontSize: "23px" }} />{" "}
                  </i>
                  <span>Cyclic Check</span>{" "}
                </Link>
              </li>
            ) : (
              ""
            )}
            {priviledge.includes("REPORTVIEW") && IsTvsvac === "false" ? (
              <li>
                <Link to="/CyclicCheckApproverIndex">
                  <i
                    className="fa fa-superpowers"
                    style={{ fontSize: "23px" }}
                  ></i>
                  <span>Cyclic Check Approver</span>{" "}
                </Link>
              </li>
            ) : (
              ""
            )}

            {priviledge.includes("SCADAVIEW") && IsTvsvac === "true" && (
              <li className="MaintabACT">
                <Link to="/scada-configuration">
                  <i className="las la-wave-square"></i> <span>SCADA</span>
                </Link>
              </li>
            )}

            {priviledge.includes("MASTERVIEW") && (
              <li>
                <Link to="/master">
                  <i className="las la-layer-group"></i>{" "}
                  <span> Master Details</span>
                </Link>
              </li>
            )}
            {priviledge.includes("MASTERVIEW") && IsTvsvac === "false" && (
              <li>
                <Link to="/departmentConfigIndex">
                  <i>
                    <BsBuildingGear />{" "}
                  </i>
                  <span> Department Configuration </span>
                </Link>
              </li>
            )}

            {priviledge.includes("ROLESVIEW") ||
            priviledge.includes("PERIODICCONFIGURATIONVIEW") ? (
              <li className="submenu">
                <Link to="">
                  <i className="la la-gear"></i> <span> Configuration</span>{" "}
                  <span className="menu-arrow"></span>
                </Link>

                <ul style={{ display: "none" }}>
                  {priviledge.includes("ROLESVIEW") ? (
                    <li>
                      <Link to="/roles">Roles & Privileges</Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {IsTvsvac === "false" && (
                    <li>
                      <Link to="/Buffer-Config">Buffer Days Configuration</Link>
                    </li>
                  )}

                  <li>
                    <Link to="/StationLocationConfiguration">
                      <span>Station Location Configuration </span>
                    </Link>
                  </li>
                  {IsTvsvac === "false" && (
                    <li>
                      <Link to="/Predifined-Fails">
                        Predefined-Fails Configuration
                      </Link>
                    </li>
                  )}
                  {IsTvsvac === "false" && (
                    <li>
                      <Link to="/AuditingLevelConfigIndex">
                        Auditing Level Configuration
                      </Link>
                    </li>
                  )}
                  {IsTvsvac === "false" && (
                    <li>
                      <Link to="/ApprovalConfig">
                        Approval Process Configuration
                      </Link>
                    </li>
                  )}
                  {IsTvsvac === "false" &&
                  priviledge.includes("PERIODICCONFIGURATIONVIEW") ? (
                    <li>
                      <Link to="/PeriodicConfiguration">
                        Periodic Maintenance Configuration
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {IsTvsvac === "true" &&
                  priviledge.includes("PERIODICCONFIGURATIONVIEW") ? (
                    <li>
                      <Link to="/PeriodicConfig">
                        Periodic Maintenance Configuration
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {priviledge.includes("PERIODICCONFIGURATIONVIEW") &&
                  IsTvsvac === "false" ? (
                    <li>
                      <Link to="/PeriodicStartMonthConfig">
                        Periodic Maintenance Start Month Configuration
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {priviledge.includes("NONREVENUECONFIGURATIONVIEW") &&
                  IsTvsvac === "true" ? (
                    <li>
                      <Link to="/nonrevenueconfig">
                        Non-Revenue/Maintenance Hour Configuration
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("STATIONCONFIGVIEW") && (
                    <li>
                      <Link to="/stationconfig">
                        <span> Station Color Configuration</span>{" "}
                      </Link>
                    </li>
                  )}

                  {priviledge.includes("DELAYEDMONITORCONFIGURATIONVIEW") &&
                    IsTvsvac === "true" && (
                      <li>
                        <Link to="/Alertconfiguration">
                          <span>File Transfer Monitor Configuration</span>{" "}
                        </Link>
                      </li>
                    )}
                </ul>
              </li>
            ) : (
              <li style={{ display: "none" }}>
                <Link to="#"></Link>
              </li>
            )}

            {priviledge.includes("REPORTVIEW") ||
            priviledge.includes("REPORTVIEW") ? (
              <li className="submenu">
                <Link to="">
                  <i className="las la-book"></i> <span> Reports</span>{" "}
                  <span className="menu-arrow"></span>
                </Link>

                <ul style={{ display: "none" }}>
                  {priviledge.includes("REPORTVIEW") &&
                  IsTvsvac === "true" &&
                  StationList.some((element) =>
                    [1, 2, 3].includes(element.StationID)
                  ) &&
                  IsPh1 ? (
                    <li>
                      <Link to="/Periodic-Report">
                        Periodic Maintenance Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {priviledge.includes("REPORTVIEW") &&
                  IsTvsvac === "true" &&
                  StationList.some(
                    (element) => ![1, 2, 3].includes(element.StationID)
                  ) ? (
                    <>
                      <li>
                        <Link to="/UG-19-PeriodicReport">
                          Periodic Maintenance Report
                        </Link>
                      </li>
                      <li>
                        <Link to="/MTBF-Report">MTBF-Report</Link>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("REPORTVIEW") &&
                  IsTvsvac === "true" &&
                  StationList.some(
                    (element) => ![1, 2, 3].includes(element.StationID)
                  ) ? (
                    <li>
                      <Link to="/CheckListReport">Operation Checks Report</Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {priviledge.includes("REPORTVIEW") && IsTvsvac === "false" ? (
                    <li>
                      <Link to="/PeriodicMaintenanceReport">
                        PeriodicMaintenance Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {priviledge.includes("REPORTVIEW") ? (
                    <li>
                      <Link to="/breakdownreport">
                        Breakdown Maintenance System Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("REPORTVIEW") && IsTvsvac === "true" ? (
                    <li>
                      <Link to="/manualreport">Manual Ticket Report</Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("REPORTVIEW") && IsTvsvac === "true" ? (
                    <li>
                      <Link to="/breakdownalarmreport">
                        Breakdown Alarm Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("REPORTVIEW") && IsTvsvac === "true" ? (
                    <li>
                      <Link to="/Report_ManulPointEntry">
                        Manual Entry Parameter Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("REPORTVIEW") ? (
                    <li>
                      <Link to="/sparereport">Equipment Spare Report</Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("REPORTVIEW") && IsTvsvac === "false" ? (
                    <li>
                      <Link to="/AuditingReport">
                        Employee Work Exception Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("REPORTVIEW") && IsTvsvac === "false" ? (
                    <li>
                      <Link to="/RCAReport">RCA Report</Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("REPORTVIEW") &&
                  IsTvsvac === "true" &&
                  StationList.some(
                    (element) => ![1, 2, 3].includes(element.StationID)
                  ) ? (
                    <li>
                      <Link to="/PerformanceReport">
                        Performance Evaluation Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("REPORTVIEW") &&
                  IsTvsvac === "true" 
                  // && StationList.some(
                  //   (element) => ![1, 2, 3].includes(element.StationID) ) 
                    ? (
                    <li>
                      <Link to="/Routine-Shift-Check-Report">
                        Routine Shift Checks Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("REPORTVIEW") &&
                  IsTvsvac === "true" &&
                  StationList.some(
                    (element) => ![1, 2, 3].includes(element.StationID)
                  ) ? (
                    <li>
                      <Link to="/DailyElectricalPanelReport">
                        Daily Electrical Panel Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {priviledge.includes("REPORTVIEW") &&
                  IsTvsvac === "true" &&
                  StationList.some(
                    (element) => ![1, 2, 3].includes(element.StationID)
                  ) ? (
                    <li>
                      <Link to="/ChillerMonitoringReport">
                        Chiller Monitoring Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("REPORTVIEW") && IsTvsvac === "false" ? (
                    <li>
                      <Link to="/CyclicCheckReport">Cyclic Check Report</Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("REPORTVIEW") &&
                  IsTvsvac === "true" 
                  //&&
                  // StationList.some(
                  //   (element) => ![1, 2, 3].includes(element.StationID)
                    ? (
                    <li>
                      <Link to="/DailyClosingReadingCheckList">
                        Daily Closing Reading CheckList Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {priviledge.includes("REPORTVIEW") &&
                  IsTvsvac === "true" 
                  // && StationList.some(
                  //   (element) => ![1, 2, 3].includes(element.StationID)  ) 
                    ? (
                    <li>
                      <Link to="/CriticalEquipmentRoomTemperatureMonitoringReport">
                        Critical Equipment Room Temperature Monitoring Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {/* {IsPh1 ? (
                    <li>
                      <Link to="/BillingSheetReportExl">
                        Billing Sheet Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )} */}

                  {priviledge.includes("REPORTVIEW") && IsTvsvac === "true" ? (
                    <li>
                      <Link to="/OverAllHistoryReport">
                        History Card Report
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {priviledge.includes("REPORTVIEW") &&
                  IsTvsvac === "true" &&
                  IsPh1 ? (
                    <li>
                      <Link to="/ReportAttendance">Attendance Report</Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {/* {priviledge.includes("REPORTVIEW") ? (
                    <li>
                      <Link to="/OverAllHistroyReport">Over All History</Link>
                    </li>
                  ) : (
                    ""
                  )} */}
                </ul>
              </li>
            ) : (
              <li style={{ display: "none" }}>
                <Link to="#"></Link>
              </li>
            )}
             {IsPh1 && priviledge.includes("BILLINGSHEETVIEW") ? (
                    <li>
                      <Link to="/BillingSheet">
                        <i class="la la-receipt"></i>
                        <span>Billing Sheet</span>{" "}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

            {priviledge.includes("REFERENCEDOCUMENTVIEW") && (
              <li>
                <Link to="/manualupload">
                  <i className="la la-info"></i>{" "}
                  <span> Reference Document</span>{" "}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
