import React, { useEffect, useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-big-calendar";
import { useNavigate } from "react-router";
import { getYMDdate, getUserDetails, Week5_render, determineWeekColor, determineWeekColorForQuarter, PeriodicSetUgMOnthDate, getCookieValue } from "../../services/CommonService";
import { AppContext } from "./../../services/ContextProvider";
import { COOKIES } from "../../services/Global";
import { PeriodicWeekData, checkDate, checkMonth } from './PeriodicWeekData';
import { PeriodicService } from "./PeriodicService";
import { PeriodicSetQuerterMOnthDate } from "../../services/CommonService";

// const IsTvsvac = localStorage.getItem('IsPh1')



//const MonthList = isPh1 ? [11, 2, 5, 8] : [11, 8, 5, 9];

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const _periodicServices = new PeriodicService();

export default function CalenderQuaterView({
  date,
  localizer,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(), "day"),
  ...props
}) {
  const [header, setHeader] = useState([]);
  const { getCookies, showLoading, hideLoading } = useContext(AppContext);
  const [JobPendingDate, setJobPendingDate] = useState([]);



  useEffect(() => {
    const ph1stations = ['1', '2', '3']
    const isPh1 = ph1stations.includes(parseInt(getCookieValue('PeriodicStationID')));
    let MonthList = isPh1 ? [11, 2, 5, 8] : [11, 9, 5, 8];

    var newdate = isPh1 ? PeriodicSetQuerterMOnthDate(date, 3) : PeriodicSetUgMOnthDate(date, 3);
    //console.log(newdate, isPh1, getCookies(COOKIES.PeriodicStationID));
    // var newdate = PeriodicSetQuerterMOnthDate(date, 3);
    // var newdate = localStorage.getItem("quaterMonth") != null ? new Date(localStorage.getItem("quaterMonth")) : new Date(); 
    // localStorage.setItem("quaterMonth", newdate)
    var curMonth = newdate.getMonth() + 1;
    var curYear = newdate.getFullYear();
    var finalDate;
    if (MonthList.includes(curMonth)) {
      finalDate = newdate;
    } else {
      while (true) {
        if (curMonth < 12) {
          curMonth = curMonth + 1;
          if (MonthList.includes(curMonth)) {
            finalDate = new Date(curYear, curMonth - 1, 1);
            break;
          }
        } else if (curMonth == 12) {
          curMonth = 1;
          curYear = curYear + 1;
        }
      }
    }
    var finalMonth = finalDate.getMonth() + 1;

    var list = [];
    list.push(Months[finalMonth - 2]);
    list.push(Months[finalMonth - 1]);
    list.push(Months[finalMonth]);

    setHeader([...list]);
  }, [date]);

  const currRange = useMemo(
    () => {

      const ph1stations = [1, 2, 3]
      const isPh1 = ph1stations.includes(parseInt(getCookieValue('PeriodicStationID')));
      var Mydate = isPh1 ? PeriodicSetQuerterMOnthDate(date, 3) : PeriodicSetUgMOnthDate(date, 3);

      if (localStorage.getItem("quaterMonth") == null) {
        localStorage.setItem("quaterMonth", getYMDdate(Mydate));
      }

      return CalenderQuaterView.range(Mydate, { localizer })
    }
    , [date, localizer]
  );




  const navigate = useNavigate();

  const navigateTo = (i, currdate) => {


    var newdate = date;
    if (currdate == '22') {
      if (localStorage.getItem("quaterMonth") == null) {
        localStorage.setItem("quaterMonth", getYMDdate(new Date(newdate.getFullYear(), newdate.getMonth() + 1, 22)))
        localStorage.setItem("quaterWeek", i)

      }
      else {
        var weekdate = new Date(localStorage.getItem("quaterMonth"));
        localStorage.setItem("quaterMonth", getYMDdate(new Date(newdate.getFullYear(), newdate.getMonth(), 22)))
        localStorage.setItem("quaterWeek", i)
      }
      navigate('/quaterlyCalender', { state: { week: i, date: getYMDdate(localStorage.getItem("quaterMonth")) } });

    }
    else {
      const ph1stations = [1, 2, 3]
      const isPh1 = ph1stations.includes(parseInt(getCookieValue('PeriodicStationID')));
      let customdate = isPh1 ? PeriodicSetQuerterMOnthDate(date, 3) : PeriodicSetUgMOnthDate(date, 3);
      localStorage.setItem("quaterMonth", getYMDdate(new Date(`${customdate.getFullYear()}-${customdate.getMonth() + 1}-${'01'}`)));
      localStorage.setItem("quaterWeek", i);
      navigate("/quaterlyCalender", {
        state: { week: i, date: getYMDdate(customdate) },
      });
    }

  };

  var week5 = PeriodicWeekData(currRange[0]);

  useEffect(() => {
    if (getUserDetails().RoleID == COOKIES.TechRoleID) {
      navigateTo(1, new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    }

  }, [])

  useEffect(() => {
    var currentMonth, currentYear, todayDate;
    const ph1stations = [1, 2, 3]
    const isPh1 = ph1stations.includes(parseInt(getCookieValue('PeriodicStationID')));
    var curDate = isPh1 ? PeriodicSetQuerterMOnthDate(date, 3) : PeriodicSetUgMOnthDate(date, 3);

    var d;
    if (curDate != null) {
      d = new Date(curDate);
      currentMonth = d.getMonth() + 1;
      currentYear = d.getFullYear();
      todayDate = getYMDdate(new Date());

    } else {
      d = new Date();
      localStorage.setItem("quaterMonth", new Date());
      currentMonth = d.getMonth() + 1;
      currentYear = d.getFullYear();
      todayDate = getYMDdate(new Date());
    }
    showLoading()
    _periodicServices.JobSchedulePendingDate(currentMonth, currentYear, todayDate, 3, parseInt(getCookies(COOKIES.PeriodicStationID)))
      .then((res) => {
        hideLoading()
        if (res != null && res.data != null && res.data != undefined) {
          setJobPendingDate([...res.data])
          // console.log(res.data)
        }

      }
      ).catch((err) => {
        console.log(err)
        hideLoading()
      });


  }, [date, localStorage.getItem("quaterMonth"), getCookies(COOKIES.PeriodicStationID)]);


  // function checkDate(date) {
  //   return date < getYMDdate(new Date());
  // }
  // function checkMonth(date) {
  //   var newdate = new Date(date);
  //   return newdate.getMonth() == new Date().getMonth();
  // }
  var week1 = currRange.slice(0, 7);
  var week2 = currRange.slice(7, 14);
  var week3 = currRange.slice(14, 21);
  var week4 = currRange.slice(21, 28);

  var weekLst = [week1, week2, week3, week4];

  var isWeek;
  var isCurrentMonth;
  let its_future_week5 = false
  let pendingcolor_5 = false
  let checkdaterange_5 = false
  let isCurrentMonth_5 = false
  let isCurrentWeek = false
  let Week5IsNoJob = false


  return (
    <>
      <div className="card p-2 m-0">
        <ul className="nav nav-tabs nav-tabs-solid nav-justified">
          {header != null && header.length > 0 ? (
            header.map((v, i) => {
              if (i == 1) {
                var id = "#solid-justified-tab" + (i + 1);
                return (
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                    // data-bs-target={id}
                    // data-bs-toggle="tab"
                    >
                      {v}
                    </a>
                  </li>
                );
              } else {
                var id = "#solid-justified-tab" + (i + 1);
                return (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                    // data-bs-target={id}
                    // data-bs-toggle="tab"
                    >
                      {v}
                    </a>
                  </li>
                );
              }
            })
          ) : (
            <></>
          )}
        </ul>
      </div>

      {weekLst.map((v, i) => {
        var color = v.map((c) => {
          return c.getDate();
        });


        var dates = v.map(c => { return getYMDdate(c) });
        var isPending = v.map(c => { return JobPendingDate.some((x) => x.JobDate == getYMDdate(c)) });
        let CheckNoJob = v.map(c => { return JobPendingDate.some((x) => x.JobDate == getYMDdate(c) && x.CheckAnyJob) });

        var pendingcolor = isPending.includes(true);
        let IsNoJob = CheckNoJob.every(x => x == false);

        var checkdaterange = dates.some(checkDate);



        isCurrentMonth = dates.some(checkMonth);
        let j = 0;
        if (color.includes(new Date().getDate())) {
          j = i + 1;
          if (j == 4) {
            isWeek = true
          }

        }
        let its_future_week = false
        if (v) {
          its_future_week = v[0].getTime() > new Date().getTime()
        }
        if (i + 1 == 4) {
          its_future_week5 = its_future_week
          pendingcolor_5 = pendingcolor
          checkdaterange_5 = checkdaterange
          isCurrentMonth_5 = isCurrentMonth
          isCurrentWeek = (i + 1) == j
          Week5IsNoJob = IsNoJob


        }


        return (
          <div className={"row calender "}>
            <div
              key={i + 1}
              className={
                "col card CalenderCard_Week " +
                determineWeekColor(i, j, isCurrentMonth, pendingcolor, IsNoJob, its_future_week)
              }
            >{`Week ${i + 1}`}</div>
            {v.map((c) => {
              let shortMonth = c.toLocaleString("default", {
                month: "short",
              });
              let date = c.getDate();
              let its_future = new Date().getTime() < c.getTime();

              return (
                <div
                  key={c.getDate()}
                  className={
                    "col card CalenderCard_Week_Date " +
                    determineWeekColorForQuarter(i, j, isCurrentMonth, pendingcolor, checkdaterange, IsNoJob, its_future)
                  }
                  onClick={() => navigateTo(i + 1, c.toDateString())}
                >
                  {date < 10 ? "0" + date : date}
                </div>
              );
            })}
          </div>
        );
      })}
      {
        <>
          {
            Week5_render(week5, isCurrentMonth_5, pendingcolor_5, checkdaterange_5, its_future_week5, isCurrentWeek, navigateTo, Week5IsNoJob)
          }
        </>}
    </>
  );
}

CalenderQuaterView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

CalenderQuaterView.range = (date, { localizer }) => {

  const start = new Date(date.getFullYear(), date.getMonth(), 1);
  const end = localizer.add(
    start,
    new Date(date.getFullYear(), date.getMonth(), 0).getDate(),
    "day"
  );

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

CalenderQuaterView.navigate = (date, action, { localizer }) => {
  const ph1stations = [1, 2, 3]
  const isPh1 = ph1stations.includes(parseInt(getCookieValue('PeriodicStationID')));
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, isPh1 ? -3 : -6, "month");

    case Navigate.NEXT:
      return localizer.add(date, isPh1 ? 3 : 6, "month");

    default:
      return date;
  }
};


CalenderQuaterView.title = (date, { localizer }) => {
  const ph1stations = [1, 2, 3]
  const isPh1 = ph1stations.includes(parseInt(getCookieValue('PeriodicStationID')));
  let QuaterlyMonths = [
    {
      Quater: "1st Quarter",
      Month: isPh1 ? 11 : 9,
    },
    {
      Quater: "2nd Quarter",
      Month: isPh1 ? 2 : 5,
    },
    {
      Quater: "3rd Quarter",
      Month: isPh1 ? 5 : 8,
    },
    {
      Quater: "4th Quarter",
      Month: isPh1 ? 8 : 11,
    },
  ];
  let MonthList = isPh1 ? [11, 2, 5, 8] : [11, 9, 5, 8];
  var newdate = isPh1 ? PeriodicSetQuerterMOnthDate(date, 3) : PeriodicSetUgMOnthDate(date, 3);
  // 
  if (localStorage.getItem("quaterMonth") == null) {
    localStorage.setItem("quaterMonth", getYMDdate(new Date(`${newdate.getFullYear()}-${newdate.getMonth() + 1}-${'01'}`)));
  }
  // var newdate = date;
  var curMonth = newdate.getMonth() + 1;
  var curYear = newdate.getFullYear();

  var finalDate;
  if (MonthList.includes(curMonth)) {
    finalDate = newdate;
  } else {
    while (true) {
      if (curMonth < 12) {
        curMonth = curMonth + 1;
        if (MonthList.includes(curMonth)) {
          finalDate = new Date(curYear, curMonth - 1, 1);
          break;
        }
      } else if (curMonth == 12) {
        curMonth = 1;
        curYear = curYear + 1;
      }
    }
  }
  var finalMonth = finalDate.getMonth() + 1;
  var quater = QuaterlyMonths.find((d) => d.Month == finalMonth);
  var finalMonthString = finalDate.toLocaleString("default", {
    month: "short",
  });

  var finalYear = finalDate.getFullYear();
  var titleString = `${quater?.Quater} (${finalMonthString}-${finalYear})`;
  return titleString;
};
