import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
// import html2pdf from "html2pdf.js";
import "./TelecomReportcs.css";
import {
  PeriodicService,
  Report_PeriodicMaintenance_DropDown,
} from "./PeriodicService";
import { RiArrowGoBackLine } from "react-icons/ri";

function TelecomQuerterlyReport() {
  const navigate = useNavigate();
  const [TelecomQuerterlyDataList, setTelecomQuarterlyDataList] = useState([]);
  const location = useLocation();
  const PeriodicServiceobj = new PeriodicService();
  const [Date, setDate] = useState();
  const [StationName, setStationName] = useState();
  const [DesignationL1, setDesignationL1] = useState();
  const [EmployeeNameL1, SetEmployeeNameL1] = useState();

  const { PeriodicCheckListMaintenanceID, PeriodicDetailID } = location.state;
  useEffect(() => {
    GetTelecomQuarterlyDataList();
  }, []);

  const GetTelecomQuarterlyDataList = async () => {
    if (PeriodicCheckListMaintenanceID && PeriodicDetailID) {
      try {
        return await PeriodicServiceobj.PeriodicMaintenance_TelecomReportList(
          PeriodicCheckListMaintenanceID,
          PeriodicDetailID
        ).then((res) => {
          debugger;

          if (res?.data?.data) {
            const { reportList, approverList } = res.data.data;
            if (reportList?.length) {
              setTelecomQuarterlyDataList(reportList);

              const { StationCode, Date } = reportList[0];

              setDate(Date);
              setStationName(StationCode);

              if (approverList?.length) {
                let Obj = approverList.find((ele) => ele.PreRoleName == "L0");
                if (Obj) {
                  setDesignationL1(Obj.Designation);
                  SetEmployeeNameL1(Obj.EmployeeCode);
                }
              }

              // setDesignationL1(DesignationL1);
              // SetEmployeeNameL1(EmployeeNameL1);
            }
          }

          // setTelecomQuarterlyDataList(result?.data);
          // const TelecomList = result?.data[0].QuarterlySubDate;
          // const stationNameList = result?.data[0].StationCode;
          // const dateOnly = TelecomList?.split("T")[0];
          // const DesignationL1 = result?.data[0].Designation;
          // const EmployeeNameL1 = result?.data[0].EmployeeNameL1;
          // setDate(dateOnly);
          // setStationName(stationNameList);
          // setDesignationL1(DesignationL1);
          // SetEmployeeNameL1(EmployeeNameL1);
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
  const ConvertHTMLFileToPDF = () => {
    const element = document.getElementById("Quarterly");

    const opt = {
      margin: [0.5, 0.5, 0.3, 0.3],
      filename: "telecom_report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true, scrollX: 0, scrollY: 0 },
      jsPDF: { unit: "in", format: [8.27, 11.69], orientation: "landscape" },
    };

    // Convert the element and save as PDF
    // html2pdf().from(element).set(opt).save();
  };
  return (
    <>
      <div>
        <div className="row align-items-center">
          <div className="col-10 mb-2 text-start">
            <button
              className="btn btn-secondary"
              style={{ backgroundColor: "#b3b3b3", borderColor: "#b3b3b3" }}
              onClick={() => {
                navigate("/PeriodicMaintenanceReport");
              }}
            >
              <RiArrowGoBackLine /> Go Back
            </button>
          </div>
          <div className="col-2">
            <button
              title="Pdf download"
              className="lar la-file-pdf btn"
              onClick={() => ConvertHTMLFileToPDF()}
            ></button>
          </div>
        </div>

        <form id="Quarterly">
          <div className="col-md-10">
            <h3 className="page-title text-center">
              Quarterly Fire Integration Test Checklist
            </h3>
          </div>
          <div className="col-3 ">
            <h4>Station : {StationName}</h4>
          </div>
          <div className="col-3 ">
            <h4>Date : {Date}</h4>
          </div>
          <div className="row border-row ">
            <div className="col-1">
              <label>S.no</label>
            </div>
            <div className="col-3">
              <label className="me-2 text-center">Description</label>
            </div>
            <div className="col-6 text-center">
              <label>Observation</label>
            </div>
            <div className="col-2 text-end">
              <label>Remarks</label>
            </div>
          </div>
          {TelecomQuerterlyDataList.map((data, index) => {
            let UpdateAccordianName1 = "TWOSMOKEDETECTOR";
            let UpdateAccordianName2 = "MCPTRIGGER";
            let FeildSeriesOne = "";
            let FeildSeriesTwo = "";
            if (
              data?.Description?.toUpperCase()?.replace(/\s+/g, "") ==
              UpdateAccordianName1
            ) {
              FeildSeriesOne = "Evac Message Display";
              FeildSeriesTwo = "Evac Message";
            } else if (
              data?.Description.toUpperCase().replace(/\s+/g, "") ==
              UpdateAccordianName2
            ) {
              FeildSeriesOne = "PIDS (Evac Message)";
              FeildSeriesTwo = "PAVA (Evac Message)";
            }
            return (
              <div key={index}>
                <div className="">
                  <div className="row mt-3 row-divider">
                    <div className="col-1 border-right">
                      <label>{index + 1}</label>
                    </div>
                    <div className="col-2 border-right">
                      <label>
                        {data?.Description} <br /> Location : {data.Location}
                      </label>
                    </div>
                    <div className="col-8 mb-1 d-flex border-right ">
                      <div className="col-2 me-1 border-right">
                        {FeildSeriesOne ? (
                          <label className="label" htmlFor="pidsNormalDisplay">
                            {FeildSeriesOne}
                          </label>
                        ) : (
                          <label className="label" htmlFor="pidsNormalDisplay">
                            PIDS Normal Display
                          </label>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          name={`pidsNormalDisplay-${index}`}
                          value={data.PIDS_EVAC_Messages}
                        />
                      </div>
                      <div className="col-2 me-1 border-right">
                        {FeildSeriesTwo ? (
                          <label className="label" htmlFor="pidsNormalDisplay">
                            {FeildSeriesTwo}
                          </label>
                        ) : (
                          <label className="label" htmlFor="pidsNormalDisplay">
                            PAVA Alert Message
                          </label>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          name={`pavaAlertMessage-${index}`}
                          value={data.PAVA_EVAC_Messages}
                        />
                      </div>
                      <div className="col-2 me-1 border-right">
                        <label>ACID</label>
                        <input
                          type="text"
                          className="form-control"
                          name={`acid-${index}`}
                          value={data.ACID}
                        />
                      </div>
                      <div className="col-2 me-1 border-right">
                        <label>CCTV Alarm Pop-Up</label>
                        <input
                          type="text"
                          className="form-control"
                          name={`cctvAlarmPopup-${index}`}
                          value={data.CCTV_Alarm_Pop_Up}
                        />
                      </div>
                      <div className="col-2 me-1 border-right">
                        <label>CCTV Camera Mapping</label>
                        <input
                          type="text"
                          className="form-control"
                          name={`cctvCameraMapping-${index}`}
                          value={data.CCTV_Camera_Mapping}
                        />
                      </div>
                      <div className="col-2 me-1">
                        <label>Visual Alarm</label>
                        <input
                          type="text"
                          className="form-control"
                          name={`visualAlarm-${index}`}
                          value={data.Visual_Alarm}
                        />
                      </div>
                    </div>
                    <div className="col-1 v">
                      <label>{data.Remarks} </label>
                    </div>
                  </div>
                </div>
                <hr style={{ border: "1px solid #ccc", margin: "10px 0" }} />
              </div>
            );
          })}
          <div>
            <h6>Done By :</h6>
            <label> Name : {EmployeeNameL1} </label>
          </div>
          <div>
            <label> Designation: {DesignationL1} </label>
          </div>
          <div>
            <label> Signature : </label>
          </div>
        </form>
      </div>
    </>
  );
}

export default TelecomQuerterlyReport;
