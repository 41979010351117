import React, { useState, useContext } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { AppContext } from "../../services/ContextProvider";
import { WithSelectDropDown } from "../../components/WithSelectDropDown";
import {
  DeepCopy,
  getL0RoleID,
  GetLoginUserID,
  getUserDetails,
  toInitCap,
  validateModel,
} from "../../services/CommonService";
import {
  PeriodicMaintenance_AddOrUpdateJobScheduleIndividualForUG19,
  PeriodicMaintenance_GetEquipmentDetailsUsingID,
  PeriodicMaintenance_GetPM_Annexure_DetailsUsingID,
  PeriodicMaintenance_Save_PM_Annexure
} from "../PeriodicMaintenance/PeriodicService";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { RiArrowGoBackLine } from "react-icons/ri";
import {
  showErrorMsg,
  showSaveSuccess,
  showWarningMsg,
} from "../../components";
import { IoMdCheckmark } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import swal from "sweetalert";
function TelecomQuarterly() {
  const navigate = useNavigate();
  const location = useLocation();
  const { showLoading, hideLoading } = useContext(AppContext);
  const [formStates, setFormStates] = useState([]);
  const [Model, setModel] = useState({
    IsAllClosed: false,
  });
  const {
    PeriodicCheckListMaintenanceID = 0,
    EquipmentDetailID =0,
    PeriodicDetailID = 0,
  } = location?.state  ?? {}
  
  const Header = ({ title }) => (
    <div className="row align-items-center mb-4">
      <div className="col-md-7">
        <h3 className="page-title">{title}</h3>
      </div>
    </div>
  );

  useEffect(() => {
    GetDescriptionByID();
  }, []);

  const GetDescriptionByID = () => {
   
    if (
      (PeriodicCheckListMaintenanceID && EquipmentDetailID, PeriodicDetailID)
    ) {
      showLoading();
      PeriodicMaintenance_GetPM_Annexure_DetailsUsingID(
        PeriodicCheckListMaintenanceID,
        EquipmentDetailID,
        GetLoginUserID(),
        PeriodicDetailID
      )
        .then((res) => {
          hideLoading();
          if (res?.data?.data?.length) {

            setFormStates(res?.data?.data);
          }
        })
        .catch((err) => {
          hideLoading();
        });
    }else{
      navigate("/Error404")
    }
  };


  const SaveTelecomeQuerterly = async (
    obj
  ) => {


      const { CopyObbj, Error } = GetErrorDetails(obj);
      if (Error) {
        setFormStates(CopyObbj);
        return;
      }

    let TelecomQuarterly = {
      ...obj,
      PeriodicDetailID,
      SavedBy: GetLoginUserID(),
    };


      await PeriodicMaintenance_Save_PM_Annexure(
        TelecomQuarterly
      )
        .then((response) => {
          if (response != null && response.data != null) {
            showSaveSuccess();
            GetDescriptionByID();
          
          }
        })
        .catch((err) => {
          hideLoading();
          showErrorMsg();
          GetDescriptionByID();
          console.log(err);
        });
  };

  const GetErrorDetails = (obj) => {
    debugger;
    const ErrMessages = {
      Location: "Location",
      PIDS_EVAC_Messages: "Messages",
      PAVA_EVAC_Messages: "Messages",
      ACID: "ACID",
      CCTV_Alarm_Pop_Up: "Alarm Pop Up",
      CCTV_Camera_Mapping: "Camera Mapping",
      Visual_Alarm: "Visual Alarm",
    };
    let Error = validateModel(obj, ErrMessages);

    if (Error) {
      const { PeriodicDetailID } = obj;
      let CopyObbj = DeepCopy(formStates);
      let FinedObj = CopyObbj.find(
        (ele) => ele.PeriodicDetailID == PeriodicDetailID
      );
      if (FinedObj) {
        FinedObj.Error = Error;
      }
      return { CopyObbj, Error };
    }
    return { Error: false };
  };

  const handleChange = (e, ID) => {
    const { name, value } = e.target;
    let CopyObj = DeepCopy(formStates);
    const updatedFormStates = CopyObj?.find(
      (item) => item.AccordionTabID === ID
    );
    if (updatedFormStates) {
      updatedFormStates[name] = value;
      setFormStates(CopyObj);
    }
  };

  const updateJobStatus = (status) => {
    debugger;
    let statusName = "";
    if (status == 1) statusName = "approve";
    else if (status == 2) statusName = "reject";
    else statusName = "save";

    swal({
      title: "Are you sure?",
      text: `Do you want to ${statusName} this job`,
      icon: `warning`,
      buttons: true,
      dangerMode: status == 0,
    }).then((willDelete) => {
      if (willDelete) {
        SaveTelecomeQuerterly(formStates[0], false, 2, status == 1);
      }
    });
  };

  return (
    <div className="mt-3">
      <div className="row align-items-center">
        <div className=" mb-2 text-start">
          <Header title="Telecom -Quarterly Fire Integration Test Checklist"  />
        </div>

        <div className="row d-flex justify-content-between">
          <div className="col-sm-3 mb-2 text-start">
            <button
              className="btn btn-secondary"
              style={{ backgroundColor: "#b3b3b3", borderColor: "#b3b3b3" }}
              onClick={() => {
                navigate(-1);
              
              }}
            >
              <RiArrowGoBackLine /> Go Back
            </button>
          </div>

        </div>
      </div>
      <Accordion allowZeroExpanded={true} allowMultipleExpanded   preExpanded={[0]} >
        {formStates?.length
          ? formStates.map((ele, index) => {
              const {
                PeriodicDetailID,
                AccordionTabID,
                Description,
                Location,
                PIDS_EVAC_Messages,
                PAVA_EVAC_Messages,
                ACID,
                CCTV_Alarm_Pop_Up,
                CCTV_Camera_Mapping,
                Visual_Alarm,
                Remarks,
                Error = {},
              } = ele;
              const NormalDisplayList = [
                {
                  key: "Normal",
                  value: "Normal",
                },
                {
                  key: "AbNormal",
                  value: "AbNormal",
                },
              ];

              let UpdateAccordianName1 = "TWOSMOKEDETECTOR";
              let UpdateAccordianName2 = "MCPTRIGGER";
              let FeildSeriesOne = "";
              let FeildSeriesTwo = "";
              if (
                Description.toUpperCase().replace(/\s+/g, "") ==
                UpdateAccordianName1
              ) {
                FeildSeriesOne = "Evac Message Display";
                FeildSeriesTwo = "Evac Message";
              } else if (
                Description.toUpperCase().replace(/\s+/g, "") ==
                UpdateAccordianName2
              ) {
                FeildSeriesOne = "PIDS (Evac Message)";
                FeildSeriesTwo = "PAVA (Evac Message)";
              }

              return (
                <AccordionItem uuid={index}>
                  <div>
                    <AccordionItemButton>
                      {" "}
                      {Description}
                    </AccordionItemButton>
                  </div>
                  <AccordionItemPanel>
                    <div className="mt-1">
                      <div className=""></div>
                      <div className="">
                        <div className="row">
                          <div className="col-md-3">
                            <label
                              className="label"
                              htmlFor="pidsNormalDisplay"
                            >
                              Location
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="Location"
                              style={{ height: "40px" }}
                              value={Location}
                              onChange={(e) =>
                                handleChange(e, AccordionTabID)
                              }
                            />{" "}
                            <span className="text-danger">
                              {Error?.Location}
                            </span>
                          </div>
                          <div className="col-md-3">
                            {FeildSeriesOne ? (
                              <label
                                className="label"
                                htmlFor="pidsNormalDisplay"
                              >
                                {FeildSeriesOne}
                                <span className="text-danger"> *</span>
                              </label>
                            ) : (
                              <label
                                className="label"
                                htmlFor="pidsNormalDisplay"
                              >
                                PIDS Normal Display
                                <span className="text-danger"> *</span>
                              </label>
                            )}
                            <WithSelectDropDown
                              data={NormalDisplayList}
                              placeholder="Select"
                              name="PIDS_EVAC_Messages"
                              initialVal={-1}
                              HandleChange={(e) =>
                                handleChange(e, AccordionTabID)
                              }
                              value={PIDS_EVAC_Messages}
                            />
                            <span className="text-danger">
                              {Error?.PIDS_EVAC_Messages}
                            </span>
                          </div>
                          <div className="col-md-3">
                            {FeildSeriesTwo ? (
                              <label
                                className="label"
                                htmlFor="pidsNormalDisplay"
                              >
                                {FeildSeriesTwo}
                                <span className="text-danger"> *</span>
                              </label>
                            ) : (
                              <label
                                className="label"
                                htmlFor="pidsNormalDisplay"
                              >
                                PAVA Alert Message
                                <span className="text-danger"> *</span>
                              </label>
                            )}

                            <WithSelectDropDown
                              data={NormalDisplayList}
                              placeholder="Select"
                              name="PAVA_EVAC_Messages"
                              initialVal={-1}
                              value={PAVA_EVAC_Messages}
                              HandleChange={(e) =>
                                handleChange(e, AccordionTabID)
                              }
                            />
                            <span className="text-danger">
                              {Error?.PAVA_EVAC_Messages}
                            </span>
                          </div>
                          <div className="col-md-3">
                            <label className="label" htmlFor="acid">
                              ACID
                              <span className="text-danger"> *</span>
                            </label>
                            <WithSelectDropDown
                              data={NormalDisplayList}
                              placeholder="Select"
                              name="ACID"
                              initialVal={-1}
                              value={ACID}
                              HandleChange={(e) =>
                                handleChange(e, AccordionTabID)
                              }
                            />
                            <span className="text-danger">{Error?.ACID}</span>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-3">
                            <label className="label" htmlFor="cctvAlarmPopup">
                              CCTV Alarm Pop-Up
                              <span className="text-danger"> *</span>
                            </label>
                            <WithSelectDropDown
                              data={NormalDisplayList}
                              placeholder="Select"
                              name="CCTV_Alarm_Pop_Up"
                              initialVal={-1}
                              value={CCTV_Alarm_Pop_Up}
                              HandleChange={(e) =>
                                handleChange(e, AccordionTabID)
                              }
                            />
                            <span className="text-danger">
                              {Error?.CCTV_Alarm_Pop_Up}
                            </span>
                          </div>
                          <div className="col-md-3">
                            <label
                              className="label"
                              htmlFor="cctvCameraMapping"
                            >
                              CCTV Camera Mapping
                              <span className="text-danger"> *</span>
                            </label>
                            <WithSelectDropDown
                              data={NormalDisplayList}
                              placeholder="Select"
                              name="CCTV_Camera_Mapping"
                              initialVal={-1}
                              value={CCTV_Camera_Mapping}
                              HandleChange={(e) =>
                                handleChange(e, AccordionTabID)
                              }
                            />
                            <span className="text-danger">
                              {Error?.CCTV_Camera_Mapping}
                            </span>
                          </div>
                          <div className="col-md-3">
                            <label className="label" htmlFor="visualAlarm">
                              Visual Alarm
                              <span className="text-danger"> *</span>
                            </label>
                            <WithSelectDropDown
                              data={NormalDisplayList}
                              placeholder="Select"
                              name="Visual_Alarm"
                              initialVal={-1}
                              value={Visual_Alarm}
                              HandleChange={(e) =>
                                handleChange(e, AccordionTabID)
                              }
                            />
                            <span className="text-danger">
                              {Error?.Visual_Alarm}
                            </span>
                          </div>
                          <div className="col-md-3">
                            <label
                              className="label"
                              htmlFor="pidsNormalDisplay"
                            >
                              Remarks
                              {/* <span className="text-danger"> *</span> */}
                            </label>
                            <textarea
                              class="form-control"
                              value={Remarks}
                              name="Remarks"
                              onChange={(e) =>
                              handleChange(e, AccordionTabID)
                              

                              }
                            ></textarea>
                          </div>
                        </div>
                        <div className="mt-3">
                          {!Model?.IsAllClosed && (
                            <button
                              type="submit"
                              onClick={() => SaveTelecomeQuerterly(ele)}
                              className="btn btn-primary"
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })
          : ""}
      </Accordion>
    </div>
  );
}

export default TelecomQuarterly;
