import React from "react";
import { HtmlTable } from "../../components/HtmlTable";
import $ from "jquery";
import BreakdownMaintenanceService from "./BreakdownMaintenanceService";
import { useState, useEffect, useReducer, useContext } from "react";
import BootrapModel from "../../components/BootrapModel";
import BootrapSide from "../../components/BootrapSide";
import { Dropdown } from "../../components/Dropdown";
import { WithoutSelectDropDown } from "../../components/WithoutSelectDropDown";
import { WithSelectDropDown } from "../../components/WithSelectDropDown";

import {
  GetLoginUserID,
  getUserDetails,
  getPrivilege,
  getL0RoleID,
} from "../../services/CommonService";
import { SaveDepManualBreakdownMaindence } from "./SaveDepManualBreakdownMaindence";

import swal from "sweetalert";
import { DownloadModel } from "./DownloadModel";
import Global from "../../services/Global";
import { AppContext } from "../../services/ContextProvider";
import { useLocation } from "react-router-dom";
import { DownloadForCorrectiveMaintanence } from "./BreakdownMaintenanceService";
import { BreakdownDepartment_GetTicketRemarks } from "./BreakdownMaintenanceService";
import { saveAs } from "file-saver";
import { SaveDepManualBreakdownMaindenceRCAReport } from "./SaveDepManualBreakdownMaindenceRCAReport";
import { GrStatusCritical } from "react-icons/gr";

const obj = new BreakdownMaintenanceService();
const { COOKIES } = Global;

export const BreakdownDepartmentList = ({ TabValue = 0 }) => {
  const [popUp, setPopUp] = useState(false);
  const [DepartmentList, SetDepartmentList] = useState([]);
  const [DepartmentID, SetDepartmentID] = useState(0);
  const [DepartmentBreakDownList, SetDepartmentBreakDownList] = useState([]);
  const [StationList, setStationList] = useState([]);
  const [CorridorList, SetCorridorList] = useState([]);
  const [FilterStationList, setFilterStationList] = useState([]);

  const [EmployeeDropDown, setEmployeeDropDown] = useState([]);
  const [StatusDropDown, setStatusDropDown] = useState([]);
  const [StatusDepDropDown, setStatusDepDropDown] = useState([]);
  const [FilteredEmployeeDropDown, setFilteredEmployeeDropDown] = useState([]);
  const [StationID, SetStationID] = useState(0);
  const [CorridorID, SetCorridorID] = useState(0);
  const [DepartmentTicketDetailes, SetDepartmentTicketDetailes] = useState([]);
  const [AssignByDropDown, setAssignByDropDown] = useState([]);
  const [ReportToDropdown, setReportToDropdown] = useState([]);
  const [EquipmentCyclicList, setEquipmentCyclicList] = useState([]);
  const [EquipmentCyclicIDS, setEquipmentCyclicIDS] = useState([]);
  const [FilteredAssignByDropDown, setFilteredAssignByDropDown] = useState([]);
  const [PartDropDown, setPartDropDown] = useState([]);
  const [FilteredPartDropDown, setFilteredPartDropDown] = useState([]);

  const [Sparelist, setSpareList] = useState([]);
  const [Remarkslist, setRemarkslist] = useState([]);
  const [FilteredSpareDropdown, setFilteredSpareDropdown] = useState([]);

  const [SelectedSpareList, setSelectedSpare] = useState([]);
  const [DepartmentTableRefresh, SetDepartmentTableRefresh] = useState(0);

  const [RefreshforForm, setRefreshforForm] = useReducer((x) => x + 1, 0);
  const [DownLoadRefresh, setDownLoadRefresh] = useReducer((x) => x + 1, 0);

  const [IsView, SetIsView] = useState(false);
  const [IsViewforManual, setIsViewforManual] = useState(false);
  const [IsViewforDepartment, setIsViewforDepartment] = useState(false);
  const [IsReportforDepartment, setIsReportforDepartment] = useState(false);
  const [isReportPopUp, setIsReportPopUp] = useState(false);
  const [Flags, setFlag] = useState(0);
  const [EqiupmentTagList, setEqiupmentTagList] = useState([]);
  const [ParameterList, setParameterList] = useState([]);
  const [StatausList, SetStatausList] = useState([]);
  const [FileDatas, setFileDatas] = useState([]);
  const [TicketID, setTicketID] = useState(0);
  const [TicketNo, setTicketNo] = useState("");
  const [StatusList, SetStatusList] = useState([]);

  const {
    getCookies,
    showLoading,
    hideLoading,
    handleGlobalStationName,
    GetLoginStationBaseOnDep,
    BreakDownFilterModel,
    setBreakDownFilterModel,
  } = useContext(AppContext);
  const [BreakdownDate, setBreakdownDate] = useState("");
  const [BreakdownTodate, SetBreakdownTodate] = useState("");
  const [StatusValue, SetStatusValue] = useState("");

  const onBreakdownDateChange = (e) => {
    setBreakdownDate(e.target.value);
  };

  const handleChange = (e) => {
    SetStatusValue(e.target.value);
  };

  const onBreakdownToDateChange = (e) => {
    SetBreakdownTodate(e.target.value);
  };
  const onBreakdownDateCleareChange = () => {
    setBreakdownDate("");
    SetBreakdownTodate("");
  };

  const columnNamesForDepartMent = [
    { title: "Ticket ID", visible: false },
    {
      title: "Station Code",
      createdCell: (td, cellData, rowData) => {
        // Add your conditional logic here to determine the class name
        var className = rowData[16] == 1 ? "text-danger" : "";

        var StatusName = rowData[11];

        let IsPending =
          rowData[18] == 1 && StatusName == "Assigned"
            ? `<i class="fa fa-flag text-danger" aria-hidden="true"></i>`
            : "";

        if (StatusName == "Attended") {
          $(td).parent().css("background", "#dedede");
          // table.dataTable.table-striped .dtfc-fixed-left
          $(td).css("background", "#87f093");
        }
        if (StatusName == "Rejected") {
          $(td).parent().css("background", "#f27d89");
        }
        if (StatusName == "Open") {
          $(td).parent().css("background", "#93a7fd");
        }
        if (StatusName == "Assigned") {
          $(td).parent().css("background", "#ffff3f");
        }

        // $(td).parent().addClass("bg-danger")
        // $(td).parent().css("background","red !important")

        $(td).addClass(className); // Add the determined class name to the cell

        let WrapDiv = $("<div>");
        let ParaTag = $(`<p>${cellData} ${IsPending}</p>`);
        WrapDiv.append(ParaTag);
        $(td).html(WrapDiv);
        // Set the cell content
        // $(td).text(rowData[11]); // Set the cell content
      },
    },

    {
      title: "Ticket No",
      createdCell: (td, cellData, rowData) => {
        var b = $(`<a class='btn icon-btn text-center ${
          rowData[16] == 1 ? "text-danger" : ""
        }' title='View' data-bs-toggle="modal" data-bs-target="#DepManualBreakdownMaintenceModel" >
            ${cellData}
          </a>`).on("click", function () {
          setIsViewforDepartment(true);
          EditDepartmentBreakdown(rowData[0], 0);
        });
        var dd = `<div class="action-label">
        <a class="btn btn-white btn-sm "  href="javascript:void(0);" style="font-size: 10px;padding: 1px;border-radius: 50px 20px !important;min-width: auto;    background: #f62d51;
        border: 1px solid #f62d51; color: #fff">
        Cyclic Check
        </a>
      </div>`;

        $(td).html(b);
        if (rowData[17] == 1) {
          $(td).append(dd);
        }
      },
    },

    {
      title: "Equipment Tag No",
      createdCell: (td, cellData, rowData) => {
        // Add your conditional logic here to determine the class name
        var className = rowData[16] == 1 ? "text-danger" : "";

        $(td).addClass(className); // Add the determined class name to the cell
        $(td).text(cellData); // Set the cell content
      },
    },
    {
      title: "Parameter",
      createdCell: (td, cellData, rowData) => {
        // Add your conditional logic here to determine the class name
        var className = rowData[16] == 1 ? "text-danger" : "";

        $(td).addClass(className); // Add the determined class name to the cell
        $(td).text(cellData); // Set the cell content
      },
      className: "hide",
    },
    {
      title: "UOM",
      createdCell: (td, cellData, rowData) => {
        // Add your conditional logic here to determine the class name
        var className = rowData[16] == 1 ? "text-danger" : "";

        $(td).addClass(className); // Add the determined class name to the cell
        $(td).text(cellData); // Set the cell content
      },
      className: "hide",
    },
    {
      title: "Assigned By",
      createdCell: (td, cellData, rowData) => {
        // Add your conditional logic here to determine the class name
        var className = rowData[16] == 1 ? "text-danger" : "";

        $(td).addClass(className); // Add the determined class name to the cell
        $(td).text(cellData); // Set the cell content
      },
    },
    {
      title: "Assigned To",
      createdCell: (td, cellData, rowData) => {
        // Add your conditional logic here to determine the class name
        var className = rowData[16] == 1 ? "text-danger" : "";

        $(td).addClass(className); // Add the determined class name to the cell
        $(td).text(cellData); // Set the cell content
      },
    },
    {
      title: "Problem Description",
      createdCell: (td, cellData, rowData) => {
        // Add your conditional logic here to determine the class name
        var className = rowData[16] == 1 ? "text-danger" : "";

        $(td).addClass(className); // Add the determined class name to the cell
        $(td).text(cellData); // Set the cell content
      },
    },
    {
      title: "Work Description",
      createdCell: (td, cellData, rowData) => {
        // Add your conditional logic here to determine the class name
        var className = rowData[16] == 1 ? "text-danger" : "";

        $(td).addClass(className); // Add the determined class name to the cell
        $(td).text(cellData); // Set the cell content
      },
    },
    {
      title: "Location",
      createdCell: (td, cellData, rowData) => {
        // Add your conditional logic here to determine the class name
        var className = rowData[16] == 1 ? "text-danger" : "";

        $(td).addClass(className); // Add the determined class name to the cell
        $(td).text(cellData); // Set the cell content
      },
    },
    {
      title: " Status",
      createdCell: (td, cellData) => {
        var b = GetPeriodicStatus(cellData);
        $(td).html(b);
      },
    },
    {
      title: "Submit  ",

      createdCell: (td, cellData, rowData) => {
        var b = "",
          c = "",
          d = "",
          f = "",
          g = "",
          e = "";

        if (getPrivilege().includes("BREAKDOWNMAINTENANCEEDIT")) {
          b =
            $(`<button class='btn icon-btn text-center' title='Edit' data-bs-toggle="modal" data-bs-target="#DepManualBreakdownMaintenceModel" >
              <i class='fa fa-pencil m-r-5'></i>
            </button>`).on("click", function () {
              setIsViewforDepartment(false);
              EditDepartmentBreakdown(rowData[0], 0);
            });
        }

        if (getPrivilege().includes("BREAKDOWNMAINTENANCEEDIT")) {
          e =
            $(`<button class='btn icon-btn text-center' title='Report' data-bs-toggle="modal" data-bs-target="#DepManualBreakdownMaintenceRCAReportModel" >
             <i class="fa fa-file-text-o" aria-hidden="true"></i>
            </button>`).on("click", function () {
              setIsReportPopUp(true);
              setPopUp(true);
              setTicketID(rowData[0]);
            });
        }

        if (getPrivilege().includes("BREAKDOWNMAINTENANCEDELETE")) {
          c = $(`<button class='btn icon-btn text-center' title='Delete'  >
        <i class='fa fa-trash m-r-5'></i>
      </button>`).on("click", function () {
            // SetIsView(true)
            EditDepartmentBreakdown(rowData[0], 1);
          });
        }
        // if (rowData[0]) {
        //   f =
        //     $(`<button class='btn icon-btn text-center' title='Selfie Download'    >
        // <i class='fa fa-file-image-o m-r-5'></i>
        //       </button>`).on("click", function () {
        //       // SetIsView(true)
        //       TicketDownloadFiles(rowData[0]);
        //       // setFlag(2);
        //     });
        // }
        if (rowData[0]) {
          g = $(`<button class='btn icon-btn text-center' title='Remarks' 
            data-bs-toggle="modal" data-bs-target="#RemarksFiles"   >
        <i class='fa fa-comment m-r-5'></i>
              </button>`).on("click", function () {
            // SetIsView(true)
            GetRemarkList(rowData[0]);

            // setFlag(2);
          });
        }

        if (rowData[13] == 1) {
          d =
            $(`<button class='btn icon-btn text-center'  data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'  >
        <i class='fa fa-download m-r-5'></i>
              </button>`).on("click", function () {
              // SetIsView(true)
              DownloadFiles(rowData[0], 0, 8);
              setFlag(2);
            });
        } else {
          d =
            $(`<button class='btn icon-btn text-center' disabled data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'  >
        <i class='fa fa-download m-r-5'></i>
              </button>`).on("click", function () {
              // SetIsView(true)
            });
        }

        $(td).html(b);
        $(td).append(c);
        $(td).append(d);
        $(td).append(f);
        $(td).append(g);
        $(td).append(e);
      },
    },
    { title: "DownloadFlag", visible: false },
    { title: "OrderFlagId", visible: false },

    // { title: "LimitExited", data: "LimitExited" },
  ];

  useEffect(() => {
    var employeeID = GetLoginUserID();
    if (DepartmentID) {
      obj
        .BreakdownMaintenance_GetDepartmentTicket(
          StationID,
          employeeID,
          BreakdownDate,
          DepartmentID,
          0,
          BreakdownTodate,
          StatusValue,
          CorridorID
        )
        .then((response) => {
          var BreakList = [];
          if (response?.data.length > 0) {
            var breakdownMaintenance = response.data;
            breakdownMaintenance.map((v) => {
              var row = [];
              row.push(v.TicketID);
              row.push(v.StationCode);
              row.push(v.TicketNo);
              row.push(v.EquipmentCode);
              row.push(v.ParameterName);
              row.push(v.ParameterUnit);
              row.push(v.AssignedByName);
              row.push(v.AssignedToName);
              row.push(v.ProblemDescription);
              row.push(v.WorkDescription);
              row.push(v.Location);
              row.push(v.StatusName);
              row.push("");
              row.push(v.DownloadFlag);
              row.push(v.OrderFlagId);
              row.push(v.Type);
              row.push(v.LimitExited);
              row.push(v.ISCyclicCheck);
              row.push(v.IsPending);
              BreakList.push(row);
            });
            SetDepartmentBreakDownList(BreakList);
          } else {
            SetDepartmentBreakDownList([]);
          }
        })
        .catch((Err) => {
          console.log(Err);
        });
    }
    if (DepartmentID) {
      obj
        .BreakdownMaintenance_GetStatusList(employeeID, DepartmentID)
        .then((response) => {
          if (response?.data) {
            SetStatusList(response?.data);
          }
        });
    }
    // hideLoading();
  }, [
    StationID,
    DepartmentTableRefresh,
    BreakdownDate,
    DepartmentID,
    BreakdownTodate,
    StatusValue,
    CorridorID,
  ]);

  function DepartmenttableRefresh(ref) {
    SetDepartmentTableRefresh(ref);
  }

  const HandleChange = (e) => {
    SetStationID(e.target.value);
    var selectedOption = e.target.options[e.target.selectedIndex];

    var selectedLabel = selectedOption.text;
    handleGlobalStationName(selectedLabel ?? "");
  };

  const HandleCorridorChange = (e) => {
    var dataLst = [{ value: 0, key: "All" }];

    let departmentIdInt = parseInt(DepartmentID, 10);
    let DefindValue = DepartmentList.find((e) => e.value === departmentIdInt);

    FilterStationList.map((data) => {
      if (
        e.target.value == data.CorridorID &&
        DefindValue.value == data.DepartmentID
      ) {
        dataLst.push({
          value: data.value,
          key: data.label,
          CorridorID: data.CorridorID,
          Type: data.Type,
        });
      }
    });
    setStationList(dataLst);
    SetStationID(0);

    SetCorridorID(e.target.value);
    // var selectedOption = e.target.options[e.target.selectedIndex];

    // var selectedLabel = selectedOption.text;
    // handleGlobalStationName(selectedLabel ?? "");
  };

  const CommonHandleChange = (e) => {
    const { value } = e.target;

    var dataLst = [{ value: 0, key: "All" }];

    let departmentIdInt = parseInt(value, 10);

    let DefindValue = DepartmentList.find((e) => e.value === departmentIdInt);

    FilterStationList.map((data) => {
      if (
        CorridorID == data.CorridorID &&
        DefindValue.value == data.DepartmentID
      ) {
        dataLst.push({
          value: data.value,
          key: data.label,
          CorridorID: data.CorridorID,
          Type: data.Type,
        });
      }
    });
    setStationList(dataLst);

    SetStationID(0);
    SetDepartmentID(value);
  };

  useEffect(() => {
    getDropDownData();
  }, []);

  const getDropDownData = async () => {
    showLoading();
    await obj
      .BreakdownMaintenance_GetDepartmentBreakdownDropdownData()
      .then(async (res) => {
        if (res?.data != null && res.data.length > 0) {
          // setEqiupmentTagList(res.data[0]);
          setParameterList(res.data[1]);
          const DropDown = res.data[2];
          let result = [];
          if (getUserDetails().RoleID == COOKIES.TechRoleID) {
            result = DropDown.filter(function (item) {
              return [26, 25].indexOf(item.value) != -1;
            });
          }
          SetStatausList(result);

          await GetDepartmentList();
          hideLoading();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetDepartmentList = async () => {
    debugger;
    var employeeID = GetLoginUserID();

    await obj
      .BreakdownDepartment_GetDepartmentList(employeeID)
      .then((res) => {
        if (
          res != null &&
          res?.data?.departmentList != null &&
          res?.data?.departmentList.length > 0
        ) {
          SetDepartmentList([...res?.data?.departmentList]);
        } else {
          SetDepartmentList([]);
        }

        var dataLst = [{ value: 0, key: "All" }];
        const { corridorList, departmentList } = res?.data;

        if (corridorList != null && corridorList.length > 0) {
          SetCorridorList(corridorList);
        }

        let stationList = GetLoginStationBaseOnDep();

        if (stationList != null && stationList.length > 0) {
          stationList.map((data) => {
            if (
              corridorList[0].value == data.CorridorID &&
              departmentList[0].value == data.DepartmentID
            ) {
              dataLst.push({
                value: data.value,
                key: data.label,
                CorridorID: data.CorridorID,
                Type: data.Type,
              });
            }
          });

          SetCorridorID(corridorList[0].value);
          SetDepartmentID(departmentList[0].value);
          setStationList(dataLst);
          setFilterStationList(stationList);
          if (TabValue == 0) {
            const {
              DepartmentID: tempDepartmentID,
              CorridorID: tempCorridorID,
              StationID: tempStationID,
            } = BreakDownFilterModel;

            if (tempDepartmentID && tempCorridorID && tempStationID) {
              let StationList = [{ value: 0, key: "All" }];
              stationList.forEach((data) => {
                if (
                  tempCorridorID == data.CorridorID &&
                  tempDepartmentID == data.DepartmentID
                ) {
                  StationList.push({
                    value: data.value,
                    key: data.label,
                    CorridorID: data.CorridorID,
                    Type: data.Type,
                  });
                }
              });
              setStationList(StationList);
              SetDepartmentID(tempDepartmentID);
              SetCorridorID(tempCorridorID);
              SetStationID(tempStationID);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const EditDepartmentBreakdown = (TicketID, IsDeleted) => {
    if (IsDeleted == 1) {
      swal({
        title: "Are you sure?",
        text: "Do you want to delete this ticket?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          showLoading();
          obj
            .BreakdownMaintenance_GetDepartmentTicketUsingTicketID(
              TicketID,
              IsDeleted
            )
            .then((res) => {
              SetDepartmentTableRefresh(DepartmentTableRefresh + 1);

              if (res.data != null && res.data.length > 0) {
              }
              hideLoading();
            })
            .catch((err) => {
              console.log(err);
              hideLoading();
            });
        }
      });
    } else {
      showLoading();
      var employeeID = GetLoginUserID();
      obj
        .BreakdownMaintenance_GetDepartmentTicketUsingTicketID(
          TicketID,
          IsDeleted,
          employeeID
        )
        .then((res) => {
          var temp = res.data[0];

          if (temp != null && temp.length > 0) {
            SetDepartmentTicketDetailes(temp[0]);
          }
          setSelectedSpare(res.data[1]);

          if (res.data[3] != null && res.data[3].length > 0) {
            var result = [];
            if (
              (temp[0].TicketStatusID == 110 ||
                temp[0].TicketStatusID == 111 ||
                temp[0].TicketStatusID == 112) &&
              (getUserDetails().PrefixName == "L3" ||
                getUserDetails().PrefixName == "L2" ||
                getUserDetails().PrefixName == "L1")
            ) {
              result = res.data[3].filter(function (item) {
                return [28].indexOf(item.value) != -1;
              });
            } else if (getUserDetails().PrefixName == "L0") {
              result = res.data[3].filter(function (item) {
                return [25, 26].indexOf(item.value) != -1;
              });
            } else if (
              getUserDetails().PrefixName == "L2" ||
              getUserDetails().PrefixName == "L3"
            ) {
              result = res.data[3].filter(function (item) {
                return [29, 30].indexOf(item.value) != -1;
              });
            } else {
              result = res.data[3].filter(function (item) {
                return [29, 30].indexOf(item.value) != -1;
              });
            }

            setStatusDepDropDown([...result]);
          }

          if (res.data[2] != null && res.data[2].length > 0) {
            const EmpList = res.data[2].filter((x) => {
              return x.roleID == getL0RoleID();
            });

            setAssignByDropDown(res.data[2]);

            // setReportToDropdown(res.data[2]);
          }

          if (res?.data[5]?.length > 0) {
            setEquipmentCyclicList([...res.data[5]]);
          }

          if (res?.data[6]?.length > 0) {
            setEquipmentCyclicIDS([...res.data[6]]);
          }

          setRefreshforForm();
        })
        .catch((err) => {
          console.log(err);
          hideLoading();
        });
    }
  };

  const DownloadFiles = (ticketID, AttachmetID, Flag) => {
    // showLoading()
    obj
      .GetFilesUsingTicketID(ticketID, AttachmetID, Flag)
      .then((res) => {
        // hideLoading()

        setFileDatas(res.data);
        // SetRefresh(Refresh + 1);

        setDownLoadRefresh();
      })
      .catch((err) => {
        // hideLoading()
        console.log(err);
      });
  };

  const GetRemarkList = (ticketID) => {
    // showLoading()
    BreakdownDepartment_GetTicketRemarks(ticketID)
      .then((res) => {
        setRemarkslist(res.data.data.remarksList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function DepSubmitBtn() {
    let SendForApprovelBtn = $(
      ` <button  type="button"  id="ManualBreakdownMaintenceTable" class="btn add-btn m-l-15" data-bs-toggle="modal" data-bs-target="#DepManualBreakdownMaintenceModel"
          > <i class="fa fa-plus"></i> Add </button> `
    ).on("click", async function (e) {
      e.preventDefault();
      await setIsViewforDepartment(false);
      //change
      await setIsReportforDepartment(false);
      //**** */
      await EditDepartmentBreakdown(0, 0);
      await SetDepartmentTicketDetailes({});
      await setRefreshforForm();
    });
    let Fragment = $("<span></span>");
    if (getPrivilege().includes("BREAKDOWNMAINTENANCECREATE")) {
      Fragment.prepend(SendForApprovelBtn);
    }

    return Fragment;
  }

  return (
    <>
      <div className="row">
        <div className={`col-md-3 px-2 `} style={{ float: "right" }}>
          <Dropdown
            data={DepartmentList}
            placeholder="--Select--"
            value={DepartmentID}
            name={"DepartmentID"}
            className="DepartmentID"
            initialVal={0}
            HandleChange={CommonHandleChange}
          />
        </div>
        <div className="col-2" style={{ float: "right" }}>
          <WithoutSelectDropDown
            data={CorridorList}
            value={CorridorID}
            name={"StationIDDropDown"}
            HandleChange={HandleCorridorChange}
          ></WithoutSelectDropDown>
        </div>
        <div className="col-1" style={{ float: "right" }}>
          <WithoutSelectDropDown
            data={StationList}
            value={StationID}
            name={"StationIDDropDown"}
            HandleChange={HandleChange}
          ></WithoutSelectDropDown>
        </div>
        <div className="col-md-6 px-2 " style={{ float: "right" }}>
          <div className="row">
            <div className="col-md-4 mb-1">
              <input
                type="date"
                className="form-control"
                id="BreakdownDate"
                style={{ height: "40px" }}
                pattern="\d{4}-\d{2}-\d{2}"
                max={new Date().toISOString().split("T")[0]}
                value={BreakdownDate}
                onChange={(e) => {
                  onBreakdownDateChange(e);
                }}
              />
            </div>
            <div className="col-md-4">
              <input
                type="date"
                className="form-control"
                id="BreakdownDate"
                style={{ height: "40px" }}
                pattern="\d{4}-\d{2}-\d{2}"
                max={new Date().toISOString().split("T")[0]}
                min={BreakdownDate}
                value={BreakdownTodate}
                onChange={(e) => {
                  onBreakdownToDateChange(e);
                }}
              />
            </div>
            <div className="col-md-2">
              <WithSelectDropDown
                data={StatusList}
                placeholder="Select"
                name="CCTV_Camera_Mapping"
                initialVal={-1}
                HandleChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-md-1 pt-1">
              <button
                type="button"
                id="addRow"
                onClick={onBreakdownDateCleareChange}
                className="btn btn-sm btn-danger"
              >
                <i className="fa fa-refresh " title="Reset" />
              </button>
            </div>
          </div>
        </div>

        {/* <PageHeaderTitle title={"Breakdown Maintenance"}></PageHeaderTitle> */}
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              {/* <div style={{ position: "relative", top: "-20px" }}> */}
              <HtmlTable
                columnList={columnNamesForDepartMent}
                rowList={DepartmentBreakDownList}
                orderby={2}
                FixedColums={{
                  left: 1,
                  right: 1,
                }}
                id={"DepMannualBreakdownMaintence"}
                SearchPostponedHtml={DepSubmitBtn}
              />{" "}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <BootrapModel
        Id={"DepManualBreakdownMaintenceModel"}
        CloseID="DepManualBreakdownMaintenceModelClose"
        ModelTitle={
          DepartmentTicketDetailes.TicketID == undefined
            ? "Breakdown Maintenance Ticket Creation"
            : IsViewforManual
            ? `Breakdown Maintenance Ticket View - (${DepartmentTicketDetailes.TicketNo})`
            : `Breakdown Maintenance Ticket Edit - ${DepartmentTicketDetailes.TicketNo}`
        }
        // modelSize={"modal-xl"}
        modelSize={"modal-fullscreen"}
        module={
          <SaveDepManualBreakdownMaindence
            IsShow={IsViewforManual}
            EqiupmentTagList={EqiupmentTagList}
            ParameterList={ParameterList}
            EmployeeDropDown={EmployeeDropDown}
            StationList={StationList}
            TicketDetailes={DepartmentTicketDetailes}
            StatusDropDown={StatusDepDropDown}
            tableRefresh={DepartmenttableRefresh}
            RefreshforForm={RefreshforForm}
            ReportToDropdown={ReportToDropdown}
            AssignByDropDown={AssignByDropDown}
            IsView={IsView}
            Sparelist={[]}
            FilterSpareList={Sparelist}
            EquipmentCyclicList={EquipmentCyclicList}
            EquipmentCyclicIDS={EquipmentCyclicIDS}
            PartDropDown={PartDropDown}
            SelectedSpareList={SelectedSpareList}
          ></SaveDepManualBreakdownMaindence>
        }
      >
        {" "}
      </BootrapModel>

      {/* report  */}
      <BootrapModel
        Id={"DepManualBreakdownMaintenceRCAReportModel"}
        CloseID="BreakdownMaintenanceRCAReportModelClose"
        ModelTitle={`Breakdown Maintenance RCA Report - (${TicketNo})`}
        closepopup={() => setPopUp((P) => !P)}
        modelSize={"modal-fullscreen"}
        module={
          <SaveDepManualBreakdownMaindenceRCAReport
            popUp={popUp}
            IsShow={IsViewforManual}
            EqiupmentTagList={EqiupmentTagList}
            ParameterList={ParameterList}
            EmployeeDropDown={EmployeeDropDown}
            StationList={StationList}
            TicketDetailes={DepartmentTicketDetailes}
            StatusDropDown={StatusDepDropDown}
            tableRefresh={DepartmenttableRefresh}
            RefreshforForm={RefreshforForm}
            ReportToDropdown={ReportToDropdown}
            AssignByDropDown={AssignByDropDown}
            IsView={IsView}
            Sparelist={[]}
            FilterSpareList={Sparelist}
            EquipmentCyclicList={EquipmentCyclicList}
            EquipmentCyclicIDS={EquipmentCyclicIDS}
            PartDropDown={PartDropDown}
            SelectedSpareList={SelectedSpareList}
            TicketID={TicketID}
          ></SaveDepManualBreakdownMaindenceRCAReport>
        }
      >
        {" "}
      </BootrapModel>

      <BootrapModel
        Id={"DownLoadFiles"}
        CloseID="DownLoadFilesClose"
        ModelTitle={"Breakdown Maintenance - Download Attachments"}
        modelSize={"modal-xl"}
        module={
          <DownloadModel
            Flag={Flags}
            DeleteAttachment={DownloadFiles}
            FileDatas={FileDatas}
            DownLoadRefresh={DownLoadRefresh}
          ></DownloadModel>
        }
      >
        {" "}
      </BootrapModel>

      <BootrapSide
        Id={"RemarksFiles"}
        CloseID="RemarksFilesClose"
        ModelTitle={"Breakdown Ticket - Remarks"}
        modelSize={"modal-dialog-scrollable"}
        module={
          <>
            <div class="chats">
              {Remarkslist != null && Remarkslist.length > 0 ? (
                Remarkslist.map((x) => (
                  <>
                    {x.Remarks != null && x.Remarks !== "" && (
                      <>
                        <div class="chat-line">
                          <span class="chat-date">{x.EmployeeName}</span>
                        </div>
                        <div class="chat chat-left">
                          <div class="chat-body">
                            <div class="chat-bubble">
                              <div class="chat-content">
                                <p></p>
                                <p>{x.Remarks} </p>

                                <span class="chat-time">
                                  {x.FormattedDate} -{x.FormattedTime}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))
              ) : (
                <div className="text-center">
                  <span className="">No Remarks Found</span>
                </div>
              )}

              {/* <div class="chat-action-btns">
           <ul>
             <li>
               <a href="#" class="share-msg" title="Share">
                 <i class="fa fa-share-alt"></i>
               </a>
             </li>
             <li>
               <a href="#" class="edit-msg">
                 <i class="fa fa-pencil"></i>
               </a>
             </li>
             <li>
               <a href="#" class="del-msg">
                 <i class="fa fa-trash-o"></i>
               </a>
             </li>
           </ul>
         </div> */}
            </div>
          </>
        }
      >
        {" "}
      </BootrapSide>
    </>
  );
};

export const GetPeriodicStatus = (value) => {
  var id = value;
  var name = value;

  if (id == "Assigned") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-info"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Open") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-primary"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Attended") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-secondary" ></i> ${name}
    </a>
  </div>`;
  } else if (id == "Completed") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-success"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Approved") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-success"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Pending") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-warning"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Waitting for Engineer") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-warning"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Closed") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-muted"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Rejected") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-danger"></i> ${name}
    </a>
  </div>`;
  } else if (id == "Under Breakdown") {
    return `<div class="action-label">
    <a class="btn btn-white btn-sm btn-rounded"  href="javascript:void(0);">
      <i class="fa fa-dot-circle-o text-danger"></i> ${name}
    </a>
  </div>`;
  }
};
