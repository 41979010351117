
import React, { useState } from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import makeAnimated from "react-select/animated";


const animatedComponents = makeAnimated();

 const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const customStyles1 = {
  option: provided => ({
    ...provided,
    color: 'black',
    height: '100%',
    background: "white",
    zIndex : 99999999,
  }),
  control: provided => ({
    ...provided,
    maxHeight : "60px",
    overflowY :'auto',
    color: 'black'
  }),
  singleValue: provided => ({
    ...provided,
    color: 'black',
    maxHeight : "1000px",
    overflowY :'auto',
    // primary: "white",
    borderColor: "black"
  }),
  menu : provided => ({
    ...provided,
      zIndex  : 9999,
      // background: "red",
  }),

}

 const ReactMultiSelect = props => {
    


  const [IsAllSelected,setIsAllSelected] = useState(props?.options?.length==props?.value?.length && props?.options?.length > 0);


  if (props.allowSelectAll) {
    return (
      <ReactSelect
      
        {...props}
        styles={ customStyles1}
        options={ props.options ? [props.allOption, ...props.options] : []}
        onChange={selected => {
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === props.allOption.value  && !IsAllSelected
          ) {
            setIsAllSelected(true)
            return props.onChange(props.options);
          }else if(
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === props.allOption.value && IsAllSelected
          ){
            setIsAllSelected(false)

            return props.onChange([]);
          }


          if(props.options.length === selected.length){
            setIsAllSelected(true)
          }


          return props.onChange(selected);
        }}
      />
    );
  }

  return <ReactSelect    
  
  
  theme={(theme) => ({
    ...theme,
     fontColor: "Black",
    colors: {
      ...theme.colors,
      primary25: 'hotpink',
    },
  })}
value={props.value}
  styles={customStyles1} {...props} />;
};

ReactMultiSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  styles: PropTypes.bool
};

ReactMultiSelect.defaultProps = {
  allOption: {
    label: "Select All/None",
    value: "*"
  }
};

 export {ReactMultiSelect,Option,MultiValue,animatedComponents};



