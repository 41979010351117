import React, { useContext, useEffect, useState } from "react";
import { ReactTreeView, Dropdown } from "../../components";
import $ from "jquery";
import { CyclicCheckContext } from "./CyclicCheckContext";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import {
  findDepartmentById,
  GetLoginUserID,
  makeUnique,
} from "../../services/CommonService";
import makeAnimated from "react-select/animated";
import { CyclicCheck_GetListByCyclicCheckId } from "./CyclicCheckServices";
import { showSaveSuccess, showSaveError, showErrorMsg, showWarningMsg } from "../../components";
import { CyclicCheck_CreateUpdate } from "./CyclicCheckServices";
import { AppContext } from "../../services/ContextProvider";
function CyclicCheckCreate(props) {
  const { getCookies, showLoading, hideLoading, handleGlobalStationName,GetLoginStationBaseOnDep } =
    useContext(AppContext);
  const animatedComponents = makeAnimated();
  const { CyclicCheckId, Render, setGridRender } = props;

  const { DropdownModel, setDropdownModel } = useContext(CyclicCheckContext);
  const { DepartmentList, YearList } = DropdownModel;

  const initialEquipmentState = {
    CyclicCheckId: "",
    WorkDescription: "",
    DepartmentID: 0,
    DepartmentName: "",
    StationID: [],
    AuditingLevel: 0,
    PercentageofJob: "",
    Frequency: "",
    TaskType: "",
    RoleIds: "",
    Component: "",
    Remarks: "",
    Weeks: "",
    SubModuleID: "",
    RoleId: "",
    CorridorId: "",
  };

  const initialerrorEquipmentState = {
    DepartmentID: "",
    AuditingLevel: "",
    PercentageofJob: "",
    Frequency: "",
    TaskType: "",
  };
  const [AuditingModel, setAuditingModel] = useState(initialEquipmentState);
  const [CyclicCheckModel, setCyclicCheckModel] = useState(
    initialEquipmentState
  );

  const [error, setformError] = useState(initialerrorEquipmentState);

  useEffect(() => {
    setCyclicCheckModel(initialEquipmentState);
    CyclicCheckGetListByCyclicCheckId();
    // GetDepartmentList();
  }, [Render]);

  const CyclicCheckGetListByCyclicCheckId = async () => {

    if (
      CyclicCheckId == 0 ||
      CyclicCheckId == null ||
      CyclicCheckId == "" ||
      CyclicCheckId == undefined
    ) {
      setAuditingModel(initialEquipmentState);
      onSelect();
    }
    await CyclicCheck_GetListByCyclicCheckId(CyclicCheckId).then((res) => {


      if (res.data.data.cyclicCheckDetailsList != "") {

        setCyclicCheckModel(...res.data.data.cyclicCheckDetailsList);
        let dataLst = [];
        let dataSystemList = [];
        var dataList = [];

        res.data.data.stationList.map((data) => {
          if (
            data.Type == res.data.data.cyclicCheckDetailsList[0].DepartmentType
          ) {
            dataLst.push({
              value: data.value,
              label: data.label,
              key: data.label,
            });
          }
        });

        const { StationID, SystemTypeID } = res.data.data.cyclicCheckDetailsList[0];
        const stationIDs = StationID.split(',');

        const filteredData = res.data.data.equipmentSystemList
          .filter(item => stationIDs.includes(item.StationID.toString()))
          .map(item => ({
            label: item.label,
            value: item.value,
            key: item.key
          }));



        dataSystemList = Array.from(
          new Map(filteredData.map(item => [`${item.label}-${item.value}-${item.key}`, item])).values()
        );
        const equipmentListfilteredData = res.data.data.equipmentList
          .filter(item => stationIDs.includes(item.StationID.toString()))
          .map(item => ({
            label: item.label,
            value: item.value,
            key: item.key
          }));

        dataList = Array.from(
          new Map(equipmentListfilteredData.map(item => [`${item.label}-${item.value}-${item.key}`, item])).values()
        );



        setCyclicCheckModel((PreviousState) => ({
          ...PreviousState,
          EquipmentList: dataList,
          StationList: dataLst,
          EquipmentSystemList: dataSystemList,

        }));
      }
      const dataMappings = [
        { dataKey: "locationList", stateKey: "LocationList" },
        { dataKey: "rolesList", stateKey: "RolesList" },
        { dataKey: "stationList", stateKey: "StationFilterList" },
        { dataKey: "corridorList", stateKey: "CorridorList" },
        { dataKey: "cyclicCheckList", stateKey: "CyclicCheckList" },
        { dataKey: "equipmentList", stateKey: "EquipmentFilterList" },
        { dataKey: "criticalList", stateKey: "CriticalList" },
        {
          dataKey: "equipmentSystemList",
          stateKey: "EquipmentFilterSystemList",
        },
        { dataKey: "weekList", stateKey: "WeekList" },
      ];

      if (res != null) {
        dataMappings.forEach((mapping) => {
          const { dataKey, stateKey } = mapping;
          const data = res.data.data[dataKey];
          if (data != null && data.length > 0) {
            setCyclicCheckModel((prevState) => ({
              ...prevState,
              [stateKey]: data,
            }));
          }
        });

        if (
          CyclicCheckId != 0 &&
          CyclicCheckId != null &&
          CyclicCheckId != "" &&
          CyclicCheckId != undefined
        ) {

          const { CorridorId, StationID } =
            res.data.data.cyclicCheckDetailsList[0];

          const getDataList = (list, id) =>
            list
              .filter((data) => data.value === id)
              .map((data) => ({
                value: data.value,
                label: data.label,
                key: data.label,
              }));

          const CorridordataLst = getDataList(
            res.data.data.corridorList,
            CorridorId
          );
          const StationdataLst = getDataList(
            res.data.data.stationList,
            StationID
          );
          const SystemTypeIDataLst = getDataList(
            res.data.data.equipmentSystemList,
            StationID
          );
          const EquipmentListDataLst = getDataList(
            res.data.data.equipmentList,
            StationID
          );
          const CyclicCheckListDataLst = getDataList(
            res.data.data.cyclicCheckList,
            StationID
          );

          setCyclicCheckModel((previousState) => ({
            ...previousState,
            CorridorId: CorridordataLst[0],
            StationID: StationdataLst,
            SystemTypeID: SystemTypeIDataLst,
            EquipmentID: EquipmentListDataLst,
            SubModuleID: CyclicCheckListDataLst,
          }));
        }
      } else {
        // Handle the case when res is null
      }
      if (res?.data?.data?.cyclicCheckDetailsList != "") {

        const { StationID, SystemTypeID, EquipmentID, SubModuleID } = res.data.data.cyclicCheckDetailsList[0]
        const filteredStationID = res.data.data.stationList.filter(item =>
          StationID?.split(',').map(id => id.trim()).includes(item.value.toString())
        );
        const SystemTypeIDArray = SystemTypeID.toString().split(',');
        const EquipmentIDArray = EquipmentID.toString().split(',');
        const SubModuleIDArray = SubModuleID.toString().split(',');

        const SystemTypefilteredData = res.data.data.equipmentSystemList
          .filter(item => SystemTypeIDArray.includes(item.value.toString()))
          .map(item => ({
            label: item.label,
            value: item.value,
            key: item.key
          }));

        const uniqueData = Array.from(
          new Map(SystemTypefilteredData.map(item => [`${item.label}-${item.value}-${item.key}`, item])).values()
        );

        const equipmentfilteredData = res.data.data.equipmentList
          .filter(item => EquipmentIDArray.includes(item.value.toString()))
          .map(item => ({
            label: item.label,
            value: item.value,
            key: item.key
          }));

        const equipmentuniqueData = Array.from(
          new Map(equipmentfilteredData.map(item => [`${item.label}-${item.value}-${item.key}`, item])).values()
        );

        const SubModuleIDfilteredData = res.data.data.cyclicCheckList
          .filter(item => SubModuleIDArray.includes(item.value.toString()))
          .map(item => ({
            label: item.label,
            value: item.value,
            key: item.key
          }));

        const SubModuleIDuniqueData = Array.from(
          new Map(SubModuleIDfilteredData.map(item => [`${item.label}-${item.value}-${item.key}`, item])).values()
        );


        setCyclicCheckModel((pre) => ({
          ...pre,
          StationID: filteredStationID,
          SystemTypeID: uniqueData[0],
          EquipmentID: equipmentuniqueData[0],
          SubModuleID: SubModuleIDuniqueData[0],
        }));
      }
    });
  };

  const onSelect = (e = 0, name = "") => {
    if (name != "") {
      let obj = findDepartmentById(DepartmentList, e);
      if (!Array.isArray(obj.children)) {
        setCyclicCheckModel((PreviousState) => ({
          ...PreviousState,
          DepartmentID: obj.id,
          DepartmentName: obj.name,
          DepartmentType: obj.Type,
        }));

        if (
          CyclicCheckModel.CorridorId.value != "" &&
          CyclicCheckModel.CorridorId.value != null
        ) {
          let dataLst = [];
          let filStationPartDropDown1 = GetLoginStationBaseOnDep();
          filStationPartDropDown1.map((data) => {
            if (
              data.DepartmentID == obj.id &&
              data.CorridorID == CyclicCheckModel.CorridorId.value
            ) {
              dataLst.push({
                value: data.value,
                label: data.label,
                key: data.label,
              });
            }

            setCyclicCheckModel((PreviousState) => ({
              ...PreviousState,
              StationList: dataLst,
              StationID: [],
              EquipmentSystemList: [],
              SystemTypeID: "",
              EquipmentID: "",

            }));
          });
        }
      }
    }
  };

  const CommonHandleChange = (e, val) => {
    if (val) {
      const { value } = e;
      setCyclicCheckModel((pre) => ({
        ...pre,
        [val]: e,
      }));
      if (
        val == "CorridorId" &&
        CyclicCheckModel.DepartmentType != "" &&
        CyclicCheckModel.DepartmentType != null
      ) {
        
        // alert();
        //Arunbala
        let dataLst = [];
        let filStationPartDropDown1 = GetLoginStationBaseOnDep();
        filStationPartDropDown1.map((data) => {
          if (
            data.DepartmentID == CyclicCheckModel.DepartmentID &&
            data.CorridorID == value
          ) {
            dataLst.push({
              value: data.value,
              label: data.label,
              key: data.label,
            });
          }

          setCyclicCheckModel((PreviousState) => ({
            ...PreviousState,
            StationList: dataLst,
            StationID: [],
            LocationID: "",
            EquipmentID: "",
            SystemTypeID: "",
          }));
        });
      }

      if (
        val == "StationID" &&
        CyclicCheckModel.DepartmentID != "" &&
        CyclicCheckModel.DepartmentID != null
      ) {
        
        // alert();
        let dataList = [];

        dataList = CyclicCheckModel.EquipmentFilterSystemList.filter(item =>
          e.map(item => item.value).includes(item.StationID)
        );
        dataList = dataList.filter(item => item.DepartmentID == CyclicCheckModel.DepartmentID)
        dataList = makeUnique(dataList, ["value", "key"])
        setCyclicCheckModel((PreviousState) => ({
          ...PreviousState,
          EquipmentID: "",
          SystemTypeID: "",
          EquipmentSystemList: dataList,
        }));
      }
      if (val == "SystemTypeID") {
        
        let dataList = [];

        dataList = CyclicCheckModel?.EquipmentFilterList.filter(item =>
          CyclicCheckModel.StationID.map(item => item.value).includes(item.StationID)
        );
        dataList = dataList.filter(item => item.DepartmentID == CyclicCheckModel?.DepartmentID && item.SystemTypeID == value)
        dataList = makeUnique(dataList, ["value", "key"])

        setCyclicCheckModel((PreviousState) => ({
          ...PreviousState,
          // [name]: value,
          EquipmentList: dataList,
        }));
      }

      if (val == "SubModuleID") {
        setCyclicCheckModel((PreviousState) => ({
          ...PreviousState,
          Weeks: "",
        }));
      }
    } else {
      const { name, value } = e.target;
      if (name == "SubModuleID") {
        setCyclicCheckModel((PreviousState) => ({
          ...PreviousState,
          [name]: value,
          Weeks: "",
        }));
      }

      if (
        name == "StationID" ||
        name == "LocationID" ||
        name == "EquipmentID" ||
        name == "RoleId" ||
        name == "CriticalTypeID" ||
        name == "CorridorId"
      ) {
        setCyclicCheckModel((PreviousState) => ({
          ...PreviousState,
          [name]: value,
        }));

        if (
          name == "StationID" &&
          CyclicCheckModel.DepartmentID != "" &&
          CyclicCheckModel.DepartmentID != null
        ) {
          // alert();
          let dataLst = [];
          CyclicCheckModel.EquipmentFilterSystemList.map((data) => {
            if (
              CyclicCheckModel.DepartmentID == data.DepartmentID &&
              value == data.StationID
            ) {
              dataLst.push({
                value: data.value,
                label: data.label,
                key: data.label,
              });
            }
            dataLst = makeUnique(dataLst, ["value", "key"])

            setCyclicCheckModel((PreviousState) => ({
              ...PreviousState,
              EquipmentSystemList: dataLst,
            }));
          });
        }
        if (
          name == "CorridorId" &&
          CyclicCheckModel.DepartmentType != "" &&
          CyclicCheckModel.DepartmentType != null
        ) {
          // alert();
          let dataLst = [];
          CyclicCheckModel.StationFilterList.map((data) => {
            if (data.Type == CyclicCheckModel.DepartmentType) {
              dataLst.push({
                value: data.value,
                label: data.label,
                key: data.label,
              });
            }

            setCyclicCheckModel((PreviousState) => ({
              ...PreviousState,
              StationList: dataLst,
              StationID: [],
              LocationID: "",
            }));
          });
        }
      } else if (name == "SystemTypeID") {

        let dataList = [];

        dataList = CyclicCheckModel?.EquipmentFilterList.filter(item =>
          CyclicCheckModel.StationID.map(item => item.value).includes(item.StationID)
        );
        dataList = dataList.filter(item => item.DepartmentID == CyclicCheckModel?.DepartmentID && item.SystemTypeID == value)
        dataList = makeUnique(dataList, ["value", "key"])

        setCyclicCheckModel((PreviousState) => ({
          ...PreviousState,
          [name]: value,
          EquipmentList: dataList,
        }));
      } else {
        setCyclicCheckModel((PreviousState) => ({
          ...PreviousState,
          [name]: value,
        }));
      }
    }
  };

  const onSubmit = (data) => {
    data.preventDefault();

    console.log(AuditingModel);
    var myerror = error;
    var flag = true;
    if (
      CyclicCheckModel.DepartmentName == null ||
      CyclicCheckModel.DepartmentName == "" ||
      CyclicCheckModel.DepartmentName == 0
    ) {
      myerror.DepartmentName = "Department is Required";
      flag = false;
    } else {
      myerror.DepartmentID = "";
    }
    if (
      CyclicCheckModel?.StationID.length == 0
    ) {
      myerror.StationID = "Station/Section is Required";
      flag = false;
    } else {
      myerror.StationID = "";
    }
    if (
      (CyclicCheckModel.LocationID == null ||
        CyclicCheckModel.LocationID == "" ||
        CyclicCheckModel.LocationID == 0) &&
      CyclicCheckModel.DepartmentType == 95
    ) {
      myerror.LocationID = "Location is Required";
      flag = false;
    } else {
      myerror.LocationID = "";
    }
    if (
      CyclicCheckModel.SystemTypeID?.value == null ||
      CyclicCheckModel.SystemTypeID?.value == "" ||
      CyclicCheckModel.SystemTypeID?.value == 0
    ) {
      myerror.SystemTypeID = "Equipment Type is Required";
      flag = false;
    } else {
      myerror.SystemTypeID = "";
    }
    if (
      CyclicCheckModel.EquipmentID?.value == null ||
      CyclicCheckModel.EquipmentID?.value == "" ||
      CyclicCheckModel.EquipmentID?.value == 0
    ) {
      myerror.EquipmentID = "Equipment is Required";
      flag = false;
    } else {
      myerror.EquipmentID = "";
    }
    if (
      CyclicCheckModel.CorridorId?.value == null ||
      CyclicCheckModel.CorridorId?.value == "" ||
      CyclicCheckModel.CorridorId?.value == 0
    ) {
      myerror.CorridorId = "Corridor is Required";
      flag = false;
    } else {
      myerror.RoleId = "";
    }
    if (
      CyclicCheckModel.RoleId == null ||
      CyclicCheckModel.RoleId == "" ||
      CyclicCheckModel.RoleId == 0
    ) {
      myerror.RoleId = "Approver is Required";
      flag = false;
    } else {
      myerror.RoleId = "";
    }

    if (
      CyclicCheckModel.SubModuleID?.value == null ||
      CyclicCheckModel.SubModuleID?.value == "" ||
      CyclicCheckModel.SubModuleID?.value == 0
    ) {
      myerror.SubModuleID = "Cyclic Check is Required";
      flag = false;
    } else {
      myerror.SubModuleID = "";
    }
    if (
      (CyclicCheckModel.Weeks == null ||
        CyclicCheckModel.Weeks == "" ||
        CyclicCheckModel.Weeks == 0) &&
      CyclicCheckModel.SubModuleID?.value != 5 &&
      CyclicCheckModel.SubModuleID?.value != 6
    ) {
      myerror.Weeks = "Week is Required";
      flag = false;
    } else {
      myerror.Weeks = "";
    }
    if (
      CyclicCheckModel.Remarks == null ||
      CyclicCheckModel.Remarks == "" ||
      CyclicCheckModel.Remarks == 0
    ) {
      myerror.Remarks = "Remarks is Required";
      flag = false;
    } else {
      myerror.Remarks = "";
    }
    if (
      CyclicCheckModel.WorkDescription == null ||
      CyclicCheckModel.WorkDescription == "" ||
      CyclicCheckModel.WorkDescription == 0
    ) {
      if (
        CyclicCheckModel.SubModuleID?.value == 5 ||
        CyclicCheckModel.SubModuleID?.value == 6
      ) {
        myerror.WorkDescription = "Work Description is Required";
      } else {
        myerror.WorkDescription = "Description is Required";
      }
      flag = false;
    } else {
      myerror.WorkDescription = "";
    }
    if (
      CyclicCheckModel.Component == null ||
      CyclicCheckModel.Component == "" ||
      CyclicCheckModel.Component == 0
    ) {
      if (
        CyclicCheckModel.SubModuleID?.value == 5 ||
        CyclicCheckModel.SubModuleID?.value == 6
      ) {
        myerror.Component = "Problem Description is Required";
      } else {
        myerror.Component = "Component is Required";
      }
      flag = false;
    } else {
      myerror.Component = "";
    }

    setformError({ ...myerror });

    if (flag) {
      var USserID = GetLoginUserID();
      CyclicCheckModel.CreatedBy = USserID;
      // CyclicCheckModel.StationIDs = CyclicCheckModel.StationID?.value;
      CyclicCheckModel.StationIDs = CyclicCheckModel?.StationID?.map(item => item.value).join(',');
      CyclicCheckModel.StationID = 0;
      CyclicCheckModel.CorridorId = CyclicCheckModel.CorridorId?.value;
      CyclicCheckModel.EquipmentID = CyclicCheckModel.EquipmentID?.value;
      CyclicCheckModel.SubModuleID = CyclicCheckModel?.SubModuleID?.value;
      CyclicCheckModel.SystemTypeID = CyclicCheckModel?.SystemTypeID?.value;

      CyclicCheckCreateUpdate(CyclicCheckModel);
    }
  };

  const CyclicCheckCreateUpdate = async (CyclicCheckModel) => {
    showLoading();
    await CyclicCheck_CreateUpdate(CyclicCheckModel)
      .then((res) => {
        // hideLoading();
        if (res.isSuccess) {
          if (res.data == -1) {
            showWarningMsg("No Equipment is mapped with station")
          }
          else {
            showSaveSuccess();
          }
          $("#AuditingLevelConfigurationClose").trigger("click");
        } else {
          showErrorMsg();
        }
        setGridRender();

      })
      .catch((err) => {
        // hideLoading();
        console.log(err);
        showSaveError();
      });
  };
  $('#AuditingLevelConfigurationClose').click(function () {
    setformError({})
  });
  return (
    <>
      {" "}
      <form id="CyclicChecksAdd" onSubmit={onSubmit}>
        <div className="row">
          <input
            type="text"
            className="form-control hide"
            placeholder="CyclicCheckId"
            value={CyclicCheckModel.CyclicCheckId}
            onChange={(e) => CommonHandleChange(e, "CyclicCheckId")}
          />

          <div className="col-md-4 col-sm-4">
            <div className="form-group">
              <label className="label">
                Department Name<span className="text-danger"> *</span>{" "}
              </label>
              <br />
              <ReactTreeView
                data={DepartmentList}
                onSelect={onSelect}
                openByDefault={true}
                isSearchNeed={false}
                width={300}
              />
              {CyclicCheckModel.DepartmentName != "" && (
                <span className="text-black mt-1 mr-1">
                  {" "}
                  Selected Department is{" "}
                  <span style={{ color: "blue !important" }}>
                    {CyclicCheckModel.DepartmentName}{" "}
                  </span>
                </span>
              )}

              <span className="text-danger mt-1"> {error.DepartmentName} </span>
            </div>
          </div>
          <div className="col-md-8 col-sm-8">
            <div className="row">
              {" "}
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Corridor <span className="text-danger"> *</span>{" "}
                  </label>
                  {/* 
                  <Dropdown
                    data={CyclicCheckModel.CorridorList}
                    value={CyclicCheckModel.CorridorId}
                    name={"CorridorId"}
                    hideSelectedOptions={false}
                    HandleChange={CommonHandleChange}
                  /> */}
                  <ReactMultiSelect
                    options={CyclicCheckModel.CorridorList}
                    value={CyclicCheckModel.CorridorId}
                    hideSelectedOptions={false}
                    onChange={(e) => {
                      CommonHandleChange(e, "CorridorId");
                    }}
                  />

                  <span className="text-danger mt-1"> {error.CorridorId} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Station/Section <span className="text-danger"> *</span>{" "}
                  </label>

                  <ReactMultiSelect
                    options={CyclicCheckModel.StationList}
                    isMulti
                    allowSelectAll
                    value={CyclicCheckModel.StationID}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={(e) => {
                      CommonHandleChange(e, "StationID");
                    }}
                  />

                  <span className="text-danger mt-1"> {error.StationID} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Location <span className="text-danger"> *</span>{" "}
                  </label>

                  <Dropdown
                    data={CyclicCheckModel.LocationList}
                    value={CyclicCheckModel.LocationID}
                    hideSelectedOptions={false}
                    HandleChange={CommonHandleChange}
                    name={"LocationID"}
                    disabled={
                      CyclicCheckModel.DepartmentType == 95 ? false : true
                    }
                  //   onChange={(e) => CommonHandleChange(e, "LocationID")}
                  />
                  <span className="text-danger mt-1"> {error.LocationID} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Equipment Type <span className="text-danger"> *</span>{" "}
                  </label>

                  <ReactMultiSelect
                    options={CyclicCheckModel.EquipmentSystemList}
                    value={CyclicCheckModel.SystemTypeID}
                    hideSelectedOptions={false}
                    onChange={(e) => {
                      CommonHandleChange(e, "SystemTypeID");
                    }}
                  />
                  <span className="text-danger mt-1">
                    {" "}
                    {error.SystemTypeID}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Equipment <span className="text-danger"> *</span>{" "}
                  </label>
                  <ReactMultiSelect
                    options={CyclicCheckModel.EquipmentList}
                    value={CyclicCheckModel.EquipmentID}
                    hideSelectedOptions={false}
                    onChange={(e) => {
                      CommonHandleChange(e, "EquipmentID");
                    }}
                  />

                  <span className="text-danger mt-1">
                    {" "}
                    {error.EquipmentID}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Approver <span className="text-danger"> *</span>{" "}
                  </label>

                  <Dropdown
                    data={CyclicCheckModel.RolesList}
                    value={CyclicCheckModel.RoleId}
                    hideSelectedOptions={false}
                    HandleChange={CommonHandleChange}
                    name={"RoleId"}
                  //    onChange={(e) => CommonHandleChange(e, "RoleId")}
                  />
                  <span className="text-danger mt-1"> {error.RoleId} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Cyclic Check <span className="text-danger"> *</span>{" "}
                  </label>

                  {/* <Dropdown
                    data={CyclicCheckModel.CyclicCheckList}
                    value={CyclicCheckModel.SubModuleID}
                    hideSelectedOptions={false}
                    HandleChange={CommonHandleChange}
                    name={"SubModuleID"}
                    //   onChange={(e) => CommonHandleChange(e, "SubModuleID")}
                  /> */}
                  <ReactMultiSelect
                    options={CyclicCheckModel.CyclicCheckList}
                    value={CyclicCheckModel.SubModuleID}
                    hideSelectedOptions={false}
                    onChange={(e) => {
                      CommonHandleChange(e, "SubModuleID");
                    }}
                  />
                  <span className="text-danger mt-1">
                    {" "}
                    {error.SubModuleID}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Week <span className="text-danger"> *</span>{" "}
                  </label>

                  <Dropdown
                    data={CyclicCheckModel.WeekList}
                    value={CyclicCheckModel.Weeks}
                    hideSelectedOptions={false}
                    HandleChange={CommonHandleChange}
                    name={"Weeks"}
                    disabled={
                      !(CyclicCheckModel?.SubModuleID?.value != 5 &&
                        CyclicCheckModel?.SubModuleID?.value != 6)
                    }
                  //   onChange={(e) => CommonHandleChange(e, "SubModuleID")}
                  />
                  <span className="text-danger mt-1"> {error.Weeks} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    Remarks <span className="text-danger"> *</span>{" "}
                  </label>

                  <textarea
                    className="form-control"
                    style={{ resize: "none" }}
                    type="text"
                    name="Remarks"
                    value={CyclicCheckModel.Remarks}
                    rows={3}
                    onChange={CommonHandleChange}
                  //    onChange={(e) => CommonHandleChange(e, "Remarks")}
                  />
                  <span className="text-danger mt-1"> {error.Remarks} </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    {CyclicCheckModel.SubModuleID?.value == 5 ||
                      CyclicCheckModel.SubModuleID?.value == 6
                      ? "Work Description"
                      : "Description"}
                    <span className="text-danger"> *</span>{" "}
                  </label>

                  <textarea
                    className="form-control"
                    style={{ resize: "none" }}
                    type="text"
                    name="WorkDescription"
                    value={CyclicCheckModel.WorkDescription}
                    rows={3}
                    onChange={CommonHandleChange}
                  //    onChange={(e) => CommonHandleChange(e, "WorkDescription")}
                  />
                  <span className="text-danger mt-1">
                    {" "}
                    {error.WorkDescription}{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label className="label">
                    {CyclicCheckModel.SubModuleID?.value == 5 ||
                      CyclicCheckModel.SubModuleID?.value == 6
                      ? "Problem Description"
                      : "Component"}
                    <span className="text-danger"> *</span>{" "}
                  </label>

                  <textarea
                    className="form-control"
                    style={{ resize: "none" }}
                    type="text"
                    name="Component"
                    value={CyclicCheckModel.Component}
                    rows={3}
                    onChange={CommonHandleChange}
                  //    onChange={(e) => CommonHandleChange(e, "WorkDescription")}
                  />
                  <span className="text-danger mt-1"> {error.Component} </span>
                </div>
              </div>
              <div className="col-md-10 col-sm-6"></div>
              <div className="col-md-2 ">
                <button
                  className="btn btn-primary mt-4 pull-right"
                  onClick={onSubmit}
                >
                  <i class="fa fa-save"></i> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
export default CyclicCheckCreate;
