import React from "react";
import { useNavigate } from "react-router-dom";
import { HtmlTable } from "../../components/HtmlTable";
import { GetSelect, GetToggleButton } from "./../../services/CommonService";
import { WithoutSelectDropDown } from "../../components/WithoutSelectDropDown";
import makeAnimated from "react-select/animated";
import swal from "sweetalert";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";
import { Decrypt } from "./../../services/CommonService";
import Global from "../../services/Global";
import {
  showErrorMsg,
  showSaveError,
  showSaveSuccess,
  showSuccessMsg,
  showDeleteError,
  showDeleteSuccess,
} from "../../components/ToastMsg";
import { useForm } from "react-hook-form";

import { AddButton, IconButton } from "../../components/Common";
import { useEffect, useContext, useState, useRef } from "react";
import { AppContext } from "./../../services/ContextProvider";
import $ from "jquery";
import EmployeeService from "./EmployeeService";
import { AddEmployee } from "./AddEmployee";
import { useReducer } from "react";
import MasterDetailService from "../master/MasterDetailService";
import { ReactMultiSelect } from "../../components/MultiSelect/ReactMultiSelect";
import { components } from "react-select";

const _empServc = new EmployeeService();
const MDS = new MasterDetailService();
const animatedComponents = makeAnimated();

const Header = ({ title }) => {
  return (
    <div className="page-header mb-3">
      <div className="row">
        <div className="col-4">
          <h3 className="page-title">{title}</h3>
        </div>
      </div>
    </div>
  );
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);






export const EmployeeGrid = () => {
  const navigate = useNavigate();
  const [empID, setempID] = useState(0);
  const [picPath, setProfilePicPath] = useState("");
  //variables
  const { showLoading, hideLoading, getCookies, handleGlobalStationName } =
    useContext(AppContext);
  const [tableRows, setTableRows] = useState([]);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const [Refresh, setRefresh] = useState(0);
  const [isDetails, setisDetails] = useState(null);
  const buttonRef = useRef();
  const viewbuttonref = useRef();
  const modalCloseRef = useRef();
  const modalOpenRef = useRef();

  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [StationList, setStationList] = useState([]);
  const [StationID, SetStationID] = useState([]);
  const [isFromAdd, setisFromAdd] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [departmentID, SetdepartmentID] = useState([]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getStationList();
    getDepartmentList();
  }, []);




  const getStationList = () => {
    var stations = Decrypt(getCookies(Global.COOKIES.Stations));
    var stationsdropdown = [];

    stations.map((data) => {
      stationsdropdown.push({ value: data.StationID, label: data.StationCode });
    });
    setStationList(stationsdropdown);
    HandleChange(stationsdropdown);
    // MDS.GetStationList().then((response) => {
    //   if (response != null && response.data.length > 0) {
    //     var dataLst = [];
    //     response.data.map((v) => {
    //       dataLst.push({ value: v.stationID, label: v.stationCode });
    //     });
    //     setStationList(dataLst);
    //     HandleChange(dataLst);
    //   }
    // });
  };

  const getDepartmentList = () => {
    var DepartmentList = Decrypt(getCookies(Global.COOKIES.Departments));
    var depdropdown = [];

    const itemsWithoutChildren = DepartmentList.filter(
      (item) => !DepartmentList.some((child) => child.parentId === item.id)
    );

    itemsWithoutChildren.map((data) => {
      depdropdown.push({ value: data.id, label: data.name });
    });




    setDepartmentList(depdropdown);
    DepartmentHandleChange(depdropdown);
  };

  const columnNames = [
    { title: "Employee ID", visible: false },
    { title: "ProfilePic", visible: false },
    {
      title: "DepartmentName",
      createdCell: (td, cellData, rowData, row, col) => {
        $(td).html(`<p class='Eclispedot' title='${cellData}'>${cellData}</p>`);
      },
    },
    {
      title: "Station Code",
      createdCell: (td, cellData, rowData, row, col) => {
        $(td).html(`<p class='Eclispedot' title=${cellData}>${cellData}</p>`);
      },
    },
    {
      title: "Employee Code",
      createdCell: (td, cellData, rowData, row, col) => {
        var b =
          $(`<a href='#'  title='${cellData}' data-bs-toggle="modal" data-bs-target="#employeeModal" >
        ${cellData}
      </a>`).on("click", function () {
            onDetailsEmployee(rowData[0], rowData[1]);
          });
        $(td).html(b);
      },
    },

    { title: "Employee Name" },

    { title: "Designation" },
    { title: "Contact Number" },
    { title: "Email ID" },
    {
      title: "Status",
      visible: getPrivilege().includes("ASSOCIATEDELETE"),

      createdCell: (td, cellData, rowData, row, col) => {
        var c = $(GetToggleButton(row, col, cellData, rowData[0])).on(
          "click",
          function () {
            var status;

            var toggle = $(this).find("input[type=checkbox]");
            if (toggle != null && toggle.length == 1) {
              status = toggle.is(":checked");
            }
            if (Boolean(cellData) != status) {
              ChangeStatus(rowData[0]);
            }
          }
        );

        $(td).html(c);
      },
    },
    {
      title: "Submit",
      visible:
        getPrivilege().includes("ASSOCIATEEDIT") ||
        getPrivilege().includes("ASSOCIATEDELETE"),
      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        var c = "";
        if (getPrivilege().includes("ASSOCIATEEDIT")) {
          b =
            $(`<button class='btn icon-btn' title='Edit' data-bs-toggle="modal" data-bs-target="#employeeModal" >
          <i class='fa fa-pencil m-r-5'></i>
        </button>`).on("click", function () {
              setisFromAdd(false);
              onEditEmployee(rowData[0], rowData[1]);
            });
        }
        if (getPrivilege().includes("ASSOCIATEDELETE")) {
          c = $(`<button class='btn icon-btn'  title='Delete'  >
          <i class='fa fa-trash m-l-15'></i>
        </button>`).on("click", function () {
            onDeleteEmployee(rowData[0], rowData[1]);
          });
        }
        $(td).html(b);
        $(td).append(c);
      },
    },
  ];

  const ChangeStatus = (id) => {
    _empServc.DeleteEmployee(id).then((response) => {
      if (response.data == 1) {
        // showSaveSuccess();
      } else {
        showSaveError();
      }
    });
  };

  const onEditEmployee = (id, picPath) => {
    PageRender();
    setempID(id);
    setProfilePicPath(picPath);
  };

  const onDeleteEmployee = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this Employee?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        _empServc
          .EmployeeDeleteEmployee(id)
          .then((response) => {
            if (response.data == 1) {
              showDeleteSuccess();
              setRefresh(Refresh + 1);
            } else {
              showSaveError();
            }
          })
          .catch((err) => {
            setRefresh(Refresh + 1);
            console.log(err);
          });
      }
    });
  };
  const onDetailsEmployee = (id, picPath) => {
    PageRender();
    setempID(id);
    setProfilePicPath(picPath);
    setisDetails(1);
  };

  useEffect(() => {
    var stationIds = StationID.map((x) => {
      return x.value;
    });

    var depIds = departmentID.map((x) => {
      return x.value;
    });
    getData(stationIds.toString(), depIds.toString());
  }, [Refresh, StationID, departmentID]);

  useEffect(() => {
    if (Number(empID) > 0) {
      getPrivilege().includes("ASSOCIATECREATE")
        ? buttonRef.current.click()
        : viewbuttonref.current.click();
    }
  }, [empID]);

  const getData = (StIDs, depIds) => {
    showLoading();
    try {
      if (StIDs != null && StIDs != "") {
        _empServc.GetEmployeeList(StIDs, depIds).then((response) => {
          if (
            response != null &&
            response.data != null &&
            response.data.length > 0
          ) {
            var rowList = [];
            response.data.map((v, i) => {
              var row = [];
              row.push(v.employeeID);
              row.push(v.profilePicPath);
              row.push(v.department);
              row.push(v.stationName);
              row.push(v.employeeCode);
              row.push(v.firstName + " " + v.lastName);
              row.push(v.designation);
              row.push(v.contactNumber);
              row.push(v.emailID);
              row.push(v.isActive);
              row.push("");
              rowList.push(row);
            });
            setTableRows(rowList);
          } else {
            setTableRows([]);
          }
          hideLoading();
        });
      } else {
        setTableRows([]);
        hideLoading();
      }
    } catch (err) {
      hideLoading();
      console.log(err);
    }
  };

  const onOpenModel = () => {
    setisFromAdd(true);
    modalOpenRef.current.click();

    setempID(0);
    PageRender();
    // setProfilePicPath(null);
  };

  const HandleChange = (e) => {
    SetStationID(e);
    // if(e?.length){
    let StationLabel = e.map((x) => x.label).join();
    handleGlobalStationName(StationLabel);
    // }
  };
  const DepartmentHandleChange = (e) => {
    SetdepartmentID(e);
  };

  return (
    <>
      <div className="page-header mb-3">
        <div className="row">
          <div className="col-2">
            <h3 className="page-title">Employee List</h3>
          </div>

          <div className="col-10">
            {/*Add button */}
            <div className="justify-content-end row">
              <div className={windowWidth > 1000 ? "col-5" : "col-5"}>
                <ReactMultiSelect
                  options={DepartmentList}
                  isMulti
                  value={departmentID}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option, MultiValue, animatedComponents }}
                  onChange={(e) => DepartmentHandleChange(e)}
                  allowSelectAll={true}
                />
              </div>
              <div className={windowWidth > 1000 ? "col-4" : "col-4"}>
                <ReactMultiSelect
                  options={StationList}
                  isMulti
                  value={StationID}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option, MultiValue, animatedComponents }}
                  onChange={(e) => HandleChange(e)}
                  allowSelectAll={true}
                />
              </div>
              {getPrivilege().includes("ASSOCIATECREATE") ? (
                <div className={windowWidth > 1000 ? "col-2" : "col-2"}>
                  <button
                    type="submit"
                    className="btn add-btn"
                    onClick={onOpenModel}
                    ref={buttonRef}
                  >
                    <i className="fa fa-plus"></i> Add
                  </button>
                </div>
              ) : (
                <div className={windowWidth > 1000 ? "col-3" : "col-4"}>
                  <button
                    type="submit"
                    className="btn add-btn"
                    onClick={onOpenModel}
                    ref={viewbuttonref}
                  >
                    <i className="fa fa-plus"></i> Add
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Open add modal */}
      <input
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#add-emp"
        hidden
        ref={modalOpenRef}
      />
      {/* Close add modal */}
      <input
        type="button"
        data-bs-dismiss="modal"
        data-bs-target="#add-emp"
        data-bs-backdrop="static"
        hidden
        ref={modalCloseRef}
      />

      <div className="card card-body mt-3">
        <HtmlTable
          columnList={columnNames}
          rowList={tableRows}
          id={"EmployeeTable"}
        />
      </div>

      <AddEmployee
        empID={empID}
        setProfilePicPath={setProfilePicPath}
        setempID={setempID}
        picPath={picPath}
        Refresh={Refresh}
        setRefresh={setRefresh}
        ModelREf={Render}
        isDetails={isDetails}
        setisDetails={setisDetails}
        IsDeleted={true}
        isFromAdd={true}
      />
    </>
  );
};
