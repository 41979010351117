import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import { AppContext } from "../../services/ContextProvider";
import {
  BootrapModel,
  PageHeaderTitle,
  ReactMultiSelect,
} from "../../components";
import {
  getYMDdate,
  Decrypt,
  DeepCopy,
  tableLayout,
} from "../../services/CommonService";
import Global from "../../services/Global";
import { WithoutSelectDropDown } from "../../components/WithoutSelectDropDown";
import {
  Attendance_ReportShift,
  Attendance_ReportDetails,
  AttendanceReport_FileDownload,
} from "./ReportAttendanceService";
import { DataTableReportAjax } from "../../components/DataTableReportAjax";
// import { BASE_URL } from "../../env";
import ExcelJS from "exceljs";
import moment from "moment";
import $, { data } from "jquery";
import { DownloadModel } from "../BreakDownMaintenance/DownloadModel";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";

export const ReportAttendance = () => {
  const {
    showLoading,
    hideLoading,
    getCookies,
    GetLoginStationList,
    handleGlobalStationName,
    GetLoginStationTypes,
  } = useContext(AppContext);
  const [FilterModel, setFilterModel] = useState({
    StationID: {},
    CurrentDate: getYMDdate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    ToDate: getYMDdate(new Date()),
  });
  // const [shiftReport, setshiftReport] = useState([]);

  const [DropdownModel, setDropdownModel] = useState({
    StationList: [],
    FilterStationList: [],
    DepartmentList: [],
    DateRange: [],
    stationTypes: [],
  });
  const [FileData, setFileData] = useState([]);
  const _breakdownService = new BreakdownMaintenanceService();

  useEffect(() => {
    getStationList();
  }, []);
  const columns = [
    {
      data: "AttendanceMaintainceID",
      title: "AttendanceMaintainceID",
      visible: false,
    },
    { data: "RowID", title: "Serial No" },
    { data: "Name", title: "Employee Name" },
    { data: "DisplayName", title: "Designation" },
    { title: "Date", data: "CurrentDate" },
    { title: "Shift", data: "ShiftName" },
    { data: "LocationName", title: `Location Name (IN / OUT) ` },
    { data: "INN_Time", title: `In Time ` },
    { data: "Out_Time", title: "Out Time" },
    {
      title: "Duration in (Hr)",
      data: "Duration",
    },
    {
      title: "Remarks",
      data: "Remarks",
    },
    {
      title: "View Details",
      data: "AttendanceMaintainceID",
      createdCell: (td, cellData, rowData, row, col) => {
        var eye = "";
        eye =
          $(`<button class='btn icon-btn text-center'data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'  >
                        <i class='fa fa-eye' style='margin-right: 10px;'></i>
                        </button>`).on("click", function () {
            DownloadFiles(rowData.AttendanceMaintainceID, 0, 9);
          });
        $(td).html(eye);
      },
    },
  ];

  const DownloadFiles = (ticketID, AttachmetID, Flag) => {
    _breakdownService
      .GetFilesUsingTicketID(ticketID, AttachmetID, Flag)
      .then((res) => {
        setFileData(res.data);
      })
      .catch((err) => {
        setFileData([]);
        console.log(err);
      });
  };

  const getStationList = () => {
    let stationList = GetLoginStationList();
    let DepartmentList = Decrypt(getCookies(Global.COOKIES.Departments));
    let stationTypes = GetLoginStationTypes();
    var dataLst = [];

    let Stations = [];
    let Department = [];
    let Types = [];
    let ignoredStation = [1, 2, 3];
    if (DepartmentList?.length) {
      const itemsWithoutChildren = DepartmentList.filter(
        (item) => !DepartmentList.some((child) => child.parentId === item.id)
      );
      itemsWithoutChildren.map((data) => {
        Department.push({
          value: data?.id,
          label: data?.name,
          Type: data?.Type,
        });
      });
      // Department = Department.filter((ele) => ele.label != "TVS & VAC");
    }
    stationList.map((data) => {
      if (ignoredStation.includes(data.value))
        dataLst.push({ value: data.value, label: data.label });
    });

    if (stationList?.length) {
      stationList.map((data) => {
        Stations.push({
          key: data.label,
          ...data,
        });
      });
      handleGlobalStationName(Stations[0].key);
    }
    stationTypes.map((data) => {
      Types.push({
        key: data.label,
        ...data,
      });
    });
    let FilStations = Stations.filter(
      (ele) =>
        ele.CorridorID == Types[0].value && ele.Type == Department[0].Type
    );
    setFilterModel((pre) => ({
      ...pre,
      StationID: FilStations?.[0] ?? {},
      DepartmentID: Department[0],
      StationTypeID: Types?.[0].value ?? 0,
    }));
    setDropdownModel((pre) => ({
      ...pre,
      StationList: FilStations,
      DepartmentList: Department,
      stationTypes: Types,
      // FilterStationList: Stations,
    }));
  };
  const CommonHandleChange = (e, name) => {
    if (name) {
      setFilterModel((pre) => ({
        ...pre,
        [name]: e,
      }));
    }
  };

  async function createExcelExportOptions(
    title,
    username,
    reportList,
    isDetail
  ) {
    const shiftReportArray = Array.isArray(reportList)
      ? reportList
      : [reportList];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Attendance Report");

    let DownloadDate = moment().format("DD-MM-YYYY");

    worksheet.mergeCells("A1:F1");

    worksheet.mergeCells("A2:F2");
    worksheet.getCell(
      "A2"
    ).value = `Downloaded By ${username} on ${DownloadDate}`;
    worksheet.getCell("A2").font = { italic: true, size: 12 };
    worksheet.getCell("A2").alignment = { horizontal: "left" };

    // Define static columns excluding TotalRows
    let staticColumns = [];
    console.log(isDetail);
    debugger;
    if (isDetail == true) {
      staticColumns = [
        { header: "Serial No", key: "SerialNo", width: 10 },
        { header: "Employee Code", key: "EmployeeCode", width: 10 },
        { header: "Employee Name", key: "EmployeeName", width: 20 },
        { header: "Designation", key: "Role", width: 20 },
        { header: "Station", key: "Station", width: 20 },
        { header: "Total Duty", key: "TotalShifts", width: 15 },
      ];
    } else {
      staticColumns = [
        { header: "Serial No", key: "SerialNo", width: 10 },
        { header: "Employee Name", key: "EmployeeName", width: 20 },
        { header: "Designation", key: "Role", width: 20 },
        { header: "Station", key: "Station", width: 20 },
        { header: "Total Duty", key: "TotalShifts", width: 15 },
      ];
    }

    // Extract dynamic column headers from the data
    const dynamicHeaders = new Set();
    if (isDetail == true) {
      shiftReportArray.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (
            ![
              "SerialNo",
              "EmployeeCode",
              "EmployeeName",
              "Role",
              "Station",
              "TotalShifts",
            ].includes(key)
          ) {
            dynamicHeaders.add(key);
          }
        });
      });
    } else {
      shiftReportArray.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (
            !["SerialNo", "EmployeeName", "Role", "TotalShifts"].includes(key)
          ) {
            dynamicHeaders.add(key);
          }
        });
      });
    }

    const sortedDynamicHeaders = Array.from(dynamicHeaders).sort();
    debugger;
    const columns = [
      ...staticColumns,
      ...sortedDynamicHeaders.map((header) => ({
        header: header,
        key: header,
        width: Math.max(header.length, 10),
      })),
    ];

    // Set columns in worksheet
    // worksheet.columns = columns;

    // Set column headers in row 3
    columns.forEach((col, index) => {
      const cell = worksheet.getCell(3, index + 1);
      cell.value = col.header;
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "d1ddf5" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      // Automatically adjust column width based on the longest content
      const maxLength = Math.max(
        col.header.length,
        ...shiftReportArray.map((item) =>
          item[col.key] ? item[col.key].toString().length : 0
        )
      );

      worksheet.getColumn(index + 1).width =
        maxLength < 10 ? 10 : maxLength + 2;
    });

    // Add rows to the worksheet starting from row 4
    shiftReportArray.forEach((item, rowIndex) => {
      const rowNumber = rowIndex + 4;
      // console.log(`Adding data to row ${rowNumber}:`, item);
      const row = worksheet.getRow(rowNumber);
      columns.forEach((col, colIndex) => {
        row.getCell(colIndex + 1).value =
          item[col.key] !== undefined ? item[col.key] : "";
      });
      row.commit();
    });

    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).alignment = { horizontal: "center" };
    worksheet.getRow(2).font = { italic: true };
    worksheet.getRow(2).alignment = { horizontal: "center" };

    worksheet.getCell("A1").value = `${title} to ${moment(
      FilterModel?.ToDate
    ).format("DD-MM-YYYY")}`;
    worksheet.getCell("A1").font = { bold: false };
    worksheet.getCell("A1").alignment = { horizontal: "left" };
    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${title}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  }

  const handleExport = async () => {
    const reportList = [];
    console.log("hi");
    await Attendance_ReportShift(
      FilterModel?.StationID?.value,
      true,
      FilterModel?.CurrentDate,
      FilterModel?.ToDate
    )
      .then((data) => {
        if (data != null && data.data != 0) {
          // setshiftReport(data.data.data)
          reportList.push(...data.data.data);
        }
      })
      .catch((err) => {});
    console.log(FilterModel?.StationID);
    const title = `Attendance_Report ${FilterModel?.StationID?.label} ${moment(
      FilterModel?.CurrentDate
    ).format("DD-MM-YYYY")} `;
    const username = getCookies(Global.COOKIES.UserName);
    createExcelExportOptions(title, username, reportList, false);
  };
  const handleDetailExport = async () => {
    const reportList = [];
    await Attendance_ReportDetails(
      FilterModel?.StationID?.value,
      true,
      FilterModel?.CurrentDate,
      FilterModel?.ToDate
    )
      .then((data) => {
        if (data != null && data.data != 0) {
          // setshiftReport(data.data.data)
          reportList.push(...data.data.data);
        }
      })
      .catch((err) => {});
    console.log(FilterModel?.StationID);
    const title = `Attendance_Report ${FilterModel?.StationID?.label} ${moment(
      FilterModel?.CurrentDate
    ).format("DD-MM-YYYY")} `;
    const username = getCookies(Global.COOKIES.UserName);
    createExcelExportOptions(title, username, reportList, true);
  };

  function ExcelExportButton() {
    console.log("ExcelExportButton function called");
    let ExcelBtn = $(
      `<button class="btn btn-secondary buttons-pdf buttons-html5 btn-primary datatablebtn rounded-3 datatablepdf-button m-b-15 shift-button"
             style="margin-right: 40px;"   
             title="Attendance Duty Details"
            >  
                 <span>
                        <i class="fa fa-download"></i>
                    </span>
                </button>  `
    ).on("click", function () {
      handleExport();
    });
    let ExcelBtn2 = $(
      `<button class="btn btn-secondary buttons-pdf buttons-html5 btn-success datatablebtn rounded-3 datatablepdf-button m-b-15 details-button"
             style="margin-right: 10px;"  
             title="Attendance Time Details"  
            >  
                 <span>
                        <i class="fa fa-download"></i>
                    </span>
                </button>  `
    ).on("click", function () {
      handleDetailExport();
    });
    let Fragment = $(`<span></span>`);
    Fragment.append(ExcelBtn2);
    Fragment.append(ExcelBtn);
    return Fragment;
  }

  return (
    <div>
      <PageHeaderTitle title={"Attendance Report"}></PageHeaderTitle>
      <div className="row col-12">
        {" "}
        <div className="col-2">
          <label className="form-check-label">Department</label>
          <ReactMultiSelect
            options={DropdownModel.DepartmentList}
            placeholder={"Department"}
            onChange={(e) => CommonHandleChange(e, "DepartmentID")}
            value={FilterModel.DepartmentID}
          ></ReactMultiSelect>
        </div>
        <div className="col-2">
          <label className="form-check-label">Corridor</label>

          <WithoutSelectDropDown
            name={"StationTypeID"}
            placeholder="Select Station Type"
            HandleChange={(e) => CommonHandleChange(e, "StationTypeID")}
            data={DropdownModel.stationTypes}
          />
        </div>
        <div className="col-2">
          <label className="form-check-label">Station</label>
          <ReactMultiSelect
            options={DropdownModel.StationList}
            placeholder={"Station"}
            onChange={(e) => CommonHandleChange(e, "StationID")}
            value={FilterModel.StationID}
          ></ReactMultiSelect>
        </div>
        <div className="col-2">
          <label className="label" for="">
            From Date{" "}
          </label>

          <input
            type="date"
            max={getYMDdate(new Date())}
            className="form-control"
            id="CurrentDate"
            style={{ height: "40px" }}
            value={FilterModel.CurrentDate}
            onChange={(e) => {
              const { value } = e.target;
              console.log(value);
              CommonHandleChange(value, "CurrentDate");
            }}
          />
        </div>
        <div className="col-2">
          <label className="label" for="">
            To Date{" "}
          </label>

          <input
            type="date"
            max={getYMDdate(new Date())}
            className="form-control"
            id="ToDate"
            style={{ height: "40px" }}
            value={FilterModel.ToDate}
            onChange={(e) => {
              const { value } = e.target;
              console.log(value);
              CommonHandleChange(value, "ToDate");
            }}
          />
        </div>
        {/* <div className="col-2">
                    <button
                        className="btn btn-secondary buttons-excel buttons-html5 datatablebtn rounded-3 datatablepdf-button m-l-5"
                        tabIndex="0"
                        aria-controls="Attendance_Report"
                        type="button"
                        onClick={handleExport}
                    >
                        <span>
                            <i style={{ marginTop: "4px", fontSize: "25px" }} className="las la-file-excel" title="Excel download"></i>
                        </span>
                    </button>
                </div> */}
      </div>

      <BootrapModel
        Id={`DownLoadFiles`}
        ModelTitle={"Download Attachments"}
        modelSize={"modal-xl"}
        module={
          <DownloadModel
            Flag={2}
            FileDatas={FileData}
            ImageID={`SystemAuditImgID`}
          ></DownloadModel>
        }
      >
        {" "}
      </BootrapModel>
      <div className="card card-body mt-3">
        <DataTableReportAjax
          StationID={FilterModel?.StationID?.value}
          FromDate={FilterModel?.CurrentDate}
          isTvsVac={true}
          ToDate={FilterModel?.ToDate}
          title={`Attendance_${FilterModel?.StationID?.label}_`}
          columnList={columns}
          url={`${window.BASE_URL}Attendance/Attendance_Report`}
          id={"Attendance_Report"}
          SearchPrependHtml={ExcelExportButton}
          exportOptions={{
            columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          }}
        ></DataTableReportAjax>
      </div>
    </div>
  );
};
