import React from "react";
import { createContext, useState, useContext } from "react";

export const PeriodicConfigurationContext = createContext({
  DropdownModel: {},
  setDropdownModel: () => { },
  DropDownInitialState: {},
  PeriodicConfigModel: {},
  setPeriodicConfigModel: () => { },
  InitialState: {},
  setPeriodicList: () => { },
  PeriodicList: [],
  FilterObj: {},
  setFilterObj: () => { },

});

function PeriodicConfigurationProvider({ children }) {
  const DropDownInitialState = {
    StationList: [],
    DepartmentList: [],
    FilterDepartmentList: [],
    FilterEquipmentList: [],
    EquipmentList: [],
    FilterConcatDepartList: [],
    ErrorObj: {},
    SaveStationList : []
  };

  const InitialState = {
    StationID: [],
    DepartmentId: "",
    EquipmentID: {},
    ScheduleType: 0,
    WeekID: "-1",
    DescriptionName: "",
    ComponentName: "",
    IsCritical: 0,
    PeriodicID: 0,
    StatusOption1: "",
    StatusOption2: "",
    StatusOption3: "",
    StatusOption4: "",
    MinValue: 0,
    MaxValue: 0,
    OrderNo: 0,
    UOM: "",
    SystemTypeID: 0,
    IsBMRequired:0,
    IsAnnexureRequired : 0
  };

  const InitialFilterObj = {
    DepartmentID: 0,
    StationID: {},
    RefreshID: 0,
  };

  const [DropdownModel, setDropdownModel] = useState(DropDownInitialState);
  const [PeriodicConfigModel, setPeriodicConfigModel] = useState(InitialState);
  const [FilterObj, setFilterObj] = useState(InitialFilterObj);
  const [PeriodicList, setPeriodicList] = useState([]);

  const contextValue = {
    DropdownModel,
    setDropdownModel,
    DropDownInitialState,
    PeriodicConfigModel,
    setPeriodicConfigModel,
    InitialState,
    setPeriodicList,
    PeriodicList,
    FilterObj,
    setFilterObj
  };

  return (
    <PeriodicConfigurationContext.Provider value={contextValue}>
      {children}
    </PeriodicConfigurationContext.Provider>
  );
}

export function ContextValues() {
  const ContextValue = useContext(PeriodicConfigurationContext);
  return ContextValue;
}

export default PeriodicConfigurationProvider;
