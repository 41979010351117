import React from "react";
import { HtmlTable } from "../../components/HtmlTable";
import {
  showSaveError,
  showSaveSuccess,
  showWarningMsg,
  showDeleteSuccess,
} from "../../components/ToastMsg";
import { Controller, useForm } from "react-hook-form";
import MasterDetailService from "./MasterDetailService";
import { AddButton } from "../../components/Common";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "./../../services/ContextProvider";
import "./master.css";
import $, { data } from "jquery";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Global from "./../../services/Global";
import swal from "sweetalert";
import { GetLoginUserID, getPrivilege } from "../../services/CommonService";

const _masterDetailService = new MasterDetailService();

export const Equipment = ({ TabID, TabValue }) => {
   
  const [allequipList, setAllEquipList] = useState([]);
  const [equipmentList, setequipmentList] = useState([]);
  const [systemList, setsystemList] = useState([]);
  const [systemListMatch, setsystemListMatch] = useState([]);
  const [isAuto, setisAuto] = useState(false);
  const { currentMasterTab, getCookies } = useContext(AppContext);
  const { showLoading, hideLoading } = useContext(AppContext);
  const [options, setOptions] = useState([]);

  const [systemType, setSystemType] = useState([]);
  const [error, setErrors] = useState(false);

  const columnNames = [
    { title: "Equipment ID", visible: false },
    { title: "System Type" },
    { title: "Equipment Name" },
    { title: "Equipment Short Name" },
    { title: "Make" },

    {
      title: "Submit",
      visible:
        getPrivilege().includes("MASTEREDIT") ||
        getPrivilege().includes("MASTERDELETE"),

      createdCell: (td, cellData, rowData, row, col) => {
        var b = "";
        var c = "";
        if (getPrivilege().includes("MASTEREDIT")) {
          b = $(`<button class='btn icon-btn' title='Edit'>
            <i class='fa fa-pencil m-r-5'></i>
          </button>`).on("click", function () {
            EditEquipment(Number(rowData[0]));

            window.scroll(0, 0);
          });
        }

        if (getPrivilege().includes("MASTERDELETE")) {
          c = $(`<button class='btn icon-btn'  title='delete'  >
            <i class='fa fa-trash m-l-15'></i>
          </button>`).on("click", function () {
            onDeleteClick(Number(rowData[0]));
          });
        }

        $(td).html(b);
        $(td).append(c);
      },
    },
  ];
  const [Refresh, setRefresh] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
    clearErrors,
    control,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const onDeleteClick = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the equipment?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        _masterDetailService
          .DeleteEquipment(id)
          .then((response) => {
            if (response.data == 1) {
              showDeleteSuccess();
              setRefresh(Refresh + 1);
            } else {
              showSaveError();
            }
          })
          .catch((err) => {
            setRefresh(Refresh + 1);
            console.log(err);
          });
      }
    });
  };

  const onSubmit = (data) => {

    var isExists = [];
    data.SavedBy = GetLoginUserID();

    let isKeyPresent = systemType.some((el) => {
      if (el.hasOwnProperty("label")) return true;
    });
     
    isExists = allequipList.find(
      (v) =>
        // v.equipmentName.toLowerCase().trim() ==
        //   data.equipmentName.toLowerCase().trim() &&
        v.equipmentShortName.toLowerCase().trim() ==
        data.equipmentShortName.toLowerCase().trim() &&
        v.equipmentID != data.equipmentID 
        // &&
        // systemType == v.systemTypeName
    );


    if (!isExists) {
      if (isKeyPresent) {
        var datas = systemType[0].label;
        delete data.systemTypeName;
        data.systemTypeName = datas;
      } else {
        var datas = systemType[0];
        delete data.systemTypeName;
        data.systemTypeName = datas;
      }
      _masterDetailService
        .SaveEquipmentMaster(data)

        .then((response) => {
          reset();
          setSystemType([]);
          showSaveSuccess();
          var count = Refresh;
          setRefresh(++count);
        })
        .catch((error) => {
          showSaveError();
        });
    } else {
      showWarningMsg("Equipment name or short name already exists");
    }
  };

  //functions
  useEffect(() => {
    // get parameter list

    if (TabID == TabValue) {
      console.log(TabID + " " + TabValue + " Equipment");
      getData();
      reset();
      setisAuto(false);
      setErrors(false);
    }
  }, [currentMasterTab]);

  const getData = () => {
    _masterDetailService
      .GetEquipmentMaster('', getCookies(Global.COOKIES.EmployeeID))
      .then((response) => {
        if (
          response != null &&
          response.data != null &&
          response.data.length > 0
        ) {
          setAllEquipList(response.data);
          var rowList = [];
          response.data.map((v, i) => {
            var row = [];
            row.push(v.equipmentID);
            row.push(v.systemTypeName);
            row.push(v.equipmentName);
            row.push(v.equipmentShortName);
            row.push(v.make);
            // row.push({ value: v.IsActive, hidden: false });
            row.push("");
            rowList.push(row);
          });
          setequipmentList(rowList);
        } else {
        }
      })
      .catch((error) => { });

    _masterDetailService
      .GetSystemType()
      .then((response) => {
        // console.log(response.data);

        var systemType = [];
        response.data.map((v, i) => {
          systemType.push(v.systemTypeName);
        });
        setOptions(systemType);

        setsystemList(response.data);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (TabID == TabValue && Refresh != 0) {
      getData();
    }
  }, [Refresh]);

  function EditEquipment(Id) {
    const found = allequipList.find((obj) => {
      return obj.equipmentID === Id;
    });
    var vals = "";
    Object.keys(found).map(
      (key, i) => (
        (vals = found["systemTypeName"]),
        setErrors(false),
        setSystemType([]),
        clearErrors(),
        setSystemType((oldArray) => [...oldArray, vals]),
        setValue(key, found[key])
      )
    );
  }

  const changeHandler = (name, value) => {
    setValue(name, value.toUpperCase());
  };

  const Validate = (data) => {
     
    //;
    var validate = systemType[0];
    if (validate == "" || validate == null) {
      setErrors(true);
      return false;
    } else {
      setErrors(false);
      onSubmit(data);
    }
  };

  const keyDown = (e) => {
    if (e.target.value != "" || e.target.value.length > 0) {
      setErrors(false);
    }
  };
  const onBlur = (e) => {
    console.log(e.target.value);
    if (e.target.value == "") {
      setErrors(true);
    } else {
      setErrors(false);
      setSystemType((oldArray) => [...oldArray, e.target.value]);
    }
  };

  return (
    <div>
      {getPrivilege().includes("MASTERCREATE") && (
      <form id="EquipmentMaster" onSubmit={handleSubmit(Validate)}>
        <div className="row d-flex justify-content-end">
          <div className="col-3">
            <div className="form-group mb-3">
              <Controller
                control={control}
                as={Typeahead}
                name="systemTypeName"
                render={({ field, fieldState }) => (
                  <div className="mb-3">
                    <label className="label" htmlFor="systemTypeName">
                      System Type <span className="text-danger">*</span>
                    </label>
                    <Typeahead
                      {...field}
                      name="systemTypeName"
                      id="systemTypeName"
                      aria-describedby="validationServerUsernameFeedback"
                      options={options}
                      allowNew
                      onChange={setSystemType}
                      // placeholder={"System Type"}
                      selected={systemType}
                      onKeyDown={(e) => keyDown(e)}
                      onBlur={(e) => onBlur(e)}
                    />

                    {error && (
                      <span className="text-danger">
                        System type is required
                      </span>
                    )}
                  </div>
                )}
              />
            </div>
          </div>

          <div className="col-3">
            <div className="form-group mb-3">
              <label className="label" htmlFor="equipmentName">
                Equipment Name <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                name="equipmentName"
                className="form-control"
                // placeholder="Equipment Name"
                maxLength="80"
                id="equipmentName"
                {...register("equipmentName", {
                  required: "Equipment name is required",
                  validate: (value) => {
                    return !!value.trim() || "Equipment name cannot be empty";
                  },
                })}
                value={register.equipmentName}
              />
              {errors.equipmentName && (
                <span className="text-danger">
                  {errors.equipmentName.message}
                </span>
              )}
            </div>
          </div>

          <div className="col-3">
            <div className="form-group mb-3">
              <label className="label" htmlFor="equipmentShortName">
                Equipment Short Name <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                name="equipmentShortName"
                className="form-control"
                // placeholder="Equipment Short Name"
                id="equipmentShortName"
                maxLength="10"
                {...register("equipmentShortName", {
                  required: "Equipment short name is required",
                  validate: (value) => {
                    return (
                      !!value.trim() || "Equipment short name cannot be empty"
                    );
                  },
                })}
                onKeyUp={(e) =>
                  changeHandler("equipmentShortName", e.target.value)
                }
                value={register.equipmentShortName}
              />
              {errors.equipmentShortName && (
                <span className="text-danger">
                  {errors.equipmentShortName.message}
                </span>
              )}
            </div>
          </div>

          <div className="col-3">
            <div className="form-group mb-3">
              <label className="label" htmlFor="equipmentShortName">
                Make <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                name="make"
                className="form-control"
                // placeholder="Equipment Short Name"
                id="make"
                maxLength="100"
                {...register("make", {
                  required: "Make is required",
                  validate: (value) => {
                    return !!value.trim() || "Make cannot be empty";
                  },
                })}
                onKeyUp={(e) => changeHandler("make", e.target.value)}
                value={register.make}
              />
              {errors.make && (
                <span className="text-danger">{errors.make.message}</span>
              )}
            </div>
          </div>

          {getPrivilege().includes("MASTERCREATE") && (
          <div className="col-2 mt-4" onClick={() => Validate()}>
            <AddButton />
          </div>
         )}
        </div>
      </form>
   )}

      <HtmlTable
        columnList={columnNames}
        rowList={equipmentList}
        id={"EquipmentTable"}
      />
    </div>
  );
};
