import React from "react";
import { TabBar } from "../../components/TabBar";
import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  AppContext,
  DefaultAppContextValues,
} from "./../../services/ContextProvider";
import { BreakdownDepartmentList } from "./BreakdownDepartmentList";
import { BreakdownDepartmentCyclicList } from "./BreakdownDepartmentCyclicList";
import { BreakdownMaintenance_GetTicketDetailsForNavigate } from "./BreakdownMaintenanceService";

export const BreakdownDepartmentIndex = () => {
  // const { handleMasterTab } = useContext(AppContext);

  const [TabInitialVal, setTabInitialVal] = useState(0);
  const location = useLocation();
  const TabHeader = [
    "Breakdown Maintenance",
    "Breakdown Maintenance Cyclic check",
  ];

  const { setBreakDownFilterModel } = DefaultAppContextValues();

  const TabBody = [
    <BreakdownDepartmentList TabID={0} TabValue={TabInitialVal} />,
    <BreakdownDepartmentCyclicList TabID={1} TabValue={TabInitialVal} />,
  ];

  useEffect(() => {
    if (location.state != null) {
      var { TICKET_NO } = location.state;
      if (TICKET_NO) {
        BreakdownMaintenance_GetTicketDetailsForNavigate("B" + TICKET_NO)
          .then((res) => {
            if (res?.data?.data?.length) {
              const { DepartmentID, CorridorID, StationID, ISCyclicCheck } =
                res?.data?.data[0];

              if (ISCyclicCheck == 1) {
                setTabInitialVal(1);
              } else {
                setTabInitialVal(0);
              }
              setBreakDownFilterModel((pre) => ({
                ...pre,
                DepartmentID,
                CorridorID,
                StationID,
              }));
              location.state = null;
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }, []);

  const onTabChange = (e) => {
    setTabInitialVal(e - 1);
  };

  return (
    <>
      <div className="card">
        <TabBar
          header={TabHeader}
          body={TabBody}
          InitialValue={TabInitialVal}
          onTabChange={onTabChange}
        />
      </div>
    </>
  );
};
