import React, { useEffect, useReducer, useState } from "react";
import { ReactMultiSelect } from "../../components";
import ReactSelect from "react-select";
import { DefaultAppContextValues } from "../../services/ContextProvider";
import BillingSheetServices from "./BillingSheetServices";
import { toast } from "react-toastify";
import $ from "jquery";
function BillingSheetSaveDetail({ BillingSheetList }) {
  const [Year, setYear] = useState(new Date().getFullYear());

  console.log(BillingSheetList);
  const [formData, setFormData] = useState({
    CheckListId: null,
    EquipmentId: "",
    CheckListNumber: "",
    CheckListDescription: "",
    StationId: "",
    // MaxMarksWeightage: "",
    // Amount: "",
    // MaxAmount: "",
    // RateAsPerContract: "",
    // VendorRemarks: "",
    // CMRLRemarks: "",
    // FromDate: `${Year}-11-01`,
    // ToDate: `${parseInt(Year)+1}-01-31`,
  });

  const [DropdownModel, setDropdownModel] = useState({
    StationList: [],
    DropEquipmentList: [],
  });
  const [errors, setErrors] = useState({});
  const { GetLoginStationList } = DefaultAppContextValues();
  const _billServc = new BillingSheetServices();
  const [Refresh, setRefresh] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    SetDropdownList();
    handleClose();
    GetEquipmentShortName();
  }, []);

  useEffect(() => {
    GetEquipmentShortName();
    setFormData((prevState) => ({
      ...prevState,
      CheckListId: BillingSheetList.CheckListId || null,
      EquipmentId: BillingSheetList.EquipmentId || null,
      CheckListNumber: BillingSheetList.CheckListNumber || "",
      CheckListDescription: BillingSheetList.CheckListDescription || "",
      StationId: BillingSheetList.StationId|| "" 
    })); 
  }, [BillingSheetList]);

  const handleClose = () => {
    setFormData({
      StationId: "",
      EquipmentId: "",
      CheckListNumber: "",
      CheckListDescription: "",
      // MaxMarksWeightage: "",
      // Amount: "",
      // MaxAmount: "",
      // RateAsPerContract: "",
      // VendorRemarks: "",
      // CMRLRemarks: "",
    });
    setErrors({
      StationId: "",
      EquipmentId: "",
      CheckListNumber: "",
      CheckListDescription: "",
      // Add other fields if necessary
    });  };
  
  
  const GetEquipmentShortName = async () => {
    try {
      const response = await _billServc.GetEquipmentShortNameList();
      debugger;
      if (response && response.data) {
        const formattedData = response.data.map((item) => ({
          label: item.equipmentShortName,
          value: item.equipmentID,
        }));
        setDropdownModel((prev) => ({
          ...prev,
          DropEquipmentList: formattedData,
        }));
      }
    } catch (error) {
      console.error("Error fetching equipment short name list:", error);
    }
  };

  const SetDropdownList = () => {
    setDropdownModel((prev) => ({
      ...prev,
      StationList: GetLoginStationList(),
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    // if (!formData.StationId) newErrors.StationId = "Station is required";
    //if (!formData.EquipmentId) newErrors.EquipmentId = "Equipment is required";
    // if (!formData.CheckListNumber)
    //   newErrors.CheckListNumber = "Checklist Number is required";
    if (!formData.CheckListDescription)
      newErrors.CheckListDescription = "Checklist Description is required";
    // if (!formData.MaxMarksWeightage)
    //     newErrors.MaxMarksWeightage = "Marks Weightage is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  // const handleEquipmentInputChange = (selectedEquipment, name) => {
  //   const selectedEquipmentValues = selectedEquipment
  //     .map((option) => option.value)
  //     .join(",");
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     name: selectedEquipmentValues,
  //   }));
  //   if (errors.EquipmentId) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       EquipmentId: "",
  //     }));
  //   }
  // };

  const handleInputNumberChange = (e) => {
    const { name, value } = e.target;

    const floatValue = value === "" ? "" : parseFloat(value);

    setFormData((prevState) => ({
      ...prevState,
      [name]: floatValue,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleMultiSelectChange = (selectedOptions, name) => {
    const selectedValues = selectedOptions
      .map((option) => option.value)
      .join(",");
    setFormData((prevState) => ({
      ...prevState,
      [name]: selectedValues,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSave = async () => {
    debugger;
    if (!validateForm()) return;

    try {
      const response = await _billServc.BillingSheetCheckList_Save(formData);

      if (response.data > 0) {
        toast.success("Save Successfully");
       
        handleClose();
        $("#CloseBillingSheetCheckList").click();
      } else {
        toast.error("Save Failed");
      }
    } catch (error) {
      console.error("Error saving billing sheet details:", error);
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-3 mb-3">
          <label className="label">Station</label>
          <ReactMultiSelect
            isMulti
            options={DropdownModel?.StationList}
            placeholder="Select"
            name="StationId"
            allOption
            value={DropdownModel?.StationList.filter((option) =>
              (formData?.StationId || "").split(",").includes(String(option.value))
            )}
            onChange={(selectedOptions) =>
              handleMultiSelectChange(selectedOptions, "StationId")
            }
          />
        </div>
        <div className="col-3 mb-3">
          <label className="label">
            Equipment
          </label>
          <ReactMultiSelect
            isMulti
            options={DropdownModel?.DropEquipmentList}
            placeholder="Select"
            name="EquipmentId"
            value={DropdownModel?.DropEquipmentList?.filter((option) =>
              (formData?.EquipmentId || "").split(",").includes(String(option.value))
            )}
            onChange={(selectedEquipment)=>handleMultiSelectChange(selectedEquipment,"EquipmentId")}
          />
          {errors.EquipmentId && (
            <div className="text-danger">{errors.EquipmentId}</div>
          )}
        </div>
        <div className="col-3 mb-3">
          <label className="label">
            CheckList Number
          </label>
          <input
            type="text"
            className="form-control"
            name="CheckListNumber"
            value={formData.CheckListNumber}
            onChange={handleInputChange}
          />
          {errors.CheckListNumber && (
            <div className="text-danger">{errors.CheckListNumber}</div>
          )}
        </div>
        <div className="col-3 mb-3">
          <label className="label">
            CheckList Description<span className="text-danger">*</span>
          </label>
          <textarea
            className="form-control PeriodicClearClass"
            style={{ resize: "none" }}
            type="text"
            name="CheckListDescription"
            value={formData.CheckListDescription}
            onChange={handleInputChange}
            rows={3}
          ></textarea>
          {errors.CheckListDescription && (
            <div className="text-danger">{errors.CheckListDescription}</div>
          )}
        </div>
      </div>

      {/* <div className="row">
        <div className="col-3 mb-3">
          <lable className="lable">
            Marks Weightage<span className="text-danger">*</span>
          </lable>
          <input
            type="number"
            className="form-control"
            name="MaxMarksWeightage"
            value={formData.MaxMarksWeightage}
            onChange={handleInputNumberChange}
          />
        </div>

        <div className="col-3 mb-3">
          <lable className="lable">Amount</lable>
          <input
            type="number"
            className="form-control"
            name="Amount"
            value={formData.Amount}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-3 mb-3">
          <lable className="lable">Max Amount</lable>
          <input
            type="number"
            className="form-control"
            name="MaxAmount"
            value={formData.MaxAmount}
            onChange={handleInputNumberChange}
          />
        </div>
        <div className="col-3 mb-3">
          <lable className="lable">Rate As Per Contract</lable>
          <input
            type="number"
            className="form-control"
            name="RateAsPerContract"
            value={formData.RateAsPerContract}
            onChange={handleInputNumberChange}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-3 mb-3">
          <lable className="lable">Vendor Remarks</lable>
          <textarea
            type="text"
            className="form-control"
            name="VendorRemarks"
            style={{ resize: "none" }}
            rows={3}
            value={formData.VendorRemarks}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-3 mb-3">
          <lable className="lable">CMRL Remarks</lable>
          <textarea
            type="text"
            className="form-control"
            name="CMRLRemarks"
            style={{ resize: "none" }}
            rows={3}
            value={formData.CMRLRemarks}
            onChange={handleInputChange}
          />
        </div>
      </div> */}
      <div className="col-3" style={{ width: "99%" }}>
        <button type="button" className="btn add-btn" onClick={handleSave}>
          Save
        </button>
      </div>
    </>
  );
}

export default BillingSheetSaveDetail;
