import React, { useEffect, useState, useContext } from "react";
import {
  CheckListHtmlTable,
  Dropdown,
  PageHeaderTitle,
  ReactMultiSelect,
} from "../../components";
import { PeriodicService } from "./PeriodicService";
import { Decrypt, getYMDdate, makeUnique } from "../../services/CommonService";
import $ from "jquery";
import { logo } from "../Image";
import moment from "moment";
import { COOKIES } from "../../services/Global";
import { AppContext } from "../../services/ContextProvider";

function UG_19_PeriodicReport() {
  const _periodicService = new PeriodicService();
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const initialData = {
    EquipmentID: 0,
    FromDate: getYMDdate(firstDay),
    EndDate: getYMDdate(),
    MaintenanceTypeID: 1,
    StationID: 0,
    StationName: ""

  };
  const [PeriodicModel, setPeriodicModel] = useState(initialData);
  const { StationID, EquipmentID, MaintenanceTypeID, FromDate, EndDate, StationName } = PeriodicModel;


  const [TopHeaderColumns, setTopHeaderColumns] = useState([]);
  const [ptwresult, setptwresult] = useState('');

  const [UGSColumnList, setUGSColumnList] = useState([]);
  const [UGSRowList, setUGSRowList] = useState([]);
  const [DropdownModel, setDropdownModel] = useState({
    StationList: [],
    EquipmentList: [],
    FilterEquipmentList: [],
    ScheduleTypeList: []
  });
  const [DesList, setDesList] = useState([]);


  const { getCookies, handleGlobalStationName, showLoading, hideLoading } =
    useContext(AppContext);

  useEffect(() => {
    getStationList();
    Report_GetJobEquipmentList();
    getType()

    return () => {
      setDropdownModel((pre) => ({
        ...pre,
        EquipmentList: [],
        FilterEquipmentList: [],
        StationList: [],
        ScheduleTypeList: []
      }));
    };
  }, []);

  useEffect(() => {
    GetDescriptionByID();


    return () => {
      setUGSColumnList([])
      setUGSRowList([])
    };



  }, [StationID, EquipmentID, MaintenanceTypeID, FromDate, EndDate]);

  const GetDescriptionByID = () => {
    const { StationID, EquipmentID, MaintenanceTypeID, FromDate, EndDate } =
      PeriodicModel;

    if (EquipmentID?.Value && StationID && FromDate && EndDate) {
      showLoading();

      _periodicService
        .Report_Periodic_GetEquipmentDetailsUsingID(
          StationID,
          EquipmentID.Value,
          MaintenanceTypeID,
          FromDate,
          EndDate
        )
        .then((res) => {
          hideLoading();

          if (res?.data?.data?.descriptionList?.length) {
            let columnsList = [];
            debugger
            const { descriptionList, equipmentList, ptwList } = res.data.data;
            console.log(ptwList)
            if (ptwList != undefined && ptwList.length > 0) {
              const groupedData = ptwList.reduce((acc, item) => {
                const key = item.ShiftTechnician;
                if (!acc[key]) {
                  acc[key] = {
                    ...item,
                    CombinedEquipment: `${item.EquipmentCode}`
                  };
                } else {
                  acc[key].CombinedEquipment = `${acc[key].CombinedEquipment},${item.EquipmentCode}`;
                }

                return acc;
              }, {});

              Object.values(groupedData).forEach(item => {
                const equipmentList = item.CombinedEquipment.split(',');
                const technicianLabel = item.ShiftTechnician;
                if (equipmentList.length === 1) {
                  item.CombinedEquipment = `${equipmentList[0]} (${technicianLabel})`;
                } else {
                  item.CombinedEquipment = `${equipmentList.join(',')} (${technicianLabel})`;
                }
                item.CombinedEquipment = `PTWNumber: ${item.PTWNumber}, PTWDate: ${new Date(item.PTWDate).toLocaleDateString()} => ${item.CombinedEquipment}`;
              });

              const combinedEquipmentList = Object.values(groupedData).map(item => item.CombinedEquipment);
              const finalCombinedEquipmentString = combinedEquipmentList.join(', ');

              setptwresult(finalCombinedEquipmentString);
            }
            setDesList([...equipmentList])
            descriptionList.forEach((element) => {
              let filList = equipmentList.filter(
                (x) => x.MaintenanceScheduleID == element.MaintenanceScheduleID
              );
              const CheckAnyClose = filList.find((x) => x.PWTDate);
              const firstObj = filList[0];
              const {
                UOM,
                RatedValue,
                Status,
                PWTDate,
                PWTNumber,
                JobScheduleID,
                JobScheduleEquipmentID,
                Location,
                JobStatus,
                PreRemarks,
                ClosedDate,
                ApprovedDate,
                FromDate,
                ToDate

              } = CheckAnyClose ?? firstObj;
              let status = "";
              if (filList?.length) {
                let overAllStatus = [...new Set(filList.map((x) => x.Status))];

                if (overAllStatus.includes("Pending")) {
                  status = "Pending";
                } else if (
                  overAllStatus.includes("Open") &&
                  overAllStatus.includes("Closed")
                ) {
                  status = "Open / Closed";
                } else if (overAllStatus.includes("Open")) {
                  status = "Open";
                } else if (
                  overAllStatus.includes("Approved") &&
                  overAllStatus.includes("Closed")
                ) {
                  status = "Closed / Approved";
                } else if (
                  overAllStatus.includes("Rejected") &&
                  overAllStatus.includes("Closed")
                ) {
                  status = "Closed / Rejected";
                } else if (overAllStatus.includes("Closed")) {
                  status = "Closed";
                } else if (
                  overAllStatus.includes("Approved") &&
                  overAllStatus.includes("Rejected")
                ) {
                  status = "Approved / Rejected";
                } else if (overAllStatus.includes("Approved")) {
                  status = "Approved";
                } else if (overAllStatus.includes("Rejected")) {
                  status = "Rejected";
                }
              }

              filList.forEach((FilterEquipment) => {
                const {
                  EquipmentDetailID,
                  ActualValue,
                  DisplayEquipmentStatus,
                  RemarksByEngineer,
                  RemarksByTechncian,
                  EquipmentCode,
                  PreRemarks,
                } = FilterEquipment;

                element[`ActualValue_${EquipmentDetailID}`] = ActualValue;

                element[`RemarksForTech_${EquipmentDetailID}`] =
                  RemarksByTechncian;
                element[`RemarksForEngg_${EquipmentDetailID}`] =
                  RemarksByEngineer;
                element[`EquipmentCode_${EquipmentDetailID}`] = EquipmentCode;
                element[`PreRemarks_${EquipmentDetailID}`] = PreRemarks;
                element[`DisplayEquipmentStatus_${EquipmentDetailID}`] =
                  DisplayEquipmentStatus;
              });

              Object.assign(element, {
                UOM,
                RatedValue,
                Status: status,
                JobScheduleID,
                PWTDate,
                PWTNumber,
                JobScheduleEquipmentID,
                Location,
                JobStatus,
                PreRemarks,
                ClosedDate,
                ApprovedDate,
                FromDate,
                ToDate
              });
            });

            let FinalRowObj = descriptionList.sort((a, b) => a.RowID - b.RowID);

            columnsList.push({
              title: " S.No",
              data: "RowID",
              width: "5px",
              className: "S_no_Class hide",
              visible: false,
            });
            columnsList.push({
              title: `<p style = 'width : 150px'> Component<p/>`,
              data: "Component",
              width: "150px ",
            });
            columnsList.push({
              title: `<p style = 'width : 150px'> Description of Work<p/>`,
              data: "Description",
              width: "150px ",
            });

            columnsList.push({
              title: "Rated Values",
              data: "RatedValue",
            });
            columnsList.push({
              title: "UOM",
              data: "UOM",
              width: "60px",
            });

            columnsList.push({
              title: "Status",
              data: `Status`,
              width: "30px",
            });
            columnsList.push({
              title: "Closed Date",
              data: `ClosedDate`,
              width: "60px",
            });
            columnsList.push({
              title: "Approved Date",
              data: `ApprovedDate`,
              width: "60px",
            });
            if (descriptionList?.length) {
              let uniqueList = makeUnique(equipmentList, [
                "EquipmentCode",
                "EquipmentDetailID",
              ]);
              let EquipmentHeaders = [];

              EquipmentHeaders.push({
                title: "",
                titleForPDf: "",
                colspan: 6,
              });

              uniqueList?.forEach((element, _) => {
                const { EquipmentCode, EquipmentDetailID, Location } = element;
                EquipmentHeaders.push({
                  title: `EQ.ID: ${EquipmentCode}`,
                  titleForPDf: `EQ.ID: ${EquipmentCode}`,
                  colspan: 4,
                  Location: `Location : ${Location}`,
                });

                columnsList.push({
                  title: "Status",
                  data: `DisplayEquipmentStatus_${EquipmentDetailID}`,
                });

                columnsList.push({
                  title: "Actual",
                  data: `ActualValue_${EquipmentDetailID}`,
                });
                columnsList.push({
                  title: "Remarks By Technician",
                  data: `RemarksForTech_${EquipmentDetailID}`,
                  width: "60px",
                });
                columnsList.push({
                  title: "Remarks By Engineer",
                  data: `RemarksForEngg_${EquipmentDetailID}`,
                  width: "60px",
                });

              });


              setTopHeaderColumns(EquipmentHeaders);

              setUGSColumnList([...columnsList]);
              setUGSRowList([...FinalRowObj]);
            } else {
              setUGSColumnList([]);
              setUGSRowList([]);
            }
          } else {
            setUGSColumnList([])
            setUGSRowList([])
          }
        })
        .catch((err) => {
          console.log(err);
          hideLoading();
          setUGSColumnList([])
          setUGSRowList([])

        });
    }
    else {
      setUGSColumnList([])
      setUGSRowList([])
    }
  };

  function CustomTopHeader(table) {
    let thead = $(table?.table()?.header());
    if (thead?.length) {
      let FirstHeaderRow = $('<tr class="header-row">');
      let SecondHeaderRow = $('<tr class="header-row">');
      TopHeaderColumns.forEach((data) => {
        SecondHeaderRow.append(
          `<th colspan="${data.colspan}"  class= "TableTopHeaderStyle"  >${data.title}</th>`
        );
        FirstHeaderRow.append(
          `<th colspan="${data.colspan}"  class= "TableTopHeaderStyle"  >${data.Location ?? ""
          }</th>`
        );
      });
      thead.prepend(SecondHeaderRow);
      thead.prepend(FirstHeaderRow);
    }
  }
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };
  function enUSFormat(date) {
    if (!date) return '';
    return new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric' }).format(new Date(date));
  }
  const PDfCustomization = (doc) => {
    const objLayout = {
      hLineWidth: () => 0.5,
      vLineWidth: () => 0.5,
      hLineColor: () => "#aaa",
      vLineColor: () => "#aaa",
      paddingLeft: () => 4,
      paddingRight: () => 4,
      paddingTop: () => 4,
      marginTop: () => 100,
    };
    // Inject the object in the document
    // Json Parse for Deep Copy
    let AllRecords_tables = JSON.parse(JSON.stringify(doc.content[1]));

    // remove first default object
    AllRecords_tables.table.body.forEach((ele) => {
      ele.splice(0, 8);
    });
    // Remaining manipulation data.
    let records = AllRecords_tables.table.body;
    let overallLength = doc.content[1].table.body[0].length;
    let tableLength = doc.content[1].table.body[0].length - 8;

    let FinalTables = [];
    let StartIndex = 0;
    let EquipmentLength = 0;
    if (tableLength >= 16) {
      EquipmentLength = 16
    }
    else {
      EquipmentLength = tableLength;
      let fixedWidths = [30, 100, 300];
      for (let j = 3; j < overallLength; j++) {
        fixedWidths.push('*');
      }
      // doc.content[1].table.widths = "*";
      doc.content[1].table.widths = fixedWidths;
    }

    for (let i = 1; i <= tableLength / EquipmentLength; i++) {
      let AllRecords = JSON.parse(JSON.stringify(doc.content[1]));
      let TopHeader = JSON.parse(JSON.stringify(TopHeaderColumns));

      let endIndex = i * EquipmentLength;
      let body = [];


      records.forEach((ele) => {
        let manipulateData = ele.slice(StartIndex, endIndex);
        body.push(manipulateData);
      });

      AllRecords.table.body.forEach((ele, _index) => {
        ele.splice(8, tableLength);
        ele.splice(8, 0, ...body[_index]);
      });
      let TopHead = [];
      let SecondTopHead = [];
      let FirstElement = { ...AllRecords.table.body[0][0] };

      FirstElement.text = TopHeader[0].titleForPDf;
      FirstElement.colSpan = TopHeader[0].colspan + 1;
      TopHeader.splice(0, 1);
      TopHead.push(FirstElement);
      SecondTopHead.push(FirstElement);
      for (let i = 0; i < 7; i++) {
        TopHead.push("");
        SecondTopHead.push("");
      }

      let topHeadVal = TopHeader.slice((endIndex / 4) - (EquipmentLength / 4), endIndex / 4);
      topHeadVal.forEach((ele) => {
        let SampleEle = {
          ...FirstElement,
          text: ele.titleForPDf,
          colSpan: ele.colspan,
        };
        let SecondSampleEle = {
          ...FirstElement,
          text: ele.Location,
          colSpan: ele.colspan,
        };

        TopHead.push(SampleEle);
        SecondTopHead.push(SecondSampleEle);
        for (let i = 0; i < ele.colspan - 1; i++) {
          TopHead.push("");
          SecondTopHead.push("");
        }
      });
      AllRecords.table.body.unshift(TopHead);
      AllRecords.table.body.unshift(SecondTopHead);

      StartIndex = endIndex;
      if (overallLength > 25) {
        FinalTables.push({
          ...AllRecords,
          pageBreak: 'after'
        });
      } else {
        FinalTables.push({
          ...AllRecords,
        });
      }
    }
    // for undivided remaining columns
    if (!(tableLength % EquipmentLength == 0)) {
      let lastEquipmentLen = tableLength % EquipmentLength;
      let AllRecords = JSON.parse(JSON.stringify(doc.content[1]));
      let body = [];

      let remainingEquipmentLen = tableLength - lastEquipmentLen;
      let RemainingTopHeader = lastEquipmentLen / 4;
      let TopHeader = JSON.parse(JSON.stringify(TopHeaderColumns));
      let TopHead = [];
      let SecondTopHead = [];
      let FirstElement = { ...AllRecords.table.body[0][0] };

      FirstElement.text = TopHeader[0].titleForPDf;
      FirstElement.colSpan = TopHeader[0].colspan + 1;
      TopHeader.splice(0, 1);
      TopHead.push(FirstElement);
      SecondTopHead.push(FirstElement);
      for (let i = 0; i < 7; i++) {
        TopHead.push("");
        SecondTopHead.push("");
      }

      let topHeadVal = TopHeader.slice(-RemainingTopHeader);
      topHeadVal.forEach((ele) => {
        let SampleEle = {
          ...FirstElement,
          text: ele.titleForPDf,
          colSpan: ele.colspan,
        };
        let SecondSampleEle = {
          ...FirstElement,
          text: ele.Location,
          colSpan: ele.colspan,
        };

        TopHead.push(SampleEle);
        SecondTopHead.push(SecondSampleEle);
        for (let i = 0; i < ele.colspan - 1; i++) {
          TopHead.push("");
          SecondTopHead.push("");
        }
      });

      records.forEach((ele) => {
        let manipulateData = ele.slice(remainingEquipmentLen);
        body.push(manipulateData);
      });

      AllRecords.table.body.forEach((ele, _index) => {
        ele.splice(8, tableLength);
        ele.splice(8, 0, ...body[_index]);
      });

      AllRecords.table.body.unshift(TopHead);
      AllRecords.table.body.unshift(SecondTopHead);
      FinalTables.push({
        ...AllRecords,
      });
    }


    let StartIndx = 1;

    let ShiftTechnician = "";
    let ShiftEngineer = "";
    FinalTables.forEach((ele) => {
      let TechnicianRow = [];
      let EngineerRow = [];
      let jobDoneBy = [];
      let approvedBy = [];
      jobDoneBy.push({
        text: "Name of the Shift Technician",
        colSpan: 3,
      });
      approvedBy.push({
        text: "Name of the Shift Approver/Engineer",
        colSpan: 3,
      });
      TechnicianRow.push({
        text: "Maintenance performer Sign/Designation",
        colSpan: 3,
      });

      EngineerRow.push({
        text: "Maintenance In charge Sign/Designation",
        colSpan: 3,
      });

      doc.content[StartIndx] = ele;
      doc.content[StartIndx].layout = objLayout;
      // doc.content[StartIndx + 1] = "\n\n";
      // doc.content[StartIndx] = ele;
      // doc.content[StartIndx].dontBreakRows = true;  // Prevent row breakage
      // doc.content[StartIndx].keepWithHeaderRows = 1;
      let headerRow = [...ele.table.body[0]];
      headerRow = headerRow.slice(1);
      const uniqueShiftTechnicians = [...new Set(DesList.map(item => item.ShiftTechnician))].join(', ');
      const uniqueShiftEngineers = [...new Set(DesList.map(item => item.ShiftEngineer))].join(', ');

      headerRow?.forEach(() => {
        jobDoneBy.push({ text: ptwresult, colSpan: headerRow.length - 2, alignment: "center", });
        approvedBy.push({ text: uniqueShiftEngineers, colSpan: headerRow.length - 2, alignment: "center", });
        TechnicianRow.push({ text: ShiftTechnician, colSpan: headerRow.length - 2, alignment: "center", });
        EngineerRow.push({ text: ShiftEngineer, colSpan: headerRow.length - 2, alignment: "center", });

      });
      ele.table.body.push(jobDoneBy);
      ele.table.body.push(approvedBy);
      ele.table.body.push(TechnicianRow);
      ele.table.body.push(EngineerRow);

      StartIndx += 2;
    });
    let DocumentNo = PeriodicModel?.EquipmentID?.DocumentNo ?? ""
    let MaintenanceType = DropdownModel?.ScheduleTypeList?.find((ele) => ele.value == MaintenanceTypeID).key ?? "";
    console.log(overallLength);
    doc["header"] = function (page, pages) {
      return {
        stack: [
          {
            columns: [
              {
                table: {

                  widths: overallLength > 10 ? [535, 535, 540] : [380, 380, 370],

                  body: [
                    [
                      {
                        text: `Document No : ${DocumentNo} \n Rev: 01`,
                        alignment: "left",
                        margin: [0, 20, 0, 0],
                      },
                      {
                        text: `Preventive Maintenance Plan - ${MaintenanceType} (${MaintenanceType !== 'Daily' && MaintenanceType !== 'Yearly' ? `${formatDate(DesList?.[0]?.FromDate)}` : `${enUSFormat(DesList?.[0]?.FromDate)}`} ${MaintenanceType !== 'Daily' && MaintenanceType !== 'Yearly' ? ` - ${formatDate(DesList?.[0]?.ToDate)}` : ''})`,                        // rowSpan: 2,
                        alignment: "center",
                        margin: [0, 23, 0, 0],
                      },
                      {
                        image: logo,
                        // rowSpan: 2,
                        alignment: "center",
                        width: 50,
                        margin: [0, 5, 0, 5],
                      },
                    ],
                  ],
                },
                margin: [18, 10, 0, 0],
              },
            ],
          },

          {
            columns: [
              {
                text: `Station Name: ${StationName}`,
                alignment: "left",
                margin: [18, 10, 0, 0],
              },

              {
                text: `Scheduled Date : ${MaintenanceType !== 'Daily' && MaintenanceType !== 'Yearly' ? `${formatDate(DesList?.[0]?.FromDate)}` : `${formatDate(DesList?.[0]?.FromDate)}`} ${MaintenanceType !== 'Daily' && MaintenanceType !== 'Yearly' ? ` to ${formatDate(DesList?.[0]?.ToDate)}` : ''}`,
                alignment: "right",
                margin: [0, 10, 25, 0],
              },
            ],

          },
        ],
        columnGap: 10,
      };
    };
  };

  const getStationList = () => {

    var stationList = Decrypt(getCookies(COOKIES.Stations));
    var dataLst = [];
    let ignoredStation = [1, 2, 3]
    stationList.map((data) => {
      if (!ignoredStation.includes(data.StationID))
        dataLst.push({ value: data.StationID, key: data.StationCode });
    });

    setPeriodicModel((pre) => ({
      ...pre,
      StationID: dataLst[0].value,
      StationName: dataLst[0].key
    }))

    handleGlobalStationName(dataLst[0].key)


    setDropdownModel((pre) => ({
      ...pre,
      EquipmentList: [],
      FilterEquipmentList: [],
      StationList: dataLst,
    }));
  };

  const Report_GetJobEquipmentList = () => {
    _periodicService
      .Report_GetJobEquipmentList(2)
      .then((res) => {
        var EquipmentList = [];

        if (res?.data.length) {
          EquipmentList = res.data[0];

          setDropdownModel((pre) => ({
            ...pre,
            EquipmentList: EquipmentList,
            FilterEquipmentList: EquipmentList,
          }));

          setPeriodicModel((pre) => ({
            ...pre,
            EquipmentID: EquipmentList[0],

          }))


        }
      })
      .catch(
        (err) => console.log(err)
      );
  };

  const CommonHandleChange = (event, name) => {
    if (Object.hasOwn(event, "target")) {
      const { name, value } = event.target;
      setPeriodicModel((pre) => ({
        ...pre,
        [name]: value,
      }));
      if (name == "StationID") {

        let SelectedStation = DropdownModel?.StationList?.find(
          (x) => x.value == value
        );


        setPeriodicModel((pre) => ({
          ...pre,
          StationName: SelectedStation.key,
        }));

        handleGlobalStationName(SelectedStation.key)


      }
    } else {
      setPeriodicModel((pre) => ({
        ...pre,
        [name]: event,
        EquipmentName: event.label,
      }));
    }
  };

  const getType = () => {
    var dataLst = [
      { key: "Daily", value: 1 },
      { key: "Monthly", value: 2 },
      { key: "Quarterly", value: 3 },
      { key: "Half-Yearly", value: 4 },
      { key: "Yearly", value: 5 },
    ];

    setDropdownModel((pre) => ({
      ...pre,
      ScheduleTypeList: dataLst,
    }));
  };

  return (
    <>
      <div>
        <div className="page-header mb-1">
          <div className="row justify-content-between">
            <div className="col-6">
              <h3 className="page-title">Periodic Maintenance Report</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Station Name
              </label>
              <Dropdown
                data={DropdownModel.StationList}
                placeholder="Select"
                name={"StationID"}
                HandleChange={CommonHandleChange}
                value={PeriodicModel.StationID}
                className=""
              />
            </div>
          </div>


          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Equipment
              </label>
              <ReactMultiSelect
                options={DropdownModel.EquipmentList}
                placeholder="Select"
                value={PeriodicModel.EquipmentID}
                onChange={(e) => {
                  CommonHandleChange(e, "EquipmentID");
                }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                ScheduleType{" "}
              </label>
              <Dropdown
                data={DropdownModel.ScheduleTypeList}
                placeholder="Select"
                name={"MaintenanceTypeID"}
                HandleChange={CommonHandleChange}
                value={PeriodicModel.MaintenanceTypeID}
                className=""
              />
            </div>
          </div>
          <div className="col-2">
            <label className="label" for="">
              From Date{" "}
            </label>

            <input
              type="date"
              className="form-control"
              id="FromDate"
              name="FromDate"
              pattern="\d{4}-\d{2}-\d{2}"
              value={PeriodicModel.FromDate}
              onChange={(e) => {
                CommonHandleChange(e);
              }}
            />
          </div>
          <div className="col-2">
            <label className="label" for="">
              To Date{" "}
            </label>

            <input
              type="date"
              className="form-control"
              value={PeriodicModel.EndDate}
              id="EndDate"
              name="EndDate"
              onChange={(e) => {
                CommonHandleChange(e);
              }}
            />
          </div>
        </div>
        {UGSRowList?.length && UGSColumnList?.length ?
          <CheckListHtmlTable
            IsASC
            IsShowPDF
            FixedColums={{
              left: 2,
            }}
            OrderTargetList={Array.from(
              { length: UGSColumnList.length - 6 },
              (_, i) => i + 4
            )}
            rowList={UGSRowList}
            columnList={UGSColumnList}

            id={`PeriodicUGStationReport`}
            tableReRender={PeriodicModel}
            orderby={0}
            AdditionalPlugin={CustomTopHeader}
            PDfCustomization={PDfCustomization}

            title="Periodic Maintenance"
            landscape
            pageSize={"a3"}
          />
          : ""
        }
      </div>
    </>
  );
}

export default UG_19_PeriodicReport;
