import React, { useEffect, useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-big-calendar";
import { HalfYearlyMOnthForPM } from "../../services/CommonService";

import { PeriodicWeekData, checkDate, checkMonth } from "./PeriodicWeekData";
import { PeriodicService } from "./PeriodicService";
import { PeriodicSetQuerterMOnthDate } from "../../services/CommonService";
import { ContextValues } from "./PeriodicContextProvider";
import moment from "moment";
import CommonWeekCalender from "./CommonWeekCalender";

export default function CalendarHalfyearlyView({
  date,
  localizer,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(), "day"),
  ...props
}) {
  const { MonthConfigObj } = ContextValues();

  const currRange = useMemo(() => {
    const { Date } = HalfYearlyMOnthForPM(date, MonthConfigObj);
    return CalendarHalfyearlyView.range(Date, { localizer });
  }, [date, localizer]);

  let week1 = currRange.slice(0, 7);
  let week2 = currRange.slice(7, 14);
  let week3 = currRange.slice(14, 21);
  let week4 = currRange.slice(21, 28);
  let week5 = PeriodicWeekData(currRange[0]);

  let weekLst = [week1, week2, week3, week4, week5];

  return <CommonWeekCalender weekLst={weekLst}></CommonWeekCalender>;
}

CalendarHalfyearlyView.propTypes = {
  date: localStorage.getItem("halfyearlyMonth"),
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

CalendarHalfyearlyView.range = (date, { localizer }) => {
  const start = new Date(date.getFullYear(), date.getMonth(), 1);
  const end = localizer.add(
    start,
    new Date(date.getFullYear(), date.getMonth(), 0).getDate(),
    "day"
  );

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

CalendarHalfyearlyView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -6, "month");

    case Navigate.NEXT:
      return localizer.add(date, 6, "month");

    default:
      return date;
  }
};

CalendarHalfyearlyView.title = (date, { localizer }) => {
  let CurrentMonth = new Date(date).getMonth() + 1;
  let CurrentYear = new Date(date).getFullYear();
  if (CurrentMonth <= 6) {
    return "Jan - June (H1) " + CurrentYear;
  } else {
    return "July - Dec (H2) " + CurrentYear;
  }
};
