import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../services/ContextProvider";

import $ from "jquery";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import {
  CheckList_DropDownDetails,
  CheckList_SaveCheckListDetails,
  CheckList_GetDescriptionByID,
  CheckList_SaveScheduleCheckListDetails,
} from "./CheckListService";
import {
  GetLoginUserID,
  Decrypt,
  getPrivilege,
  getYMDdate,
  GetCurrentWeek,
  getUserDetails,
  SmallGetToggleButton,
  addDays,
} from "../../services/CommonService";
import Global, { COOKIES, STAND_BY } from "../../services/Global";
import {
  showWarningMsg,
  showSaveSuccess,
  Dropdown,
  ReactMultiSelect,
  HtmlTable,
  showSaveError,
  showErrorMsg,
  BootrapModel,
  showSuccessMsg,
} from "../../components";
import { makeUnique } from "../../services/CommonService";
import { DownloadModel } from "../BreakDownMaintenance/DownloadModel";
import { PageHeader } from "../../components/PageHeader";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";
import swal from "sweetalert";

const _breakdownService = new BreakdownMaintenanceService();
export default function CheckList({ InitialScheduleType = 43 }) {
  const [DropdownModel, setDropdownModel] = useState({
    StationList: [],
    EquipmentList: [],
    FilterEquipmentList: [],
    DescriptionList: [],
    FilterEmployeeList: [],
    TechnicianList: [],
    EngineerList: [],
  });
  const initialCheckList = {
    EquipmentID: {},
    StationID: "",
    Description: "",
    ShiftTechnicianID: "",
    ShiftEngineerID: "",
    OrderNo: 0,
    IsShowSubmitBtn: false,
    CheckAllAttended: false,
    CheckAllApproved: false,
    CheckAllRejected: false,
    DescriptionID: 0,
  };

  const [CheckListModel, setCheckListModel] = useState(initialCheckList);

  const [columnList, setColumnList] = useState([]);
  const [RowList, setRowList] = useState([]);
  const [FileData, setFileData] = useState([]);
  const [CheckListScheduleType, setCheckListScheduleType] = useState(43);

  const [errors, setErrors] = useState({
    EquipmentID: "",
    StationID: "",
    Description: "",
  });

  const {
    getCookies,
    TypeDetails,
    HandleTypeDetails,
    handleGlobalStationName,
    showLoading,
    hideLoading,
  } = useContext(AppContext);

  useEffect(() => {
    setCheckListScheduleType(InitialScheduleType);
    initialDropDown(InitialScheduleType);

    return () => {
      setDropdownModel({});
    };
  }, []);

  useEffect(() => {
    setCheckListModel((pre) => ({
      ...pre,
      Description: "",
      OrderNo: 0,
      DescriptionID: 0,
    }));
    GetDescriptionByID();
  }, [
    CheckListModel.EquipmentID,
    CheckListModel.StationID,
    TypeDetails.CheckDate,
  ]);

  /**
   * Initializes dropdown values and equipment details.
   */
  const initialDropDown = async (ScheduleType) => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    let ignoredStation = ["stc", "str", "swa"];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });
    await CheckList_DropDownDetails(ScheduleType)
      .then((res) => {
        if (res?.data?.data) {
          const { equipmentList, descriptionList, employeeList } =
            res.data.data;

          setCheckListModel(initialCheckList);

          if (equipmentList?.length) {
            let EquipmentList =
              equipmentList?.filter(
                (x) => x.StationID == TypeDetails.StationID
              ) ?? [];
            setDropdownModel((preState) => ({
              ...preState,
              StationList: FinalStationList,
              EquipmentList: EquipmentList,
              FilterEquipmentList: equipmentList ?? [],
              DescriptionList: descriptionList ?? [],
              FilterEmployeeList: employeeList ?? [],
              TechnicianList: [],
              EngineerList: [],
            }));

            setCheckListModel((pre) => ({
              ...pre,
              // StationID: FinalStationList[0].value,
              EquipmentID: EquipmentList[0],
            }));
            // handleGlobalStationName(FinalStationList[0].key);
          }
        } else {
          setDropdownModel({});
          setCheckListModel(initialCheckList);
        }
      })
      .catch((err) => {
        console.log(err);
        setDropdownModel({});
      });
  };

  const AddHandleClick = async (e) => {
    e.preventDefault();

    let obj = { ...CheckListModel };
    obj.StationID = TypeDetails.StationID;
    let isError = false;
    let modelErrors = errors;
    if (obj.EquipmentID?.Value) {
      modelErrors.EquipmentID = "";
    } else {
      modelErrors.EquipmentID = "Please Select Equipment.";
      isError = true;
    }
    if (obj.StationID) {
      modelErrors.StationID = "";
    } else {
      modelErrors.StationID = "Please Select Station.";
      isError = true;
    }
    if (obj.Description?.length) {
      modelErrors.Description = "";
    } else {
      modelErrors.Description = "Please Select Description.";
      isError = true;
    }
    if (obj.OrderNo != 0) {
      modelErrors.OrderNo = "";
    } else {
      modelErrors.OrderNo = "Please Enter OrderNo.";
      isError = true;
    }
    setErrors({ ...modelErrors });
    if (!isError) {
      obj.EquipmentID = obj.EquipmentID.Value ?? 0;
      obj.SavedBy = GetLoginUserID();
      obj.ScheduleType = CheckListScheduleType;
      obj.DescriptionID = obj.DescriptionID;
      debugger;

      CheckList_SaveCheckListDetails(obj)
        .then((res) => {
          const { data } = res?.data;
          if (data == "-1") {
            showSaveError();
          } else {
            showSaveSuccess();
            setCheckListModel((pre) => ({
              ...pre,
              Description: "",
              OrderNo: 0,
              DescriptionID: 0,
            }));
          }
          GetDescriptionByID();
        })
        .catch((err) => {
          console.log(err);
          setCheckListModel((pre) => ({
            ...pre,
            Description: "",
            OrderNo: 0,
            DescriptionID: 0,
          }));
        });
    }
  };
  const confirmSelection = (e, rowData) => {
    swal({
      title: "Are you sure?",
      text: `Do you want to Delete this job`,
      icon: `warning`,
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        onDeleteClick(e, rowData)
        //updateJobStatus(status);
      }
    });

  }

  const GetDescriptionByID = async () => {
    var CheckDate = TypeDetails.CheckDate;
    let checkFuture = false;
    if (CheckListScheduleType == 43) {
      checkFuture = TypeDetails.CheckDate <= getYMDdate(new Date());
    } else {
      checkFuture = TypeDetails.CheckDate <= GetCurrentWeek();
    }

    if (
      TypeDetails.StationID &&
      CheckListModel.EquipmentID?.Value &&
      checkFuture
    ) {
      await CheckList_GetDescriptionByID(
        TypeDetails.StationID,
        CheckListModel.EquipmentID.Value ?? 0,
        TypeDetails.ScheduleType,
        CheckDate
      )
        .then((res) => {
          if (res?.data?.data) {
            let columnsList = [];
            columnsList.push({
              title: " S.No",
              data: "RowID",
              width: "5px",
              className: "S_no_Class",
              // visible: false
            });
            columnsList.push({
              title: `<p style = 'width : 150px;z-index:1'> Description of Work<p/>`,
              data: "Description",
              width: "150px ",
            });

            const { descriptionList, equipmentList, checkListData } =
              res.data.data;
            if (descriptionList?.length) {
              let uniqueList = makeUnique(equipmentList, [
                "EquipmentCode",
                "EquipmentDetailID",
              ]);
              let IsAllowToEnter = IsAllowToEnterData();
              uniqueList?.forEach((element, _) => {
                let IsToggleChecked =
                  element.CheckListData.replaceAll(" ", "") ==
                  STAND_BY.replaceAll(" ", "");

                let div = $(`<span><span/>`);
                let para = $(
                  `<p style ="width : 100px">${element.EquipmentCode}</p>`
                );
                let toggle = SmallGetToggleButton(
                  IsToggleChecked,
                  element.EquipmentDetailID
                );

                div.append(para);
                if (IsAllowToEnter) {
                  div.append(toggle);
                } else {
                  IsToggleChecked = true;
                }
                columnsList.push({
                  title: div.html(),
                  data: element.EquipmentDetailID.toString(),
                  // width: "100px",

                  createdCell: function (td, cellData, rowData, row, col) {
                    var inputElement = "";

                    if (IsToggleChecked) {
                      inputElement = $(
                        `<input type="text" disabled class="form-control_copy Equipment_${element.EquipmentDetailID}" style="width:60px" value="${cellData}"/>`
                      );
                    } else {
                      inputElement = $(
                        `<input type="text"  class="form-control_copy Equipment_${element.EquipmentDetailID}" style="width:60px" value="${cellData}"/>`
                      );
                    }
                    inputElement.on("keydown", function (e) {
                      $(`.Equipment_${element.EquipmentDetailID}`).autocomplete(
                        {
                          source: checkListData,
                        }
                      );
                    });

                    inputElement.on("change", function (e) {
                      e.preventDefault();
                      const { value } = e.target;
                      rowData[element.EquipmentDetailID] = value;
                    });

                    $(td).html(inputElement);
                  },
                });
              });

              columnsList.push({
                title: "UOM",
                data: "UOM",
                width: "60px",
                createdCell: function (td, cellData, rowData, row, col) {
                  var inputElement = "";
                  if (IsAllowToEnter) {
                    inputElement = $(
                      `<input type="text" class="form-control_copy"  style="width:60px" value="${cellData}"/>`
                    ).on("change", function (e) {
                      e.preventDefault();
                      const { value } = e.target;
                      rowData.UOM = value;
                    });
                  } else {
                    inputElement = $(
                      `<input type="text" class="form-control_copy" disabled   style="width:60px" value="${cellData}"/>`
                    );
                  }

                  $(td).html(inputElement);
                },
              });
              columnsList.push({
                title: "Remarks",
                data: "Remarks",
                width: "60px",
                createdCell: function (td, cellData, rowData, row, col) {
                  var inputElement = "";

                  inputElement = $(
                    `<textarea type="text" class="form-control_copy"  style="width:130px;resize:none" >${cellData}</textarea>`
                  ).on("change", function (e) {
                    e.preventDefault();
                    const { value } = e.target;
                    rowData.Remarks = value;
                  });

                  // else{
                  //   inputElement = $(
                  //     `<input type="text" class="form-control" disabled  style="width:60px" value="${cellData}"/>`
                  //   )
                  // }

                  $(td).html(inputElement);
                },
              });
              columnsList.push({
                title: "Status",
                data: "StatusName",
                width: "60px",
                createdCell: function (td, cellData, rowData, row, col) {
                  $(td).html(GetPeriodicStatus(cellData));
                },
              });
              columnsList.push({
                title: "Attachments",
                data: "Upload",
                width: "40px",

                createdCell: function (td, cellData, rowData, row, col) {
                  var editbtn = "";
                  var deletebtn = "";
                  editbtn =
                    $(`<button class='btn icon-btn' title='Edit' data-bs-toggle="modal" data-bs-target="#timebutton" >
                  <i class='fa fa-pencil m-l-15'></i>
                </button>`).on("click", function () {
                      EditChecklist(rowData);
                    });
                  // }
                  // if (getPrivilege().includes("NONREVENUECONFIGURATIONDELETE")) {
                  deletebtn = $(`<button class='btn icon-btn'  title='delete'  >
                        <i class='fa fa-trash m-l-15'></i>
                      </button>`).on("click", function (e) {
                    confirmSelection(e, rowData);
                  });
                  let normalDiv = $('<div class="file-upload-div "> </div>');
                  let UploadIcon = $(
                    '<i class="fa fa-upload col-3" aria-hidden="true"></i>'
                  ).on("click", function (e) {
                    var parentElement = $(e.target).closest(".file-upload-div");
                    parentElement.find(".file-input").click();
                  });
                  var inputElement = $(`
                    <input type="file" class="form-control hide file-input"  multiple value="${cellData}"/>`).on(
                    "change",
                    async function (e) {
                      e.preventDefault();

                      const formData = new FormData();
                      $.each(e.target.files, function (i, file) {
                        formData.append("files", file);
                      });
                      if (e.target?.files.length > 0) {
                        let returnValue = await fileUpload(formData);
                        if (returnValue) {
                          rowData.Upload = JSON.stringify(returnValue);
                        }
                      } else {
                        rowData.Upload = "";
                      }
                    }
                  );
                  let IsDownloadEnable = rowData.DownloadFlag == 1;
                  let DownloadIcon = "";
                  if (IsDownloadEnable) {
                    DownloadIcon =
                      $(`<button class='btn icon-btn text-center'data-bs-toggle="modal" data-bs-target="#DownLoadFiles" title='Download'  >
                  <i class='fa fa-download m-r-5'></i>
                  </button>`).on("click", function () {
                        DownloadFiles(rowData.CheckListMaintenceID, 0, 5);
                      });
                  } else {
                    DownloadIcon =
                      $(`<button class='btn icon-btn text-center' disabled title='Download'  >
                <i class='fa fa-download m-r-5'></i>
                </button>`);
                  }
                  if (IsAllowToEnter) {
                    normalDiv.append(UploadIcon);
                  }
                  normalDiv.append(inputElement);
                  normalDiv.append(DownloadIcon);
                  if (getPrivilege().includes("CHECKLISTEDIT")) {
                    normalDiv.append(editbtn);
                  }
                  if (getPrivilege().includes("CHECKLISTDELETE")) {
                    normalDiv.append(deletebtn);
                  }
                  $(td).html(normalDiv);
                },
              });
              descriptionList.forEach((element) => {
                let filList = equipmentList.filter(
                  (x) => x.CheckListID == element.CheckListID
                );
                filList.forEach((y) => {
                  let column_name = y.EquipmentDetailID.toString();
                  element[column_name] = y.CheckListData;
                });
              });

              let CheckDay =
                CheckListScheduleType == 43
                  ? getYMDdate(new Date())
                  : GetCurrentWeek();

              let IsShowSubmitBtn = false;
              let checkCurrentRange = CheckDate == CheckDay;
              let CheckAllAttended = false,
                CheckAllApproved = false;
              let checkAnyRejected = descriptionList.some(
                (x) => x.Status == 47
              );

              CheckAllAttended = descriptionList.some((x) => x.Status == null);
              CheckAllApproved = descriptionList.some((x) => x.Status == 46);

              if (CheckAllAttended && checkCurrentRange) {
                IsShowSubmitBtn = true;
              }
              if (checkAnyRejected && CheckListScheduleType == 44) {
                IsShowSubmitBtn = true;
              }

              setCheckListModel((pre) => ({
                ...pre,
                IsShowSubmitBtn: IsShowSubmitBtn,
                CheckAllAttended: CheckAllAttended,
                CheckAllApproved: CheckAllApproved,
                CheckAllRejected: checkAnyRejected,
              }));

              let FinalRowObj = descriptionList.sort(
                (a, b) => a.RowID - b.RowID
              );

              setColumnList([...columnsList]);
              setRowList([...FinalRowObj]);
            } else {
              setColumnList([]);
              setRowList([]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setColumnList([]);
      setRowList([]);
    }
  };

  const EditChecklist = (rowData) => {
    setCheckListModel((pre) => ({
      ...pre,
      Description: rowData.Description,
      DescriptionID: rowData.DescriptionID,
      OrderNo: rowData.OrderNo,
    }));
  };
  const onDeleteClick = (e, rowData) => {

    let obj = { ...CheckListModel };
    obj.StationID = TypeDetails.StationID;
    obj.EquipmentID = obj.EquipmentID.Value ?? 0;
    obj.SavedBy = GetLoginUserID();
    obj.OrderNo = rowData.OrderNo;
    obj.DescriptionID = rowData.DescriptionID;
    obj.ScheduleType = CheckListScheduleType;
    obj.Description = rowData.Description;
    obj.IsDelete = 1;

    CheckList_SaveCheckListDetails(obj)
      .then((res) => {
        const { data } = res?.data;
        if (data == "-1") {
          showSaveError();
        } else {
          showSaveSuccess();
          setCheckListModel((pre) => ({
            ...pre,
            Description: "",
            OrderNo: 0,
            DescriptionID: 0,
          }));
        }
        GetDescriptionByID();
      })
      .catch((err) => {
        console.log(err);
        setCheckListModel((pre) => ({
          ...pre,
          Description: "",
          OrderNo: 0,
          DescriptionID: 0,
        }));
      });
  };
  const CommonHandleChange = (event, name) => {
    if (Object.hasOwn(event, "target")) {
      event.preventDefault();

      const { name, value } = event.target;
      setCheckListModel((pre) => ({
        ...pre,
        [name]: value,
      }));
      if (name == "StationID") {
        const EquipmentList = DropdownModel.FilterEquipmentList?.filter((x) => {
          return x.StationID == value;
        });

        HandleTypeDetails(name, value);
        setDropdownModel((pre) => ({
          ...pre,
          EquipmentList: EquipmentList,
        }));

        setCheckListModel((pre) => ({
          ...pre,
          EquipmentID: EquipmentList[0],
        }));
        let selectedStation = DropdownModel.StationList.find(
          (x) => x.value == value
        );

        handleGlobalStationName(selectedStation?.key ?? "");
      }
    } else {
      setCheckListModel((pre) => ({
        ...pre,
        [name]: event,
      }));
    }
  };

  function SubmitButton() {
    return $(
      `<button  class="btn btn-primary m-l-15"
        >  Submit</button>  `
    ).on("click", function (e) {
      e.preventDefault();
      Submit_SaveCheckListDetails(45);
    });
  }
  function Submit_SaveCheckListDetails(Status) {
    let tableData = $("#CheckListTable").DataTable().rows().data().toArray();
    if (tableData.length) {
      let FinalObj = [];
      let manipulateData = JSON.parse(JSON.stringify(tableData));
      let parentObj = [
        "RowID",
        "Description",
        "Remarks",
        "Upload",
        "CheckListID",
        "DescriptionID",
        "CheckListMaintenceID",
        "DownloadFlag",
        "ShiftEngineerID",
        "ShiftTechnicianID",
        "StatusName",
        "UOM",
        "Status",
        "OrderNo",
      ];

      let EquipmentList = Object.keys(manipulateData[0]);
      debugger;

      let EquipmentData = EquipmentList.filter((ele) => {
        return !parentObj.includes(ele);
      });

      if (EquipmentData?.length) {
        manipulateData.forEach((parentEle) => {
          let obj = [];
          EquipmentData.forEach((ele) => {
            obj.push({
              EquipmentDetailID: ele,
              CheckListData: parentEle[ele],
            });
            delete parentEle[ele];
          });
          let IsDataAvailable = obj.some((check) => check.CheckListData);
          if (!IsDataAvailable) {
            IsDataAvailable = parentEle.UOM?.length > 0;
            if (!IsDataAvailable) {
              IsDataAvailable = parentEle.Remarks?.length > 0;
            }
            if (!IsDataAvailable) {
              IsDataAvailable = parentEle.Upload?.length > 0;
            }
          }
          parentEle.CheckList = JSON.stringify(obj);
          parentEle.SavedBy = GetLoginUserID();
          parentEle.ScheduleType = CheckListScheduleType;

          parentEle.CheckDate = TypeDetails.CheckDate;

          parentEle.Status = Status;
          if (Status == 45) {
            let WorkingEquipment = obj.filter(
              (x) => x.CheckListData != STAND_BY
            );

            let CheckStatus = WorkingEquipment.some(
              (check) => check.CheckListData
            );
            if (CheckStatus || !WorkingEquipment.length) {
              //parentEle.Status = Status;
            } else {
              parentEle.Status = null;
            }
            parentEle.ShiftTechnicianID = GetLoginUserID();
          } else {
            parentEle.ShiftEngineerID = GetLoginUserID();
          }
          if (IsDataAvailable || parentEle.CheckListMaintenceID > 0) {
            FinalObj.push(parentEle);
          }
        });
        debugger;
        SaveCheckListDetails(FinalObj);
      }
    }
  }

  const fileUpload = async (formData) => {
    showLoading();
    return await _breakdownService
      .FileUpload(formData)
      .then((res) => {
        hideLoading();
        showSuccessMsg("Files Uploaded SuccessFully.");
        return res;
      })
      .catch((err) => {
        hideLoading();
        showErrorMsg("Something Went Wrong! Please Try Again.");

        console.log(err);
        return 0;
      });
  };

  async function SaveCheckListDetails(data) {
    if (data?.length) {
      showLoading();
      await CheckList_SaveScheduleCheckListDetails(data)
        .then((res) => {
          hideLoading();
          if (res.data.isSuccess) {
            showSaveSuccess();
          } else {
            showErrorMsg();
          }
          GetDescriptionByID();
        })
        .catch((err) => {
          hideLoading();
          console.log(err);
          showSaveError();
        });
    } else {
      showWarningMsg("Please enter at least one value to submit!");
    }
  }

  const DownloadFiles = (ticketID, AttachmetID, Flag) => {
    _breakdownService
      .GetFilesUsingTicketID(ticketID, AttachmetID, Flag)
      .then((res) => {
        setFileData(res.data);
      })
      .catch((err) => {
        setFileData([]);
        console.log(err);
      });
  };

  function ApprovedBtn() {
    let ApproveBtn = $(
      `<button  class="btn btn-success disabled"
          >  Approve </button>  `
    ).on("click", function (e) {
      e.preventDefault();

      Submit_SaveCheckListDetails(46);
    });
    let RejectBTn = $(
      `<button  class="btn btn-danger m-l-15 m-r-10 disabled"
          >  Reject </button>  `
    ).on("click", function (e) {
      e.preventDefault();
      Submit_SaveCheckListDetails(47);
    });

    let Fragment = $(`<span></span>`);
    let currentCheckListDate = new Date(TypeDetails.CheckDate);
    currentCheckListDate = CheckListScheduleType == 43 ? currentCheckListDate : addDays(currentCheckListDate, 6)
    let ForMaintenanceDays = addDays(currentCheckListDate, 10);
    let ForEngineerDays = addDays(currentCheckListDate, 15);

    const { CheckAllAttended, CheckAllRejected, CheckAllApproved } =
      CheckListModel;

    if (getUserDetails().RoleID !== COOKIES.TechRoleID) {
      Fragment.append(ApproveBtn);
      Fragment.append(RejectBTn);
    }

    if (
      getUserDetails().RoleID == COOKIES.MaintainerRolID &&
      getYMDdate(ForMaintenanceDays) >= getYMDdate(new Date()) &&
      !CheckAllAttended &&
      !CheckAllApproved &&
      !CheckAllRejected
    ) {
      ApproveBtn.removeClass("disabled");
      RejectBTn.removeClass("disabled");
    }

    if (
      getPrivilege().includes("CHECKLISTAPPROVAL") &&
      getUserDetails().RoleID !== COOKIES.MaintainerRolID &&
      !CheckAllAttended &&
      !CheckAllApproved
      // && (getYMDdate(new Date()) > getYMDdate(ForMaintenanceDays) || CheckAllApproved)
    ) {
      ApproveBtn.removeClass("disabled");
    }

    if (
      getPrivilege().includes("CHECKLISTAPPROVAL") &&
      getUserDetails().RoleID !== COOKIES.MaintainerRolID &&
      getYMDdate(ForEngineerDays) >= getYMDdate(new Date()) &&
      !CheckAllAttended &&
      (getYMDdate(new Date()) > getYMDdate(ForMaintenanceDays) ||
        CheckAllApproved)
    ) {
      RejectBTn.removeClass("disabled");
    }

    return Fragment;
  }
  function IsAllowToEnterData() {
    return getUserDetails().RoleID == COOKIES.TechRoleID;
  }

  function addedPlugin() {
    $(".EquipmentRunningToggle").on("change", function () {
      let IsChecked = $(this).prop("checked");

      let ColumnData = $(this).attr("id");
      if (ColumnData.startsWith("switch_annual_")) {
        let CellValue = IsChecked ? STAND_BY : "";

        ColumnData = ColumnData.replace("switch_annual_", "");
        let table = $("#CheckListTable").DataTable();
        let TableData = table.rows().data().toArray();
        TableData.forEach((x) => {
          x[ColumnData] = CellValue;
        });
        $(`.Equipment_${ColumnData}`).val(CellValue);
        table.on("draw.dt", function () {
          // Assuming ColumnData is a valid variable with the correct value
          $(`.Equipment_${ColumnData}`).prop("disabled", IsChecked);
          $(`.Equipment_${ColumnData}`).val(CellValue);
        });
        $(`.Equipment_${ColumnData}`).prop("disabled", IsChecked);
      }
    });
  }

  return (
    <div>
      <div style={{ position: "absolute", top: "65px", right: "33px" }}>
        {CheckListModel?.EquipmentID?.DocumentNo &&
          `Doc Number :${CheckListModel?.EquipmentID?.DocumentNo}`}
      </div>
      <div className="row">
        <PageHeader
          title={`${CheckListScheduleType == 43 ? "Daily" : "Weekly"
            } CheckList`}
        >
          {" "}
        </PageHeader>
      </div>
      <form id="CheckList">
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Station Name<span className="text-danger"> *</span>{" "}
              </label>
              <Dropdown
                data={DropdownModel.StationList}
                placeholder="Select"
                name={"StationID"}
                HandleChange={CommonHandleChange}
                value={TypeDetails.StationID}
                className=""
              />
              <span className="text-danger mt-1"> {errors.StationID} </span>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Equipment
                <span className="text-danger"> *</span>
              </label>
              <ReactMultiSelect
                options={DropdownModel.EquipmentList}
                placeholder="Select"
                value={CheckListModel.EquipmentID}
                onChange={(e) => {
                  CommonHandleChange(e, "EquipmentID");
                }}
              />
              <span className="text-danger"> {errors.EquipmentID} </span>
            </div>
          </div>
          {getPrivilege().includes("CHECKLISTCREATE") && (
            <>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" for="empCode">
                    Description <span className="text-danger"> *</span>{" "}
                  </label>{" "}
                  <textarea
                    className="form-control "
                    style={{ resize: "none" }}
                    // type="text"
                    name="Description"
                    value={CheckListModel.Description}
                    rows={2}
                    onChange={(e) => CommonHandleChange(e)}
                  >
                    {/* {CheckListModel.Description} */}
                  </textarea>
                  <span className="text-danger mt-1">
                    {" "}
                    {errors.Description}{" "}
                  </span>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" for="empCode">
                    Order No <span className="text-danger"> *</span>{" "}
                  </label>{" "}
                  <input
                    className="form-control "
                    type="number"
                    name="OrderNo"
                    value={CheckListModel.OrderNo}
                    rows={2}
                    onChange={(e) => CommonHandleChange(e)}
                  />
                  <span className="text-danger mt-1"> {errors.OrderNo} </span>
                </div>
              </div>

              <div className="col-md-2 mt-4 alert-dismissible">
                <button
                  type="submit"
                  id="addRow_ers"
                  onClick={AddHandleClick}
                  className="btn add-btn"
                >
                  <i className="fa fa-plus"></i> Add
                </button>
              </div>
            </>
          )}
        </div>
      </form>

      {columnList?.length ? (
        <>
          <div style={{ marginTop: "-25px" }}>
            <HtmlTable
              FixedColums={{
                left: 2,
                right: 2,
              }}
              OrderTargetList={Array.from(
                { length: columnList.length - 6 },
                (_, i) => i + 2
              )}
              IsASC
              rowList={RowList}
              columnList={columnList}
              SearchPrependHtml={ApprovedBtn}
              SearchPostponedHtml={
                IsAllowToEnterData() && CheckListModel.IsShowSubmitBtn
                  ? SubmitButton
                  : ""
              }
              addedPlugin={addedPlugin}
              id={`CheckListTable`}
            />
          </div>
          <div className="row"></div>
        </>
      ) : (
        <div className="row col-12 text-center  mb-2">
          <p className="text-muted"></p>
        </div>
      )}

      <BootrapModel
        Id={"DownLoadFiles"}
        CloseID="DownLoadFilesClose"
        ModelTitle={"Download Attachments"}
        modelSize={"modal-xl"}
        module={
          <DownloadModel
            Flag={5}
            DeleteAttachment={DownloadFiles}
            FileDatas={FileData}
          // DownLoadRefresh={DownLoadRefresh}
          ></DownloadModel>
        }
      >
        {" "}
      </BootrapModel>
    </div>
  );
}
