import React, { useRef, useState, useEffect, useContext } from "react";
import { BootrapModel, HtmlTable, ReactMultiSelect } from "../../components";
import { MultiValue, Option } from "../../components";
import makeAnimated from "react-select/animated";
import StackedChart from "../../components/charts/StackedChart_for_UG19";
import {
  Dashboard_GetEquAvaList,
  Dashboard_GetCyclicChekList,
  Dashboard_PM_StationWiseGetDataForReport,
} from "./DashboardService";

import { PieChart } from "../../components/charts/PieChart";
import {
  EquipmentAvailabilitydata,
  AllEquipmentWiseData,
  StackedDataForBDEquipmentWise,
} from "./TempData";
import {
  Dashboard_GetBreakdownDepartmentList,
  Dashboard_GetStattionWiseBreakdownData,
  Dashboard_GetStattionWiseEquipAvaData,
  Dashboard_PM_StationWiseAndDepartmentWise,
  DashboardBreakdown_Filter,
} from "./DashboardService";
import $ from "jquery";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { AppContext } from "../../services/ContextProvider";
import { CommonBarChart } from "../../components/charts/CommonBarChart";
import { FilterDropdown } from "../../components/FilterDropdown";
import { WithoutSelectDropDown } from "../../components/WithoutSelectDropDown";
import {
  DeepCopy,
  ShortMonths,
  generateQuarters,
  makeUnique,
} from "../../services/CommonService";
import { Dashboard_GetBreakdownDepStationList } from "./DashboardService";
import { GetLoginUserID } from "../../services/CommonService";
import moment from "moment";

export default function BreakdownDepartmentDashboard() {
  const DepartmentChartRef = useRef(null);
  const {
    getCookies,
    showLoading,
    hideLoading,
    handleGlobalStationName,
    GetLoginStationBaseOnDep,
  } = useContext(AppContext);
  const [FilterModel, setFilterModel] = useState({
    Departments: {},
    FromDate: "",
    ToDate: "",
    Corridor: "",
    Frequency: "",
    Stations: [],
    ScheduleType: { label: "Monthly", value: 2 },
    DateRange: new Date().getMonth(),
    Year: new Date().getFullYear(),
  });

  const [PeriodicModel, setPeriodicModel] = useState({
    ShowBar: false,
    PeriodicEquipmentID: 0,
    MaintenanceReportData: [],
    AvailableShowBar: false,
    FilterStationData: [],
    StationWiseData: {
      labels: [],
      datasets: [],
    },
    Availabilitydata: [],
    ListEquipmentAvailabilitydata: EquipmentAvailabilitydata,
    ListAllEquipmentWiseData: AllEquipmentWiseData,
    FilterDepartmentData: {
      labels: [],
      datasets: [],
      EquipmentIds: [],
    },

    DepartmentData: {
      labels: [],
      datasets: [],
      EquipmentIds: [],
    },
    EquipmentWiseBm: [],
    StationWiseDataForBM: [],
    EquAvaStationWiseDataForBM: [],
    DepartmentName: "",
    StartMonthConfigList: [],
    PeriodicStartMonth: 0,
    DateRange: ShortMonths(),
  });

  const { datasets } = PeriodicModel.DepartmentData;
  const { datasets: FilDatasets } = PeriodicModel.FilterDepartmentData;

  const animatedComponents = makeAnimated();
  const [BreadListModel, setBreadListModel] = useState([]);
  const [EquAVABreadListModel, setEquAVABreadListModel] = useState([]);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [BreakdownList, setBreakdownList] = useState([]);
  const [EquipmentAvaList, setEquipmentAvaList] = useState([]);
  const [EquipmentListFilter, setEquipmentListFilter] = useState([]);
  const [EquipmentList, setEquipmentList] = useState([]);
  const [StationIDs, setStationIDs] = useState("");
  const [BreakdownStatus, SetBreakdownStatus] = useState("");
  const [EquipmnetAvaStatus, SetEquipmnetAvaStatus] = useState("");
  const [DepartmentIDs, setDepartmentIDs] = useState("");
  const [EquipmentDetailIDS, setEquipmentDetailIDs] = useState("");
  const [StationList, setStationList] = useState([]);

  const [FilterStationList, setFilterStationList] = useState([]);
  const [CorridorList, setCorridorList] = useState([]);
  const [EqCodeStr, setEqCodeStr] = useState([]);
  const [DashboardListModel, setDashboardListModel] = useState({
    StackedData: [],
    StackedDataForBDEquipmentWise: [],
    StackedDataForEquiAvaWise: [],
    StationDashboardList: [],
    EquStationDashboardList: [],
    SetDashboardListModel: [],
    DashBoardData: {
      Open: 0,
      Assigned: 0,
      Overdue: 0,
      Attended: 0,
      Approved: 0,
      Rejected: 0,
    },
  });

  useEffect(() => {
    $(".main-loader").show();
    $(".loader").show();
    $("#loader").show();

    DashboardBreakdown_FilterList();
  }, []);
  useEffect(() => {
    if (FilterModel?.Departments?.value) {
      GetDashboardData();
      Dashboard_GetEquAva();
      DashboardGetCyclicChekList();
    }
  }, [
    StationIDs,
    DepartmentIDs,
    EquipmentDetailIDS,
    FilterModel.Departments,
    FilterModel?.ToDate,
    FilterModel?.FromDate,
  ]);

  useEffect(() => {
    getPMDashData();
  }, [JSON.stringify(FilterModel)]);

  useEffect(() => {
    console.log(localStorage.getItem("ID"));
    if (localStorage.getItem("ID") == 0) {
      localStorage.setItem("ID", 1);
      window.location.reload();
    }
  }, []);

  const DashboardBreakdown_FilterList = () => {
    var employeeID = GetLoginUserID();

    DashboardBreakdown_Filter(employeeID)
      .then((res) => {
        if (res?.data?.data) {
          const {
            departmentList,
            equipmentList,
            stationList,
            corridorList,
            startMonthConfig,
          } = res.data.data;

          let Department = [];
          const itemsWithoutChildren = departmentList.filter(
            (item) =>
              !departmentList.some((child) => child.parentId === item.value)
          );
          itemsWithoutChildren.map((data) => {
            Department.push({
              value: data.value,
              label: data.label,
              type: data.Type,
            });
          });

          setDepartmentList(Department);
          setEquipmentListFilter(equipmentList);
          setFilterStationList(stationList);
          setCorridorList(corridorList);
          setPeriodicModel((pre) => ({
            ...pre,
            StartMonthConfigList: startMonthConfig,
          }));

          if (Department?.length && stationList?.length) {
            const DepartmentID = Department[0].value;
            const filStationPartDropDown = equipmentList.filter((ele) => {
              return (
                ele.DepartmentID == DepartmentID &&
                stationList.some((station) => station.value == ele.StationID)
              );
            });

            setEquipmentList(
              makeUnique(filStationPartDropDown, ["value", "label"])
            );
          }
          let filStationPartDropDown = GetLoginStationBaseOnDep();

          let filStationPartDropDown1 = filStationPartDropDown.filter(
            (x) =>
              x.CorridorID == corridorList[0].value &&
              x.DepartmentID == Department[0].value
          );

          let UniqueList = makeUnique(filStationPartDropDown1, [
            "label",
            "value",
          ]);
          // console.log(filStationPartDropDown)

          setStationList(UniqueList);
          let finalstaionData = UniqueList.map((item) => item.value).join(",");

          // setEquipmentList(stationIDs);

          setFilterModel((pre) => ({
            ...pre,
            Corridor: corridorList[0],
            Departments: Department[0],
            Stations: UniqueList,
          }));
          setStationIDs(finalstaionData);
        }
      })
      .catch((err) => console.log(err));
  };

  const GetDashboardData = () => {
    //

    Dashboard_GetBreakdownDepartmentList(
      StationIDs,
      FilterModel?.Departments?.value,
      EquipmentDetailIDS,
      FilterModel?.ToDate,
      FilterModel?.FromDate
    )
      .then((res) => {
        if (res?.data?.data) {
          const { breadList, dashboardList, stationDashboardList } =
            res.data.data;

          setBreadListModel(breadList[0]);
          if (dashboardList?.datasets?.length) {
            const { labels, datasets } = dashboardList;
            dashboardList.labels = labels[0].split(",");
            if (labels[0] == "All") {
              datasets.forEach((ele) => (ele.data = [ele.data]));
              // datasets.forEach((ele) => (ele.data = [ele.data.split(",")]));
            } else {
              datasets.forEach((ele) => (ele.data = ele.data.split(",")));
            }
          }
          if (stationDashboardList?.labels?.length) {
            const { labels, datasets } = stationDashboardList;
            stationDashboardList.labels = labels[0].split(",");
            datasets.forEach((ele) => (ele.data = ele.data.split(",")));
          }

          setDashboardListModel((pre) => ({
            ...pre,
            StackedDataForBDEquipmentWise: dashboardList,
            StationDashboardList: stationDashboardList,
          }));

          setPeriodicModel((pre) => ({
            ...pre,
            StationWiseDataForBM: [],
            EquAvaStationWiseDataForBM: [],
          }));
        }
      })
      .catch((err) => console.log(err));
  };
  const Dashboard_GetEquAva = () => {
    Dashboard_GetEquAvaList(
      StationIDs,
      FilterModel?.Departments?.value,
      EquipmentDetailIDS,
      FilterModel?.ToDate,
      FilterModel?.FromDate
    )
      .then((res) => {
        if (res?.data?.data) {
          const { breadList, equipmentAvaList, equStationDashboardList } =
            res.data.data;

          setEquAVABreadListModel(breadList[0]);
          if (equipmentAvaList?.datasets?.length) {
            const { datasets } = equipmentAvaList;
            datasets.forEach((ele) => (ele.data = [ele.data]));
          }

          if (equStationDashboardList?.labels?.length) {
            const { labels, datasets } = equStationDashboardList;
            equStationDashboardList.labels = labels[0].split(",");
            datasets.forEach((ele) => (ele.data = ele.data.split(",")));
          }

          setDashboardListModel((pre) => ({
            ...pre,
            StackedDataForEquiAvaWise: equipmentAvaList,
            EquStationDashboardList: equStationDashboardList,
          }));

          setPeriodicModel((pre) => ({
            ...pre,
            StationWiseDataForBM: [],
          }));
        }
      })
      .catch((err) => console.log(err));
  };
  const DashboardGetCyclicChekList = () => {
    Dashboard_GetCyclicChekList(
      StationIDs,
      FilterModel?.Departments?.value,
      EquipmentDetailIDS,
      FilterModel?.ToDate,
      FilterModel?.FromDate
    )
      .then((res) => {
        if (res?.data?.data) {
          const { cyclicCheckList, cyclicCheckTableList } = res.data.data;

          if (cyclicCheckList?.labels?.length) {
            const { labels, datasets } = cyclicCheckList;
            // cyclicCheckList.labels = labels[0].split(",");
            datasets.forEach((ele) => (ele.data = ele.data.split(",")));
            datasets.forEach(
              (ele) => (ele.backgroundColor = ele.backgroundColor.split(","))
            );
            datasets.borderWidth = 1;
          }

          setDashboardListModel((pre) => ({
            ...pre,
            CyclicCheckList: cyclicCheckList,
            CyclicCheckTableList: cyclicCheckTableList,
          }));

          // setPeriodicModel((pre) => ({
          //   ...pre,
          //   StationWiseDataForBM: [],
          // }));
        }
      })
      .catch((err) => console.log(err));
  };

  const DepartmentValueChange = (e) => {
    let dd = DepartmentList;

    setFilterModel((pre) => ({
      ...pre,
      Departments: e,
      Stations: [],
      Equipment: [],
    }));

    const DepartId = e.value;
    setDepartmentIDs(DepartId);

    if (e?.length) {
      let PeriodicStartMonth = PeriodicModel.StartMonthConfigList.find(
        (ele) => ele.DepartmentId == DepartId
      );
      setPeriodicModel((pre) => ({
        ...pre,
        PeriodicStartMonth: PeriodicStartMonth ?? 0,
      }));
    }

    let filStationPartDropDown1 = GetLoginStationBaseOnDep();
    let filStationPartDropDown = filStationPartDropDown1.filter(
      (x) =>
        x.CorridorID == FilterModel.Corridor.value && x.DepartmentID == e?.value
    );

    let UniqueList = makeUnique(filStationPartDropDown, ["label", "value"]);

    setStationList(UniqueList);

    setFilterModel((pre) => ({ ...pre, Stations: filStationPartDropDown }));

    const departmentIDsArray = [e.value];
    const StationsIDsArray = filStationPartDropDown.map((e) => e.value);
    let finalstaionData = filStationPartDropDown
      .map((item) => item.value)
      .join(",");

    setStationIDs(finalstaionData);
    const filStationPartEquipmentDropDown = EquipmentListFilter.filter(
      (ele) => {
        const { StationID, DepartmentID } = ele;
        return (
          departmentIDsArray.includes(DepartmentID) &&
          StationsIDsArray?.includes(StationID)
        );
      }
    );

    const filterEquipmentPartDropDown = filStationPartEquipmentDropDown.map(
      (item) => ({
        value: item.value,
        label: item.label,
      })
    );

    const uniqueData = filterEquipmentPartDropDown.filter(
      (obj, index, self) =>
        index ===
        self.findIndex((t) => t.value === obj.value && t.label === obj.label)
    );

    setEquipmentList(uniqueData);

    // setDepartmentIDs
  };

  const CorridorListChange = (e) => {
    setFilterModel((pre) => ({
      ...pre,
      Corridor: e,
      Stations: [],
      Equipment: [],
    }));

    let stationList = GetLoginStationBaseOnDep();

    let filStationPartDropDown = stationList.filter(
      (x) =>
        x.CorridorID == e.value &&
        x.DepartmentID == FilterModel?.Departments?.value
    );
    let UniqueList = makeUnique(filStationPartDropDown, ["label", "value"]);

    setStationList(UniqueList);
    let finalstaionData = filStationPartDropDown
      .map((item) => item.value)
      .join(",");

    setStationIDs(finalstaionData);

    setFilterModel((pre) => ({ ...pre, Stations: filStationPartDropDown }));

    const departmentIDsArray = [FilterModel.Departments.value];
    const StationsIDsArray = filStationPartDropDown.map((e) => e.value);

    const filStationPartEquipmentDropDown = EquipmentListFilter.filter(
      (ele) => {
        const { StationID, DepartmentID } = ele;
        return (
          departmentIDsArray.includes(DepartmentID) &&
          StationsIDsArray?.includes(StationID)
        );
      }
    );

    const filterEquipmentPartDropDown = filStationPartEquipmentDropDown.map(
      (item) => ({
        value: item.value,
        label: item.label,
      })
    );

    const uniqueData = filterEquipmentPartDropDown.filter(
      (obj, index, self) =>
        index ===
        self.findIndex((t) => t.value === obj.value && t.label === obj.label)
    );

    setEquipmentList(uniqueData);

    // setDepartmentIDs
  };

  const EquipmentDetailChange = (e) => {
    setFilterModel((pre) => ({ ...pre, Equipment: e }));

    const filEquipmentDropDown = e.map((item) => item.value).join(",");
    setEquipmentDetailIDs(filEquipmentDropDown);

    // setDepartmentIDs
  };

  const DateFilterChange = (e, val) => {
    setFilterModel((pre) => ({
      ...pre,
      FromDate: e?.format("YYYY-MM-DD"),
      ToDate: val?.format("YYYY-MM-DD"),
    }));
    // setDepartmentIDs
  };

  const StationValueChange = (e) => {
    setFilterModel((pre) => ({ ...pre, Stations: e }));

    const finalstaionData = e.map((item) => item.value).join(",");
    setStationIDs(finalstaionData);

    const departmentIDsArray = [FilterModel.Departments.value];
    const StationsIDsArray = finalstaionData
      ? finalstaionData.split(",").map((ele) => parseInt(ele, 10))
      : [];

    const filStationPartDropDown = EquipmentListFilter.filter((ele) => {
      const { StationID, DepartmentID } = ele;
      return (
        departmentIDsArray.includes(DepartmentID) &&
        StationsIDsArray?.includes(StationID)
      );
    });

    const filterEquipmentPartDropDown = filStationPartDropDown.map((item) => ({
      value: item.value,
      label: item.label,
    }));

    const uniqueData = filterEquipmentPartDropDown.filter(
      (obj, index, self) =>
        index ===
        self.findIndex((t) => t.value === obj.value && t.label === obj.label)
    );

    setEquipmentList(uniqueData);
  };

  const DashboardCard = ({
    OpenCount = 0,
    OverdueCount = 0,
    AssendCount,
    CompletedCount = 0,
    ApprovedCount = 0,
    RejectedCount = 0,
    TotalCount = OpenCount +
      OverdueCount +
      CompletedCount +
      ApprovedCount +
      RejectedCount ?? 0,
    onClick,
    CompletedText = "Completed",
    ApprovedText = "Approved",
    AttendText,
    AttendCount,
  }) => {
    return (
      <div class="row">
        <div class="col xs12 m6 s12 l6 xl3">
          <div
            class="card dash-widget dashboard_background_open"
            onClick={() => {
              onClick("Open");
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-openid"></i>
              </span>
              <div class="dash-widget-info">
                <h3 className="">
                  {/* 116 */}
                  {OpenCount}
                  <span className="font-18 text-black-50">
                    {" "}
                    / {TotalCount}
                  </span>{" "}
                </h3>
                <span>Open</span>
              </div>
            </div>
          </div>
        </div>

        {OverdueCount != null && (
          <div class="col xs12 m6 s12 l6 xl3">
            <div
              class="card dash-widget dashboard_background_Overdue"
              onClick={() => {
                onClick("Overdue");
              }}
            >
              <div class="card-body" style={{ padding: 10 }}>
                <span class="dash-widget-icon">
                  <i class="fa fa-hourglass-end"></i>
                </span>
                <div class="dash-widget-info">
                  <h3 className="">
                    {/* 355 */}
                    {OverdueCount}
                    <span className="font-18 text-black-50">
                      {" "}
                      / {TotalCount}
                    </span>{" "}
                  </h3>
                  <span>Overdue</span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div class="col xs12 m6 s12 l6 xl3">
          <div
            class="card dash-widget dashboard_background_attended"
            style={
              CompletedText === "Assigned"
                ? { background: "#ffff00" }
                : { background: "grey" }
            }
            onClick={() => {
              onClick("Completed");
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-eercast"></i>
              </span>
              <div class="dash-widget-info">
                <h3 className="">
                  {/* 287  */}
                  {CompletedCount}{" "}
                  <span className="font-18 text-black-50"> / {TotalCount}</span>
                </h3>
                <span>{CompletedText}</span>
              </div>
            </div>
          </div>
        </div>
        {AttendText != null && (
          <div class="col xs12 m6 s12 l6 xl3">
            <div
              class="card dash-widget"
              style={{ background: "#dedede" }}
              onClick={() => {
                onClick("Overdue");
              }}
            >
              <div class="card-body" style={{ padding: 10 }}>
                <span class="dash-widget-icon">
                  <i class="fa fa-hourglass-end"></i>
                </span>
                <div class="dash-widget-info">
                  <h3 className="">
                    {/* 355 */}
                    {AttendCount}
                    <span className="font-18 text-black-50">
                      {" "}
                      / {TotalCount}
                    </span>{" "}
                  </h3>
                  <span>{AttendText}</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div class="col xs12 m6 s12class l6 xl3">
          <div
            class="card dash-widget dashboard_background_Approved"
            onClick={() => {
              onClick("Approved");
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-thumbs-o-up"></i>
              </span>
              <div class="dash-widget-info">
                <h3 className="">
                  {ApprovedCount}{" "}
                  <span className="font-18 text-black-50"> / {TotalCount}</span>{" "}
                </h3>
                <span>{ApprovedText}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col xs12 m6 s12 l6 xl3">
          <div
            class="card dash-widget dashboard_background_Rejected"
            onClick={() => {
              onClick("Rejected");
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-thumbs-o-down"></i>
              </span>
              <div class="dash-widget-info">
                <h3 className="">
                  {RejectedCount}{" "}
                  <span className="font-18 text-black-50"> / {TotalCount}</span>
                </h3>
                <span>Rejected</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const BM_DashboardCard = ({
    OpenCount = 0,
    OverdueCount = 0,
    CompletedCount = 0,
    ApprovedCount = 0,
    RejectedCount = 0,
    TotalCount = OpenCount +
      OverdueCount +
      CompletedCount +
      ApprovedCount +
      RejectedCount ?? 0,
    onClick,
    CompletedText = "Completed",
    ApprovedText = "Approved",
    AttendText,
    AttendCount,
  }) => {
    return (
      <div class="row">
        <div class="col-3 xs12 m6 s12 l6 xl3">
          <div
            class="card dash-widget dashboard_background_open"
            onClick={() => {
              onClick("Open");
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-openid"></i>
              </span>
              <div class="dash-widget-info">
                <h3 className="">
                  {/* 116 */}
                  {OpenCount}
                  <span className="font-18 text-black-50">
                    {" "}
                    / {TotalCount}
                  </span>{" "}
                </h3>
                <span>Open</span>
              </div>
            </div>
          </div>
        </div>

        {OverdueCount != null && (
          <div class="col-3 xs12 m6 s12 l6 xl3">
            <div
              class="card dash-widget dashboard_background_Overdue"
              onClick={() => {
                onClick("Overdue");
              }}
            >
              <div class="card-body" style={{ padding: 10 }}>
                <span class="dash-widget-icon">
                  <i class="fa fa-hourglass-end"></i>
                </span>
                <div class="dash-widget-info">
                  <h3 className="">
                    {/* 355 */}
                    {OverdueCount}
                    <span className="font-18 text-black-50">
                      {" "}
                      / {TotalCount}
                    </span>{" "}
                  </h3>
                  <span>Overdue</span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div class="col-3 xs12 m6 s12 l6 xl3">
          <div
            class="card dash-widget dashboard_background_attended"
            style={
              CompletedText === "Assigned"
                ? { background: "#ffff00" }
                : { background: "grey" }
            }
            onClick={() => {
              onClick("Completed");
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-eercast"></i>
              </span>
              <div class="dash-widget-info">
                <h3 className="">
                  {/* 287  */}
                  {CompletedCount}{" "}
                  <span className="font-18 text-black-50"> / {TotalCount}</span>
                </h3>
                <span>{CompletedText}</span>
              </div>
            </div>
          </div>
        </div>
        {AttendText != null && (
          <div class="col-3 xs12 m6 s12 l6 xl3">
            <div
              class="card dash-widget"
              style={{ background: "#dedede" }}
              onClick={() => {
                onClick("Overdue");
              }}
            >
              <div class="card-body" style={{ padding: 10 }}>
                <span class="dash-widget-icon">
                  <i class="fa fa-hourglass-end"></i>
                </span>
                <div class="dash-widget-info">
                  <h3 className="">
                    {/* 355 */}
                    {AttendCount}
                    <span className="font-18 text-black-50">
                      {" "}
                      / {TotalCount}
                    </span>{" "}
                  </h3>
                  <span>{AttendText}</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div class="col-3 xs12 m6 s12class l6 xl3">
          <div
            class="card dash-widget dashboard_background_Approved"
            onClick={() => {
              onClick("Approved");
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-thumbs-o-up"></i>
              </span>
              <div class="dash-widget-info">
                <h3 className="">
                  {ApprovedCount}{" "}
                  <span className="font-18 text-black-50"> / {TotalCount}</span>{" "}
                </h3>
                <span>{ApprovedText}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 xs12 m6 s12 l6 xl3">
          <div
            class="card dash-widget dashboard_background_Rejected"
            onClick={() => {
              onClick("Rejected");
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-thumbs-o-down"></i>
              </span>
              <div class="dash-widget-info">
                <h3 className="">
                  {RejectedCount}{" "}
                  <span className="font-18 text-black-50"> / {TotalCount}</span>
                </h3>
                <span>Rejected</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState(null);

  const EquAVAWiseClickForBM = (event, chartElements) => {
    if (chartElements.length > 0) {
      const clickedElementIndex = chartElements[0].index;
      const clickedDataSetIndex = chartElements[0].datasetIndex;
      setPeriodicModel((pre) => ({
        ...pre,
        DepartmentName:
          StackedDataForBDEquipmentWise.labels[clickedElementIndex],
      }));
      let StationWiseData = JSON.parse(
        JSON.stringify(DashboardListModel?.EquStationDashboardList)
      );
      let filterLabel =
        DashboardListModel?.EquStationDashboardList.datasets[
          clickedDataSetIndex
        ].label;

      SetEquipmnetAvaStatus(filterLabel);
      StationWiseData.datasets = StationWiseData.datasets.filter(
        (ele) => ele.label == filterLabel
      );
      setPeriodicModel((pre) => ({
        ...pre,
        EquAvaStationWiseDataForBM: StationWiseData,
      }));
      return 1;
    }
    SetEquipmnetAvaStatus("All");
    // EquAvaStationWiseDataForBM

    setPeriodicModel((pre) => ({
      ...pre,
      EquAvaStationWiseDataForBM: DashboardListModel?.EquStationDashboardList,
    }));
  };
  const DepartmentWiseClickForBM = (event, chartElements) => {
    if (chartElements.length > 0) {
      const clickedElementIndex = chartElements[0].index;
      const clickedDataSetIndex = chartElements[0].datasetIndex;

      const { labels } = DashboardListModel.StackedDataForBDEquipmentWise;
      let EqCode = labels[clickedElementIndex];
      setEqCodeStr(EqCode);

      if (EqCode != "All") {
        Dashboard_GetBreakdownDepStationList(
          StationIDs,
          FilterModel.Departments.value,
          EqCode
        )
          .then((res) => {
            if (res?.data?.data) {
              const { dashboardList } = res.data.data;
              let filterdatalabel =
                DashboardListModel?.StackedDataForBDEquipmentWise.datasets[
                  clickedDataSetIndex
                ].label;
              const dash = res.data.data.dashboardList;
              const colorMap = {
                Assigned: "#ffff00",
                Open: "#93a7fd",
                Overdue: "#ff9f40",
                Attended: "#dedede",
                Approved: "#87f093",
                Rejected: "#f27d89",
              };
              let StationWiseDatas = JSON.parse(
                JSON.stringify(DashboardListModel?.StationDashboardList)
              );
              if (filterdatalabel in colorMap) {
                // Update the backgroundColor property based on the label
                dash.datasets[0].backgroundColor = colorMap[filterdatalabel];
              }

              dash.datasets[0].label = filterdatalabel;

              dash.datasets[0].data =
                dash.datasets[0][filterdatalabel].split(",");
              dash.labels = StationWiseDatas.labels;

              setPeriodicModel((pre) => ({
                ...pre,
                StationWiseDataForBM: dash,
              }));
            }
          })
          .catch((err) => console.log(err));
      } else {
        setPeriodicModel((pre) => ({
          ...pre,
          DepartmentName:
            StackedDataForBDEquipmentWise.labels[clickedElementIndex],
        }));
        let StationWiseData = JSON.parse(
          JSON.stringify(DashboardListModel?.StationDashboardList)
        );
        let filterLabel =
          DashboardListModel?.StackedDataForBDEquipmentWise.datasets[
            clickedDataSetIndex
          ].label;

        SetBreakdownStatus(filterLabel);
        StationWiseData.datasets = StationWiseData.datasets.filter(
          (ele) => ele.label == filterLabel
        );
        setPeriodicModel((pre) => ({
          ...pre,
          StationWiseDataForBM: StationWiseData,
        }));
      }
      return 1;
    }
    SetBreakdownStatus("All");

    setPeriodicModel((pre) => ({
      ...pre,
      StationWiseDataForBM: DashboardListModel?.StationDashboardList,
    }));
  };

  const EquipmentAvaWiseClickForBM = (event, chartElements) => {
    if (chartElements.length > 0) {
      const clickedElementIndex = chartElements[0].index;
      const clickedDataSetIndex = chartElements[0].datasetIndex;

      const { labels } = DashboardListModel.StackedDataForBDEquipmentWise;
      let EqCode = labels[clickedElementIndex];
      setEqCodeStr(EqCode);

      if (EqCode != "All") {
        Dashboard_GetBreakdownDepStationList(
          StationIDs,
          FilterModel.Departments.value,
          EqCode
        )
          .then((res) => {
            if (res?.data?.data) {
              const { dashboardList } = res.data.data;
              let filterdatalabel =
                DashboardListModel?.StackedDataForBDEquipmentWise.datasets[
                  clickedDataSetIndex
                ].label;
              const dash = res.data.data.dashboardList;
              const colorMap = {
                Assigned: "#ffff00",
                Open: "#93a7fd",
                Overdue: "#ff9f40",
                Attended: "#dedede",
                Approved: "#87f093",
                Rejected: "#f27d89",
              };
              let StationWiseDatas = JSON.parse(
                JSON.stringify(DashboardListModel?.StationDashboardList)
              );
              if (filterdatalabel in colorMap) {
                // Update the backgroundColor property based on the label
                dash.datasets[0].backgroundColor = colorMap[filterdatalabel];
              }

              dash.datasets[0].label = filterdatalabel;

              dash.datasets[0].data =
                dash.datasets[0][filterdatalabel].split(",");
              dash.labels = StationWiseDatas.labels;
              console.log(dash);

              setPeriodicModel((pre) => ({
                ...pre,
                StationWiseDataForBM: dash,
              }));
            }
          })
          .catch((err) => console.log(err));
      } else {
        setPeriodicModel((pre) => ({
          ...pre,
          DepartmentName:
            StackedDataForBDEquipmentWise.labels[clickedElementIndex],
        }));
        let StationWiseData = JSON.parse(
          JSON.stringify(DashboardListModel?.StationDashboardList)
        );
        let filterLabel =
          DashboardListModel?.StackedDataForBDEquipmentWise.datasets[
            clickedDataSetIndex
          ].label;

        SetBreakdownStatus(filterLabel);
        StationWiseData.datasets = StationWiseData.datasets.filter(
          (ele) => ele.label == filterLabel
        );
        setPeriodicModel((pre) => ({
          ...pre,
          StationWiseDataForBM: StationWiseData,
        }));
      }
      return 1;
    }
    SetBreakdownStatus("All");

    setPeriodicModel((pre) => ({
      ...pre,
      StationWiseDataForBM: DashboardListModel?.StationDashboardList,
    }));
  };

  const StationDepartmentWiseClickForBM = (_, datasets) => {
    // alert("You clicked on " + event.chart.data.datasets[0].label);
    // alert("You clicked on " + event.chart.data.labels[3]);
    // chartElements

    if (datasets?.length) {
      const StatusName = datasets[0].element.$context.dataset.label;
      const { labels } = PeriodicModel?.StationWiseDataForBM;
      let DatasetIndex = datasets[0].index;
      const StationName = labels[DatasetIndex];
      $("#modelTriggerBtnBM").click();

      Dashboard_GetStattionWiseBreakdownData(
        StationIDs,
        FilterModel.Departments.value,
        EquipmentDetailIDS,
        StatusName,
        StationName,
        EqCodeStr
      )
        .then((res) => {
          if (res?.data?.data) {
            const { breakdownList } = res.data.data;

            setBreakdownList(breakdownList);
          }
        })
        .catch((err) => console.log(err));
    }

    // ;
  };
  const StationDepartmentWiseClickForEquAva = (_, datasets) => {
    if (datasets?.length) {
      const StatusName = datasets[0].element.$context.dataset.label;
      const { labels } = PeriodicModel?.EquAvaStationWiseDataForBM;
      let DatasetIndex = datasets[0].index;
      const StationName = labels[DatasetIndex];

      $("#modelTriggerBtnEquipmentAvaList").click();

      Dashboard_GetStattionWiseEquipAvaData(
        StationIDs,
        FilterModel.Departments.value,
        EquipmentDetailIDS,
        StatusName,
        StationName,
        EqCodeStr
      )
        .then((res) => {
          if (res?.data?.data) {
            const { equipmentList } = res.data.data;

            setEquipmentAvaList(equipmentList);
          }
        })
        .catch((err) => console.log(err));

      // ;
    }
  };



  const ReportTable_1 = () => {
    // alert(StationIDs);
    const ColumNames = [
      {
        title: "StationCode ",
        data: "StationCode",
        width: "300",
      },
      {
        title: "Ticket No",
        data: "TicketNo",
      },
      {
        title: "Equipment Tag No",
        data: "EquipmentCode",
      },

      // {
      //   title: "Assigned By",
      //   data: "AssignedByName",
      // },
      {
        title: "Worked By",
        data: "AssignedToName",
        width: "300",
      },

      {
        title: "Approved By",
        data: "ApprovelByName",
      },
      {
        title: "Rejected By",
        data: "RejectByName",
      },
      {
        title: "Problem Description",
        data: "ProblemDescription",
        width: "300",
      },
      {
        title: "Work Description",
        data: "WorkDescription",
      },
      {
        title: "Status",
        data: "StatusName",
      },
      // {
      //   title: "Remarks",
      //   data: "remarks",
      // },
      // {
      //   title: "Action",
      //   data: "Action",
      // },
    ];

    return (
      <HtmlTable
        columnList={ColumNames}
        rowList={BreakdownList}
        id={"MaintenanceChecksReportTableForBM"}
      ></HtmlTable>
    );
  };
  const ReportTable_2 = () => {
    // alert(StationIDs);
    const ColumNames = [
      {
        title: "StationCode ",
        data: "StationCode",
      },
      {
        title: "Equipment Tag No",
        data: "EquipmentCode",
      },
      {
        title: "Last Maintenance Date",
        data: "LastToDatedf",
      },
      {
        title: "Status",
        data: "StatusName",
      },
    ];

    return (
      <HtmlTable
        columnList={ColumNames}
        rowList={EquipmentAvaList}
        id={"MaintenanceChecksReportTableForEqu"}
      ></HtmlTable>
    );
  };

  const MaintenanceCheckListReport = () => {
    // alert(StationIDs);
    const ColumNames = [
      {
        title: "Department",
        data: "DepartmentName",
      },
      {
        title: "Station Code ",
        data: "StationCode",
      },
      {
        title: "Equipment Short Name",
        data: "EquipmentShortName",
      },
      {
        title: "Equipment Tag No",
        data: "EquipmentCode",
      },
      {
        title: "Component Name",
        data: "ComponentName",
        className: "hide",
      },
      {
        title: "Description",
        data: "DescriptionName",
      },
      {
        title: "Week",
        data: "WeekName",
      },

      {
        title: "Scheduled Date",
        data: "ScheduledDate",
      },
      {
        title: "Last Maintenance Date",
        data: "LastMaintenanceDate",
      },
      {
        title: "Status",
        data: "Status",
      },
    ];

    return (
      <HtmlTable
        columnList={ColumNames}
        rowList={PeriodicModel?.MaintenanceReportData ?? []}
        id={"MaintenanceChecksReportTable"}
      ></HtmlTable>
    );
  };

  // ----- for PM

  const getPMDashData = () => {
    const {
      FromDate,
      ToDate,
      Departments,
      Stations,
      ScheduleType,
      Year,
      DateRange,
    } = FilterModel;
    const DepartId = Departments?.value;
    const StationId = Stations?.map((ele) => ele.value).join(",");
    const { CopyStackFromDate, CopyStackToDate } = ManipulateObject({
      FromDate,
      ToDate,
      Frequency: ScheduleType.value ?? 1,
      Year,
      DateRange,
    });
    if (
      ScheduleType?.value &&
      DepartId &&
      StationId &&
      CopyStackFromDate &&
      CopyStackToDate
    ) {
      Dashboard_PM_StationWiseAndDepartmentWise(
        ScheduleType?.value ?? "",
        DepartId,
        StationId,
        CopyStackFromDate,
        CopyStackToDate
      )
        .then((res) => {
          if (res?.data?.data) {
            const { equipmentWise, stationWise } = res.data.data;

            if (equipmentWise?.length) {
              const labels = equipmentWise.map((ele) => ele.EquipmentShortName);
              const EquipmentIds = equipmentWise.map((ele) => ele.EquipmentID);
              const OpenCount = equipmentWise.map((ele) => ele.OpenCount);
              const PendingCount = equipmentWise.map((ele) => ele.PendingCount);
              const CloseCount = equipmentWise.map((ele) => ele.CloseCount);
              const ApproveCount = equipmentWise.map((ele) => ele.ApproveCount);
              const RejectedCount = equipmentWise.map(
                (ele) => ele.RejectedCount
              );

              const data = {
                labels: labels,
                EquipmentIds,
                datasets: [
                  {
                    label: "Open",
                    data: OpenCount,
                    backgroundColor: "#93a7fd",

                    borderWidth: 1,
                  },
                  {
                    label: "Overdue",
                    data: PendingCount,
                    backgroundColor: "Orange",

                    borderWidth: 1,
                  },
                  {
                    label: "Completed",
                    data: CloseCount,
                    backgroundColor: "grey",

                    borderWidth: 1,
                  },
                  {
                    label: "Approved",
                    data: ApproveCount,
                    backgroundColor: "#87f093",

                    borderWidth: 1,
                  },
                  {
                    label: "Rejected",
                    data: RejectedCount,
                    backgroundColor: "#f27d89",
                    borderWidth: 1,
                  },
                ],
              };

              setPeriodicModel((pre) => ({
                ...pre,
                DepartmentData: data,
                FilterDepartmentData: data,
                FilterStationData: stationWise,
              }));
            } else {
              setPeriodicModel((pre) => ({
                ...pre,
                DepartmentData: {
                  labels: [],
                  datasets: [],
                },
                StationWiseData: {
                  labels: [],
                  datasets: [],
                },
              }));
            }
            // console.log(EquipmentWise, StationWise);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getPMDashDataForReport = async (datasets) => {
    if (datasets?.length) {
      let DatasetIndex = datasets[0].index;
      let IndexDatasetIndex = datasets[0].datasetIndex;
      const {
        StationIDs,
        labels,
        datasets: StoredDataSets,
      } = PeriodicModel.StationWiseData;
      const StationID = StationIDs?.[DatasetIndex] ?? 0;
      const Status = StoredDataSets[IndexDatasetIndex].label;

      const StatusObj = {
        Open: 1,
        Overdue: 1,
        Completed: 2,
        Approved: 3,
        Rejected: 4,
      };
      let StatusID = StatusObj[Status] ?? 0;

      const {
        FromDate,
        ToDate,
        Departments,

        ScheduleType,
        Year,
        DateRange,
      } = FilterModel;

      const { PeriodicEquipmentID } = PeriodicModel;
      const DepartId = Departments?.value;
      // const StationId = Stations?.map((ele) => ele.value).join(",");
      const { CopyStackFromDate, CopyStackToDate } = ManipulateObject({
        FromDate,
        ToDate,
        Frequency: ScheduleType.value ?? 1,
        Year,
        DateRange,
      });
      if (
        (ScheduleType?.value &&
          DepartId &&
          StationID &&
          CopyStackFromDate &&
          CopyStackToDate &&
          PeriodicEquipmentID,
        StatusID)
      ) {
        await Dashboard_PM_StationWiseGetDataForReport(
          ScheduleType?.value ?? "",
          DepartId,
          StationID,
          CopyStackFromDate,
          CopyStackToDate,
          PeriodicEquipmentID,
          StatusID
        )
          .then((res) => {
            if (res?.data?.data) {
              const { stationWiseReport } = res.data.data;

              if (stationWiseReport?.length) {
                setPeriodicModel((pre) => ({
                  ...pre,
                  MaintenanceReportData: stationWiseReport,
                }));
              } else {
                setPeriodicModel((pre) => ({
                  ...pre,
                  MaintenanceReportData: [],
                }));
              }
              // console.log(EquipmentWise, StationWise);
            }
          })
          .catch((err) => console.log(err));
      } else {
        setPeriodicModel((pre) => ({
          ...pre,
          MaintenanceReportData: [],
        }));
      }
    }
  };

  function ManipulateObject(Obj1) {
    var Obj = { ...Obj1 };

    let year = parseInt(Obj.Year);
    let Month = parseInt(Obj.DateRange);

    if (Obj.Frequency == 1) {
      if (Obj.StackToDate != "") {
        Obj.CopyStackFromDate = Obj.FromDate;
        Obj.CopyStackToDate = Obj.ToDate;
      } else {
        Obj.CopyStackFromDate = Obj.StackFromDate;
        Obj.CopyStackToDate = Obj.StackFromDate;
      }
    } else if (Obj.Frequency == 2) {
      Obj.CopyStackFromDate = new Date(year, Month, 1, 6)
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
      Obj.CopyStackToDate = new Date(year, Month + 1, 0, 8)
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
    } else if (Obj.Frequency == 3) {
      var nextMonth = Month + 3;
      if (PeriodicModel.DateRange?.[0]?.value == Month && nextMonth >= 13) {
        year = year - 1;
      }
      Obj.CopyStackFromDate = new Date(year, Month, 1, 6)
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
      Obj.CopyStackToDate = new Date(year, nextMonth, 0, 6)
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
    } else if (Obj.Frequency == 4) {
      Obj.CopyStackFromDate = `${Obj.Year}-${Obj.DateRange}-01`;
      var nextMonth = Month + 5;
      Obj.CopyStackToDate = new Date(year, nextMonth, 0, 6)
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
    } else if (Obj.Frequency == 5) {
      Obj.CopyStackFromDate = `${Obj.Year}-01-01`;
      Obj.CopyStackToDate = new Date(year, 12, 0, 6)
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
    }
    return Obj;
  }

  const QuarterlyMonths = () => {
    const QuarterList = [
      { value: 0, key: "Jan - Mar" },
      { value: 3, key: "Apr - Jun" },
      { value: 6, key: "Jul - Sep" },
      { value: 9, key: "Oct - Dec" },
    ];

    return QuarterList;
  };

  const HalfMonths = () => {
    return [
      { value: 5, key: "Jan - Jun" },
      { value: 11, key: "July - Dec" },
    ];
  };

  const RangeObj = {
    2: ShortMonths(),
    3: QuarterlyMonths(),
    4: HalfMonths(),
  };

  const HandleChange = (event, CustomName) => {
    if (CustomName) {
      setFilterModel((pre) => ({
        ...pre,
        [CustomName]: event,
      }));

      if (CustomName == "ScheduleType") {
        $(".RangeDate").attr("disabled", true);
        const { value } = event;
        if (Number(value) != 1 && Number(value) != 5) {
          const DropDownValue = RangeObj[Number(value)];
          setFilterModel((prevState) => ({
            ...prevState,
            ["DateRange"]: DropDownValue[0].value,
          }));

          setPeriodicModel((pre) => ({
            ...pre,
            DateRange: DropDownValue,
          }));
        }
      }
    } else {
      const { name, value } = event.target;
      setFilterModel((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
  };

  const ChartHandleChange = (_, datasets) => {
    if (datasets?.length) {
      let DatasetIndex = datasets[0].index;
      const { EquipmentIds } = PeriodicModel.DepartmentData;
      const EquipmentID = EquipmentIds?.[DatasetIndex] ?? 0;
      let StationWiseList = PeriodicModel.FilterStationData.filter(
        (ele) => ele.EquipmentID == EquipmentID
      );

      if (StationWiseList?.length) {
        const labels = StationWiseList.map((ele) => ele.StationCode);
        const StationIDs = StationWiseList.map((ele) => ele.StationID);
        const OpenCount = StationWiseList.map((ele) => ele.OpenCount);
        const PendingCount = StationWiseList.map((ele) => ele.PendingCount);
        const CloseCount = StationWiseList.map((ele) => ele.CloseCount);
        const ApproveCount = StationWiseList.map((ele) => ele.ApproveCount);
        const RejectedCount = StationWiseList.map((ele) => ele.RejectedCount);

        const data = {
          labels: labels,
          StationIDs,
          datasets: [
            {
              label: "Open",
              data: OpenCount,
              backgroundColor: "#93a7fd",

              borderWidth: 1,
            },
            {
              label: "Overdue",
              data: PendingCount,
              backgroundColor: "rgb(255, 159, 64)",

              borderWidth: 1,
            },
            {
              label: "Completed",
              data: CloseCount,
              backgroundColor: "#6c757d",

              borderWidth: 1,
            },
            {
              label: "Approved",
              data: ApproveCount,
              backgroundColor: "#87f093",

              borderWidth: 1,
            },
            {
              label: "Rejected",
              data: RejectedCount,
              backgroundColor: "#f27d89",
              borderWidth: 1,
            },
          ],
        };

        setPeriodicModel((pre) => ({
          ...pre,
          StationWiseData: data,
          ShowBar: true,
          PeriodicEquipmentID: EquipmentID,
        }));
      } else {
        setPeriodicModel((pre) => ({
          ...pre,
          DepartmentData: {
            labels: [],
            datasets: [],
            ShowBar: true,
          },
        }));
      }
    }
  };

  const MacnineOverdueOnClick = (label) => {
    let DepartmentData = JSON.parse(JSON.stringify(EquipmentAvailabilitydata));
    let dataindex = DepartmentData.datasets[0].data.findIndex(
      (ele) => ele == label
    );
    DepartmentData.datasets[0].data = [
      DepartmentData.datasets[0].data[dataindex],
    ];
    DepartmentData.datasets[0].backgroundColor = [
      DepartmentData.datasets[0].backgroundColor[dataindex],
    ];

    setPeriodicModel((pre) => ({
      ...pre,
      AvailableShowBar: false,
      ListEquipmentAvailabilitydata: DepartmentData,
    }));
  };

  const CardOnClick = (_currentStatus) => {
    debugger;
    const { datasets } = PeriodicModel.FilterDepartmentData;
    const CopyDataSet = DeepCopy(datasets);
    const DepartmentData = DeepCopy(PeriodicModel.DepartmentData);

    CopyDataSet.forEach((ele) => {
      if (ele.label !== _currentStatus) {
        ele.data = [];
      }
    });
    DepartmentData.datasets = CopyDataSet;
    setPeriodicModel((pre) => ({
      ...pre,
      DepartmentData,
    }));
  };

  // console.log(FilDatasets);

  const DashboardAvaCard = ({
    OpenCount,
    OverdueCount,
    CompletedCount,
    ApprovedCount,
    RejectedCount,
    TotalCount,
  }) => {
    return (
      <div class="row">
        {/* <div class="col xs12 m6 s12 l6 xl3 page-title-box"></div> */}
        <div class="col-md-1"></div>

        {/* <div class="col xs12 m6 s12class l6 xl3"> */}
        <div class="col-md-3">
          <div
            class="card dash-widget dashboard_background_Approved"
            onClick={() => {
              MacnineOverdueOnClick(ApprovedCount);
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-check"></i>
              </span>
              <div class="dash-widget-info">
                <h2 className="">
                  {ApprovedCount}{" "}
                  <span className="font-18 text-black-50"> / {TotalCount}</span>{" "}
                </h2>
                <span>Running</span>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="col xs12 m6 s12 l6 xl3"> */}
        <div class="col-md-3">
          <div
            class="card dash-widget dashboard_background_Overdue"
            onClick={() => {
              MacnineOverdueOnClick(OverdueCount);
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-exclamation"></i>
              </span>
              <div class="dash-widget-info">
                <h2 className="">
                  {/* 355 */}
                  {OverdueCount}
                  <span className="font-18 text-black-50">
                    {" "}
                    / {TotalCount}
                  </span>{" "}
                </h2>
                <span>Under Maintenance</span>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="col xs12 m6 s12 l6 xl3"> */}
        <div class="col-md-3">
          <div
            class="card dash-widget dashboard_background_Rejected"
            onClick={() => {
              MacnineOverdueOnClick(RejectedCount);
            }}
          >
            <div class="card-body" style={{ padding: 10 }}>
              <span class="dash-widget-icon">
                <i class="fa fa-chain-broken "></i>
              </span>
              <div class="dash-widget-info">
                <h2 className="">
                  {RejectedCount}{" "}
                  <span className="font-18 text-black-50"> / {TotalCount}</span>
                </h2>
                <span>Breakdown</span>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="col xs12 m6 s12 l6 xl3 page-title-box"></div> */}
      </div>
    );
  };
  const ColumNames = [
    // {
    //   title: "Ticket ID",
    //   data: "TicketID",
    // },
    {
      title: "Equipment",
      data: "EquipmentCode",
    },
    {
      title: "Station",
      data: "StationCode",
    },
    {
      title: "Last Maintenance Date",
      data: "LastToDatedf",
    },

    {
      title: "Status",
      data: "Verified",
    },

    {
      title: "Remarks",
      data: "RejectedRemark",
    },
  ];

  const isOutsideRange = (day) => {
    // Example: disallow dates after 1 year from today
    return day.isAfter(moment().add(1, "year"), "day");
  };
  

  return (
    <div>
      <div className="row mb-2">
        <div className="-md-12">
          <div className="row">
            <div className="col-12">
              <h3>Dashboard</h3>
            </div>
            <div className="col-3">
              <ReactMultiSelect
                // isMulti
                options={DepartmentList}
                placeholder={"Departments"}
                // components={{ Option, MultiValue, animatedComponents }}
                // allowSelectAll
                onChange={(e) => DepartmentValueChange(e)}
                value={FilterModel.Departments}
              ></ReactMultiSelect>
            </div>

            <div className="col-2">
              <ReactMultiSelect
                // isMulti
                options={CorridorList}
                placeholder={"Corridor"}
                // components={{ Option, MultiValue, animatedComponents }}
                // allowSelectAll
                onChange={(e) => CorridorListChange(e)}
                value={FilterModel.Corridor}
              ></ReactMultiSelect>
            </div>

            <div className="col-2">
              <ReactMultiSelect
                isMulti
                options={StationList}
                placeholder={"All Stations"}
                components={{ Option, MultiValue, animatedComponents }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll
                onChange={(e) => StationValueChange(e)}
                value={FilterModel.Stations}
              ></ReactMultiSelect>
            </div>
            <div className="col-2 ">
              <ReactMultiSelect
                isMulti
                options={EquipmentList}
                placeholder={"Equipment"}
                components={{ Option, MultiValue, animatedComponents }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll
                onChange={(e) => EquipmentDetailChange(e)}
                value={FilterModel.Equipment}
              ></ReactMultiSelect>
            </div>

            <div className="col-3 ">
              <DateRangePicker
                startDate={startDate}
                startDateId="your_unique_start_date_id"
                endDate={endDate}
                // minDate={new Date(2020, 1, 1)}
                // maxDate={new Date(2020, 1, 29)}
                endDateId="your_unique_end_date_id"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                  DateFilterChange(startDate, endDate);
                }}
                minDate={moment().add(100, "days")} // Minimum selectable date
                maxDate={moment().add(7, "days")}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                showClearDates={true}
                // enableOutsideDays={true}
                displayFormat="DD-MM-yyyy"
                isOutsideRange={isOutsideRange}
                // minDate={false}
                small
                // block
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="row mt-1">
            <ol
              class="breadcrumb text-muted mb-0 col-12"
              style={{ padding: "0 13px" }}
            ></ol>

            <div className="col-4"></div>
          </div>
        </div>
      </div>

      <div className="row ">
        <div className="col-12 card">
          <h3 className="card-title m-0 text-center" style={{ paddingTop: 15 }}>
            Cyclic Check Report
          </h3>
          <hr style={{ marginTop: 10 }} />
          <div className="row">
            <div className="col-6">
              <div className="card" style={{ marginBottom: 10 }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12  text-center">
                      <h3 class="card-title mt-2">
                        Cyclic Check Report Status
                      </h3>
                      <hr style={{ marginTop: 10 }} />
                    </div>
                    <div
                      class="col-md-12"
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <div
                        class=" m-0"
                        style={{
                          maxWidth: "680px !important",
                          width: "680px",
                        }}
                      >
                        <div class="row d-flex justify-content-center">
                          <div class="mt-0 col-md-12 col-sm-6 ">
                            <div>
                              <div
                                class="col-md-12"
                                style={{
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                <div
                                  class=" m-0"
                                  style={{
                                    maxWidth: "540px !important",
                                    width: "500px",
                                  }}
                                >
                                  <div class="row d-flex justify-content-center">
                                    <div class="mt-0 col-md-10 col-sm-6 ">
                                      <div>
                                        {DashboardListModel?.CyclicCheckList
                                          ?.datasets && (
                                          <PieChart
                                            data={
                                              DashboardListModel.CyclicCheckList ??
                                              []
                                            }
                                            title={false}
                                            // clickable={AvailabilityWiseClick}
                                            // onDoubleClick={() => {
                                            //   AvailabilityWiseClick("_", false);
                                            // }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="col xs12 m6 s12 l6 xl3 text-center">
                <div className="card" style={{ marginBottom: 10 }}>
                  <div className="card-body">
                    <h3 class="card-title mt-2">Cyclic Check Report</h3>
                    <hr style={{ marginTop: 10 }} />

                    <div style={{ maxHeight: "400PX", overflowY: "auto" }}>
                      <HtmlTable
                        columnList={ColumNames}
                        rowList={DashboardListModel.CyclicCheckTableList}
                        id={"AvaReportTableList"}
                      ></HtmlTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 card">
          <h3 className="card-title m-0 text-center" style={{ paddingTop: 15 }}>
            Equipment Availability Status{" "}
          </h3>

          <div className="row">
            <div className="col-12 ">
              <hr style={{ marginTop: 10 }} />

              {DashboardAvaCard({
                OverdueCount: EquAVABreadListModel.UnderMDCount,
                ApprovedCount: EquAVABreadListModel.RunningCount,
                RejectedCount: EquAVABreadListModel.UnderBDCount,
                TotalCount: EquAVABreadListModel.TotalCount,
                // OverdueCount: 133,
                // ApprovedCount: 107,
                // RejectedCount: 99,
                // TotalCount: 339,
              })}
            </div>
            <div className="row  mt-2">
              <div className="col-6">
                <div className="card" style={{ marginBottom: 10 }}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12  text-center">
                        <h3 class="card-title mt-2">
                          Equipment Availability Status
                        </h3>
                        <hr style={{ marginTop: 10 }} />
                      </div>
                      <div
                        class="col-md-12"
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <div
                          class=" m-0"
                          style={{
                            maxWidth: "680px !important",
                            width: "680px",
                          }}
                        >
                          <div class="row d-flex justify-content-center">
                            <div class="mt-0 col-md-12 col-sm-6 ">
                              <div>
                                {DashboardListModel?.StackedDataForEquiAvaWise
                                  ?.datasets && (
                                  <>
                                    {/* <CommonBarChart
                                    // IsHorizontal
                                    // data={StackedDataForBDEquipmentWise}
                                    data={
                                      DashboardListModel?.StackedDataForEquiAvaWise
                                    }
                                    IsShow={true}
                                    LegendPosition={"bottom"}
                                    Title=""
                                    BarClick={EquAVAWiseClickForBM}
                                    onDoubleClick={() => {
                                      EquAVAWiseClickForBM("_", false);
                                    }}
                                    // yLabel={"Equipments"}
                                    // xTitle={"Equipments"}
                                  ></CommonBarChart> */}

                                    <StackedChart
                                      // IsHorizontal
                                      // data={StackedDataForBDEquipmentWise}
                                      data={
                                        DashboardListModel?.StackedDataForEquiAvaWise
                                      }
                                      IsShow={true}
                                      LegendPosition={"bottom"}
                                      Title=""
                                      BarClick={EquAVAWiseClickForBM}
                                      onDoubleClick={() => {
                                        EquAVAWiseClickForBM("_", false);
                                      }}
                                      // yLabel={"Equipments"}
                                    ></StackedChart>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 mt-2 text-center">
                {PeriodicModel.EquAvaStationWiseDataForBM?.datasets && (
                  <>
                    <div className="card" style={{ marginBottom: 10 }}>
                      <div className="card-body">
                        <h3 class="card-title mt-2">
                          Equipment Availability Status - {EquipmnetAvaStatus} -
                          By Station Wise
                        </h3>
                        <hr style={{ marginTop: 10 }} />
                        {/* <StackedChart
                          data={PeriodicModel.EquAvaStationWiseDataForBM}
                          IsShow={true}
                          LegendPosition={"bottom"}
                          reff={DepartmentChartRef}
                          BarClick={StationDepartmentWiseClickForEquAva}
                          onDoubleClick={() => {
                            StationDepartmentWiseClickForEquAva("_", false);
                          }}
                          Title=""
                        ></StackedChart> */}
                        <CommonBarChart
                          IsShow={true}
                          reff={DepartmentChartRef}
                          data={PeriodicModel?.EquAvaStationWiseDataForBM}
                          clickable={StationDepartmentWiseClickForEquAva}
                          LegendBoxWidth={10}
                          dataLabelsShow
                        ></CommonBarChart>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 card">
          <div className=" p-2 row ">
            <h3
              className="card-title m-0 text-center"
              style={{ paddingTop: "10px" }}
            >
              Breakdown Maintenance Status{" "}
            </h3>
            <hr style={{ marginTop: 10 }} />
            {BM_DashboardCard({
              OverdueCount: BreadListModel.Overdue,
              OpenCount: BreadListModel.OpenCount,

              CompletedCount: BreadListModel.Assigned,

              ApprovedCount: BreadListModel.ApprovedCount,
              RejectedCount: BreadListModel.RejectedCount,
              TotalCount: BreadListModel.TotalCount,
              CompletedText: "Assigned",
              AttendText: "Attended",
              AssendCount: 1,
              AttendCount: BreadListModel.Attended,
            })}

            <div className="col-6 mt-2 text-center">
              <div className="card" style={{ marginBottom: 10 }}>
                <div className="card-body">
                  <h3 class="card-title mt-2">
                    Breakdown Maintenance Status - By Equipment Wise
                  </h3>
                  <hr style={{ marginTop: 10 }} />
                  {DashboardListModel?.StackedDataForBDEquipmentWise
                    ?.datasets && (
                    <StackedChart
                      // IsHorizontal
                      // data={StackedDataForBDEquipmentWise}
                      data={DashboardListModel?.StackedDataForBDEquipmentWise}
                      IsShow={true}
                      LegendPosition={"bottom"}
                      Title=""
                      BarClick={DepartmentWiseClickForBM}
                      onDoubleClick={() => {
                        DepartmentWiseClickForBM("_", false);
                      }}
                      // yLabel={"Equipments"}
                    ></StackedChart>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 mt-2 text-center">
              {PeriodicModel.StationWiseDataForBM?.datasets && (
                <>
                  <div className="card" style={{ marginBottom: 10 }}>
                    <div className="card-body">
                      <h3 class="card-title mt-2">
                        Breakdown Maintenance Status - By Station Wise
                      </h3>
                      <hr style={{ marginTop: 10 }} />
                      {/* <StackedChart
                        // IsHorizontal
                        // data={PeriodicModel.StationWiseDataForBM}
                        data={PeriodicModel.StationWiseDataForBM}
                        IsShow={true}
                        LegendPosition={"bottom"}
                        reff={DepartmentChartRef}
                        // yLabel={"Stations"}

                        BarClick={StationDepartmentWiseClickForBM}
                        onDoubleClick={() => {
                          StationDepartmentWiseClickForBM("_", false);
                        }}
                        Title=""
                      ></StackedChart> */}

                      <CommonBarChart
                        IsShow={true}
                        reff={DepartmentChartRef}
                        data={PeriodicModel?.StationWiseDataForBM}
                        clickable={StationDepartmentWiseClickForBM}
                        LegendBoxWidth={10}
                        dataLabelsShow
                      ></CommonBarChart>
                    </div>
                  </div>
                </>
              )}
            </div>

            <BootrapModel
              module={<ReportTable_1 />}
              Id={"MaintenanceChecksReportTableModelForBM"}
              modelSize={"modal-fullscreen"}
              ModelTitle={"Breakdown Maintenance Status Report"}
            ></BootrapModel>
            <BootrapModel
              module={<ReportTable_2 />}
              Id={"MaintenanceChecksReportTableModelForEqu"}
              modelSize={"modal-xl"}
              ModelTitle={"Equipment Availability Status Report"}
            ></BootrapModel>

            <BootrapModel
              module={<MaintenanceCheckListReport />}
              Id={"MaintenanceChecksReportTableModel"}
              modelSize={"modal-xl"}
              ModelTitle={"Periodic Maintenance Detail Report"}
            ></BootrapModel>

            <button
              id="modelTriggerBtn"
              hidden
              data-bs-toggle="modal"
              data-bs-target="#MaintenanceChecksReportTableModel"
            ></button>
            <button
              id="modelTriggerBtnBM"
              hidden
              data-bs-toggle="modal"
              data-bs-target="#MaintenanceChecksReportTableModelForBM"
            ></button>
            <button
              id="modelTriggerBtnEquipmentAvaList"
              hidden
              data-bs-toggle="modal"
              data-bs-target="#MaintenanceChecksReportTableModelForEqu"
            ></button>
          </div>
        </div>

        <div className="col-12 card">
          <h3 className="card-title m-0 text-center" style={{ paddingTop: 15 }}>
            Preventive Maintenance Status
          </h3>
          <div className="row col-12">
            {" "}
            <div className="col-3 m-1">
              <label className="form-check-label" htmlFor="Year">
                Schedule Type
              </label>
              <ReactMultiSelect
                options={[
                  { label: "Daily", value: 1 },
                  { label: "Monthly", value: 2 },
                  { label: "Quarterly", value: 3 },
                  { label: "Half Yearly", value: 4 },
                  { label: "Yearly", value: 5 },
                ]}
                onChange={(e) => HandleChange(e, "ScheduleType")}
                value={FilterModel.ScheduleType ?? {}}
              ></ReactMultiSelect>
            </div>
            <div className="col-3 m-1">
              <label className="form-check-label" htmlFor="Year">
                Year
              </label>
              <FilterDropdown
                code={"Year"}
                name={"Year"}
                onChange={(e) => HandleChange(e)}
                value={FilterModel.Year}
              />
            </div>
            <div className="col-md-3">
              <label className="form-check-label" htmlFor="category1">
                Select
              </label>
              <WithoutSelectDropDown
                name={"DateRange"}
                value={FilterModel.DateRange}
                disabled={FilterModel?.ScheduleType.value == 1}
                placeholder="Select Date Range"
                HandleChange={HandleChange}
                data={PeriodicModel.DateRange}
              />
            </div>
          </div>
          <hr style={{ marginTop: 10 }} />
          {DashboardCard({
            OverdueCount: FilDatasets?.length
              ? FilDatasets?.[1].data.reduce(function (x, y) {
                  return x + y;
                }, 0)
              : 0,
            OpenCount: FilDatasets?.length
              ? FilDatasets?.[0].data.reduce(function (x, y) {
                  return x + y;
                }, 0)
              : 0,
            CompletedCount: FilDatasets?.length
              ? FilDatasets?.[2].data.reduce(function (x, y) {
                  return x + y;
                }, 0)
              : 0,
            ApprovedCount: FilDatasets?.length
              ? FilDatasets?.[3].data.reduce(function (x, y) {
                  return x + y;
                }, 0)
              : 0,
            RejectedCount: FilDatasets?.length
              ? FilDatasets?.[4].data.reduce(function (x, y) {
                  return x + y;
                }, 0)
              : 0,
            onClick: CardOnClick,
          })}
          <div className="row col-12 text-center mx-auto mt-1">
            <div className="col-6 mt-1 mb-1">
              <div className="card" style={{ marginBottom: 10 }}>
                <div className="card-body">
                  <h3 class="card-title mt-2">
                    Preventive Maintenance Status - Equipment Wise
                  </h3>
                  <hr style={{ marginTop: 10 }} />
                  {PeriodicModel?.DepartmentData.labels?.length ? (
                    <>
                      <CommonBarChart
                      IsShow={true}
                      reff={DepartmentChartRef}
                      data={PeriodicModel?.DepartmentData}
                      clickable={ChartHandleChange}
                      LegendBoxWidth={10}
                      dataLabelsShow
                    ></CommonBarChart>
                    </>
                  
                    
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            {PeriodicModel.ShowBar && (
              <div className="col-6 mt-1 mb-1 ">
                <div className="card" style={{ marginBottom: 10 }}>
                  <div className="card-body">
                    <h3 class="card-title mt-2 m-l-15">
                      {" "}
                      Preventive Maintenance Status - Station Wise
                    </h3>
                    <hr style={{ marginTop: 10 }} />

                    {PeriodicModel?.StationWiseData.labels?.length ? (
                      <>
                        <CommonBarChart
                          xTitle={"Stations"}
                          data={PeriodicModel?.StationWiseData}
                          clickable={(e, datasets) => {
                            getPMDashDataForReport(datasets);
                            $("#modelTriggerBtn").click();
                          }}
                          IsScrollable={true}
                          IsShow={true}
                          LegendBoxWidth={10}
                          dataLabelsShow
                        ></CommonBarChart>

                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )}

            
          </div>
        </div>
      </div>
    </div>
  );
}
