import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import $ from "jquery";
import jsPDF from "jspdf";
import {
  PeriodicService,
  Report_PeriodicMaintenance_DropDown,
} from "./PeriodicService";
// import html2pdf from "html2pdf.js";
import "./TelecomReportcs.css";
function TelecomReport() {
  const location = useLocation();
  const PeriodicServiceobj = new PeriodicService();
  const [TelecomDataList, setTelecomDataList] = useState([]);
  const [L0Name, setL0Name] = useState();
  const [L1Name, setL1Name] = useState();
  // const { showLoading, hideLoading } = DefaultAppContextValues();
  useEffect(() => {
    if (location.state) {
      const { PeriodicCheckListMaintenanceID, PeriodicDetailID } =
        location.state;
      GetTelecomDataList(PeriodicCheckListMaintenanceID, PeriodicDetailID);
    }
  }, []);
  const GetTelecomDataList = async (
    PeriodicCheckListMaintenanceID,
    PeriodicDetailID
  ) => {

    if (PeriodicCheckListMaintenanceID > 0) {
      try {
        return await PeriodicServiceobj.PeriodicMaintenance_TelecomReportList(
          PeriodicCheckListMaintenanceID,
          PeriodicDetailID
        ).then((res) => {
          debugger
          if (res?.data?.data) {
            const { reportList, approverList } = res.data.data;
            setTelecomDataList(reportList);

            const L0Name =
            approverList?.find((ele) => ele.PreRoleName == "L0")
                ?.EmployeeCode ?? "";
            const L1Name =
            approverList?.find((ele) => ele.PreRoleName == "L1")
                ?.EmployeeCode ?? "";

            setL0Name(L0Name);
            setL1Name(L1Name);
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const ConvertHTMLFileToPDF = () => {
    const element = document.getElementById("Apply");

    // Set PDF options (optional)
    const opt = {
      margin: [0.2, 0.2, 0.3, 0.3],
      filename: "Telecom_report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true, scrollX: 0, scrollY: 0 },
      jsPDF: { unit: "in", format: [8.27, 11.69], orientation: "portrait" },
    };

    // Convert the element and save as PDF
    // html2pdf().from(element).set(opt).save();
  };
  return (
    <>
      <div className="">
        <div className="row align-items-center">
          <div className="col-md-10">
            {/* <h3 className="page-title">
              TELECOM - MONTHLY PREVENTIVE MAINTENANCE CHECKLIST
            </h3> */}
          </div>
          <div className="col-2">
            <button
              title="Pdf download"
              className="lar la-file-pdf btn"
              onClick={() => ConvertHTMLFileToPDF()}
            ></button>
          </div>
        </div>
        <form id="Apply" style={{ marginLeft: "80px" }}>
          <div className="col-md-10 text-center">
            <h3 className="page-title">
              Telecom - Monthly Preventive Maintenance Checklist
            </h3>
          </div>
          {TelecomDataList.map((data, index) => (
            <div key={index}>
              <div className="">
                <div className="row" style={{ height: "20px" }}>
                  <div className="col-8 mt-1">
                    <h3>{data.DescriptionName}</h3>
                  </div>
                  <div className="col-2">
                    <label>Date</label>
                    <input
                      className="form-control"
                      type="text"
                      name={`date-${index}`}
                      readOnly
                      value={data.Date}
                    />
                  </div>
                  <div className="col-1">
                    <label>Time</label>
                    <input
                      className="form-control"
                      type="text"
                      name={`time-${index}`}
                      readOnly
                      value={data.Time}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-2 d-flex">
                <label style={{ marginRight: "4px" }} className="mt-1">
                  Input Voltage :
                </label>
                <div className="d-flex gap-2">
                  <div className="mb-1 d-flex">
                    <label style={{ marginRight: "4px" }} className="mt-1">
                      {" "}
                      R
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={data.VoltageR}
                      readOnly
                    />
                  </div>
                  <div className="mb-1 d-flex">
                    <label style={{ marginRight: "4px" }} className="mt-1">
                      B
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name={`inputVoltage-b-${index}`}
                      readOnly
                      value={data.VoltageB}
                    />
                  </div>
                  <div className="mb-1 d-flex">
                    <label style={{ marginRight: "4px" }} className="mt-1">
                      Y
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      value={data.VoltageG}
                    />
                  </div>
                </div>
              </div>

              <h5 className="">i. Measurement of individual cell voltage</h5>
              <div className="row">
                <div className="col-8">
                  <table className="table ">
                    <tbody>
                      {[...Array(6)].map((_, rowIndex) => (
                        <tr key={rowIndex} style={{ height: "20px" }}>
                          {[...Array(4)].map((_, colIndex) => {
                            const measurementIndex = rowIndex * 4 + colIndex;
                            const measurementValue =
                              data[`M${measurementIndex + 1}`] || "";
                            return (
                              <React.Fragment key={colIndex}>
                                <td
                                  style={{
                                    padding: "2px",
                                    width: "2px",
                                    fontSize: "10px",
                                  }}
                                >
                                  {measurementIndex + 1}
                                </td>
                                <td style={{ padding: "2px", width: "2px" }}>
                                  <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                    value={measurementValue}
                                  />
                                </td>
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-3">
                  <label className="text-center ">Remarks :</label>
                  <textarea
                    className="form-control"
                    readOnly
                    value={data.Remarks}
                  ></textarea>
                </div>
              </div>

              <div className="d-flex flex-wrap">
                <div className="me-2 d-flex">
                  <label style={{ marginRight: "4px" }} className="mt-1">
                    Total Voltage
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    readOnly
                    value={data.TotalVoltage}
                  />
                </div>
                <div className="me-2 d-flex">
                  <label style={{ marginRight: "4px" }} className="mt-1">
                    Load Current
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    readOnly
                    value={data.LoadCurrent}
                  />
                </div>
                <div className="me-2 d-flex">
                  <label style={{ marginRight: "4px" }} className="mt-1">
                    Battery Current
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    readOnly
                    value={data.BatteryCurrent}
                  />
                </div>
              </div>

              <hr
                className="mb-2"
                style={{
                  backgroundColor: "gray",
                  height: "0.5px",
                  border: "none",
                }}
              />
            </div>
          ))}
          <div className="row">
            <div className="col-6">
              <div className="mb-1 d-flex">
                <label style={{ marginRight: "4px" }}>
                  Technician Signature :{" "}
                </label>
              </div>
              <div className="mb-1">
                <label style={{ marginRight: "4px" }}>
                  Technician Name : {L1Name}
                </label>
              </div>
            </div>
            <div className="col-6">
              <div className="mb-1 d-flex">
                <label style={{ marginRight: "4px" }}>JE Signature : </label>
              </div>
              <div className="mb-1">
                <label style={{ marginRight: "4px" }}>JE Name : {L0Name}</label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default TelecomReport;
