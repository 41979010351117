import React, { useEffect, useState, useContext, useReducer } from "react";
import {
  BootrapModel,
  Dropdown,
  HtmlTable,
  showErrorMsg,
  showSaveError,
  showSaveSuccess,
  showSuccessMsg,
  showWarningMsg,
} from "../../components";
import {
  AuditingProcess_ApproveReject,
  AuditingProcess_GetPeriodicDetailsByID,
  AuditingProcess_Getdropdown,
} from "../AuditingProcess/AuditingProcessService.js";

import { useLocation } from "react-router-dom";

import {
  PeriodicMaintenance_GetOverAllRemarks,
  PeriodicMaintenance_SaveSpareData,
  PeriodicService,
} from "../PeriodicMaintenance/PeriodicService";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { AppContext } from "../../services/ContextProvider";
import $ from "jquery";
import {
  GetLoginUserID,
  GetSelect,
  getL0RoleID,
  getUserDetails,
  makeUnique,
} from "../../services/CommonService";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";
import { useNavigate } from "react-router";
import { SpareList } from "../PeriodicMaintenance/SpareList";
import { RiArrowGoBackLine } from "react-icons/ri";
import { COOKIES } from "../../services/Global.js";
import { WithSelectDropDown } from "../../components/WithSelectDropDown.js";
import { IoMdCheckmark } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const _breakdownService = new BreakdownMaintenanceService();

function OvrCrt_EarthFaultRelay_110_33_View() {
  const _periodicService = new PeriodicService();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    showLoading,
    hideLoading,
    PeriodicModel,
    setPeriodicModel,
    getCookies,
  } = useContext(AppContext);

  const {
    PeriodicCheckListMaintenanceID,
    fromdate,
    enddate,
    EquipmentDetails,
    DepartmentID,
    header,
    selectedModel,
  } = location.state;
  debugger
  const InitialState = {
    PWTNumber: "",
    PWTDate: "",
    Error: {},
    FeederNo: "",
    SLNo: "",
    CT_Rated_Primary_Current: 0,
    CT_Rated_Secondary_Current: 0,
    Rated_Primary_Voltage: 0,
    Rated_Secondary_Voltage: 0,
    isBreakDown: EquipmentDetails?.EquipAvailStatus ?? 0,

    CT_PT_Testing_Current: [
      {
        phases: "L1-E",
        CurrentInjected: 0,
        IL1: 0,
        IL2: 0,
        IL3: 0,
        IN: 0,
      },
      {
        phases: "L2-E",
        CurrentInjected: 0,
        IL1: 0,
        IL2: 0,
        IL3: 0,
        IN: 0,
      },
      {
        phases: "L3-E",
        CurrentInjected: 0,
        IL1: 0,
        IL2: 0,
        IL3: 0,
        IN: 0,
      },
      {
        phases: "L1-L2-L3",
        CurrentInjected: 0,
        IL1: 0,
        IL2: 0,
        IL3: 0,
        IN: 0,
      },
    ],
    CT_PT_Testing_Voltage: [
      {
        phases: "L1-E",
        Va: 0,
        Vb: 0,
        Vc: 0,
        Vab: 0,
        Vbc: 0,
        Vca: 0,
      },
      {
        phases: "L2-E",
        Va: 0,
        Vb: 0,
        Vc: 0,
        Vab: 0,
        Vbc: 0,
        Vca: 0,
      },
      {
        phases: "L3-E",
        Va: 0,
        Vb: 0,
        Vc: 0,
        Vab: 0,
        Vbc: 0,
        Vca: 0,
      },
      {
        phases: "L1-L2-L3",
        Va: 0,
        Vb: 0,
        Vc: 0,
        Vab: 0,
        Vbc: 0,
        Vca: 0,
      },
    ],

    Overcurrent_Protection_50_1_Pickup_Setting: 0,
    Overcurrent_Protection_50_1_Delay_Setting: 0,

    Overcurrent_Protection_Definite_Time: [
      {
        phases: "R",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "Y",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "B",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "RYB",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
    ],

    Overcurrent_Protection_51_TOC_Pickup_Setting: 0,
    Overcurrent_Protection_51_TOC_Time_Dial_Setting: 0,
    Overcurrent_Protection_Inverse_Time: [
      {
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
      },
      {
        phases: "R",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
      {
        phases: "Y",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
      {
        phases: "B",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
      {
        phases: "RYB",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
    ],
    Earth_Fault_Protection_50N_1_Pickup: 0,
    Earth_Fault_Protection_50N_1_Delay: 0,
    Earth_Fault_Protection_Definite_Time: [
      {
        phases: "R-N",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "Y-N",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "B-N",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
    ],
    Earth_Fault_Protection_51N_TOC_Pickup: 0,
    Earth_Fault_Protection_51N_TOC_Time_Dial: 0,
    Earth_Fault_Protection_Inverse_Time: [
      {
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
      },
      {
        phases: "R-N",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
      {
        phases: "Y-N",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
      {
        phases: "B-N",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
    ],
    Under_Voltage_Protection_Pickup: 0,
    Under_Voltage_Protection_Delay: 0,
    Under_Voltage_Protection: [
      {
        phases: "R",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "Y",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "B",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "RYB",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
    ],
    Over_Voltage_Protection_Pickup: 0,
    Over_Voltage_Protection_Delay: 0,
    Over_Voltage_Protection: [
      {
        phases: "R",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "Y",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "B",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "RYB",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
    ],
    Breaker_Failure_Protection_Final_Checklist: [
      {
        label: "BINARY INPUTS",
        options: "",
      },
      {
        label: "BINARY OUTPUTS",
        options: "",
      },
      {
        label: "LED INDICATORS",
        options: "",
      },
      {
        label: "Tripping of CB / Lockout Relay Checked",
        options: "",
        colspan: true,
      },
      {
        label: "Final Settings Downloaded to Relay after Test",
        options: "",
        colspan: true,
      },
      {
        label: "Relay found Healthy",
        options: "",
        colspan: true,
      },
    ],
    Breaker_Failure_Protection: "",
  };
  const [formData, setFormData] = useState(InitialState);
  const { PWTDate, PWTNumber, EquipmentDetailId, OverAllRemarksList } =
    PeriodicModel;
  useEffect(() => {
    setPeriodicModel({
      PeriodicCheckListMaintenanceID,
      DepartmentID,
      FromDate: fromdate,
      EndDate: enddate,
      PWTDate: "",
      EquipmentDetailId: EquipmentDetails[0]?.value ?? 0,
      Location: "",
      PWTNumber: "",
    });
    localStorage.setItem("FilePaths", "");
    localStorage.setItem("isAlreadyUploaded", "");
    return () => {
      //   setUGSColumnList([]);
      //   setUGSRowList([]);
      localStorage.setItem("FilePaths", "");
    };
  }, []);

  useEffect(() => {
    GetDescriptionByID();
  }, [PeriodicCheckListMaintenanceID, EquipmentDetailId]);

  const GetDescriptionByID = () => {
    showLoading();
    AuditingProcess_GetPeriodicDetailsByID(
      PeriodicCheckListMaintenanceID,
      EquipmentDetailId
    )
      .then((res) => {
        hideLoading();
        // convertColum(res);
        if (res?.data?.data?.length) {
          debugger;
          const { data } = res.data;
          if (data?.length) {
            const Obj = data[0];

            let FinalObj = {};
            let ParseNeedProp = [
              "CT_PT_Testing_Current",
              "CT_PT_Testing_Voltage",
              "Overcurrent_Protection_Definite_Time",
              "Overcurrent_Protection_Inverse_Time",
              "Earth_Fault_Protection_Definite_Time",
              "Earth_Fault_Protection_Inverse_Time",
              "Under_Voltage_Protection",
              "Over_Voltage_Protection",
              "Breaker_Failure_Protection_Final_Checklist",
            ];

            Object.keys(Obj).forEach((ele) => {
              if (Obj[ele]) {
                if (ParseNeedProp.includes(ele)) {
                  FinalObj[ele] = JSON.parse(Obj[ele]);
                } else {
                  FinalObj[ele] = Obj[ele];
                }
              } else {
                FinalObj[ele] = InitialState[ele];
              }
            });
            setFormData(FinalObj);

            // setFormData((pre) => ({
            //   ...pre,
            //   ...data[0],
            //   CT_PT_Testing_Current: CT_PT_Testing_Current ? JSON.parse(CT_PT_Testing_Current) : InitialState.CT_PT_Testing_Current,
            //   CT_PT_Testing_Voltage: CT_PT_Testing_Voltage ? JSON.parse(CT_PT_Testing_Voltage) : InitialState.CT_PT_Testing_Voltage,
            //   Overcurrent_Protection_Definite_Time: Overcurrent_Protection_Definite_Time ? JSON.parse(Overcurrent_Protection_Definite_Time) : InitialState.Overcurrent_Protection_Definite_Time,
            //   Overcurrent_Protection_Inverse_Time: Overcurrent_Protection_Inverse_Time ? JSON.parse(Overcurrent_Protection_Inverse_Time) : InitialState.Overcurrent_Protection_Inverse_Time,
            //   Earth_Fault_Protection_Definite_Time: Earth_Fault_Protection_Definite_Time ? JSON.parse(Earth_Fault_Protection_Definite_Time) : InitialState.Earth_Fault_Protection_Definite_Time,
            //   Earth_Fault_Protection_Inverse_Time: Earth_Fault_Protection_Inverse_Time ? JSON.parse(Earth_Fault_Protection_Inverse_Time) : InitialState.Earth_Fault_Protection_Inverse_Time,
            //   Under_Voltage_Protection: Under_Voltage_Protection ? JSON.parse(Under_Voltage_Protection) : InitialState.Under_Voltage_Protection,
            //   Over_Voltage_Protection: Over_Voltage_Protection ? JSON.parse(Over_Voltage_Protection) : InitialState.Over_Voltage_Protection,
            //   Breaker_Failure_Protection_Final_Checklist: Breaker_Failure_Protection_Final_Checklist ? JSON.parse(Breaker_Failure_Protection_Final_Checklist) : InitialState.Breaker_Failure_Protection_Final_Checklist
            // }));
          } else {
            setFormData(InitialState);
          }
        }
        else{
            setFormData(InitialState);
        }
      })
      .catch((err) => {
        console.log(err);
        hideLoading();
      });
  };
  const RejectionDetails = (selectedModel, handleRefresh) => {
    const [DropdownModel, setdropdownModel] = useState([]);
    const [approveModel, setapproveModel] = useState({
      level: 0,
      Remarks: "",
    });

    useEffect(() => {
      DropDown();

      function DropDown() {
        AuditingProcess_Getdropdown().then((res) => {
          if (
            res != null &&
            res.data.data.dropdownList != null &&
            res.data.data.dropdownList.length > 0
          ) {
            setdropdownModel(res.data.data.dropdownList);
          }
        });
      }
    }, []);

    const CommonHandleChange = (e) => {
      const { name, value } = e.target;
      setapproveModel((pre) => ({
        ...pre,
        [name]: value,
      }));
    };
    const rejectAction = (e) => {
      e.preventDefault();
      if (selectedModel != null) {
        if (approveModel.level != 0) {
          let Obj = selectedModel.selectedModel;
          let model = {};
          model.ModuleType = Obj.Type;
          model.ID = Obj.PeriodicCheckListMaintenanceID;
          model.Status = Obj.Type == "Preventive Maintenance" ? 4 : 105;
          model.RejectionStatus = approveModel.level;
          model.Remarks = approveModel.Remarks;
          model.SavedBy = GetLoginUserID();
          model.StationID = Obj.StationID;
          model.RoleID = getCookies(COOKIES.UserRoleID);

          AuditingProcess_ApproveReject(model)
            .then((response) => {
              const { data } = response?.data;
              if (data == "-1" || data == null) {
                showSaveError();
                handleRefresh();
              } else {
                showSaveSuccess();
              }
              GetDescriptionByID();
              $(`#closeRejection_`).click();
            })
            .catch((err) => {
              console.log(err);
              GetDescriptionByID();
              $(`#closeRejection_`).click();
            });
        } else {
          showWarningMsg("Please select Rejection level");
        }
      }
    };

    return (
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="stationCode">
              Risk levels<span className="text-danger"> *</span>{" "}
            </label>
            <WithSelectDropDown
              data={DropdownModel}
              placeholder="Select"
              name={"level"}
              HandleChange={CommonHandleChange}
              value={approveModel.level}
              className=""
            />
            {/* <span className="text-danger"> {errors.DepartmentID} </span> */}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="remarks">
              Remarks
            </label>
            <textarea
              name="Remarks"
              className="form-control"
              id="remarks"
              value={approveModel.Remarks}
              onChange={CommonHandleChange}
              rows="3"
              placeholder="Enter remarks here..."
            ></textarea>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-danger"
              type="submit"
              onClick={rejectAction}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    );
  };
  const ApproveDetails = (selectedModel, handleRefresh) => {
    const [approverRemarks, setapproverRemarks] = useState("");

    const appproveAction = () => {
      if (selectedModel != null) {
        let Obj = selectedModel.selectedModel;
        let model = {};
        model.ModuleType = Obj.Type;
        model.ID = Obj.PeriodicCheckListMaintenanceID;
        model.Status = Obj.Type == "Preventive Maintenance" ? 3 : 113;
        model.Remarks = approverRemarks.value;
        model.SavedBy = GetLoginUserID();
        model.StationID = Obj.StationID;
        model.RoleID = getCookies(COOKIES.UserRoleID);

        AuditingProcess_ApproveReject(model)
          .then((response) => {
            const { data } = response?.data;
            if (data == "-1" || data == null) {
              showSaveError();
              handleRefresh();
            } else {
              showSaveSuccess();
            }
            navigate(-1);
            $(`#closeApproval_`).click();
          })
          .catch((err) => {
            console.log(err);
            GetDescriptionByID();
            $(`#closeApproval_`).click();
          });
      }
    };

    const CommonHandleChange = (e) => {
      const { value } = e.target;
      setapproverRemarks(() => ({
        value,
      }));
    };

    return (
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="remarks">
              Remarks
            </label>
            <textarea
              className="form-control"
              id="remarks"
              rows="3"
              placeholder="Enter remarks here..."
              onChange={CommonHandleChange}
            ></textarea>
          </div>
        </div>
        <div className="col-md-6 d-flex justify-content-between align-items-center">
          <button className="btn btn-success" onClick={appproveAction}>
            Approve
          </button>
        </div>
      </div>
    );
  };
  const CalculationTimeFormula = (Amp, TMS) => {
    return (TMS * (0.14 / (Math.pow(Amp, 0.02) - 1)))?.toFixed(2) ?? 0;
  };
  const OverAllRemarksComponent = () => {
    return (
      <>
        <div class="chats">
          {OverAllRemarksList?.length
            ? OverAllRemarksList.map((ele) => {
                console.log(ele);
                return (
                  <>
                    <div class="chat-line">
                      <span class="chat-date">
                        {ele.EmployeeName} - {ele.StatusName}
                      </span>
                    </div>
                    <div class="chat chat-left">
                      <div class="chat-body">
                        <div class="chat-bubble">
                          <div class="chat-content">
                            <p className="text-black text-sm">
                              {ele.Remarks?.length > 0 ? ele.Remarks : "-"}{" "}
                            </p>

                            <span
                              class="chat-time pull-right text-small"
                              style={{ fontSize: "8px" }}
                            >
                              {ele.FormattedDate}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            : ""}
        </div>
      </>
    );
  };
  const relayInputs = ["IL1", "IL2", "IL3", "IN"];
  const voltageFields = ["Va", "Vb", "Vc", "Vab", "Vbc", "Vca"];
  const NormalDisplayList = [
    {
      key: "Checked",
      value: "Checked",
    },
    {
      key: "UnChecked",
      value: "UnChecked",
    },
  ];
  const CommonHandleChange = async (event, CusName) => {
   
    if (CusName) {
      if (CusName == "EquipmentDetailId") {
        setPeriodicModel({
          [CusName]: event,
          isBreakDown: event.EquipAvailStatus,
        });
      }
    } else {
      const { name, value } = event.target;
      if (name == "FileObj") {
        event.preventDefault();
        const formData = new FormData();
        $.each(event.target.files, function (i, file) {
          formData.append("files", file);
        });
        if (event.target?.files.length > 0) {
          let returnValue = await fileUpload(formData);
          if (returnValue) {
            let FilePaths = localStorage.getItem("FilePaths");
            if (FilePaths) {
              FilePaths = JSON.parse(FilePaths);
            } else FilePaths = [];
            // let FilePaths = CopyFileObj.FilePaths;

            if (!Array.isArray(FilePaths)) {
              FilePaths = [];
            }

            returnValue.forEach((ele) => (ele.Type = "UPLOAD"));
            FilePaths = FilePaths.concat(returnValue);
            localStorage.setItem("FilePaths", JSON.stringify(FilePaths));
          }
        }
      } else {
        if (name == "ApprovalRemarks") {
          $(".approveRemarks").removeClass("border-danger");
        }
        setPeriodicModel({ [name]: value });
      }
    }
  };
  const fileUpload = async (formData) => {
    showLoading();
    return await _breakdownService
      .FileUpload(formData)
      .then((res) => {
        hideLoading();
        showSuccessMsg("Files Uploaded SuccessFully.");
        return res;
      })
      .catch((err) => {
        hideLoading();
        showErrorMsg("Something Went Wrong! Please Try Again.");

        console.log(err);
        return 0;
      });
  };
  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row d-flex justify-content-between">
            <div className="col-sm-3 mb-2 text-start">
              <button
                className="btn btn-success"
                style={{ backgroundColor: "#b3b3b3", borderColor: "#b3b3b3" }}
                onClick={() => navigate(-1)}
              >
                <RiArrowGoBackLine /> Go Back
              </button>
            </div>
            {formData.IsApproved === 1 ? (
  <div className="col-sm-6 mb-2 text-end">
    <button
      className="btn btn-success m-l-5"
      data-bs-toggle="modal"
      data-bs-target="#Approve_"
      title="Approve"
    >
      <span>
        <IoMdCheckmark /> Approve
      </span>
    </button>

    <button
      className="btn btn-danger m-l-5"
      title="Reject"
      data-bs-toggle="modal"
      data-bs-target="#Rejection_"
    >
      <span>
        <IoClose /> Reject
      </span>
    </button>
  </div>
) : null}

          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label className="label" for="empCode">
                  PWT Number
                </label>{" "}
                <input
                  className="form-control PWTNumber"
                  type="text"
                  name="PWTNumber"
                  disabled={getUserDetails().RoleID !== getL0RoleID()}
                    value={formData.PWTNumber}
                  //   onChange={CommonHandleChange}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label className="label" for="empCode">
                  PWT Date
                </label>{" "}
                <input
                  className="form-control PWTDate"
                  type="date"
                  name="PWTDate"
                  disabled={getUserDetails().RoleID !== getL0RoleID()}
                  //   onChange={CommonHandleChange}
                    value={formData.PWTDate}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label className="label" for="empCode">
                  EquipmentCode{" "}
                </label>{" "}
                <Dropdown
                  className="form-control"
                  data={EquipmentDetails ?? []}
                  name="EquipmentDetailId"
                    value={EquipmentDetailId}
                    HandleChange={CommonHandleChange}
                />
              </div>
            </div>
          </div>

          <div className="card card-body">
            <div className="row">
              <div className="col-2 ">
                <label className="label d-flex">
                  Feeder No <span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="FeederNo"
                  style={{ height: "40px" }}
                  value={formData.FeederNo}
                />{" "}
              </div>
              <div className="col-2 ">
                <label className="label d-flex">
                  SL No <span className="text-danger">*</span>{" "}
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="SLNo"
                  style={{ height: "40px" }}
                  value={formData.SLNo}
                />{" "}
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <h5 className="mt-3">CT & PT Ratings :</h5>
                <div className="table-responsive">
                  <table
                    className="responsive"
                    style={{ width: "100%" }}
                    cellpadding="10"
                  >
                    <tr>
                      <td>CT Rated Primary Current (A)</td>
                      <td>
                        {" "}
                        <input
                          type="number"
                          className="form-control"
                          name="CT_Rated_Primary_Current"
                          value={formData.CT_Rated_Primary_Current}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>CT Rated Secondary Current (A)</td>
                      <td>
                        {" "}
                        <input
                          type="number"
                          className="form-control"
                          name="CT_Rated_Secondary_Current"
                          value={formData.CT_Rated_Secondary_Current}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Rated Primary Voltage (L-L) (KV)</td>
                      <td>
                        {" "}
                        <input
                          type="number"
                          className="form-control"
                          name="Rated_Primary_Voltage"
                          value={formData.Rated_Primary_Voltage}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Rated Secondary Voltage (L-L) (V)</td>
                      <td>
                        {" "}
                        <input
                          type="number"
                          className="form-control"
                          name="Rated_Secondary_Voltage"
                          value={formData.Rated_Secondary_Voltage}
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <h5>CT & PT Testing :</h5>
              <p className="mt-2">
                Inject current in the relay as per the table below. Observe the
                reading on the relay display, in primary values.{" "}
              </p>
              <table
                className="phase-table"
                border="1"
                style={{ borderCollapse: "collapse", width: "100%" }}
              >
                <thead>
                  <tr>
                    <th rowSpan="3">Phase</th>
                    <th rowSpan="2">Current Injected (A)</th>
                    <th colSpan="4">Primary Current Displayed on Relay (A)</th>
                  </tr>
                  <tr>
                    {relayInputs.map((input) => (
                      <th key={input}>{input}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {formData?.CT_PT_Testing_Current?.map((phase, index) => (
                    <tr key={index}>
                      <td>{phase.phases}</td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="CurrentInjected"
                          value={phase.CurrentInjected}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="IL1"
                          value={phase?.IL1}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="IL2"
                          value={phase.IL2}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="IL3"
                          value={phase.IL3}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="IN"
                          value={phase.IN}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="mt-3">
                <p>
                  Inject voltage in the relay as per the table below. Observe
                  the reading on the relay display, in primary values.
                </p>
                <table
                  className="voltage-table"
                  border="1"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Phase</th>
                      {voltageFields.map((field) => (
                        <th key={field}>{field}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.CT_PT_Testing_Voltage?.map((phase, index) => (
                      <tr key={index}>
                        <td>{phase.phases}</td>

                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Va"
                            value={phase.Va}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Vb"
                            value={phase.Vb}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Vc"
                            value={phase.Vc}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Vab"
                            value={phase.Vab}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Vbc"
                            value={phase.Vbc}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Vca"
                            value={phase.Vca}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-3">
              <h4>RELAY FUNCTIONAL TEST :</h4>
              <h5 className="mt-2">1. Overcurrent Protection (50-1,51-TOC)</h5>
              <h5>Definite Time:</h5>
              <div className="row">
                <div className="col-3 d-flex flex-column">
                  <label className="label">50-1 Pickup Setting (A):</label>
                  <input
                    type="number"
                    name="Overcurrent_Protection_50_1_Pickup_Setting"
                    className="form-control"
                    value={formData?.Overcurrent_Protection_50_1_Pickup_Setting}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        Overcurrent_Protection_50_1_Pickup_Setting: parseFloat(
                          e.target.value
                        ),
                      }))
                    }
                  />
                </div>
                <div className="col-3 d-flex flex-column">
                  <label className="label"> 50-1 Delay Setting (S): </label>
                  <input
                    type="number"
                    className="form-control"
                    name="Overcurrent_Protection_50_1_Delay_Setting"
                    value={formData.Overcurrent_Protection_50_1_Delay_Setting}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        Overcurrent_Protection_50_1_Delay_Setting: parseFloat(
                          e.target.value
                        ),
                      }))
                    }
                  />
                </div>
              </div>
              <p className="mt-2">
                Note: IDMT stage is disabled while testing DMT stage.
              </p>
              <table
                className="current-trip-table"
                border="1"
                style={{ borderCollapse: "collapse", width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Phase</th>
                    <th>Actual Injected Current (A)</th>
                    <th>Trip Time (From Relay) (ms)</th>
                  </tr>
                </thead>
                <tbody>
                  {formData?.Overcurrent_Protection_Definite_Time?.map(
                    (phase, index) => (
                      <tr key={index}>
                        <td>{phase.phases}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Actual_injected_current_A"
                            value={phase.Actual_injected_current_A}
                            // onChange={(e) =>
                            //   handleInputChange(
                            //     e,
                            //     index,
                            //     "Overcurrent_Protection_Definite_Time"
                            //   )
                            // }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Trip_Time_From_Relay_ms"
                            value={phase.Trip_Time_From_Relay_ms}
                            // onChange={(e) =>
                            //   handleInputChange(
                            //     e,
                            //     index,
                            //     "Overcurrent_Protection_Definite_Time"
                            //   )
                            // }
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <div className="mt-3">
                <h5>Inverse Time:</h5>
                <div className="row">
                  <div className="col-3 d-flex flex-column">
                    <label className="label">IEC Curve: </label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={"Normal Inverse"}
                    />
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <label className="label">
                      {" "}
                      51-TOC Pickup Setting (A) :
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={
                        formData.Overcurrent_Protection_51_TOC_Pickup_Setting
                      }
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          Overcurrent_Protection_51_TOC_Pickup_Setting:
                            parseFloat(e.target.value),
                        }))
                      }
                    />
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <label className="label">
                      {" "}
                      51-TOC Time Dial Setting (S) :{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={
                        formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                      }
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          Overcurrent_Protection_51_TOC_Time_Dial_Setting:
                            parseFloat(e.target.value),
                        }))
                      }
                    />
                  </div>
                </div>
                <p className="mt-3">
                  {" "}
                  Note: DMT stage is disabled while testing IDMT stage.
                </p>

                <table
                  className="trip-details-table"
                  border="1"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Phase</th>
                      <th colspan="4">Current Injected (A)</th>
                      <th colspan="8">
                        Operating Time (Trip Time from Relay) (ms)
                      </th>
                    </tr>

                    <tr>
                      <th></th>
                      <th colspan="4">
                        Current Injected should be below no of times Ip
                      </th>
                      {Object.entries(
                        formData?.Overcurrent_Protection_Inverse_Time?.[0]
                      ).map((ele, index) => {
                        const name = ele[0];
                        const value = ele[1];
                        const mutiplyIp =
                          value *
                          formData.Overcurrent_Protection_51_TOC_Pickup_Setting;

                        return <th colSpan="2">{mutiplyIp}</th>;
                      })}
                    </tr>

                    <tr>
                      <th></th>
                      {Object.entries(
                        formData?.Overcurrent_Protection_Inverse_Time?.[0]
                      ).map((ele, index) => {
                        const name = ele[0];
                        const value = ele[1];

                        return (
                          <th>
                            {" "}
                            <input
                              type="number"
                              name={name}
                              value={value}
                              className="form-control"
                            />
                          </th>
                        );
                      })}

                      <th>Calc. Time-1</th>
                      <th>Trip Time </th>
                      <th>Calc. Time-2</th>
                      <th>Trip Time</th>
                      <th>Calc. Time-3</th>
                      <th>Trip Time </th>
                      <th>Calc. Time-4</th>
                      <th>Trip Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.Overcurrent_Protection_Inverse_Time?.map(
                      (phase, index) => {
                        let Ambiverts =
                          formData.Overcurrent_Protection_Inverse_Time[0];
                        let Ambiverts_1 = Ambiverts.A1;
                        let Ambiverts_2 = Ambiverts.A2;
                        let Ambiverts_3 = Ambiverts.A3;
                        let Ambiverts_4 = Ambiverts.A4;
                        if (index != 0) {
                          return (
                            <tr key={index}>
                              <td>{phase.phases}</td>

                              {Object.entries(
                                formData
                                  ?.Overcurrent_Protection_Inverse_Time?.[0]
                              ).map((ele, index) => {
                                const value = ele[1];
                                const multiplyIp =
                                  value *
                                  formData.Overcurrent_Protection_51_TOC_Pickup_Setting;
                                return <td>{multiplyIp}</td>;
                              })}

                              {/* <td>{78 * Ambiverts_1}</td> */}
                              <td>
                                {CalculationTimeFormula(
                                  Ambiverts_1,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time1}
                                  name="Trip_Time1"
                                  className="form-control"
                                />
                              </td>

                              <td>
                                {" "}
                                {CalculationTimeFormula(
                                  Ambiverts_2,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time2}
                                  name="Trip_Time2"
                                  className="form-control"
                                />
                              </td>

                              <td>
                                {" "}
                                {CalculationTimeFormula(
                                  Ambiverts_3,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time3}
                                  name="Trip_Time3"
                                  className="form-control"
                                />
                              </td>
                              <td>
                                {" "}
                                {CalculationTimeFormula(
                                  Ambiverts_4,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time4}
                                  name="Trip_Time4"
                                  className="form-control"
                                />
                              </td>
                            </tr>
                          );
                        }
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-3">
              <h5 className="mt-2">
                {" "}
                2. Earth fault Protection (50N-1,51N-TOC)
              </h5>
              <h5>Definite Time:</h5>
              <div className="row">
                <div className="col-3 d-flex flex-column">
                  <label className="label">50N-1 Pickup Setting (A) :</label>
                  <input
                    type="number"
                    className="form-control"
                    value={formData.Earth_Fault_Protection_50N_1_Pickup}
                  />
                </div>
                <div className="col-3 d-flex flex-column">
                  <label className="label">50N-1 Delay Setting (S) : </label>
                  <input
                    type="number"
                    className="form-control"
                    value={formData.Earth_Fault_Protection_50N_1_Delay}
                  />
                </div>
              </div>
              <p className="mt-2">
                Note: IDMT stage is disabled while testing DMT stage.
              </p>
              <table
                className="current-trip-table"
                border="1"
                style={{ borderCollapse: "collapse", width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Phase</th>
                    <th>Actual Injected Current (A)</th>
                    <th>Trip Time (From Relay) (ms)</th>
                  </tr>
                </thead>
                <tbody>
                  {formData?.Earth_Fault_Protection_Definite_Time?.map(
                    (phase, index) => (
                      <tr key={index}>
                        <td>{phase.phases}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Actual_injected_current_A"
                            value={phase.Actual_injected_current_A}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Trip_Time_From_Relay_ms"
                            value={phase.Trip_Time_From_Relay_ms}
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <div className="mt-3">
                <h5>Inverse Time:</h5>
                <div className="row">
                  <div className="col-3 d-flex flex-column">
                    <label className="label">IEC Curve: </label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={"Normal Inverse"}
                    />
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <label className="label">
                      {" "}
                      51N-TOC Pickup Setting (A) :
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={formData.Earth_Fault_Protection_51N_TOC_Pickup}
                    />
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <label className="label">
                      {" "}
                      51N-TOC Time Dial Setting (s) :{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={formData.Earth_Fault_Protection_51N_TOC_Time_Dial}
                    />
                  </div>
                </div>
                <p className="mt-3">
                  {" "}
                  Note: DMT stage is disabled while testing IDMT stage.
                </p>

                <table
                  className="trip-details-table"
                  border="1"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Phase</th>
                      <th colspan="4">Current Injected (A)</th>
                      <th colspan="8">
                        Operating Time (Trip Time from Relay) (ms)
                      </th>
                    </tr>

                    <tr>
                      <th></th>
                      <th colspan="4">
                        Current Injected should be below no of times Ip
                      </th>
                      {Object.entries(
                        formData?.Earth_Fault_Protection_Inverse_Time?.[0]
                      ).map((ele, index) => {
                        const name = ele[0];
                        const value = ele[1];
                        const mutiplyIp =
                          value *
                          formData.Overcurrent_Protection_51_TOC_Pickup_Setting;

                        return <th colSpan="2">{mutiplyIp}</th>;
                      })}
                    </tr>

                    <tr>
                      <th></th>
                      {Object.entries(
                        formData?.Earth_Fault_Protection_Inverse_Time?.[0]
                      ).map((ele, index) => {
                        const name = ele[0];
                        const value = ele[1];

                        return (
                          <th>
                            {" "}
                            <input
                              type="number"
                              name={name}
                              value={value}
                              className="form-control"
                            />
                          </th>
                        );
                      })}

                      <th>Calc. Time-1</th>
                      <th>Trip Time </th>
                      <th>Calc. Time-2</th>
                      <th>Trip Time</th>
                      <th>Calc. Time-3</th>
                      <th>Trip Time </th>
                      <th>Calc. Time-4</th>
                      <th>Trip Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.Earth_Fault_Protection_Inverse_Time?.map(
                      (phase, index) => {
                        let Ambiverts =
                          formData.Earth_Fault_Protection_Inverse_Time[0];
                        let Ambiverts_1 = Ambiverts.A1;
                        let Ambiverts_2 = Ambiverts.A2;
                        let Ambiverts_3 = Ambiverts.A3;
                        let Ambiverts_4 = Ambiverts.A4;
                        if (index != 0) {
                          return (
                            <tr key={index}>
                              <td>{phase.phases}</td>

                              {Object.entries(
                                formData
                                  ?.Earth_Fault_Protection_Inverse_Time?.[0]
                              ).map((ele, index) => {
                                const value = ele[1];
                                const multiplyIp =
                                  value *
                                  formData.Earth_Fault_Protection_51N_TOC_Pickup;
                                return <td>{multiplyIp}</td>;
                              })}

                              {/* <td>{78 * Ambiverts_1}</td> */}
                              <td>
                                {CalculationTimeFormula(
                                  Ambiverts_1,
                                  formData.Earth_Fault_Protection_51N_TOC_Time_Dial
                                )}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time1}
                                  name="Trip_Time1"
                                  className="form-control"
                                />
                              </td>

                              <td>
                                {" "}
                                {CalculationTimeFormula(
                                  Ambiverts_2,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time2}
                                  name="Trip_Time2"
                                  className="form-control"
                                />
                              </td>

                              <td>
                                {" "}
                                {CalculationTimeFormula(
                                  Ambiverts_3,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time3}
                                  name="Trip_Time3"
                                  className="form-control"
                                />
                              </td>
                              <td>
                                {" "}
                                {CalculationTimeFormula(
                                  Ambiverts_4,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time4}
                                  name="Trip_Time4"
                                  className="form-control"
                                />
                              </td>
                            </tr>
                          );
                        }
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-3">
              <h5> 3. Under Voltage Protection (27):</h5>
              <h5>Definite Time:</h5>
              <div className="row">
                <div className="col-3 d-flex flex-column">
                  <label className="label">27 Pickup Setting (V) :</label>
                  <input
                    type="number"
                    name="Under_Voltage_Protection_Pickup"
                    className="form-control"
                    value={formData.Under_Voltage_Protection_Pickup}
                  />
                </div>
                <div className="col-3 d-flex flex-column">
                  <label className="label"> 27 Delay Setting (S): </label>
                  <input
                    type="number"
                    name="Under_Voltage_Protection_Delay"
                    className="form-control"
                    value={formData.Under_Voltage_Protection_Delay}
                  />
                </div>
              </div>

              <table
                className="current-trip-table mt-3"
                border="1"
                style={{ borderCollapse: "collapse", width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Phase</th>
                    <th>Actual Injected Current (A)</th>
                    <th>Trip Time (From Relay) (ms)</th>
                  </tr>
                </thead>
                <tbody>
                  {formData?.Under_Voltage_Protection?.map((phase, index) => (
                    <tr key={index}>
                      <td>{phase.phases}</td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="Actual_injected_current_A"
                          value={phase.Actual_injected_current_A}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="Trip_Time_From_Relay_ms"
                          value={phase.Trip_Time_From_Relay_ms}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-3">
              <h5>4. Over Voltage Protection (59):</h5>
              <div>
                <h5>Definite Time:</h5>
                <div className="row">
                  <div className="col-3 d-flex flex-column">
                    <label className="label">59 Pickup Setting (V) :</label>
                    <input
                      type="number"
                      name="Under_Voltage_Protection_Pickup"
                      className="form-control"
                      value={formData.Over_Voltage_Protection_Pickup}
                    />
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <label className="label"> 59 Delay Setting (S) : </label>
                    <input
                      type="number"
                      name="Under_Voltage_Protection_Pickup"
                      className="form-control"
                      value={formData.Over_Voltage_Protection_Delay}
                    />
                  </div>
                </div>

                <table
                  className="current-trip-table mt-3"
                  border="1"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Phase</th>
                      <th>Actual Injected Current (A)</th>
                      <th>Trip Time (From Relay) (ms)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.Over_Voltage_Protection?.map((phase, index) => (
                      <tr key={index}>
                        <td>{phase.phases}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Actual_injected_current_A"
                            value={phase.Actual_injected_current_A}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Trip_Time_From_Relay_ms"
                            value={phase.Trip_Time_From_Relay_ms}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="mt-3">
              <h5>5. Breaker Failure Protection (50BF)</h5>
              <div>
                <div className="row">
                  <div className="col-8 d-flex">
                    <label className="label">
                      (Disabled during other protection testing. Enabled after
                      testing before charging the feeder).
                    </label>
                    <WithSelectDropDown
                      data={NormalDisplayList}
                      placeholder="Select"
                      name="Breaker_Failure_Protection"
                      initialVal={-1}
                      value={formData.Breaker_Failure_Protection || -1}
                      
                    />
                  </div>
                </div>

                <table className="relay-table mt-3">
                  <tbody>
                    {formData.Breaker_Failure_Protection_Final_Checklist?.map(
                      (item, index) => (
                        <tr key={index}>
                          {/* Conditionally handle colspan */}
                          {item.colspan ? (
                            <>
                              <td colSpan="2">{item.label}</td>
                              <td>
                                <WithSelectDropDown
                                  data={NormalDisplayList}
                                  placeholder="Select"
                                  name="Breaker_Failure_Protection_Final_Checklist"
                                  initialVal={-1}
                                  value={
                                    formData
                                      .Breaker_Failure_Protection_Final_Checklist[
                                      index
                                    ]?.options || -1
                                  }
                                  
                                />
                              </td>
                            </>
                          ) : (
                            <>
                              <td>{item.label}</td>
                              <td>
                                {item.options.value} as per Relay Assignment
                              </td>
                              <td>
                                <WithSelectDropDown
                                  data={NormalDisplayList}
                                  placeholder="Select"
                                  name="Breaker_Failure_Protection_Final_Checklist"
                                  initialVal={-1}
                                  value={
                                    formData
                                      .Breaker_Failure_Protection_Final_Checklist[
                                      index
                                    ]?.options || -1
                                  }
                                
                                />
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                 
                </table>
              </div>
            </div>
          </div>

          <div className="card card-body">
            {/* <h4 className='text-center'> Used Spare</h4> */}
            {/* <SpareList
              IsAdd={getUserDetails().RoleID == getL0RoleID()}
            //   Equlist={equipmentDetailsList}
              saveSpare={saveSpare}
              SelectedSpareList={spareList}
            ></SpareList> */}
            <br></br>
            {/* {getUserDetails().RoleID == getL0RoleID() && (
              <div className="row d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary col-md-2 col-sm-2"
                  onClick={SaveSpareData}
                >
                  Save
                </button>
              </div>
            )} */}
          </div>

          <BootrapModel
            module={<OverAllRemarksComponent />}
            ModelTitle={"Over All Remarks"}
            modelSize={"modal-lg"}
            CloseID={"CloseOverAllRemarksComponent"}
            Id={"OverAllRemarksComponent"}
          ></BootrapModel>

          <BootrapModel
            Id={`Rejection_`}
            modelSize={"modal-xs"}
            CloseID={`closeRejection_`}
            ModelTitle={header}
            module={
              <RejectionDetails
                // handleRefresh={handleRefresh}
                selectedModel={selectedModel}
              />
            }
            // closepopup={handleRefresh}
          />
          <BootrapModel
            Id={`Approve_`}
            modelSize={"modal-xs"}
            ModelTitle={header}
            CloseID={`closeApproval_`}
            module={
              <ApproveDetails
                // handleRefresh={handleRefresh}
                selectedModel={selectedModel}
              />
            }
            // closepopup={handleRefresh}
          />
        </div>
      </div>
    </>
  );
}

export default OvrCrt_EarthFaultRelay_110_33_View;
