import React from "react";
import { useState, useEffect, useContext } from "react";
import { Decrypt, makeUnique } from "../../services/CommonService";
import SCADAConfigService from "./SCADAConfigService";
import { AppContext } from "../../services/ContextProvider";
import Global from "../../services/Global";
import { ReactMultiSelect } from "../../components/MultiSelect/ReactMultiSelect";
import $ from "jquery";
import {
  showSaveError,
  showSaveSuccess,
  showWarningMsg,
  showDeleteSuccess,
} from "../../components/ToastMsg";

import { WithoutSelectDropDown } from "../../components/WithoutSelectDropDown";
const AddScada = ({
  EquipmentShortNameList,
  EquipmentCodeList,
  ParameterList,
  AlaramStatus,
  StatusList,
  scadaFlag,
  SetScadaFlag
}) => {
  console.log(ParameterList, "ParameterList");
  const SCADAConfigServiceobj = new SCADAConfigService();
  const [StationList, setStationList] = useState([]);
  const [StationID, SetStationID] = useState(0);
  const { showLoading, hideLoading, getCookies, handleGlobalStationName } =
    useContext(AppContext);
  const [equipmentShortNameDropDownList, SetEquipmentShortNameDropDownList] =
    useState([]);
  const [equipmentCodeDropDownList, SetEquipmentCodeDropDownList] = useState(
    []
  );
  const [parameterNameDropDownList, SetParameterNameDropDownList] = useState(
    []
  );

  const [equipmentId, setEquipmentId] = useState();
  const [parameterId, setParameterId] = useState();
  const [parameterName, SetParameterName] = useState();
  const [equipmentDetailsId, setEquipmentDetailsId] = useState();
  const [alaramStatus, SetAlaramStatus] = useState();
  const [routingrange, SetRoutingrange] = useState();
  const [validationFlag,SetValidationFlag]=useState(true)
  const [unit,SetUnit]=useState()
  // const[dropdown3,setDropdown3]=useState([])
  // const[dropdown4,setDropdown4]=useState([])
  useEffect(() => {
    GetEquipmentShortName(StationID);
  }, [StationID]);
  useEffect(() => {
    getStationList();
  }, [
    EquipmentShortNameList,
    EquipmentCodeList,
    ParameterList,
    AlaramStatus,
    StatusList,
  ]);
  const GetEquipmentShortName = () => {
    SCADAConfigServiceobj.GetEquipmentShortName(StationID).then((result) => {});
  };
  const getStationList = () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, label: data.StationCode });
    });
    setStationList(dataLst);
    //SetStationID(dataLst[0].value);
    
    if (
      AlaramStatus != null &&
      AlaramStatus != undefined &&
      AlaramStatus.length > 0
    ) {
      var id = AlaramStatus[0].value;
      SetAlaramStatus(id);
    }
    if (
      StatusList != null &&
      StatusList != undefined &&
      StatusList.length > 0
    ) {
      var id = StatusList[0].value;
      SetRoutingrange(id);
    }
    
    if (
      dataLst[0].value != null &&
      dataLst[0].value != undefined &&
      dataLst[0].value > 0
    ) {
      var id = dataLst[0].value;
      const dummyList =
        EquipmentShortNameList != null &&
        EquipmentShortNameList.length > 0 &&
        EquipmentShortNameList.filter((e) => e.StationID === id).map((x) => {
          return {
            label: x.EquipmentShortName,
            value: x.EquipmentID,
          };
        });
     
      SetEquipmentShortNameDropDownList(dummyList);
    }
    handleGlobalStationName(dataLst[0].label ?? "");
    console.log(dataLst, "stationname");
  };
  const HandleAlarmChange = (e) => {
    
    var selectedOption = e.target.options[e.target.selectedIndex];
    const id = parseInt(e.target.value);
    SetAlaramStatus(id);
  };
  const HandleRoutingChange = (e) => {
    
    var selectedOption = e.target.options[e.target.selectedIndex];
    const id = parseInt(e.target.value);
    SetRoutingrange(id);
  };
  const HandleChange = (e) => {
    $("#stationNameError").hide();
    // var selectedOption = e.target.options[e.target.selectedIndex];
    const id = parseInt(e.value);
    var selectedLabel = e.label;
    handleGlobalStationName(selectedLabel ?? "");
    SetStationID(e);
    if (id > 0) {
      const dummyList =
        EquipmentShortNameList != null &&
        EquipmentShortNameList.length > 0 &&
        EquipmentShortNameList.filter((e) => e.StationID === id).map((x) => {
          return {
            label: x.EquipmentShortName,
            value: x.EquipmentID,
          };
        });
        setEquipmentId(0)
        setEquipmentDetailsId(0)
        setParameterId(0)
      SetEquipmentShortNameDropDownList(dummyList);
    }
  };
  const HandleEquipmentCodeChange = (e) => {
    
    $("#equipmentCodeError").hide()
    const selectedOption = e.value;
    const id = parseInt(selectedOption); // Assuming e.text is a string representing the number
    setEquipmentDetailsId(e);

  };
  const HandleParameterNameChange = (e) => {
    
    $('#parameterNameErr').hide();
    const id = parseInt(e.value);
    const name = e.label;
    setParameterId(e);
    SetParameterName(name);
    if (id > 0 && ParameterList != null && ParameterList.length > 0) {
      const matchedParameter = ParameterList.find(
        (param) => param.ParameterID === id
      )
       if (matchedParameter) {
        const unitName = matchedParameter.ParameterUnit;
       
        SetUnit(unitName  ? unitName : ""); // Set the unit name to a text box field
      } else {
        // Handle the case where no matching parameter is found
        SetUnit('');
      }
    } 
  };
  const HandleShortNameChange = (e) => {
    $("#equipmentShortNameError").hide();
    var selectedOption = e.value;

    const id = parseInt(e.value);
    setEquipmentId(e);
    var selectedLabel = selectedOption.value;
    if (id > 0) {
      const dummyList =
        EquipmentCodeList != null &&
        EquipmentCodeList.length > 0 &&
        EquipmentCodeList.filter(
          (e) => e.StationID == StationID.value && e.EquipmentID === id
        ).map((x) => {
          return {
            label: x.EquipmentCode,
            value: x.EquipmentDetailID,
          };
        });
      SetEquipmentCodeDropDownList(dummyList);
      SelectParameter(id)
      setEquipmentDetailsId(0)
      setParameterId(0)
    }
  
  };
  const SelectParameter = (id) => {
    
    const SelectParameter = ParameterList?.filter((e) => e.EquipmentID === id).map((x) => {
       return {
         label: x.ParameterDisplayName,
         value: x.ParameterID,
       };
     });
     
     SetParameterNameDropDownList(SelectParameter);
  }

  const [errors, setErrors] = useState({
    parameterErr: false,
    Hz30Err: false,
    Hz50Err: false,
    emailErr: false,
    parameterRangeLowErr: false,
    parameterRangeHighErr: false,
    unitsErr: false,
    routingUsageErr: false,
    thresoldValueLowErr: false,
    thresoldValueLHighErr: false,
    scadaCodeErr: false,
  });
  const saveSCADA = (data) => {
    onSubmit(data);
  };
  function clearForm(){
    $('#scadaForm').trigger('reset');
          setEquipmentId(0)
          SetStationID(0)
          setEquipmentDetailsId(0)
          setParameterId(0)
          $('#stationNameError').hide()
          $('#equipmentShortNameError').hide()
          $('#equipmentCodeError').hide();
         
          $('#parameterNameErr').hide();
          SetUnit("")
  
  }
$('#addSacdaDetailsModelClose').click(clearForm);
  const onSubmit = (data) => {
    data.preventDefault();
    createSCADA(data);
  };
  function createSCADA(data) {
    
    var Flag= true
    if(equipmentId==undefined || equipmentId==0){
         $("#equipmentShortNameError").show()
      Flag=false
    }
    if(equipmentDetailsId==undefined || equipmentDetailsId==0){
      $("#equipmentCodeError").show()
   Flag=false
 }
 if(parameterId==undefined || parameterId==0){
  $('#parameterNameErr').show()
  Flag=false
 }
 if(StationID==undefined|| StationID==0){
  $('#stationNameError').show()
  Flag=false
 }


   
    if(Flag){
      console.log(equipmentDetailsId,parameterId,equipmentId)
      var scada = {
        EquipmentID: Number(equipmentId.value),
        ParameterName: parameterName,
        SCADAID: Number(0),
        EquipmentDetailID: Number(equipmentDetailsId.value),
        ParameterID: Number(parameterId.value),
        AT30Hz: Number(data.target.Hz30.value),
        AT50Hz: Number(data.target.Hz50.value),
        AcceptableParameterLow: Number(data.target.parameterRangeLow.value),
        AcceptableParameterHigh: Number(data.target.parameterRangeHigh.value),
        RoutineUsage: Number(routingrange),
        thresholdLow: Number(data.target.thresholdLow.value),
        thresholdHigh: Number(data.target.thresholdHigh.value),
        ScadaEquipmentCode: data.target.ScadaEquipmentCode.value,
        frequencyName: "",
        parameterUnit: unit,
        AlaramORTrand: Number(alaramStatus),
      };
      if(scada !=null && scada != undefined){
        SCADAConfigServiceobj.ScadaDetailesSave(scada).then((res) => {
      
          if(res.data>0){
            showSaveSuccess("Saved Sucessfully.");
            $("#addSacdaDetailsModelClose").click();  
            clearForm();
          }
          if(res.data==-1){
            showWarningMsg("Already Exists.");
           
          }
         
        })
        .catch((err) => {
          showSaveSuccess("Something Wrong please Try Again.");
          $("#add_Scada").prop("disabled", false);
          clearForm();
          // $(`#save-${data[0]}+${data[1]}`).attr("disabled", false);
         
        });
        
      }
   
    }
   
  }



const SCADACodeChange=()=>{

}
  return (
    <form onSubmit={saveSCADA} id="scadaForm">
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <label>
              Station Name <span className="text-danger">*</span>
            </label>
            {/* <WithoutSelectDropDown
              data={StationList}
              id="station"
              name={"StationIDDropDown"}
              HandleChange={HandleChange}
            ></WithoutSelectDropDown> */}

<ReactMultiSelect
              options={StationList}
              hideSelectedOptions={false}
              id={"station"}
              value={StationID}
              onChange={HandleChange}
            />
      <span id="stationNameError" style={{display:"none"}} className="text-danger">Please select a station</span>

          </div>
          {errors.parameterErr && (
            <p className="text-danger">Parameter is required</p>
          )}
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>
              Equipment Short Name <span className="text-danger">*</span>
            </label>

            <ReactMultiSelect
              options={equipmentShortNameDropDownList}
              hideSelectedOptions={false}
              id={"equipmentShortName"}
              value={equipmentId}
              onChange={HandleShortNameChange}
            />
            <span id="equipmentShortNameError" style={{display:"none"}} className="text-danger">Please select a equipment name</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>
              Equipment Code <span className="text-danger">*</span>
            </label>

            <ReactMultiSelect
              options={equipmentCodeDropDownList}
              hideSelectedOptions={false}
              value={equipmentDetailsId}
              onChange={HandleEquipmentCodeChange}
            />
   <span id="equipmentCodeError" style={{display:"none"}} className="text-danger">Please select a equipment code</span>

          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>
              Parameter Name <span className="text-danger">*</span>
            </label>

            <ReactMultiSelect
              options={parameterNameDropDownList}
              hideSelectedOptions={false}
              value={parameterId}
              onChange={HandleParameterNameChange}
            />
               <span id="parameterNameErr" style={{display:"none"}} className="text-danger">Please select a parameter name</span>

          </div>

        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>
              Alaram Status <span className="text-danger">*</span>
            </label>
            <WithoutSelectDropDown
              data={AlaramStatus}
              id="AlaramStatus"
              name={"AlaramStatusDropDown"}
              HandleChange={HandleAlarmChange}
            ></WithoutSelectDropDown>
          </div>
          {errors.parameterErr && (
            <p className="text-danger">parameter is required</p>
          )}
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>
              {" "}
              Comm. At 30hz
            </label>
            <input type="number" id="Hz30" class="form-control"></input>
            {errors.Hz30Err && <p className="text-danger">Hz is required</p>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>
              {" "}
              Comm. At 50hz 
            </label>
            <input type="number" class="form-control" id="Hz50"></input>
            {errors.Hz50Err && <p className="text-danger">Hz is required</p>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>
              {" "}
              Parameter range Low 
            </label>
            <input
              type="number"
              id="parameterRangeLow"
              class="form-control"
            ></input>
            {errors.parameterRangeLowErr && (
              <p className="text-danger">Range is required</p>
            )}
          </div>
        </div>

        <div className="col-md-3">
          <div className="form-group">
            <label>
              {" "}
              Parameter range High 
            </label>
            <input
              type="number"
              id="parameterRangeHigh"
              class="form-control"
            ></input>
            {errors.parameterRangeHighErr && (
              <p className="text-danger">Range is required</p>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>
              {" "}
              Unit
            </label>
            <input type="text" class="form-control" value={unit} disabled={!!unit} ></input>
            {errors.unitsErr && (
              <p className="text-danger">Units is required</p>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>
              {" "}
              Routing Usage 
            </label>
            <WithoutSelectDropDown
              data={StatusList}
              id="routingRange"
              name={"routingRangeDropDown"}
              HandleChange={HandleRoutingChange}
            ></WithoutSelectDropDown>
            {errors.routingUsageErr && (
              <p className="text-danger">Routing Usage is required</p>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>
              {" "}
              Thresold Value Low 
            </label>
            <input type="number" id="thresholdLow" class="form-control"></input>
            {errors.thresoldValueLowErr && (
              <p className="text-danger">Thresold Value is required</p>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>
              {" "}
              Thresold Value High 
            </label>
            <input
              type="number"
              id="thresholdHigh"
              class="form-control"
            ></input>
            {errors.thresoldValueLHighErr && (
              <p className="text-danger">First name is required</p>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>
              {" "}
              SCADA Equipment Code 
            </label>
            <input
              type="text"
              id="ScadaEquipmentCode"
              class="form-control"
              onChange={SCADACodeChange}
            ></input>
            <span id="scadaCodeErr" style={{display:"none"}} className="text-danger">Please Enter a SCADA Code</span>

          </div>
        </div>
        <div className="submit-section text-end">
          <button type="submit" className="btn btn-primary submit-btn" id="add_Scada">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddScada;
