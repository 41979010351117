import React, { useState, useContext } from "react";
import { useEffect } from "react";
import $ from "jquery";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { Link } from "react-router-dom";
import makeAnimated from "react-select/animated";
import {
  ReactMultiSelect,
  MultiValue,
  Option,
} from "../../components/MultiSelect/ReactMultiSelect";
import MasterDetailService from "../master/MasterDetailService";
import { AppContext } from "../../services/ContextProvider";
import { useLocation } from "react-router-dom";
import Global from "../../services/Global";
import {
  Decrypt,
  GetLoginUserID,
  remarksAndDescriptionDownload,
  BreakdownDepartmentReportDownload,
} from "../../services/CommonService";
import { DataTableReportAjax } from "../../components/DataTableReportAjax";
import { BASE_URL } from "../../env";
import { WithSelectDropDown } from "../../components/WithSelectDropDown";
const _breakdownService = new BreakdownMaintenanceService();
const animatedComponents = makeAnimated();
const obj = new BreakdownMaintenanceService();

const columnNames = [
  { title: "Serial No", data: "RowId" },
  { title: "Station Code", data: "StationCode" },
  { title: "Ticket No", data: "TicketNo" },
  { title: "Breakdown Date", data: "BreakdownDate" },
  { title: "Breakdown Time", data: "BreakdownTime" },
  { title: "Equipment Tag No", data: "EquipmentCode" },
  { title: "Parameter", data: "ParameterName" },
  { title: "UOM", data: "ParameterUnit" },

  { title: "Acceptable Param Value", data: "AcceptableParameterHigh" },
  { title: "Actual Value", data: "CurrentReading" },
  { title: "Assigned By", data: "AssignedBy" },
  { title: "Assigned To", data: "AssignedTo" },
  { title: "Problem Description", data: "ProblemDescription" },
  { title: "Work Description", data: "WorkDescription" },
  // { title: "Remarks", data: "Remarks" },
  { title: "Technician Remarks", data: "RemarksForTechnician" },
  { title: "Engineer Remarks", data: "RemarksForEngineer" },
  { title: "Work Completion Date", data: "ClosedDate" },
  { title: "Status", data: "StatusName" },
];

const DepartmentcolumnNames = [
  { title: "Serial No", data: "RowID" },
  { title: "Department Name", data: "DepartmentName" },
  { title: "Station Code", data: "StationCode" },
  { title: "Ticket No", data: "TicketNo" },
  { title: "Breakdown Date", data: "CreatedDate" },
  { title: "PTW Number", data: "BreakdownPTWNumber" },
  { title: "PTW Date", data: "BreakdownPTWDate" },
  { title: "Equipment Tag Number", data: "EquipmentCode" },
  { title: "Parameter Name", data: "ParameterName" },
  { title: "Assigned By", data: "AssignedBy" },
  { title: "Assigned To", data: "AssignedTo" },
  { title: "Approval Status", data: "StatusName" },

  {
    title: "Download Remarks",
    data: "TicketID",
    createdCell: (td, cellData, rowData, row, col) => {
      var b =
        $(`<button class='btn icon-btn text-center' title='Download Remarks'>
      <i class='fa fa-download m-r-5'></i>
    </button>`).on("click", function () {
          BreakdownRepotGetDetailesbyTicketID(rowData.TicketID, rowData);
        });
      $(td).html(b);
    },
  },
  // {
  //   title: "Download",
  //   data: "download_url",
  //   visible: false,
  //   className: "hide",
  // },
  // { title: "Status", data: "StatusName" },
];

const BreakdownRepotGetDetailesbyTicketID = (TicketID = 0, rowdata) => {

  _breakdownService
    .BreakdownDepartmentReport_GetDetailesbyTicketID(TicketID)
    .then((response) => {
      const { Table } = response.data.data;

      var jobDescriptionList = [];
      if (Table?.length > 0) {
        // let file_name =
        //   currentDate.format("DD_MM_YYYY_HH_mm_ss_") +
        //   Equipment_tag_no +
        //   ".zip";
        // var url_path = `${BASE_URL}/PeriodicMaintenance/download_uploaded_files?JobScheduleID=${JobScheduleID}&EquipmentDetailID=${EquipmentDetailID}&Filename=${file_name}`;
        var url_path = `${window.BASE_URL}BreakdownMaintenance/download_uploaded_files?`;


        rowdata["Department Name"] = rowdata.DepartmentName;
        rowdata["Station Code"] = rowdata.StationCode;
        rowdata["Ticket No"] = rowdata.TicketNo;
        rowdata["Breakdown Date"] = rowdata.CreatedDate;
        rowdata["PTW Number"] = rowdata.BreakdownPTWNumber;
        rowdata["PTW Date"] = rowdata.BreakdownPTWDate;
        rowdata["Approval Status"] = rowdata.StatusName;
        rowdata["Assigned To"] = rowdata.AssignedTo;
        rowdata["Assigned By"] = rowdata.AssignedBy;

        // 			Selfies	FIles

        const res = Table.map((x) => {
          var obj = { ...rowdata };
          obj["User Level"] = x.Username;
          obj["Remarks"] = x.Remarks;
          obj["Status"] = x.StatusName;
          obj["Downloads"] =
            x.Attachment == 1
              ? url_path +
              "GuidName=" +
              x.GuidName +
              "&OrginalFile=" +
              x.OrginalFile +
              "&FilePath=" +
              x.FilePath
              : "No Attachment Found";
          jobDescriptionList.push(obj);
          return obj;
        });

        BreakdownDepartmentReportDownload(res);
      }

      // saveAs(new Blob([response.data], { type: contentType }), `${file_name}`)
    })
    .catch((err) => {
      console.log(err);
    });
};

export const BreakdownReport = () => {
  const { getCookies, handleGlobalStationName } = useContext(AppContext);

  const [fromDate, setFromDate] = useState("");
  const [ToDate, setToDate] = useState("");
  const [StatusDropDown, setStatusDropDown] = useState([]);

  const [statusId, setStatusId] = useState(null);
  const [StationList, setStationList] = useState([]);
  const [FilterStationList, setFilterStationList] = useState([]);
  const [EquipmentTagList, setEquipmentTagList] = useState([]);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [FilterEquipmentTagList, setFilterEquipmentTagList] = useState([]);
  const [StationID, SetStationID] = useState([]);
  const [EquipmentIds, SetEquipmentIds] = useState([]);
  const [DepartmentIds, SetDepartmentIds] = useState("");
  const [IsTvsvac, setIsTvsvac] = useState(false);

  // const [faultList, setFaultList] = useState([]);
  // const [orginalList, setOrginalList] = useState([]);
  // const [, setData] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [stationIdss, setStataionIds] = useState("");
  // const [FromDate, SetfromDate] = useState("");
  // const [ToDate, SetToDate] = useState("");

  const location = useLocation();

  // const removeFaultList = (e) => {
  //   setFaultList(
  //     faultList.filter((faultItem) => statusId !== faultItem.statusId)
  //   );
  // };

  useEffect(() => {
    if (location.state != null) {
      var val = location.state;
      if (val.isBack == "True") {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }
  }, []);

  useEffect(() => {
    setIsTvsvac(getCookies(Global.COOKIES.IsTvsvac));
    getStationList();
    GetStatus();
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
    var lastDay = new Date();
    setFromDate(firstDay.toISOString().split("T")[0]);
    setToDate(lastDay.toISOString().split("T")[0]);
  }, []);

  const getStationList = async () => {
    var stationList = Decrypt(getCookies(Global.COOKIES.Stations));
    var dataLst = [];

    stationList.map((data) => {
      dataLst.push({ value: data.StationID, label: data.StationCode, Type: data.Type });
    });
    HandleChange([dataLst[0]]);
    await get_equipment_list(dataLst[0]);
    setStationList(dataLst);
    setFilterStationList(dataLst)
  };

  const get_equipment_list = async (data) => {
    var employeeID = GetLoginUserID();
    await obj
      .BreakdownMaintenance_ReportDropDown(employeeID)
      .then((res) => {
        if (res?.data?.data) {

          let Department = [];
          const { equipmentTagList, departmentList } = res.data.data;
          let DepartmentList = Decrypt(getCookies(Global.COOKIES.Departments));

          // if (DepartmentList?.length) {
          //   const itemsWithoutChildren = DepartmentList.filter(
          //     (item) =>
          //       !DepartmentList.some((child) => child.parentId === item.id)
          //   );
          //   
          //   itemsWithoutChildren.map((data) => {
          //     Department.push({ value: data.id, key: data.name });
          //   });

          //   setDepartmentList(Department);

          //   // setPeriodicModel((pre) => ({
          //   //   ...pre,
          //   //   DepartmentID: Department[0].value,
          //   // }));
          // }

          if (departmentList) {
            setDepartmentList(departmentList);
          } else {
            setDepartmentList([]);
          }
          // all_equipment?.map((x) => {
          //   x.key = x.label
          //   x.value = x.Value
          // })
          const filterEquipmentTagList = equipmentTagList.filter(
            (x) => x.StationID == data.value
          );
          setEquipmentTagList(filterEquipmentTagList);
          setFilterEquipmentTagList(equipmentTagList);
        }
      })
      .catch((err) => console.log(err));
  };

  const HandleChange = (e, type = "STATION") => {
    if (type == "STATION") {
      SetStationID(e);
      let StationLabel = e.map((x) => x.label).join();
      handleGlobalStationName(StationLabel);

      const Equipment_list = e
        ?.map((item) =>
          FilterEquipmentTagList?.filter((tag) => tag.StationID === item.value)
        )
        .flat();
      setEquipmentTagList([...Equipment_list]);
    } else if (type == "STATUS") {
      setStatusId(e.target.value);
    } else if (type == "EQUIPMENT") {
      SetEquipmentIds(e.target.value);
    } else if (type == "Department") {
      const { value } = e.target;
      SetDepartmentIds(value);
      let obj = DepartmentList?.find(ele => ele.value == value)
      let StationList = FilterStationList?.filter((ele) => ele.Type == obj.Type);
      setStationList(StationList ?? [])
      if (StationList?.length) {
        SetStationID([StationList[0]])
      } else {
        SetStationID([])
      }
    }
  };

  const onFromDateChange = (e) => {
    setFromDate(e.target.value);
  };
  const onToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const onclear = () => {
    $("#FromDate").val(null);
    $("#ToDate").val(null);
    setFromDate("");
    setToDate("");
  };

  const GetStatus = () => {
    _breakdownService.GetStatusList().then((res) => {
      if (res != null && res.data != null && res.data.length > 0) {
        var status = [];
        console.log(res.data);
        res.data.map((v) => {
          status.push({ key: v.statusName, value: v.statusID });
        });
        setStatusDropDown(status);
      }
    });
  };

  useEffect(() => {
    var stationIds = StationID.map((x) => {
      return x.value;
    });
    var ID = stationIds.join(",");

    setStataionIds(ID);

    // GetFault(ID);
  }, [fromDate, ToDate, StationID, EquipmentIds, statusId]);

  // console.log(EquipmentIds,statusId)

  return (
    <div>
      <Header
        IsTvsvac={IsTvsvac}
        title={"Breakdown Maintenance System Report"}
        stationchange={HandleChange}
        EquipmentTagChange={HandleChange}
        onFromDateChange={onFromDateChange}
        onToDateChange={onToDateChange}
        stationList={StationList}
        EquipmentTagList={EquipmentTagList}
        DepartmentList={DepartmentList}
        stationIDs={StationID}
        statusId={statusId}
        EquipmentIds={EquipmentIds}
        DepartmentID={DepartmentIds}
        fromDate={fromDate}
        ToDate={ToDate}
        StatusDropDown={StatusDropDown}
        onclear={onclear}
        showButton={showButton}
      />
      {(DepartmentIds === "" ||
        DepartmentIds === 0 ||
        DepartmentIds === "0") && (
          <div className="card card-body">
            <DataTableReportAjax
              columnList={columnNames}
              id={"FaultTable"}
              title={"Breakdown Maintenance System Report"}
              isAsc={true}
              FromDate={fromDate}
              ToDate={ToDate}
              url={`${window.BASE_URL}BreakdownMaintenance/BreakdownReport`}
              StationID={stationIdss ? stationIdss : "0"}
              EquipmentCode={EquipmentIds ? EquipmentIds : "0"}
              DepartmentID={DepartmentIds ? DepartmentIds : "0"}
              BreakdownStatus={statusId ? statusId : "0"}
              landscape
            />
            {/* <DataTableReports columnList={columnNames} rowList={orginalList} id={'FaultTable'} title={"Breakdown Maintenance Report"} isAsc={true} /> */}
          </div>
        )}
      {DepartmentIds !== "" && DepartmentIds !== 0 && DepartmentIds !== "0" && (
        <div className="card card-body">
          <DataTableReportAjax
            columnList={DepartmentcolumnNames}
            id={"FaultTables"}
            title={"Breakdown Maintenance System Report"}
            isAsc={true}
            FromDate={fromDate}
            ToDate={ToDate}
            url={`${window.BASE_URL}BreakdownMaintenance/BreakdownReport`}
            StationID={stationIdss ? stationIdss : "0"}
            EquipmentCode={EquipmentIds ? EquipmentIds : "0"}
            DepartmentID={DepartmentIds ? DepartmentIds : "0"}
            BreakdownStatus={statusId ? statusId : "0"}
            // landscape
            exportOptions={{
              columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            }}
          />
        </div>
      )}
    </div>
  );
};

const Header = ({
  IsTvsvac,
  title,
  onFromDateChange,
  stationList,
  EquipmentTagList,
  DepartmentList,
  stationIDs,
  stationchange,
  EquipmentTagChange,
  onToDateChange,
  fromDate,
  ToDate,
  StatusDropDown,
  showButton,
}) => {
  useEffect(() => {
    $(function () {
      // var dtToday = new Date();

      // var month = dtToday.getMonth() + 1; //jan=0; feb=1 .......
      // var day = dtToday.getDate();
      // var year = dtToday.getFullYear() - 18;
      // if (month < 10) month = "0" + month.toString();
      // if (day < 10) day = "0" + day.toString();
      // var minDate = year + "-" + month + "-" + day;
      // var maxDate = year + "-" + month + "-" + day;
      var lastDay = new Date();

      if (fromDate != null) {
        $("#ToDate").attr("min", fromDate);
        $("#ToDate").attr("max", lastDay.toISOString().split("T")[0]);
      }
      if (ToDate != null) {
        $("#FromDate").attr("max", ToDate);
      }
    });
  }, [fromDate, ToDate]);
  return (
    <div className="page-header mb-3">
      <div className="row justify-content-end">
        <div className="col-12">
          <h3 className="page-title">{title}</h3>
          {/* <span style={{color: 'gray'}}>Breakdown Maintenance</span> */}
          {showButton && (
            <>
              <Link
                to="/dashboard"
                className="btn btn-primary"
                state={{ Type: "1" }}
              >
                <i class="las la-chevron-left"></i>{" "}
                <span> Back to Dashboard</span>{" "}
              </Link>
            </>
          )}
        </div>
        <div className="col-12 row">
          {IsTvsvac === "false" && (
            <div className="col-2">
              <label className="label">Department</label>
              <WithSelectDropDown
                placeholder={"Select Department"}
                name={"DepartmentID"}
                data={DepartmentList}
                initialVal={0}
                HandleChange={(e) => {
                  EquipmentTagChange(e, "Department");
                }}
              />
            </div>
          )}
          <div className="col-2">
            <label className="label" for="">
              From Date{" "}
            </label>

            <input
              type="date"
              className="form-control"
              id="FromDate"
              pattern="\d{4}-\d{2}-\d{2}"
              defaultValue={fromDate}
              onChange={(e) => {
                onFromDateChange(e);
              }}
            />
          </div>
          <div className="col-2">
            <label className="label" for="">
              To Date{" "}
            </label>

            <input
              type="date"
              className="form-control"
              defaultValue={ToDate}
              id="ToDate"
              onChange={(e) => {
                onToDateChange(e);
              }}
            />
          </div>

          <div className="col-2">
            <label className="label">Stations</label>
            <ReactMultiSelect
              options={stationList}
              isMulti
              value={stationIDs}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={(e) => stationchange(e, "STATION")}
              allowSelectAll={true}
            />
          </div>
          <div className="col-2">
            <label className="label">Equipment Tag No</label>

            <WithSelectDropDown
              placeholder={"Select Equipment Tag No"}
              name={"equipment_code"}
              data={EquipmentTagList}
              initialVal={0}
              HandleChange={(e) => {
                EquipmentTagChange(e, "EQUIPMENT");
              }}
            />
          </div>

          <div className="col-2">
            <label className="label">Status</label>

            <WithSelectDropDown
              placeholder={"Select Status"}
              name={"statuscode"}
              data={StatusDropDown}
              initialVal={0}
              HandleChange={(e) => {
                EquipmentTagChange(e, "STATUS");
              }}
            />
          </div>
        </div>

        {/* <div className='col-1 mt-4'>
            <button className='btn btn-primary' onClick={onclear}>Clear</button>
        </div> */}
      </div>
    </div>
  );
};
