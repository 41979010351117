import React, { useContext, useState, useEffect } from "react";
import {
  DeepCopy,
  GetLoginUserID,
  GetSelect,
  getL0RoleID,
  getUserDetails,
  makeUnique,
  toInitCap,
  validateModel
} from "../../services/CommonService";
import { IoClose } from "react-icons/io5";
import { TbCameraSelfie } from "react-icons/tb";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../services/ContextProvider";
import { WithSelectDropDown } from "../../components/WithSelectDropDown";
import swal from "sweetalert";
import {
  PeriodicMaintenance_AddOrUpdateJobScheduleIndividualForUG19,
  PeriodicMaintenance_GetEquipmentDetailsUsingID,
} from "../PeriodicMaintenance/PeriodicService";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  BootrapModel,
  HtmlTable,
  ReactMultiSelect,
  WebCam,
  showErrorMsg,
  showSaveError,
  showSaveSuccess,
  showSuccessMsg,
  showWarningMsg,
} from "../../components";
import { IoMdCheckmark } from "react-icons/io";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import $ from "jquery";
import "./OverCurrent_EarthFaultRelay_110_33KV.css";
const _breakdownService = new BreakdownMaintenanceService();
function OverCurrent_EarthFaultRelay_110_33KV() {
  const { showLoading, hideLoading, PeriodicModel, setPeriodicModel } =
    useContext(AppContext);
  const location = useLocation();
  const [formStates, setFormStates] = useState([]);
  const {
    PeriodicCheckListMaintenanceID,
    FromDate,
    enddate,
    EquipmentDetails,
    DepartmentID,
  } = location.state;

  const [Model, setModel] = useState({
    IsAllClosed: false,
  });
  const equipmentValues = EquipmentDetails[0]?.value;

  const InitialState = {
    PWTNumber:"",
    PWTDate: "",
    Error : {},
    FeederNo: "",
    SLNo: "",
    CT_Rated_Primary_Current: 0,
    CT_Rated_Secondary_Current: 0,
    Rated_Primary_Voltage: 0,
    Rated_Secondary_Voltage: 0,
    isBreakDown: EquipmentDetails?.EquipAvailStatus ?? 0,

    CT_PT_Testing_Current: [
      {
        phases: "L1-E",
        CurrentInjected: 0,
        IL1: 0,
        IL2: 0,
        IL3: 0,
        IN: 0,
      },
      {
        phases: "L2-E",
        CurrentInjected: 0,
        IL1: 0,
        IL2: 0,
        IL3: 0,
        IN: 0,
      },
      {
        phases: "L3-E",
        CurrentInjected: 0,
        IL1: 0,
        IL2: 0,
        IL3: 0,
        IN: 0,
      },
      {
        phases: "L1-L2-L3",
        CurrentInjected: 0,
        IL1: 0,
        IL2: 0,
        IL3: 0,
        IN: 0,
      },
    ],
    CT_PT_Testing_Voltage: [
      {
        phases: "L1-E",
        Va: 0,
        Vb: 0,
        Vc: 0,
        Vab: 0,
        Vbc: 0,
        Vca: 0,
      },
      {
        phases: "L2-E",
        Va: 0,
        Vb: 0,
        Vc: 0,
        Vab: 0,
        Vbc: 0,
        Vca: 0,
      },
      {
        phases: "L3-E",
        Va: 0,
        Vb: 0,
        Vc: 0,
        Vab: 0,
        Vbc: 0,
        Vca: 0,
      },
      {
        phases: "L1-L2-L3",
        Va: 0,
        Vb: 0,
        Vc: 0,
        Vab: 0,
        Vbc: 0,
        Vca: 0,
      },
    ],

    Overcurrent_Protection_50_1_Pickup_Setting: 0,
    Overcurrent_Protection_50_1_Delay_Setting: 0,

    Overcurrent_Protection_Definite_Time: [
      {
        phases: "R",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "Y",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "B",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "RYB",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
    ],

    Overcurrent_Protection_51_TOC_Pickup_Setting: 0,
    Overcurrent_Protection_51_TOC_Time_Dial_Setting: 0,
    Overcurrent_Protection_Inverse_Time: [
      {
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
      },
      {
        phases: "R",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
      {
        phases: "Y",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
      {
        phases: "B",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
      {
        phases: "RYB",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
    ],
    Earth_Fault_Protection_50N_1_Pickup: 0,
    Earth_Fault_Protection_50N_1_Delay: 0,
    Earth_Fault_Protection_Definite_Time: [
      {
        phases: "R-N",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "Y-N",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "B-N",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
    ],
    Earth_Fault_Protection_51N_TOC_Pickup: 0,
    Earth_Fault_Protection_51N_TOC_Time_Dial: 0,
    Earth_Fault_Protection_Inverse_Time: [
      {
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
      },
      {
        phases: "R-N",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
      {
        phases: "Y-N",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
      {
        phases: "B-N",
        A1: 0,
        A2: 0,
        A3: 0,
        A4: 0,
        Calc_Time_1: 0,
        Trip_Time1: 0,
        Calc_Time_2: 0,
        Trip_Time2: 0,
        Calc_Time_3: 0,
        Trip_Time3: 0,
        Calc_Time_4: 0,
        Trip_Time4: 0,
      },
    ],
    Under_Voltage_Protection_Pickup: 0,
    Under_Voltage_Protection_Delay: 0,
    Under_Voltage_Protection: [
      {
        phases: "R",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "Y",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "B",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "RYB",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
    ],
    Over_Voltage_Protection_Pickup: 0,
    Over_Voltage_Protection_Delay: 0,
    Over_Voltage_Protection: [
      {
        phases: "R",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "Y",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "B",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
      {
        phases: "RYB",
        Actual_injected_current_A: 0,
        Trip_Time_From_Relay_ms: 0,
      },
    ],
    Breaker_Failure_Protection_Final_Checklist: [
      {
        label: "BINARY INPUTS",
        options: "",
      },
      {
        label: "BINARY OUTPUTS",
        options: "",
      },
      {
        label: "LED INDICATORS",
        options: "",
      },
      {
        label: "Tripping of CB / Lockout Relay Checked",
        options: "",
        colspan: true,
      },
      {
        label: "Final Settings Downloaded to Relay after Test",
        options: "",
        colspan: true,
      },
      {
        label: "Relay found Healthy",
        options: "",
        colspan: true,
      },
    ],
    Breaker_Failure_Protection: "",
  };

  const [formData, setFormData] = useState(InitialState);

  useEffect(() => {
    setPeriodicModel({
      PeriodicCheckListMaintenanceID,
      DepartmentID,
      FromDate: FromDate,
      EndDate: enddate,
      // PWTDate: "",
      EquipmentDetailId: EquipmentDetails[0] ?? {},
      isBreakDown: EquipmentDetails[0]?.EquipAvailStatus ?? 0,
      Location: "",
      // PWTNumber: "",
    });
    localStorage.setItem("FilePaths", "");
    localStorage.setItem("isAlreadyUploaded", "");
    localStorage.setItem("IsJobAssigner", "");
    localStorage.setItem("AllClosed", "");
    GetDescriptionByID();
  }, []);
  const GetDescriptionByID = () => {
    showLoading();
    if (PeriodicCheckListMaintenanceID && EquipmentDetailId?.value) {
      PeriodicMaintenance_GetEquipmentDetailsUsingID(
        PeriodicCheckListMaintenanceID,
        EquipmentDetailId?.value,
        GetLoginUserID()
      )
        .then((res) => {
          
          hideLoading();
          let Showbtn = true;
          debugger;
          if (res?.data?.data?.length) {
            const { data } = res.data;
            let IsAllClosed = data.some((ele) => ele.IsAllClosed);
            let IsApproved = data.some((ele) => ele.InitialApproved);
            setModel((pre) => ({
              ...pre,
              IsAllClosed,
              IsApproved,
              Showbtn,
            }));

            if (data?.length) {
              const Obj = data[0];

              let FinalObj = {};
              let ParseNeedProp = [
                "CT_PT_Testing_Current",
                "CT_PT_Testing_Voltage",
                "Overcurrent_Protection_Definite_Time",
                "Overcurrent_Protection_Inverse_Time",
                "Earth_Fault_Protection_Definite_Time",
                "Earth_Fault_Protection_Inverse_Time",
                "Under_Voltage_Protection",
                "Over_Voltage_Protection",
                "Breaker_Failure_Protection_Final_Checklist",
              ];

              Object.keys(Obj).forEach((ele) => {
                if (Obj[ele]) {
                  if (ParseNeedProp.includes(ele)) {
                    FinalObj[ele] = JSON.parse(Obj[ele]);
                  } else {
                    FinalObj[ele] = Obj[ele];
                  }
                } else {
                  FinalObj[ele] = InitialState[ele];
                }
              });
              setFormData(FinalObj)

              // setFormData((pre) => ({
              //   ...pre,
              //   ...data[0],
              //   CT_PT_Testing_Current: CT_PT_Testing_Current ? JSON.parse(CT_PT_Testing_Current) : InitialState.CT_PT_Testing_Current,
              //   CT_PT_Testing_Voltage: CT_PT_Testing_Voltage ? JSON.parse(CT_PT_Testing_Voltage) : InitialState.CT_PT_Testing_Voltage,
              //   Overcurrent_Protection_Definite_Time: Overcurrent_Protection_Definite_Time ? JSON.parse(Overcurrent_Protection_Definite_Time) : InitialState.Overcurrent_Protection_Definite_Time,
              //   Overcurrent_Protection_Inverse_Time: Overcurrent_Protection_Inverse_Time ? JSON.parse(Overcurrent_Protection_Inverse_Time) : InitialState.Overcurrent_Protection_Inverse_Time,
              //   Earth_Fault_Protection_Definite_Time: Earth_Fault_Protection_Definite_Time ? JSON.parse(Earth_Fault_Protection_Definite_Time) : InitialState.Earth_Fault_Protection_Definite_Time,
              //   Earth_Fault_Protection_Inverse_Time: Earth_Fault_Protection_Inverse_Time ? JSON.parse(Earth_Fault_Protection_Inverse_Time) : InitialState.Earth_Fault_Protection_Inverse_Time,
              //   Under_Voltage_Protection: Under_Voltage_Protection ? JSON.parse(Under_Voltage_Protection) : InitialState.Under_Voltage_Protection,
              //   Over_Voltage_Protection: Over_Voltage_Protection ? JSON.parse(Over_Voltage_Protection) : InitialState.Over_Voltage_Protection,
              //   Breaker_Failure_Protection_Final_Checklist: Breaker_Failure_Protection_Final_Checklist ? JSON.parse(Breaker_Failure_Protection_Final_Checklist) : InitialState.Breaker_Failure_Protection_Final_Checklist
              // }));
            } else {
              setFormData(InitialState);
            }
          } else {
            setFormStates([]);
            setFormData(InitialState);
            Showbtn = false;
            setModel((pre) => ({
              ...pre,
              Showbtn,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
          hideLoading();
        });
    }
  };

  const relayInputs = ["IL1", "IL2", "IL3", "IN"];
  const voltageFields = ["Va", "Vb", "Vc", "Vab", "Vbc", "Vca"];

  const {
    PWTDate,
    PWTNumber,
    ApprovalRemarks,
    EquipmentDetailId,
    OverAllRemarksList,
    AllClosed,
  } = PeriodicModel;

  const NormalDisplayList = [
    {
      key: "Checked",
      value: "Checked",
    },
    {
      key: "UnChecked",
      value: "UnChecked",
    },
  ];

  useEffect(() => {
    GetDescriptionByID();
  }, [PeriodicCheckListMaintenanceID, EquipmentDetailId?.value]);

  const CommonHandleChange = async (event, CusName) => {
   
    if (CusName) {
      if (CusName == "EquipmentDetailId") {
        setPeriodicModel({
          [CusName]: event,
          isBreakDown: event.EquipAvailStatus,
        });
      }
    } else {
      const { name, value } = event.target;
      if (name == "FileObj") {
        event.preventDefault();
        const formData = new FormData();
        $.each(event.target.files, function (i, file) {
          formData.append("files", file);
        });
        if (event.target?.files.length > 0) {
          let returnValue = await fileUpload(formData);
          if (returnValue) {
            let FilePaths = localStorage.getItem("FilePaths");
            if (FilePaths) {
              FilePaths = JSON.parse(FilePaths);
            } else FilePaths = [];
            // let FilePaths = CopyFileObj.FilePaths;

            if (!Array.isArray(FilePaths)) {
              FilePaths = [];
            }

            returnValue.forEach((ele) => (ele.Type = "UPLOAD"));
            FilePaths = FilePaths.concat(returnValue);
            localStorage.setItem("FilePaths", JSON.stringify(FilePaths));
          }
        }
      } else {
        if (name == "ApprovalRemarks") {
          $(".approveRemarks").removeClass("border-danger");
        }
        setPeriodicModel({ [name]: value });
      }
    }
  };
  const fileUpload = async (formData) => {
    showLoading();
    return await _breakdownService
      .FileUpload(formData)
      .then((res) => {
        hideLoading();
        showSuccessMsg("Files Uploaded SuccessFully.");
        return res;
      })
      .catch((err) => {
        hideLoading();
        showErrorMsg("Something Went Wrong! Please Try Again.");

        console.log(err);
        return 0;
      });
  };
  const getCameraImagePath = (objString = "") => {
    if (objString) {
      let imageObj = JSON.parse(objString);
      let FilePaths = localStorage.getItem("FilePaths");
      if (FilePaths) {
        FilePaths = JSON.parse(FilePaths);
      } else FilePaths = [];
      let CopyFileObj = DeepCopy(FilePaths);
      if (!Array.isArray(CopyFileObj)) {
        CopyFileObj = [];
      }
      imageObj[0].Type = "SELFIE";
      CopyFileObj = CopyFileObj.concat(imageObj);

      localStorage.setItem("FilePaths", JSON.stringify(CopyFileObj));
      $("#CloseSelfiePeriodicModel").click();
    }
  };
  const OverAllRemarksComponent = () => {
    return (
      <>
        <div class="chats">
          {OverAllRemarksList?.length
            ? OverAllRemarksList.map((ele) => {
                const { DescriptionName } = ele;
                let manipulatedDesc = DescriptionName?.length
                  ? `${DescriptionName} - `
                  : "";
                return (
                  <>
                    <div class="chat-line">
                      <span class="chat-date">
                        {ele.EmployeeName} - {ele.StatusName}
                      </span>
                    </div>
                    <div class="chat chat-left">
                      <div class="chat-body">
                        <div class="chat-bubble">
                          <div class="chat-content">
                            <p className="text-black text-sm">
                              {ele.Remarks?.length > 0
                                ? manipulatedDesc + ele.Remarks
                                : `${manipulatedDesc}`}{" "}
                            </p>

                            <span
                              class="chat-time pull-right text-small"
                              style={{ fontSize: "8px" }}
                            >
                              {ele.FormattedDate}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            : ""}
        </div>
      </>
    );
  };

  const handleInputChange = (e, index, section) => {
    debugger;
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedSection = prevData[section].map((item, idx) => {
        if (idx == index) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      });

      return {
        ...prevData,
        [section]: updatedSection,
      };
    });
  };

  const handleChange = (e, index, section) => {
    debugger;
    const { value } = e.target;

    setFormData((prevData) => {
      const updatedSection = prevData[section].map((item, idx) => {
        if (idx == index) {
          return {
            ...item,
            options: value, // Directly set the `options` string value
          };
        }
        return item;
      });

      return {
        ...prevData,
        [section]: updatedSection,
      };
    });
  };

  const GetErrorDetails = (obj) => {
    debugger;
    const ErrMessages = {
      FeederNo: "FeederNo",
      SLNo : "SLNo",
      PWTNumber :"PWTNumber"
     
    };
    let Error = validateModel(obj, ErrMessages);

    if (Error) {
      const { PeriodicDetailID } = obj;
      let CopyObbj = DeepCopy(formData);
      CopyObbj.Error = Error;
      return { CopyObbj, Error };
    }
    return { Error: false };
  };
  
  const SaveCT_PT_Testing = async (
    obj,
    IsAllClosed = false,
    Type = 1,
    IsApproved,
    IsOverallbtn
  ) => {
    debugger
    try {
      const { CopyObbj, Error } = GetErrorDetails(obj);
      if (Error) {
        setFormData(CopyObbj);
        return;
      }
      if(IsOverallbtn){
        const { CT_PT_Testing_Current } = obj;
        const { CurrentInjected, IL1, IL2, IL3, IN } = CT_PT_Testing_Current;
        const hasZeroValue = [CurrentInjected, IL1, IL2, IL3, IN].some(value => value === 0);     
        if (!hasZeroValue) {
          const willProceed = await swal({
              title: "Are you sure?",
              text: "Some fields have a value of 0. Do you want to proceed with this job?",
              icon: "warning",
              buttons: true,
          });
  
          if (!willProceed) {
              return; // Exit if the user clicked "Cancel"
          }
      }
      }
     
    debugger
      const allJobs = [];
      if (IsAllClosed) {
        

        formStates.forEach((ele) => {
          allJobs.push(ele);
        });
      }
      let newAllJob = [];
      allJobs.forEach((ele) => {
        newAllJob.push(toInitCap(ele));
      });

      let SaveCT_PT_Testing = {
        ...formData,
        // PWTDate: $(".PWTDate").val(),
        // PWTNumber: $(".PWTNumber").val(),
        isBreakDown: $("input.isBreakDown:checked").val(),
        CurrentStatgeID: 106,
        Type,
        Status: 2,
        IsApproved,
        IsAllClosed,
        AllJobs: JSON.stringify(newAllJob),
        SavedBy: GetLoginUserID(),
        CT_PT_Testing_Current: JSON.stringify(formData.CT_PT_Testing_Current),
        CT_PT_Testing_Voltage: JSON.stringify(formData.CT_PT_Testing_Voltage),
        Overcurrent_Protection_Definite_Time: JSON.stringify(
          formData.Overcurrent_Protection_Definite_Time
        ),
        Overcurrent_Protection_Inverse_Time: JSON.stringify(
          formData.Overcurrent_Protection_Inverse_Time
        ),
        Earth_Fault_Protection_Definite_Time: JSON.stringify(
          formData.Earth_Fault_Protection_Definite_Time
        ),
        Earth_Fault_Protection_Inverse_Time: JSON.stringify(
          formData.Earth_Fault_Protection_Inverse_Time
        ),
        Under_Voltage_Protection: JSON.stringify(
          formData.Under_Voltage_Protection
        ),
        Over_Voltage_Protection: JSON.stringify(
          formData.Over_Voltage_Protection
        ),
        Breaker_Failure_Protection_Final_Checklist: JSON.stringify(
          formData.Breaker_Failure_Protection_Final_Checklist
        ),
      };

      await PeriodicMaintenance_AddOrUpdateJobScheduleIndividualForUG19(
        SaveCT_PT_Testing
      )
        .then((response) => {
          hideLoading();
          if (response != null && response.data != null) {
            showSaveSuccess();
            GetDescriptionByID();
            return true;
          }
          return null;
        })
        .catch((err) => {
          hideLoading();
          showErrorMsg();
          GetDescriptionByID();
          console.log(err);
        });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const CalculationTimeFormula = (Amp, TMS) => {
    return (TMS * (0.14 / (Math.pow(Amp, 0.02) - 1)))?.toFixed(2) ?? 0;
  };

  const updateJobStatus = (status) => {
    debugger;
    let statusName = "";
    if (status == 1) statusName = "approve";
    else if (status == 2) statusName = "reject";
    else statusName = "save";

    swal({
      title: "Are you sure?",
      text: `Do you want to ${statusName} this job`,
      icon: `warning`,
      buttons: true,
      dangerMode: status == 0,
    }).then((willDelete) => {
      if (willDelete) {
        SaveCT_PT_Testing(formData, false, 2, status == 1);
      }
    });
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="row d-flex justify-content-between">
          <div className="col-sm-3 mb-2 text-start">
            <button
              className="btn btn-success"
              style={{ backgroundColor: "#b3b3b3", borderColor: "#b3b3b3" }}
              onClick={() => {
                window.history.back();
              }}
            >
              <RiArrowGoBackLine /> Go Back
            </button>
          </div>
          <div className="col-sm-6 mb-2 text-end">
            {getL0RoleID() === getUserDetails().RoleID &&
            !Model?.IsAllClosed ? (
              <button
                className="btn btn-primary m-l-5"
                title="Approve selected jobs"
                onClick={() => SaveCT_PT_Testing(formData, true,1, undefined, true)}
              >
                Submit
              </button>
            ) : (
              ""
            )}
            {/* <button
              className="btn btn-primary m-l-5"
              title="Approve selected jobs"
              onClick={() => SaveCT_PT_Testing(formData[0], true)}
            >
              Submit
            </button> */}

            {getUserDetails().RoleID !== getL0RoleID() &&
            !Model?.IsApproved &&
            Model?.Showbtn ? (
              <>
                <button
                  className="btn btn-success m-l-5"
                  title="Approve selected jobs"
                  onClick={() => updateJobStatus(1)}
                >
                  <span>
                    <IoMdCheckmark /> Approve
                  </span>
                </button>
                <button
                  className="btn btn-danger m-l-5"
                  title="Reject selected jobs"
                  onClick={() => updateJobStatus(2)}
                >
                  <span>
                    <IoClose /> Reject
                  </span>
                </button>
              </>
            ) : (
              ""
            )}

            <button
              data-bs-toggle="modal"
              data-bs-target="#SelfiePeriodicModel"
              className="btn Camera-btn btn-secondary TakeSelfie m-l-5"
              title="Take Selfie"
            >
              <span>
                {" "}
                <TbCameraSelfie /> Take Selfie
              </span>
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                PTW Number<span className="text-danger"> *</span>
              </label>{" "}
              <input
                className="form-control PWTNumber"
                type="text"
                name="PWTNumber"
                disabled={getUserDetails().RoleID !== getL0RoleID()}
                value={formData.PWTNumber}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    PWTNumber: e.target.value,
                  }))
                }
              />
               <span className="text-danger">
                              {formData.Error?.PWTNumber}
                            </span>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                PTW Date <span className="text-danger"> *</span>
              </label>{" "}
              <input
                className="form-control PWTDate"
                type="date"
                name="PWTDate"
                disabled={getUserDetails().RoleID !== getL0RoleID()}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    PWTDate: e.target.value,
                  }))
                }
                value={formData.PWTDate}
              />
               <span className="text-danger">
                              {formData.Error?.PWTDate}
                            </span>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="label" for="empCode">
                Equipment Code{" "}
              </label>{" "}
              <ReactMultiSelect
                options={EquipmentDetails ?? []}
                name="EquipmentDetailId"
                value={EquipmentDetailId}
                onChange={(e) => CommonHandleChange(e, "EquipmentDetailId")}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Remarks
              </label>{" "}
              <textarea
                className="form-control OverAllRemarks approveRemarks"
                name="ApprovalRemarks"
                style={{ resize: "none" }}
                rows={2}
                disabled={getUserDetails().RoleID == getL0RoleID()}
                onChange={CommonHandleChange}
                // value={}
              >
                {" "}
              </textarea>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Upload{" "}
              </label>{" "}
              <input
                type="file"
                className="form-control"
                name="FileObj"
                multiple
                onChange={CommonHandleChange}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group ">
              <div class="form-check form-check-inline">
                <input
                  className="form-check-input isBreakDown"
                  type="radio"
                  name="isBreakDown"
                  id="inlineRadio1"
                  value="117"
                  checked={PeriodicModel.isBreakDown == 117}
                  disabled={getUserDetails().RoleID !== getL0RoleID()}
                  onChange={CommonHandleChange}
                />
                <label class="form-check-label" for="inlineRadio1">
                  Under Breakdown
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  className="form-check-input isBreakDown"
                  type="radio"
                  name="isBreakDown"
                  id="inlineRadio2"
                  checked={PeriodicModel.isBreakDown == 116}
                  disabled={getUserDetails().RoleID !== getL0RoleID()}
                  value="116"
                  onChange={CommonHandleChange}
                />
                <label class="form-check-label" for="inlineRadio2">
                  Running
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  className="form-check-input isBreakDown"
                  type="radio"
                  name="isBreakDown"
                  id="inlineRadio2"
                  checked={PeriodicModel.isBreakDown == 115}
                  disabled={getUserDetails().RoleID !== getL0RoleID()}
                  value="115"
                  onChange={CommonHandleChange}
                />
                <label class="form-check-label" for="inlineRadio2">
                  Under Maintenance
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-2 ">
            <label className="label d-flex">Feeder No <span className="text-danger"> *</span></label>
            <input
              type="text"
              class="form-control"
              name="FeederNo"
              style={{ height: "40px" }}
              value={formData.FeederNo}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  FeederNo: e.target.value,
                }))
              }
            />{" "}
             <span className="text-danger">
                              {formData.Error?.FeederNo}
                            </span>
          </div>
          <div className="col-2 ">
            <label className="label d-flex">SL No <span className="text-danger">*</span> </label>
            <input
              type="text"
              class="form-control"
              name="SLNo"
              style={{ height: "40px" }}
              value={formData.SLNo}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  SLNo: e.target.value,
                }))
              }
            />{" "}
               <span className="text-danger">
                              {formData.Error?.SLNo}
                            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <h5 className="mt-3">CT & PT Ratings :</h5>
            <div className="table-responsive">
              <table
                className="responsive"
                style={{ width: "100%" }}
                cellpadding="10"
              >
                <tr>
                  <td>CT Rated Primary Current (A)</td>
                  <td>
                    {" "}
                    <input
                      type="number"
                      className="form-control"
                      name="CT_Rated_Primary_Current"
                      value={formData.CT_Rated_Primary_Current}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          CT_Rated_Primary_Current: e.target.value,
                        }))
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>CT Rated Secondary Current (A)</td>
                  <td>
                    {" "}
                    <input
                      type="number"
                      className="form-control"
                      name="CT_Rated_Secondary_Current"
                      value={formData.CT_Rated_Secondary_Current}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          CT_Rated_Secondary_Current: e.target.value,
                        }))
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Rated Primary Voltage (L-L) (KV)</td>
                  <td>
                    {" "}
                    <input
                      type="number"
                      className="form-control"
                      name="Rated_Primary_Voltage"
                      value={formData.Rated_Primary_Voltage}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          Rated_Primary_Voltage: e.target.value,
                        }))
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Rated Secondary Voltage (L-L) (V)</td>
                  <td>
                    {" "}
                    <input
                      type="number"
                      className="form-control"
                      name="Rated_Secondary_Voltage"
                      value={formData.Rated_Secondary_Voltage}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          Rated_Secondary_Voltage: e.target.value,
                        }))
                      }
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <Accordion
          allowZeroExpanded={true}
          allowMultipleExpanded
          preExpanded={[1]}
        >
          <AccordionItem uuid={1}>
            <div>
              <AccordionItemButton>{"CT & PT Testing:"}</AccordionItemButton>
            </div>
            <AccordionItemPanel>
              <p>
                Inject current in the relay as per the table below. Observe the
                reading on the relay display, in primary values.{" "}
              </p>
              <table
                className="phase-table"
                border="1"
                style={{ borderCollapse: "collapse", width: "100%" }}
              >
                <thead>
                  <tr>
                    <th rowSpan="3">Phase</th>
                    <th rowSpan="2">Current Injected (A)</th>
                    <th colSpan="4">Primary Current Displayed on Relay (A)</th>
                  </tr>
                  <tr>
                    {relayInputs.map((input) => (
                      <th key={input}>{input}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {formData?.CT_PT_Testing_Current?.map((phase, index) => (
                    <tr key={index}>
                      <td>{phase.phases}</td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="CurrentInjected"
                          value={phase.CurrentInjected}
                          onChange={(e) =>
                            handleInputChange(e, index, "CT_PT_Testing_Current")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="IL1"
                          value={phase?.IL1}
                          onChange={(e) =>
                            handleInputChange(e, index, "CT_PT_Testing_Current")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="IL2"
                          value={phase.IL2}
                          onChange={(e) =>
                            handleInputChange(e, index, "CT_PT_Testing_Current")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="IL3"
                          value={phase.IL3}
                          onChange={(e) =>
                            handleInputChange(e, index, "CT_PT_Testing_Current")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="IN"
                          value={phase.IN}
                          onChange={(e) =>
                            handleInputChange(e, index, "CT_PT_Testing_Current")
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="mt-3">
                <p>
                  Inject voltage in the relay as per the table below. Observe
                  the reading on the relay display, in primary values.
                </p>
                <table
                  className="voltage-table"
                  border="1"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Phase</th>
                      {voltageFields.map((field) => (
                        <th key={field}>{field}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.CT_PT_Testing_Voltage?.map((phase, index) => (
                      <tr key={index}>
                        <td>{phase.phases}</td>

                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Va"
                            value={phase.Va}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "CT_PT_Testing_Voltage"
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Vb"
                            value={phase.Vb}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "CT_PT_Testing_Voltage"
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Vc"
                            value={phase.Vc}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "CT_PT_Testing_Voltage"
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Vab"
                            value={phase.Vab}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "CT_PT_Testing_Voltage"
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Vbc"
                            value={phase.Vbc}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "CT_PT_Testing_Voltage"
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Vca"
                            value={phase.Vca}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "CT_PT_Testing_Voltage"
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mt-3">
                  {!Model?.IsAllClosed && (
                  <button
                    type="submit"
                    onClick={() => SaveCT_PT_Testing(formData)}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                  )}
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid={2}>
            <div>
              <AccordionItemButton>
                {"Overcurrent Protection (50-1,51-TOC)"}
              </AccordionItemButton>
            </div>
            <AccordionItemPanel>
              <h5>Definite Time:</h5>
              <div className="row">
                <div className="col-3 d-flex flex-column">
                  <label className="label">50-1 Pickup Setting (A):</label>
                  <input
                    type="number"
                    name="Overcurrent_Protection_50_1_Pickup_Setting"
                    className="form-control"
                    value={formData?.Overcurrent_Protection_50_1_Pickup_Setting}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        Overcurrent_Protection_50_1_Pickup_Setting: parseFloat(
                          e.target.value
                        ),
                      }))
                    }
                  />
                </div>
                <div className="col-3 d-flex flex-column">
                  <label className="label"> 50-1 Delay Setting (S): </label>
                  <input
                    type="number"
                    className="form-control"
                    name="Overcurrent_Protection_50_1_Delay_Setting"
                    value={formData.Overcurrent_Protection_50_1_Delay_Setting}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        Overcurrent_Protection_50_1_Delay_Setting: parseFloat(
                          e.target.value
                        ),
                      }))
                    }
                  />
                </div>
              </div>
              <p className="mt-2">
                Note: IDMT stage is disabled while testing DMT stage.
              </p>
              <table
                className="current-trip-table"
                border="1"
                style={{ borderCollapse: "collapse", width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Phase</th>
                    <th>Actual Injected Current (A)</th>
                    <th>Trip Time (From Relay) (ms)</th>
                  </tr>
                </thead>
                <tbody>
                  {formData?.Overcurrent_Protection_Definite_Time?.map(
                    (phase, index) => (
                      <tr key={index}>
                        <td>{phase.phases}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Actual_injected_current_A"
                            value={phase.Actual_injected_current_A}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "Overcurrent_Protection_Definite_Time"
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Trip_Time_From_Relay_ms"
                            value={phase.Trip_Time_From_Relay_ms}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "Overcurrent_Protection_Definite_Time"
                              )
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <div className="mt-3">
                <h5>Inverse Time:</h5>
                <div className="row">
                  <div className="col-3 d-flex flex-column">
                    <label className="label">IEC Curve: </label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={"Normal Inverse"}
                    />
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <label className="label">
                      {" "}
                      51-TOC Pickup Setting (A) :
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={
                        formData.Overcurrent_Protection_51_TOC_Pickup_Setting
                      }
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          Overcurrent_Protection_51_TOC_Pickup_Setting:
                            parseFloat(e.target.value),
                        }))
                      }
                    />
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <label className="label">
                      {" "}
                      51-TOC Time Dial Setting (S) :{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={
                        formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                      }
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          Overcurrent_Protection_51_TOC_Time_Dial_Setting:
                            parseFloat(e.target.value),
                        }))
                      }
                    />
                  </div>
                </div>
                <p className="mt-3">
                  {" "}
                  Note: DMT stage is disabled while testing IDMT stage.
                </p>

                <table
                  className="trip-details-table"
                  border="1"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Phase</th>
                      <th colspan="4">Current Injected (A)</th>
                      <th colspan="8">
                        Operating Time (Trip Time from Relay) (ms)
                      </th>
                    </tr>

                    <tr>
                      <th></th>
                      <th colspan="4">
                        Current Injected should be below no of times Ip
                      </th>
                      {Object.entries(
                        formData?.Overcurrent_Protection_Inverse_Time?.[0]
                      ).map((ele, index) => {
                        const name = ele[0];
                        const value = ele[1];
                        const mutiplyIp =
                          value *
                          formData.Overcurrent_Protection_51_TOC_Pickup_Setting;

                        return <th colSpan="2">{mutiplyIp}</th>;
                      })}
                    </tr>

                    <tr>
                      <th></th>
                      {Object.entries(
                        formData?.Overcurrent_Protection_Inverse_Time?.[0]
                      ).map((ele, index) => {
                        const name = ele[0];
                        const value = ele[1];

                        return (
                          <th>
                            {" "}
                            <input
                              type="number"
                              name={name}
                              value={value}
                              className="form-control"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  0,
                                  "Overcurrent_Protection_Inverse_Time"
                                )
                              }
                            />
                          </th>
                        );
                      })}

                      <th>Calc. Time-1</th>
                      <th>Trip Time </th>
                      <th>Calc. Time-2</th>
                      <th>Trip Time</th>
                      <th>Calc. Time-3</th>
                      <th>Trip Time </th>
                      <th>Calc. Time-4</th>
                      <th>Trip Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.Overcurrent_Protection_Inverse_Time?.map(
                      (phase, index) => {
                        let Ambiverts =
                          formData.Overcurrent_Protection_Inverse_Time[0];
                        let Ambiverts_1 = Ambiverts.A1;
                        let Ambiverts_2 = Ambiverts.A2;
                        let Ambiverts_3 = Ambiverts.A3;
                        let Ambiverts_4 = Ambiverts.A4;
                        if (index != 0) {
                          return (
                            <tr key={index}>
                              <td>{phase.phases}</td>

                              {Object.entries(
                                formData
                                  ?.Overcurrent_Protection_Inverse_Time?.[0]
                              ).map((ele, index) => {
                                const value = ele[1];
                                const multiplyIp =
                                  value *
                                  formData.Overcurrent_Protection_51_TOC_Pickup_Setting;
                                return <td>{multiplyIp}</td>;
                              })}

                              {/* <td>{78 * Ambiverts_1}</td> */}
                              <td>
                                {CalculationTimeFormula(
                                  Ambiverts_1,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time1}
                                  name="Trip_Time1"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "Overcurrent_Protection_Inverse_Time"
                                    )
                                  }
                                />
                              </td>

                              <td>
                                {" "}
                                {CalculationTimeFormula(
                                  Ambiverts_2,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time2}
                                  name="Trip_Time2"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "Overcurrent_Protection_Inverse_Time"
                                    )
                                  }
                                />
                              </td>

                              <td>
                                {" "}
                                {CalculationTimeFormula(
                                  Ambiverts_3,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time3}
                                  name="Trip_Time3"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "Overcurrent_Protection_Inverse_Time"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                {" "}
                                {CalculationTimeFormula(
                                  Ambiverts_4,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time4}
                                  name="Trip_Time4"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "Overcurrent_Protection_Inverse_Time"
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          );
                        }
                      }
                    )}
                  </tbody>
                </table>
                <div className="mt-3">
                  {!Model?.IsAllClosed && (
                  <button
                    type="submit"
                    onClick={() => SaveCT_PT_Testing(formData)}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                  )}
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid={3}>
            <div>
              <AccordionItemButton>
                {"Earth fault Protection (50N-1,51N-TOC)"}
              </AccordionItemButton>
            </div>
            <AccordionItemPanel>
              <h5>Definite Time:</h5>
              <div className="row">
                <div className="col-3 d-flex flex-column">
                  <label className="label">50N-1 Pickup Setting (A) :</label>
                  <input
                    type="number"
                    className="form-control"
                    value={formData.Earth_Fault_Protection_50N_1_Pickup}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        Earth_Fault_Protection_50N_1_Pickup: parseFloat(
                          e.target.value
                        ),
                      }))
                    }
                  />
                </div>
                <div className="col-3 d-flex flex-column">
                  <label className="label">50N-1 Delay Setting (S) : </label>
                  <input
                    type="number"
                    className="form-control"
                    value={formData.Earth_Fault_Protection_50N_1_Delay}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        Earth_Fault_Protection_50N_1_Delay: parseFloat(
                          e.target.value
                        ),
                      }))
                    }
                  />
                </div>
              </div>
              <p className="mt-2">
                Note: IDMT stage is disabled while testing DMT stage.
              </p>
              <table
                className="current-trip-table"
                border="1"
                style={{ borderCollapse: "collapse", width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Phase</th>
                    <th>Actual Injected Current (A)</th>
                    <th>Trip Time (From Relay) (ms)</th>
                  </tr>
                </thead>
                <tbody>
                  {formData?.Earth_Fault_Protection_Definite_Time?.map(
                    (phase, index) => (
                      <tr key={index}>
                        <td>{phase.phases}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Actual_injected_current_A"
                            value={phase.Actual_injected_current_A}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "Earth_Fault_Protection_Definite_Time"
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Trip_Time_From_Relay_ms"
                            value={phase.Trip_Time_From_Relay_ms}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "Earth_Fault_Protection_Definite_Time"
                              )
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <div className="mt-3">
                <h5>Inverse Time:</h5>
                <div className="row">
                  <div className="col-3 d-flex flex-column">
                    <label className="label">IEC Curve: </label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={"Normal Inverse"}
                    />
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <label className="label">
                      {" "}
                      51N-TOC Pickup Setting (A) :
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={formData.Earth_Fault_Protection_51N_TOC_Pickup}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          Earth_Fault_Protection_51N_TOC_Pickup: parseFloat(
                            e.target.value
                          ),
                        }))
                      }
                    />
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <label className="label">
                      {" "}
                      51N-TOC Time Dial Setting (s) :{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={formData.Earth_Fault_Protection_51N_TOC_Time_Dial}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          Earth_Fault_Protection_51N_TOC_Time_Dial: parseFloat(
                            e.target.value
                          ),
                        }))
                      }
                    />
                  </div>
                </div>
                <p className="mt-3">
                  {" "}
                  Note: DMT stage is disabled while testing IDMT stage.
                </p>

                <table
                  className="trip-details-table"
                  border="1"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Phase</th>
                      <th colspan="4">Current Injected (A)</th>
                      <th colspan="8">
                        Operating Time (Trip Time from Relay) (ms)
                      </th>
                    </tr>

                    <tr>
                      <th></th>
                      <th colspan="4">
                        Current Injected should be below no of times Ip
                      </th>
                      {Object.entries(
                        formData?.Earth_Fault_Protection_Inverse_Time?.[0]
                      ).map((ele, index) => {
                        const name = ele[0];
                        const value = ele[1];
                        const mutiplyIp =
                          value *
                          formData.Overcurrent_Protection_51_TOC_Pickup_Setting;

                        return <th colSpan="2">{mutiplyIp}</th>;
                      })}
                    </tr>

                    <tr>
                      <th></th>
                      {Object.entries(
                        formData?.Earth_Fault_Protection_Inverse_Time?.[0]
                      ).map((ele, index) => {
                        const name = ele[0];
                        const value = ele[1];

                        return (
                          <th>
                            {" "}
                            <input
                              type="number"
                              name={name}
                              value={value}
                              className="form-control"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  0,
                                  "Earth_Fault_Protection_Inverse_Time"
                                )
                              }
                            />
                          </th>
                        );
                      })}

                      <th>Calc. Time-1</th>
                      <th>Trip Time </th>
                      <th>Calc. Time-2</th>
                      <th>Trip Time</th>
                      <th>Calc. Time-3</th>
                      <th>Trip Time </th>
                      <th>Calc. Time-4</th>
                      <th>Trip Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.Earth_Fault_Protection_Inverse_Time?.map(
                      (phase, index) => {
                        let Ambiverts =
                          formData.Earth_Fault_Protection_Inverse_Time[0];
                        let Ambiverts_1 = Ambiverts.A1;
                        let Ambiverts_2 = Ambiverts.A2;
                        let Ambiverts_3 = Ambiverts.A3;
                        let Ambiverts_4 = Ambiverts.A4;
                        if (index != 0) {
                          return (
                            <tr key={index}>
                              <td>{phase.phases}</td>

                              {Object.entries(
                                formData
                                  ?.Earth_Fault_Protection_Inverse_Time?.[0]
                              ).map((ele, index) => {
                                const value = ele[1];
                                const multiplyIp =
                                  value *
                                  formData.Earth_Fault_Protection_51N_TOC_Pickup;
                                return <td>{multiplyIp}</td>;
                              })}

                              {/* <td>{78 * Ambiverts_1}</td> */}
                              <td>
                                {CalculationTimeFormula(
                                  Ambiverts_1,
                                  formData.Earth_Fault_Protection_51N_TOC_Time_Dial
                                )}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time1}
                                  name="Trip_Time1"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "Earth_Fault_Protection_Inverse_Time"
                                    )
                                  }
                                />
                              </td>

                              <td>
                                {" "}
                                {CalculationTimeFormula(
                                  Ambiverts_2,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time2}
                                  name="Trip_Time2"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "Earth_Fault_Protection_Inverse_Time"
                                    )
                                  }
                                />
                              </td>

                              <td>
                                {" "}
                                {CalculationTimeFormula(
                                  Ambiverts_3,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time3}
                                  name="Trip_Time3"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "Earth_Fault_Protection_Inverse_Time"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                {" "}
                                {CalculationTimeFormula(
                                  Ambiverts_4,
                                  formData.Overcurrent_Protection_51_TOC_Time_Dial_Setting
                                )}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={phase.Trip_Time4}
                                  name="Trip_Time4"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "Earth_Fault_Protection_Inverse_Time"
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          );
                        }
                      }
                    )}
                  </tbody>
                </table>
                <div className="mt-3">
                  {!Model?.IsAllClosed && (
                  <button
                    type="submit"
                    onClick={() => SaveCT_PT_Testing(formData)}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                 )} 
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid={4}>
            <div>
              <AccordionItemButton>
                {"Under Voltage Protection (27):"}
              </AccordionItemButton>
            </div>
            <AccordionItemPanel>
              <div>
                <h5>Definite Time:</h5>
                <div className="row">
                  <div className="col-3 d-flex flex-column">
                    <label className="label">27 Pickup Setting (V) :</label>
                    <input
                      type="number"
                      name="Under_Voltage_Protection_Pickup"
                      className="form-control"
                      value={formData.Under_Voltage_Protection_Pickup}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          Under_Voltage_Protection_Pickup: parseFloat(
                            e.target.value
                          ),
                        }))
                      }
                    />
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <label className="label"> 27 Delay Setting (S): </label>
                    <input
                      type="number"
                      name="Under_Voltage_Protection_Delay"
                      className="form-control"
                      value={formData.Under_Voltage_Protection_Delay}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          Under_Voltage_Protection_Delay: parseFloat(
                            e.target.value
                          ),
                        }))
                      }
                    />
                  </div>
                </div>

                <table
                  className="current-trip-table mt-3"
                  border="1"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Phase</th>
                      <th>Actual Injected Current (A)</th>
                      <th>Trip Time (From Relay) (ms)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.Under_Voltage_Protection?.map((phase, index) => (
                      <tr key={index}>
                        <td>{phase.phases}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Actual_injected_current_A"
                            value={phase.Actual_injected_current_A}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "Under_Voltage_Protection"
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Trip_Time_From_Relay_ms"
                            value={phase.Trip_Time_From_Relay_ms}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "Under_Voltage_Protection"
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mt-3">
                  {!Model?.IsAllClosed && (
                  <button
                    type="submit"
                    onClick={() => SaveCT_PT_Testing(formData)}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                  )}
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid={5}>
            <div>
              <AccordionItemButton>
                {"Over Voltage Protection (59):"}
              </AccordionItemButton>
            </div>
            <AccordionItemPanel>
              <div>
                <h5>Definite Time:</h5>
                <div className="row">
                  <div className="col-3 d-flex flex-column">
                    <label className="label">59 Pickup Setting (V) :</label>
                    <input
                      type="number"
                      name="Under_Voltage_Protection_Pickup"
                      className="form-control"
                      value={formData.Over_Voltage_Protection_Pickup}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          Over_Voltage_Protection_Pickup: parseFloat(
                            e.target.value
                          ),
                        }))
                      }
                    />
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <label className="label"> 59 Delay Setting (S) : </label>
                    <input
                      type="number"
                      name="Under_Voltage_Protection_Pickup"
                      className="form-control"
                      value={formData.Over_Voltage_Protection_Delay}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          Over_Voltage_Protection_Delay: parseFloat(
                            e.target.value
                          ),
                        }))
                      }
                    />
                  </div>
                </div>

                <table
                  className="current-trip-table mt-3"
                  border="1"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Phase</th>
                      <th>Actual Injected Current (A)</th>
                      <th>Trip Time (From Relay) (ms)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.Over_Voltage_Protection?.map((phase, index) => (
                      <tr key={index}>
                        <td>{phase.phases}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Actual_injected_current_A"
                            value={phase.Actual_injected_current_A}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "Over_Voltage_Protection"
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="Trip_Time_From_Relay_ms"
                            value={phase.Trip_Time_From_Relay_ms}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                "Over_Voltage_Protection"
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mt-3">
                  {!Model?.IsAllClosed && (
                  <button
                    type="submit"
                    onClick={() => SaveCT_PT_Testing(formData)}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                 )}
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid={6}>
            <div>
              <AccordionItemButton>
                {"Breaker Failure Protection (50BF):"}
              </AccordionItemButton>
            </div>
            <AccordionItemPanel>
              <div>
                <div className="row">
                  <div className="col-8 d-flex">
                    <label className="label">
                      (Disabled during other protection testing. Enabled after
                      testing before charging the feeder).
                    </label>
                    <WithSelectDropDown
                      data={NormalDisplayList}
                      placeholder="Select"
                      name="Breaker_Failure_Protection"
                      initialVal={-1}
                      value={formData.Breaker_Failure_Protection || -1}
                      HandleChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,

                          Breaker_Failure_Protection: e.target.value, // Update the options field with the selected value
                        }))
                      }
                    />
                  </div>
                </div>

                <table className="relay-table mt-3">
                  <tbody>
                    {formData.Breaker_Failure_Protection_Final_Checklist?.map(
                      (item, index) => (
                        <tr key={index}>
                          {/* Conditionally handle colspan */}
                          {item.colspan ? (
                            <>
                              <td colSpan="2">{item.label}</td>
                              <td>
                                <WithSelectDropDown
                                  data={NormalDisplayList}
                                  placeholder="Select"
                                  name="Breaker_Failure_Protection_Final_Checklist"
                                  initialVal={-1}
                                  value={
                                    formData
                                      .Breaker_Failure_Protection_Final_Checklist[
                                      index
                                    ]?.options || -1
                                  }
                                  HandleChange={(e) =>
                                    handleChange(
                                      e,
                                      index,
                                      "Breaker_Failure_Protection_Final_Checklist"
                                    )
                                  }
                                />
                              </td>
                            </>
                          ) : (
                            <>
                              <td>{item.label}</td>
                              <td>
                                {item.options.value} as per Relay Assignment
                              </td>
                              <td>
                                <WithSelectDropDown
                                  data={NormalDisplayList}
                                  placeholder="Select"
                                  name="Breaker_Failure_Protection_Final_Checklist"
                                  initialVal={-1}
                                  value={
                                    formData
                                      .Breaker_Failure_Protection_Final_Checklist[
                                      index
                                    ]?.options || -1
                                  }
                                  HandleChange={(e) =>
                                    handleChange(
                                      e,
                                      index,
                                      "Breaker_Failure_Protection_Final_Checklist"
                                    )
                                  }
                                />
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                  <div className="mt-3">
                    {!Model?.IsAllClosed && (
                    <button
                      type="submit"
                      onClick={() => SaveCT_PT_Testing(formData)}
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                    )}
                  </div>
                </table>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <BootrapModel
          module={<WebCam HandleChange={getCameraImagePath} />}
          ModelTitle={"Selfie"}
          modelSize={"modal-lg"}
          CloseID={"CloseSelfiePeriodicModel"}
          Id={"SelfiePeriodicModel"}
        ></BootrapModel>

        <BootrapModel
          module={<OverAllRemarksComponent />}
          ModelTitle={"Over All Remarks"}
          modelSize={"modal-lg"}
          CloseID={"CloseOverAllRemarksComponent"}
          Id={"OverAllRemarksComponent"}
        ></BootrapModel>
      </div>
    </div>
  );
}

export default OverCurrent_EarthFaultRelay_110_33KV;
