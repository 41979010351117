import React, { useEffect, useState, useContext, useReducer } from "react";
import {
  BootrapModel,
  Dropdown,
  HtmlTable,
  showErrorMsg,
  showSaveError,
  showSaveSuccess,
  showSuccessMsg,
  showWarningMsg,
} from "../../components";
import { AuditingProcess_ApproveReject, AuditingProcess_GetPeriodicDetailsByID, AuditingProcess_Getdropdown } from "./AuditingProcessService.js";

import { useLocation } from "react-router-dom";

import {
  PeriodicMaintenance_GetOverAllRemarks,
  PeriodicMaintenance_SaveSpareData,
  PeriodicService,
} from "../PeriodicMaintenance/PeriodicService";
import BreakdownMaintenanceService from "../BreakDownMaintenance/BreakdownMaintenanceService";
import { AppContext } from "../../services/ContextProvider";
import $ from "jquery";
import {
  GetLoginUserID,
  GetSelect,
  getL0RoleID,
  getUserDetails,
  makeUnique,
} from "../../services/CommonService";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";
import { useNavigate } from "react-router";
import { SpareList } from "../PeriodicMaintenance/SpareList";
import { RiArrowGoBackLine } from "react-icons/ri";
import { COOKIES } from "../../services/Global.js";
import { WithSelectDropDown } from "../../components/WithSelectDropDown.js";
import { IoMdCheckmark } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const _breakdownService = new BreakdownMaintenanceService();
function PeriodicDetailsView() {
  const _periodicService = new PeriodicService();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    showLoading,
    hideLoading,
    PeriodicModel,
    setPeriodicModel,
    getCookies,
  } = useContext(AppContext);

  const {
    PeriodicCheckListMaintenanceID,
    fromdate,
    enddate,
    EquipmentDetails,
    DepartmentID,
    header,
    selectedModel
  } = location.state;

  const {
    PWTDate,
    PWTNumber,
    EquipmentDetailId,
    OverAllRemarksList,
  } = PeriodicModel;

  const [UGSColumnList, setUGSColumnList] = useState([]);
  const [UGSRowList, setUGSRowList] = useState([]);

  const [spareList, setSpareList] = useState([]);

  const [equipmentDetailsList, setEquipmentDetailsList] = useState([]);
  const [FileObj, setFileObj] = useState({
    FilePaths: [],
    isAlreadyUploaded: false,
  });
  const [TestNo, setTestNo] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    setPeriodicModel({
      PeriodicCheckListMaintenanceID,
      DepartmentID,
      FromDate: fromdate,
      EndDate: enddate,
      PWTDate: "",
      EquipmentDetailId: EquipmentDetails[0]?.value ?? 0,
      Location: "",
      PWTNumber: "",
    });
    localStorage.setItem("FilePaths", "");
    localStorage.setItem("isAlreadyUploaded", "");
    return () => {
      setUGSColumnList([]);
      setUGSRowList([]);
      localStorage.setItem("FilePaths", "");
    };
  }, []);

  useEffect(() => {
    GetDescriptionByID();
  }, [PeriodicCheckListMaintenanceID, EquipmentDetailId]);

  const CommonHandleChange = async (event) => {
    const { name, value } = event.target;
    if (name == "FileObj") {
      event.preventDefault();
      const formData = new FormData();
      $.each(event.target.files, function (i, file) {
        formData.append("files", file);
      });
      if (event.target?.files.length > 0) {
        let returnValue = await fileUpload(formData);
        if (returnValue) {
          let FilePaths = localStorage.getItem("FilePaths");
          if (FilePaths) {
            FilePaths = JSON.parse(FilePaths);
          } else FilePaths = [];
          // let FilePaths = CopyFileObj.FilePaths;

          if (!Array.isArray(FilePaths)) {
            FilePaths = [];
          }
          FilePaths = FilePaths.concat(returnValue);
          FilePaths.forEach((ele) => (ele.Type = "UPLOAD"));
          localStorage.setItem("FilePaths", JSON.stringify(FilePaths));
          setFileObj((pre) => ({
            ...pre,
            FilePaths,
          }));
        }
      }
    } else {
      setPeriodicModel({ [name]: value });
    }
  };

  const fileUpload = async (formData) => {
    showLoading();
    return await _breakdownService
      .FileUpload(formData)
      .then((res) => {
        hideLoading();
        showSuccessMsg("Files Uploaded SuccessFully.");
        return res;
      })
      .catch((err) => {
        hideLoading();
        showErrorMsg("Something Went Wrong! Please Try Again.");

        console.log(err);
        return 0;
      });
  };

  const GetDescriptionByID = () => {
    showLoading();
    AuditingProcess_GetPeriodicDetailsByID(
      PeriodicCheckListMaintenanceID,
      EquipmentDetailId
    )
      .then((res) => {
        hideLoading();
        convertColum(res);
      })
      .catch((err) => {
        console.log(err);
        hideLoading();
      });
  };
  function convertColum(res) {
    if (res?.data?.data) {
      let columnsList = [];
      const data = res.data.data;
      let InpEnable = getL0RoleID() == getUserDetails().RoleID;

      let DescriptionName = "Cell Sl.No";

      if (data[0]?.ConfigType == "Config_Type_1") {
        DescriptionName = "Work Description";
      }

      columnsList.push({
        title: "ComponentName",
        data: "ComponentName",
        visible: false,
        className: "false",
      });

      columnsList.push({
        title: `<p style ='width:150px;'> Component<p/>`,
        data: "ComponentName",
        width: "150px",
        className: `${data[0]?.ConfigType == "Config_Type_1" ? "" : "hide"}`,
        visible: data[0]?.ConfigType == "Config_Type_1",
        createdCell: function (td, cellData, rowData, row, col) {
          let Div = $(`<p>`);
          let tag = $(`<p>${cellData}</p>`);
          let Critical = $(`<small class ='criticalTag'>Critical</small>`);
          let CyclicCheck = $(
            `<small class ='criticalTag' style="background : #a89eff">Cyclic Check</small>`
          );

          let { IsCritical, IsCyclicCheck } = rowData;
          if (IsCritical) {
            $(Div).append(Critical);
          }
          if (IsCyclicCheck) {
            $(Div).append(CyclicCheck);
          }
          $(Div).append(tag);
          $(td).html(Div);
        },
      });
      columnsList.push({
        title: `<p style = 'width : 150px'>${DescriptionName}<p/>`,
        data: "DescriptionName",
        width: "150px ",
      });

      let uniqueList = makeUnique(data, ["EquipmentCode", "EquipmentDetailID"]);

      let EquipForSpare = [];

      uniqueList?.forEach((element, _) => {
        const { EquipmentCode, EquipmentDetailID } = element;

        EquipForSpare.push({
          key: EquipmentCode,
          value: EquipmentDetailID,
        });
      });

      let Columns = [];
      if (data[0]?.ConfigType == "Config_Type_1") {
        Columns = Config_Column_1();
      } else {
        Columns = Config_Column(data[0]?.ConfigType);
      }

      Columns.forEach((ele) => columnsList.push(ele));



      columnsList.push({
        title: "<p  style=`width:150px`>Lo Remarks </p>",
        data: "Remarks",
        createdCell: function (td, cellData, rowData, row, col) {
          let Remarks = $(
            `<textarea  class="form-control" style="width:150px"  ${
              InpEnable ? "" : "disabled"
            }>${cellData}</textarea>`
          );

          Remarks.on("change", function (e) {
            e.preventDefault();
            const { value } = e.target;
            rowData[`Remarks`] = value;
          });
          $(td).html(Remarks);
        },
      });
      columnsList.push({
        title: "Status",
        data: "StatusName",
        width: "60px",
        createdCell: function (td, cellData, rowData, row, col) {
          let Status = cellData;
          if (getL0RoleID() !== getUserDetails().RoleID) {
            if (rowData.InitialApproved == null) {
              Status = "Closed";
            } else {
              Status = rowData.InitialApproved ? "Approved" : "Rejected";
            }
          }

          $(td).html(GetPeriodicStatus(Status));
        },
      });

      setUGSColumnList([...columnsList]);
      setUGSRowList([...data]);

      setPeriodicModel({
        PWTDate: data[0]?.PWTDate,
        PWTNumber: data[0]?.PWTNumber,
        ApprovalRemarks: data[0]?.ApprovalRemarks,
      });

      $(".PWTDate").val(data[0]?.PWTDate);
      $(".PWTNumber").val(data[0]?.PWTNumber);
    } else {
      setUGSColumnList([]);
      setUGSRowList([]);
    }
  }

  const Config_Column_1 = () => {
    let InpEnable = getL0RoleID() == getUserDetails().RoleID;
    let div = $(`<div style ="width : 300px">`);
    let span = $(`<span style ="width : 300px ;display: flex;" >`);
    let ActualRatingHeading = $(
      '<p class= "row" ><span class ="col"> Status</span><span  class ="col">Actual Value</span></p>'
    );

    div.append(span);
    div.append(ActualRatingHeading);

    let Columns = [
      {
        title: "Rated Value",
        data: "RatedValue",
        width: "50px ",
      },
      {
        title: "<p style='width:100px'>UOM</p>",
        data: "UOM",
        width: "60px",
      },
      {
        title: div.html(),
        data: `EquipmentStatus`,
        // width: "100px",
        createdCell: function (td, cellData, rowData, row, col) {
          let WrapDiv = $("<div class='btn-group' style='width:200px'>");

          let ActualValueInp = "";
          let StatusValueInp = "";
          const ActualValue = rowData[`Actual`];
          const EquipmentStatus = rowData[`EquipmentStatus`] ?? "";
          const { MaxValue, MinValue } = rowData;
          let ActualValBColor = "";

          if (
            MaxValue > 0 &&
            MinValue > 0 &&
            rowData.InitialApproved !== null
          ) {
            ActualValBColor =
              MaxValue >= ActualValue && MinValue <= ActualValue
                ? ""
                : "badge-danger";
          }

          if (rowData.IsHasOption) {
            StatusValueInp = $(
              GetSelect(
                [
                  {
                    key: rowData.StatusOption1?.trim(),
                    value: rowData.StatusOption1?.trim(),
                  },
                  {
                    key: rowData.StatusOption2?.trim(),
                    value: rowData.StatusOption2?.trim(),
                  },
                ],
                EquipmentStatus?.trim(),
                !InpEnable,
                "m-r-5",
                "-"
              )
            );
          } else {
            StatusValueInp = $(
              `<input type="text"  class="form-control " ${
                InpEnable ? "" : "disabled"
              } value="${EquipmentStatus}"/>`
            );
          }
          StatusValueInp.on("change", function (e) {
            e.preventDefault();
            const { value } = e.target;
            rowData[`EquipmentStatus`] = value;
          });

          ActualValueInp = $(
            `<input type="number" style="margin-left: 5px;"  class="form-control ${ActualValBColor}" ${
              InpEnable ? "" : "disabled"
            } value="${ActualValue}"/>`
          );

          ActualValueInp.on("change", function (e) {
            e.preventDefault();

            const { value } = e.target;
            if (MaxValue > 0 && MinValue > 0) {
              if (MaxValue >= value && MinValue <= value) {
                $(e.target).removeClass("badge-danger");
              } else {
                $(e.target).addClass("badge-danger");
              }
            }
            rowData[`Actual`] = value;
          });

          WrapDiv.append(StatusValueInp);
          WrapDiv.append(ActualValueInp);

          $(td).html(WrapDiv);
        },
      },
    ];
    return Columns;
  };

  const Config_Column = (columnType) => {
    let InpEnable = false;

    // Define column object lists based on the column type
    let ColumnObjList = [];
    if (columnType === "Config_Type_3") {
      ColumnObjList = [
        {
          name: "InitialNoLoadCondition",
          DisplayName: "Initial No Load Condition",
        },
        { name: "Time_1", DisplayName: "1 hrs" },
        { name: "Time_2", DisplayName: "2 hrs" },
        { name: "Time_3", DisplayName: "3 hrs" },
        { name: "Time_4", DisplayName: "4 hrs" },
        { name: "Time_5", DisplayName: "5 hrs" },
        {
          name: "FinalNoLoadCondition",
          DisplayName: "Final No Load Condition ",
        },
        { name: "BoostVoltage", DisplayName: "Boost Voltage" },
        { name: "SpecificGravity", DisplayName: "Specific Gravity" },
      ];
    } else if (columnType === "Config_Type_2") {
      ColumnObjList = [
        {
          name: "Details",
          DisplayName: "Details",
          disableCondition: (rowData) => {
            const { DescriptionName = "" } = rowData;
            let tempDescriptionName = DescriptionName.trim().toUpperCase();
            if (
              tempDescriptionName == "TIME" ||
              tempDescriptionName == "CURRENT" ||
              tempDescriptionName == "TOTALVOLTAGE"
            ) {
              return false;
            } else return true;
          },
        },
        {
          name: "CellVoltageFloatMode",
          DisplayName: "Cell Voltage Float Mode",
        },
        {
          name: "CellVoltageDischargeMode",
          DisplayName: "Cell Voltage Discharge Mode",
        },
        { name: "SpecificGravity", DisplayName: "Specific Gravity" },
      ];

    }

    let Columns = [];

    ColumnObjList.forEach((ele) => {
      const {
        name,
        DisplayName,
        disableCondition = () => {
          return true;
        },
      } = ele;

      Columns.push({
        title: `<span style="width:120px;display:block;">${DisplayName}</span>`,
        data: name,
        width: "150px",
        createdCell: function (td, cellData, rowData, row, col) {
          let WrapDiv = $("<div class='btn-group' style='width:120px'>");
          let Input = "";
          let CellValue = rowData[name];
          let isNotDisabled = disableCondition(rowData);

          Input = $(
            `<input type="text" style="margin-left: 5px;" class="form-control" ${
              InpEnable && isNotDisabled ? "" : "disabled"
            } value="${CellValue}"/>`
          );

          Input.on("change", function (e) {
            e.preventDefault();
            const { value } = e.target;
            rowData[name] = value;
          });

          WrapDiv.append(Input);
          $(td).html(WrapDiv);
        },
      });
    });

    return Columns;
  };



  const getOverAllRemarks = () => {
    PeriodicMaintenance_GetOverAllRemarks(
      PeriodicCheckListMaintenanceID,
      EquipmentDetailId
    )
      .then((res) => {
        if (res?.data?.data) {
          setPeriodicModel({ OverAllRemarksList: res.data.data });
        } else {
          setPeriodicModel({ OverAllRemarksList: [] });
        }
      })
      .catch((err) => console.log(err));
  };

  const saveSpare = (list) => {
    setSpareList(list);
  };
  const SaveSpareData = () => {
    var obj = {
      PeriodicCheckListMaintenanceID:
        PeriodicModel.PeriodicCheckListMaintenanceID,
      SavedBy: GetLoginUserID(),
      SpareList: JSON.stringify(spareList),
    };

    PeriodicMaintenance_SaveSpareData(obj)
      .then((res) => {
        hideLoading();
        if (res?.data?.isSuccess) {
          showSaveSuccess();
          navigate(-1);
        } else {
          console.log(res.data);
          showSaveError();
        }
      })
      .catch((err) => {
        console.log(err);
        hideLoading();
      });
  };

  const RemarksBtn = () => {
    let btn = $(
      `<button class='btn btn-primary m-l-5'  data-bs-toggle="modal" data-bs-target="#OverAllRemarksComponent" title='Show OverAll Remarks' >`
    );
    let RemarksIcon = $(`<i class="fa fa-comments-o" aria-hidden="true"></i>`);

    btn.on("click", (e) => {
      e.preventDefault();
      getOverAllRemarks();
    });

    btn.append(RemarksIcon);
    return btn;
  };

  const OverAllRemarksComponent = () => {
    return (
      <>
        <div class="chats">
          {OverAllRemarksList?.length
            ? OverAllRemarksList.map((ele) => {
                console.log(ele);
                return (
                  <>
                    <div class="chat-line">
                      <span class="chat-date">
                        {ele.EmployeeName} - {ele.StatusName}
                      </span>
                    </div>
                    <div class="chat chat-left">
                      <div class="chat-body">
                        <div class="chat-bubble">
                          <div class="chat-content">
                            <p className="text-black text-sm">
                              {ele.Remarks?.length > 0 ? ele.Remarks : "-"}{" "}
                            </p>

                            <span
                              class="chat-time pull-right text-small"
                              style={{ fontSize: "8px" }}
                            >
                              {ele.FormattedDate}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            : ""}
        </div>
      </>
    );
  };

  const RejectionDetails = (selectedModel, handleRefresh) => {
    const [DropdownModel, setdropdownModel] = useState([]);
    const [approveModel, setapproveModel] = useState({
      level: 0,
      Remarks: "",
    });

    useEffect(() => {
      DropDown();

      function DropDown() {
        AuditingProcess_Getdropdown().then((res) => {
          if (
            res != null &&
            res.data.data.dropdownList != null &&
            res.data.data.dropdownList.length > 0
          ) {
            setdropdownModel(res.data.data.dropdownList);
          }
        });
      }
    }, []);

    const CommonHandleChange = (e) => {
      const { name, value } = e.target;
      setapproveModel((pre) => ({
        ...pre,
        [name]: value,
      }));
    };
    const rejectAction = (e) => {
      e.preventDefault();
      if (selectedModel != null) {
        if (approveModel.level != 0) {
          let Obj = selectedModel.selectedModel;
          let model = {};
          model.ModuleType = Obj.Type;
          model.ID = Obj.PeriodicCheckListMaintenanceID;
          model.Status = Obj.Type == "Preventive Maintenance" ? 4 : 105;
          model.RejectionStatus = approveModel.level;
          model.Remarks = approveModel.Remarks;
          model.SavedBy = GetLoginUserID();
          model.StationID = Obj.StationID;
          model.RoleID = getCookies(COOKIES.UserRoleID);

          AuditingProcess_ApproveReject(model)
            .then((response) => {
              const { data } = response?.data;
              if (data == "-1" || data == null) {
                showSaveError();
                handleRefresh();
              } else {
                showSaveSuccess();
              }
              GetDescriptionByID();
              $(`#closeRejection_`).click();
            })
            .catch((err) => {
              console.log(err);
              GetDescriptionByID();
              $(`#closeRejection_`).click();
            });
        } else {
          showWarningMsg("Please select Rejection level");
        }
      }
    };

    return (
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="stationCode">
              Risk levels<span className="text-danger"> *</span>{" "}
            </label>
            <WithSelectDropDown
              data={DropdownModel}
              placeholder="Select"
              name={"level"}
              HandleChange={CommonHandleChange}
              value={approveModel.level}
              className=""
            />
            {/* <span className="text-danger"> {errors.DepartmentID} </span> */}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="remarks">
              Remarks
            </label>
            <textarea
              name="Remarks"
              className="form-control"
              id="remarks"
              value={approveModel.Remarks}
              onChange={CommonHandleChange}
              rows="3"
              placeholder="Enter remarks here..."
            ></textarea>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-danger"
              type="submit"
              onClick={rejectAction}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ApproveDetails = (selectedModel, handleRefresh) => {
    const [approverRemarks, setapproverRemarks] = useState("");

    const appproveAction = () => {
      if (selectedModel != null) {
        let Obj = selectedModel.selectedModel;
        let model = {};
        model.ModuleType = Obj.Type;
        model.ID = Obj.PeriodicCheckListMaintenanceID;
        model.Status = Obj.Type == "Preventive Maintenance" ? 3 : 113;
        model.Remarks = approverRemarks.value;
        model.SavedBy = GetLoginUserID();
        model.StationID = Obj.StationID;
        model.RoleID = getCookies(COOKIES.UserRoleID);

        AuditingProcess_ApproveReject(model)
          .then((response) => {
            const { data } = response?.data;
            if (data == "-1" || data == null) {
              showSaveError();
              handleRefresh();
            } else {
              showSaveSuccess();
            }
            navigate(-1)
            $(`#closeApproval_`).click();
          })
          .catch((err) => {
            console.log(err);
            GetDescriptionByID();
            $(`#closeApproval_`).click();
          });
      }
    };

    const CommonHandleChange = (e) => {
      const { value } = e.target;
      setapproverRemarks(() => ({
        value,
      }));
    };

    return (
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="remarks">
              Remarks
            </label>
            <textarea
              className="form-control"
              id="remarks"
              rows="3"
              placeholder="Enter remarks here..."
              onChange={CommonHandleChange}
            ></textarea>
          </div>
        </div>
        <div className="col-md-6 d-flex justify-content-between align-items-center">
          <button className="btn btn-success" onClick={appproveAction}>
            Approve
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row d-flex justify-content-between">
            <div className="col-sm-3 mb-2 text-start">
              <button
                className="btn btn-success"
                style={{ backgroundColor: "#b3b3b3", borderColor: "#b3b3b3" }}
                onClick={() => navigate(-1)}
              >
                <RiArrowGoBackLine /> Go Back
              </button>
            </div>
            <div className="col-sm-6 mb-2 text-end">

                  <button
                    className="btn btn-success m-l-5"
                    data-bs-toggle="modal" data-bs-target="#Approve_" title="Reject"
                    
                  >
                    <span>
                      <IoMdCheckmark /> Approve
                    </span>
                  </button>
               
                  <button
                    className="btn btn-danger m-l-5"
                    title="Reject"
                    data-bs-toggle="modal" data-bs-target="#Rejection_"
                  >
                    <span>
                      <IoClose /> Reject
                    </span>
                  </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label className="label" for="empCode">
                  PWT Number
                </label>{" "}
                <input
                  className="form-control PWTNumber"
                  type="text"
                  name="PWTNumber"
                  disabled={getUserDetails().RoleID !== getL0RoleID()}
                  value={PWTNumber}
                  onChange={CommonHandleChange}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label className="label" for="empCode">
                  PWT Date
                </label>{" "}
                <input
                  className="form-control PWTDate"
                  type="date"
                  name="PWTDate"
                  disabled={getUserDetails().RoleID !== getL0RoleID()}
                  onChange={CommonHandleChange}
                  value={PWTDate}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label className="label" for="empCode">
                  EquipmentCode{" "}
                </label>{" "}
                <Dropdown
                  className="form-control"
                  data={EquipmentDetails ?? []}
                  name="EquipmentDetailId"
                  value={EquipmentDetailId}
                  HandleChange={CommonHandleChange}
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: "-25px" }}>
            <HtmlTable
              IsASC
              FixedColums={{
                left: 1,
                right: 2,
              }}
              OrderTargetList={Array.from(
                { length: UGSColumnList.length - 6 },
                (_, i) => i + 4
              )}
              rowList={UGSRowList}
              columnList={UGSColumnList}
              id={`PeriodicUGStation`}
              SearchPostponedHtml={RemarksBtn}
              // addedPlugin={addedPlugin}
              tableReRender={TestNo}
              orderby={0}
            />
          </div>
          <div className="card card-body">
            {/* <h4 className='text-center'> Used Spare</h4> */}
            <SpareList
              IsAdd={getUserDetails().RoleID == getL0RoleID()}
              Equlist={equipmentDetailsList}
              saveSpare={saveSpare}
              SelectedSpareList={spareList}
            ></SpareList>
            <br></br>
            {getUserDetails().RoleID == getL0RoleID() && (
              <div className="row d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary col-md-2 col-sm-2"
                  onClick={SaveSpareData}
                >
                  Save
                </button>
              </div>
            )}
          </div>

          <BootrapModel
            module={<OverAllRemarksComponent />}
            ModelTitle={"Over All Remarks"}
            modelSize={"modal-lg"}
            CloseID={"CloseOverAllRemarksComponent"}
            Id={"OverAllRemarksComponent"}
          ></BootrapModel>

          <BootrapModel
            Id={`Rejection_`}
            modelSize={"modal-xs"}
            CloseID={`closeRejection_`}
            ModelTitle={header}
            module={
              <RejectionDetails
                // handleRefresh={handleRefresh}
                selectedModel={selectedModel}
              />
            }
            // closepopup={handleRefresh}
          />
          <BootrapModel
            Id={`Approve_`}
            modelSize={"modal-xs"}
            ModelTitle={header}
            CloseID={`closeApproval_`}
            module={
              <ApproveDetails
                // handleRefresh={handleRefresh}
                selectedModel={selectedModel}
              />
            }
            // closepopup={handleRefresh}
          />
        </div>
      </div>
    </>
  );
}

export default PeriodicDetailsView;
