import React from "react";
import {
  Dropdown,
  ReactMultiSelect,
  HtmlTable,
  showWarningMsg,
  showSaveSuccess,
  showErrorMsg,
  showSaveError,
} from "../../components";
import {
  getPrivilege,
  Decrypt,
  getYMDdate,
  GetLoginUserID,
  getUserDetails,
  addDays,
  YMDGracePeriod,
  isCurrentTimeBetween12PMAnd1AM,
} from "../../services/CommonService";
import { useState, useEffect, useContext } from "react";
import { AppContext } from "../../services/ContextProvider";
import Global, { COOKIES } from "../../services/Global";
import {
  EquipmentRunning_DropDownDetails,
  EquipmentEnergyConsumption_SaveConfig,
  EquipmentEnergyConsumption_GetEquipmentDetails,
  EquipmentEnergyConsumption_DetailsSaveConfig,
  EquipmentEnergyConsumption_GetDocumentID,
} from "./ManualLogService";
import $ from "jquery";
import swal from "sweetalert";
import moment from "moment";
import { GetPeriodicStatus } from "../BreakDownMaintenance/MainGrid";
import { toast } from "react-toastify";

export default function EnergyConsumptionLog({ TabValue }) {

  const initialEquipmentState = {
    StationID: "",
    RoomID: "",
    IsDeleted: false,
    CurrentDate: YMDGracePeriod(new Date()),
    checkAllAttended: false,
    checkAllApproved: false,
    checkAllRejected: false,
    documentNo: "",
  };
  const [StationList, setStationList] = useState([]);
  const [errors, setErrors] = useState({
    EquipmentDetailID: "",
    StationID: "",
  });
  const [EquipmentConsumptionList, setEquipmentConsumptionList] = useState([]);
  const [EquipmentEnergyConsumptionModel, setEquipmentEnergyConsumptionModel] =
    useState(initialEquipmentState);
  const [flag, setflag] = useState(false);

  const { getCookies, showLoading, hideLoading, handleGlobalStationName } =
    useContext(AppContext);

  /**
   * Column Names for the Equipment Consumption  Table.
   */
  const columnNames = [
    {
      title: "EquipmentEnergyConsumptionID",
      data: "EquipmentEnergyConsumptionID",
      visible: false,
      orderable: false,
    },
    { title: "OrderNo", data: "OrderNo", visible: false },

    { title: "Station", data: "StationCode" },
    { title: "Description", data: "RoomName" },
    {
      title: "Today Date",
      data: "TodayDate",
    },
    {
      title: "kWh/kL Opening Value",
      data: "KWHOpeningValue",
      createdCell: function (td, cellData, rowData, row, col) {
        let ReadOnly = (cellData != 0 && getUserDetails().RoleID == COOKIES.TechRoleID) ? "readOnly" : "";
        let canDisable = rowData.OrderNo == 6 || rowData.OrderNo == 9;

        let tableValues = $("#EquipmentConsumptionTable")
          .DataTable()
          .table()
          .data()
          .toArray();

        var vacList = tableValues.filter((e) => e.OrderNo >= 1 && e.OrderNo <= 5);
        var tvsList = tableValues.filter((e) => e.OrderNo >= 7 && e.OrderNo <= 8);

        function calculateTotalKWHOpeningValue() {
          return vacList.reduce((sum, e) => sum + (e.KWHOpeningValue || 0), 0);
        }
        function tvsCalculateTotalKWHOpeningValue() {
          return tvsList.reduce((sum, e) => sum + (e.KWHOpeningValue || 0), 0);
        }

        var totalKWHOpeningValue = calculateTotalKWHOpeningValue();
        var totalTvsKWHOpeningValue = tvsCalculateTotalKWHOpeningValue();

        var valuecellData = rowData.OrderNo === 6 ? totalKWHOpeningValue : rowData.OrderNo === 9 ? totalTvsKWHOpeningValue : cellData;

        const { checkAllApproved, checkAllAttended } = EquipmentEnergyConsumptionModel;
        let IsDisable =
          IsAllowToEnterData() && !checkAllApproved ? "" : "disabled";

        let IsDiableForApprover = checkAllAttended ? '' : 'disabled';
        let isDisabled = canDisable ? "disabled" : "";

        var inputElement = $(`
          <input type="number" class="form-control KWHOpeningValueCls" ${ReadOnly} ${isDisabled} 
          ${getUserDetails().RoleID == COOKIES.TechRoleID ? IsDisable : COOKIES.EnggRoleID ? IsDiableForApprover : 'disabled'} value="${valuecellData}"/>
        `).on("change", function (e) {
          e.preventDefault();

          let newValue = parseFloat(e.target.value);
          if (!isNaN(newValue)) {
            // Only update the Opening Value related fields
            rowData.KWHNewOpeningValue = newValue;
            rowData.KWHOpeningValue = newValue;

            // Update the current row in vacList to keep track of changes for recalculation
            let currentRow = vacList.find((item) => item.OrderNo === rowData.OrderNo);
            if (currentRow) {
              currentRow.KWHOpeningValue = newValue;
            }

            // Recalculate and update only relevant UI elements for Opening Value
            let rowElement = $(e.target).closest("tr");
            let consumptionValueCell = rowElement.find(".KWHConsumptionValueCls");

            if (consumptionValueCell.length > 0) {
              rowData.KWHConsumptionValue = rowData.KWHClosingValue - newValue; // Recalculate Consumption Value
              consumptionValueCell.text(rowData.KWHConsumptionValue.toFixed(2));
            }

            // Recalculate total opening value for OrderNo 6 and update it
            totalKWHOpeningValue = calculateTotalKWHOpeningValue();
            totalTvsKWHOpeningValue = tvsCalculateTotalKWHOpeningValue();

            $("#EquipmentConsumptionTable").find("tr").each(function () {
              let rowDataForUpdate = $("#EquipmentConsumptionTable").DataTable().row($(this)).data();
              if (rowDataForUpdate && rowDataForUpdate.OrderNo === 6) {
                $(this).find('.KWHOpeningValueCls').val(totalKWHOpeningValue);
                rowDataForUpdate.KWHOpeningValue = totalKWHOpeningValue;
              } else if (rowDataForUpdate && rowDataForUpdate.OrderNo === 9) {
                $(this).find('.KWHOpeningValueCls').val(totalTvsKWHOpeningValue);
                rowDataForUpdate.KWHOpeningValue = totalKWHOpeningValue;
                rowDataForUpdate.KWHConsumptionValue = rowDataForUpdate.KWHClosingValue - totalKWHOpeningValue;
              }
            });

            // Handle changes only related to Opening Value
            EquipmentEnergyHandleChange(
              rowData,
              getUserDetails().RoleID == COOKIES.TechRoleID ? 51 : 127
            );
            UpdateLastUpdateTime(e);
          }
        });

        $(td).html(inputElement);
      }
    },
    {
      title: "kWh/kL Closing/Cumulative Value",
      data: "KWHClosingValue",
      createdCell: function (td, cellData, rowData, row, col) {
        const { checkAllApproved, checkAllAttended } = EquipmentEnergyConsumptionModel;
        let IsDisable = IsAllowToEnterData() && !checkAllApproved ? "" : "disabled";
        let canDisable = rowData.OrderNo == 6 || rowData.OrderNo == 9;
        let is11Pm = isCurrentTimeBetween12PMAnd1AM(new Date()) == true ? '' : "disabled";
        let tableValues = $("#EquipmentConsumptionTable")
          .DataTable()
          .table()
          .data()
          .toArray();
        var vacList = tableValues.filter((e) => e.OrderNo >= 1 && e.OrderNo <= 5);
        var tvsList = tableValues.filter((e) => e.OrderNo >= 7 && e.OrderNo <= 8);

        function calculateTotalKWHClosingValue() {
          return vacList.reduce((sum, e) => sum + (e.KWHClosingValue || 0), 0);
        }
        function calculateTvsTotalKWHClosingValue() {
          return tvsList.reduce((sum, e) => sum + (e.KWHClosingValue || 0), 0);
        }

        var totalKWHClosingValue = calculateTotalKWHClosingValue();
        var totalTvsKWHClosingValue = calculateTvsTotalKWHClosingValue();
        var valuecellData = rowData.OrderNo === 6 ? totalKWHClosingValue : rowData.OrderNo === 9 ? totalTvsKWHClosingValue : cellData;
        let isDisabled = canDisable ? "disabled" : "";
        var inputElement = $(
          `<input type="number" class="form-control KWHClosingValueCls" ${IsDisable} ${isDisabled} ${is11Pm} value="${valuecellData}"/>`
        ).on("change", function (e) {
          e.preventDefault();

          let newValue = parseFloat(e.target.value);

          if (!isNaN(newValue)) {
            if (newValue > rowData.KWHOpeningValue) {
              // Only update the Closing Value related fields
              rowData.KWHClosingValue = newValue;

              rowData.KWHConsumptionValue = newValue - rowData.KWHOpeningValue;

              let currentRow = vacList.find((item) => item.OrderNo === rowData.OrderNo);
              if (currentRow) {
                currentRow.KWHClosingValue = newValue;
              }

              let rowElement = $(e.target).closest("tr");
              let consumptionValueCell = rowElement.find(".KWHConsumptionValueCls");

              if (consumptionValueCell.length > 0) {
                consumptionValueCell.text(rowData.KWHConsumptionValue.toFixed(2));
              }

              totalKWHClosingValue = calculateTotalKWHClosingValue();
              totalTvsKWHClosingValue = calculateTvsTotalKWHClosingValue();

              // Handle changes only related to Closing Value
              EquipmentEnergyHandleChange(rowData);
              UpdateLastUpdateTime(e);
              UpdateStatus(e, rowData);

              $("#EquipmentConsumptionTable").find("tr").each(function () {
                let rowDataForUpdate = $("#EquipmentConsumptionTable").DataTable().row($(this)).data();
                if (rowDataForUpdate && rowDataForUpdate.OrderNo === 6) {
                  $(this).find('.KWHClosingValueCls').val(totalKWHClosingValue);
                  rowDataForUpdate.KWHClosingValue = totalKWHClosingValue;
                  EquipmentEnergyHandleChange(rowDataForUpdate);
                  UpdateStatus(e, rowDataForUpdate);
                } else if (rowDataForUpdate && rowDataForUpdate.OrderNo === 9) {
                  rowDataForUpdate.KWHClosingValue = totalTvsKWHClosingValue;
                  $(this).find('.KWHClosingValueCls').val(totalTvsKWHClosingValue);
                  // $(this).find('.KWHConsumptionValueCls').val(rowDataForUpdate.KWHClosingValue - rowDataForUpdate.KWHNewOpeningValue);
                  // rowDataForUpdate.KWHConsumptionValue = rowDataForUpdate.KWHClosingValue - rowDataForUpdate.KWHNewOpeningValue;
                  debugger;
                  EquipmentEnergyHandleChange(rowDataForUpdate);
                  UpdateStatus(e, rowDataForUpdate);
                }
              });


            } else {
              toast.warning("KWH Closing Value must be greater than Opening Value");
            }
          }
        });

        $(td).html(inputElement);
      }
    }

    ,

    {
      title: "kWh/kL Consumption Value",
      data: "KWHConsumptionValue",
      className: "KWHConsumptionValueCls",
    },
    {
      title: "Last Updated Date",
      data: "LastUpdatedDate",
      className: "ConsumptionDateCls",
    },
    {
      title: "UOM",
      data: "UOM",
      createdCell: (td, cellData, rowData, row, col) => {
        const { checkAllApproved, checkAllAttended } =
          EquipmentEnergyConsumptionModel;
        let IsDisable =
          IsAllowToEnterData() && !checkAllApproved && !checkAllAttended
            ? ""
            : "disabled";
        var b = $(
          `<input  class="form-control " ${IsDisable} value="${cellData}"></input>`
        ).on("change", (e) => {
          e.preventDefault();
          rowData.UOM = e.target.value;
          EquipmentEnergyHandleChange(rowData);
          UpdateLastUpdateTime(e);
          UpdateStatus(e, rowData);
        });

        $(td).html(b);
      },
    },
    {
      title: "Remarks",
      data: "Remarks",
      createdCell: (td, cellData, rowData, row, col) => {
        const { checkAllApproved, checkAllAttended } =
          EquipmentEnergyConsumptionModel;

        let IsDisable =
          IsAllowToEnterData() && !checkAllApproved && !checkAllAttended
            ? ""
            : "disabled";
        var b = $(
          `<textarea  class="form-control " ${IsDisable} style = "
            resize: none;
            min-width: 150px !important;"  value="${cellData}">${cellData} </textarea>`
        ).on("change", (e) => {
          e.preventDefault();
          rowData.Remarks = e.target.value;
          EquipmentEnergyHandleChange(rowData);
          UpdateLastUpdateTime(e);
          UpdateStatus(e, rowData);
        });

        $(td).html(b);
      },
    },
    {
      title: "Status",
      data: "StatusName",
      width: "60px",
      createdCell: function (td, cellData, rowData, row, col) {
        let span = $(`<span class="StatusCls" > </span>`);
        span.append(GetPeriodicStatus(cellData));
        $(td).html(span);
      },
    },
  ];

  function UpdateLastUpdateTime(e) {
    if (e) {
      let rowElement = $(e.target).closest("tr");
      let consumptionValueCell = rowElement.find(".ConsumptionDateCls");
      if (consumptionValueCell.length > 0) {
        let currentDateTime = moment().format("DD-MM-YYYY h:mm A");
        consumptionValueCell.text(currentDateTime);
      }
    }
  }

  useEffect(() => {
    // if (TabValue == 0) {
    //   initialDropDown();
    // }
    initialDropDown();

    return () => {
      setStationList([]);
    };
  }, []);

  useEffect(() => {
    getEquipmentDetails();
  }, [
    EquipmentEnergyConsumptionModel.CurrentDate,
    EquipmentEnergyConsumptionModel.StationID,
  ]);

  /**
   * Handle change event for common inputs.
   *
   * @param {Event} event - The input change event.
   */
  const CommonHandleChange = (event) => {
    if (Object.hasOwn(event, "target")) {
      const { name, value } = event.target;

      setEquipmentEnergyConsumptionModel((PreviousState) => ({
        ...PreviousState,
        [name]: value,
      }));
      if (name == "StationID") {
        let currentStation = StationList.find((x) => x.value == value);
        handleGlobalStationName(currentStation?.key ?? "");
      }
    } else {
      setEquipmentEnergyConsumptionModel((PreviousState) => ({
        ...PreviousState,
        EquipmentDetailID: event,
      }));
    }
  };

  /**
   * Event handler for adding a new equipment running configuration.
   *
   * @param {Event} e - The click event.
   */
  const AddHandleClick = async (e) => {
    e.preventDefault();

    let obj = { ...EquipmentEnergyConsumptionModel };
    let isError = false;
    let modelErrors = errors;

    if (obj.RoomID) {
      modelErrors.RoomID = "";
    } else {
      modelErrors.RoomID = "Please enter description";
      isError = true;
    }
    if (obj.StationID) {
      modelErrors.StationID = "";
    } else {
      modelErrors.StationID = "Please select station";
      isError = true;
    }
    setErrors({ ...modelErrors });

    if (!isError) {
      obj.CreatedBy = GetLoginUserID();
      EquipmentEnergyConsumption_SaveConfig(obj)
        .then((res) => {
          const { data } = res?.data;
          if (data == "-1") {
            showWarningMsg(
              "This equipment tag number is already associated with this station"
            );
          } else {
            showSaveSuccess();
            getEquipmentDetails();
            setEquipmentEnergyConsumptionModel((pre) => ({
              ...pre,
              RoomID: "",
            }));
          }
        })
        .catch((err) => {
          console.log(err);
          showSaveError();
        });
    }
  };

  const deleteHandleClick = async (e) => {
    e.preventDefault();

    let obj = { ...EquipmentEnergyConsumptionModel };
    let isError = false;
    let modelErrors = errors;
    if (obj.EquipmentDetailID.Value) {
      modelErrors.EquipmentDetailID = "";
    } else {
      modelErrors.EquipmentDetailID = "Please Select Equipment Tag Number";
      isError = true;
    }
    if (obj.StationID) {
      modelErrors.StationID = "";
    } else {
      modelErrors.StationID = "Please Select Station";
      isError = true;
    }
    setErrors({ ...modelErrors });

    if (!isError) {
      obj.EquipmentDetailID = obj.EquipmentDetailID.Value ?? 0;
      obj.CreatedBy = GetLoginUserID();
      obj.IsDeleted = true;

      swal({
        title: "Are you sure?",
        text: "Do you want to delete this configuration?",
        icon: "error",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          EquipmentEnergyConsumption_SaveConfig(obj)
            .then((res) => {
              const { data } = res?.data;
              if (data == "-1") {
                showWarningMsg(
                  "This equipment tag number is already associated with this station"
                );
              } else {
                showSaveSuccess();
                getEquipmentDetails();
                setEquipmentEnergyConsumptionModel({
                  ...initialEquipmentState,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
  };

  /**
   * Initializes dropdown values and equipment details.
   */
  const initialDropDown = async () => {
    const userStationsList = Decrypt(getCookies(Global.COOKIES.Stations));
    let FinalStationList = [];
    //let ignoredStation = ["stc", "str", "swa"];
    let ignoredStation = [""];
    let filterStation = userStationsList.filter((x) => {
      return !ignoredStation.includes(x.StationCode.toLocaleLowerCase());
    });
    FinalStationList = filterStation?.map((data) => {
      return { value: data.StationID, key: data.StationCode };
    });
    setEquipmentEnergyConsumptionModel((prevState) => ({
      ...prevState,
      StationID: FinalStationList[0]?.value ?? 0,
    }));
    handleGlobalStationName(FinalStationList[0]?.key ?? "");
    setStationList([...FinalStationList]);

    await EquipmentEnergyConsumption_GetDocumentID()
      .then((res) => {
        if (res?.data?.data) {
          const { data } = res.data;
          let document = data.find(
            (x) =>
              x.ModuleName == "DAILY_CLOSING_READING_CHECKLIST_AND_CONSUMPTION"
          );
          setEquipmentEnergyConsumptionModel((pre) => ({
            ...pre,
            documentNo: document.DocumentNo,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Fetch equipment details for a specific Energy Consumption date.
   */
  const getEquipmentDetails = async () => {
    const { CurrentDate, StationID } = EquipmentEnergyConsumptionModel;
    if (StationID > 0) {
      showLoading();
      await EquipmentEnergyConsumption_GetEquipmentDetails(
        CurrentDate,
        StationID
      )
        .then((res) => {
          hideLoading();

          if (res?.data?.data?.length) {
            const { data } = res.data;
            let checkAllAttended = data.every((x) => x.StatusName == 'Attended');

            let checkAllApproved = data.every(
              (x) => x.Status == 52 || x.Status == 53
            );
            let checkAllRejected = data.every((x) => x.Status == 53);

            const sortedData = data.sort((a, b) => {
              return a.orderNo - b.orderNo;
            });
            setflag(true);
            // const sortedData = [...data].sort((a, b) => b.EquipmentEnergyConsumptionID - a.EquipmentEnergyConsumptionID);
            // setEquipmentConsumptionList(isOk ? [...data] : [...sortedData]);
            setEquipmentConsumptionList([...data]);
            setEquipmentEnergyConsumptionModel((pre) => ({
              ...pre,
              checkAllApproved: checkAllApproved,
              checkAllAttended: checkAllAttended,
              checkAllRejected: checkAllRejected,
            }));
          } else {
            setEquipmentConsumptionList([]);
          }
        })
        .catch((err) => {
          hideLoading();
          console.log(err);
        });
    }
  };

  /**
   * Handle changes in equipment energy Consumption and save the configuration.
   *
   * @param {Object} obj - The equipment energy Consumption  configuration object.
   */
  const EquipmentEnergyHandleChange = async (obj, Status = 51) => {
    obj.SavedBy = GetLoginUserID();
    obj.Status = Status;
    if (getUserDetails().RoleID == COOKIES.TechRoleID) {
      obj.ShiftTechnicianID = GetLoginUserID();
      obj.isTechnician = true;
    } else {
      obj.ShiftEngineerID = GetLoginUserID();
      obj.isTechnician = false;
    }
    obj.ConsumptionDate =
      EquipmentEnergyConsumptionModel.CurrentDate ?? getYMDdate();
    await EquipmentEnergyConsumption_DetailsSaveConfig(obj)
      .then((res) => {
        const { isSuccess } = res.data;
        if (isSuccess && (Status == 127 || Status == 52 || Status == 53)) {
          showSaveSuccess();
          getEquipmentDetails()
        } else if (!isSuccess) {
          showErrorMsg("Something want Wrong Please try again.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function ApprovedBtn() {
    let tableValues = $("#EquipmentConsumptionTable")
      .DataTable()
      .table()
      .data()
      .toArray();

    let ApproveBtn = $(
      `<button  class="btn btn-success disabled"
          >  Approve </button>  `
    ).on("click", async function (e) {
      e.preventDefault();
      if (tableValues?.length) {
        let rowData = tableValues[0];
        await EquipmentEnergyHandleChange(rowData, 52);
        await getEquipmentDetails();
      }
      //  Submit_SaveCheckListDetails(46);
    });
    let RejectBTn = $(
      `<button  class="btn btn-danger m-l-15 m-r-10 disabled"
          >  Reject </button>  `
    ).on("click", async function (e) {
      e.preventDefault();

      if (tableValues?.length) {
        let rowData = tableValues[0];
        await EquipmentEnergyHandleChange(rowData, 53);
        await getEquipmentDetails();
      }
    });

    let Fragment = $(`<span></span>`);

    if (getUserDetails().RoleID !== COOKIES.TechRoleID) {
      Fragment.append(ApproveBtn);
      Fragment.append(RejectBTn);
    }
    const {
      checkAllAttended,
      CurrentDate,
      checkAllApproved,
      checkAllRejected,
    } = EquipmentEnergyConsumptionModel;

    if (
      checkAllAttended &&
      getUserDetails().RoleID == COOKIES.MaintainerRolID &&
      getYMDdate(new Date()) <= getYMDdate(addDays(CurrentDate, 10))
    ) {
      ApproveBtn.removeClass("disabled");
      RejectBTn.removeClass("disabled");
    }

    if (
      (checkAllAttended || checkAllApproved || checkAllRejected) &&
      getUserDetails().RoleID != COOKIES.MaintainerRolID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) <= getYMDdate(addDays(CurrentDate, 15)) &&
      (getYMDdate(new Date()) > getYMDdate(addDays(CurrentDate, 10)) ||
        checkAllApproved)
    ) {
      RejectBTn.removeClass("disabled");
    }

    if (
      (checkAllAttended || checkAllApproved || checkAllRejected) &&
      getUserDetails().RoleID != COOKIES.MaintainerRolID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) <= getYMDdate(addDays(CurrentDate, 15)) &&
      (getYMDdate(new Date()) > getYMDdate(addDays(CurrentDate, 10)) ||
        checkAllRejected)
    ) {
      ApproveBtn.removeClass("disabled");
    }

    if (
      (checkAllAttended || checkAllApproved || checkAllRejected) &&
      getUserDetails().RoleID != COOKIES.MaintainerRolID &&
      getPrivilege().includes("MANUALLOGAPPROVAL") &&
      getYMDdate(new Date()) > getYMDdate(addDays(CurrentDate, 15)) &&
      checkAllAttended
    ) {
      ApproveBtn.removeClass("disabled");
    }

    return Fragment;
  }
  function SubmitBtn() {
    let tableValues = $("#EquipmentConsumptionTable")
      .DataTable()
      .table()
      .data()
      .toArray();
    let SendForApprovelBtn = $(
      `<button id="submitButton" class="btn btn-primary m-l-15" >  Submit </button> `
    ).on("click", async function (e) {
      e.preventDefault();
      let everyapprove = tableValues.every((x) => x.StatusName == "Attended");
      let everyareject = tableValues.every((x) => x.StatusName == "Rejected");

      if (tableValues?.length && everyapprove || everyareject) {
        let allValid = tableValues.every((rowData) => {
          if (rowData.KWHOpeningValue < rowData.KWHClosingValue) {
            return true; // Valid row
          } else {
            toast.warning(
              `KWH Closing Value must be greater than Opening Value`
            );
            return false; // Invalid row, stop submission
          }
        });
        if (allValid) {
          let rowData = tableValues[0];
          await EquipmentEnergyHandleChange(rowData, 127);
          await getEquipmentDetails();
        }
      } else {
        showWarningMsg("Please completed all checklist.");
      }
    });
    const { checkAllAttended, CurrentDate } = EquipmentEnergyConsumptionModel;
    let Fragment = $(`<span ></span>`);
    if (
      getUserDetails().RoleID === COOKIES.TechRoleID &&
      getYMDdate(new Date()) == getYMDdate(CurrentDate)
    ) {
      Fragment.append(SendForApprovelBtn);
    }
    if (
      getUserDetails().RoleID === COOKIES.TechRoleID &&
      getYMDdate(new Date()) == getYMDdate(CurrentDate) &&
      checkAllAttended
    ) {
      setTimeout(() => {
        $("#submitButton").addClass("disabled");
      }, 600);
    }
    return Fragment;
  }

  function DateFilter() {
    return $(
      `<label class='m-l-15' style ="display:ruby" > <span> Date : </span>
        <input type="date" max=${getYMDdate(
        new Date()
      )} style="height:35px;width: auto;" class="form-control m-l-15" value ="${EquipmentEnergyConsumptionModel.CurrentDate
      }"
        /></label>
        `
    ).on("change", function (e) {
      const { value } = e.target;
      setEquipmentEnergyConsumptionModel((pre) => ({
        ...pre,
        CurrentDate: value,
      }));
    });
  }
  //   <label class='m-l-15' style ="display:inline-flex" > <span> ${EquipmentEnergyConsumptionModel?.documentNo ? `Doc Number: ${EquipmentEnergyConsumptionModel.documentNo}` : ''}</span>
  // </label>
  function UpdateStatus(e, Data) {
    if (e) {
      let rowElement = $(e.target).closest("tr");
      let StatusClsCell = rowElement.find(".StatusCls");

      if (StatusClsCell.length > 0) {
        Data.StatusName = "Attended";

        StatusClsCell.html(GetPeriodicStatus("Attended")); // You can format the value as needed
        SubmitBtn();
      }
    }
  }

  function IsAllowToEnterData() {
    const newDate = new Date();
    let date = newDate.setTime(newDate.getTime() - 3600000);
    let currentDate = new Date(date).toISOString().slice(0, 10);
    let CheckDate = new Date(EquipmentEnergyConsumptionModel.CurrentDate)
      .toISOString()
      .slice(0, 10);
    //return true
    return (
      getUserDetails().RoleID == COOKIES.TechRoleID && currentDate === CheckDate
    );
  }
  function IsAllowToEnterDataForOpeningValue() {
    let currentDate = new Date().toISOString().slice(0, 10);
    let CheckDate = new Date(EquipmentEnergyConsumptionModel.CurrentDate)
      .toISOString()
      .slice(0, 10);
    //return true
    return (
      (getUserDetails().RoleID == COOKIES.TechRoleID && currentDate === CheckDate) || (getUserDetails().RoleID == COOKIES.MaintainerRolID &&
        getYMDdate(new Date()) <= getYMDdate(addDays(CheckDate, 10)))
    );
  }
  return (
    <div>
      <div className="col-md-12">
        <label
          className="label"
          for="empCode"
          style={{ float: "right", position: "relative", top: "-15px" }}
        >
          {EquipmentEnergyConsumptionModel?.documentNo &&
            `Doc Number : ${EquipmentEnergyConsumptionModel.documentNo}`}
        </label>
      </div>
      <form id="EquipmentRunningStatus">
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label className="label" for="empCode">
                Station Name<span className="text-danger"> *</span>{" "}
              </label>
              <Dropdown
                data={StationList}
                placeholder="Select"
                name={"StationID"}
                HandleChange={CommonHandleChange}
                value={EquipmentEnergyConsumptionModel.StationID}
                className=""
              />
              <span className="text-danger mt-1"> {errors.StationID} </span>
            </div>
          </div>
          {getPrivilege().includes("MANUALLOGCREATE") && (
            <>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label" for="empCode">
                    Description <span className="text-danger"> *</span>{" "}
                  </label>{" "}
                  <textarea
                    className="form-control "
                    style={{ resize: "none" }}
                    // type="text"
                    name="RoomID"
                    value={EquipmentEnergyConsumptionModel.RoomID}
                    rows={2}
                    onChange={(e) => CommonHandleChange(e)}
                  ></textarea>
                  <span className="text-danger mt-1"> {errors.RoomID} </span>
                </div>
              </div>

              <div className="col-md-2 mt-4">
                <button
                  type="submit"
                  id="addRow_ers"
                  onClick={AddHandleClick}
                  className="btn add-btn"
                >
                  <i className="fa fa-plus"></i> Add
                </button>
              </div>
            </>
          )}
          {getPrivilege().includes("MANUALLOGDELETE11") && (
            <div className="col-md-2 mt-4">
              <button
                type="button"
                onClick={deleteHandleClick}
                className="btn btn-danger"
              >
                <i className="fa fa-trash"></i> Delete
              </button>
            </div>
          )}

          {/* <div className="col-md-12">
            <label
              className="label"
              for="empCode"
              style={{ float: "right", position: "relative", top: "-15px" }}
            >
              {EquipmentEnergyConsumptionModel?.documentNo &&
                `Doc Number : ${EquipmentEnergyConsumptionModel.documentNo}`}
            </label>
          </div> */}
        </div>
      </form>

      <div className="" style={{ position: "relative", top: "-30px" }}>
        <HtmlTable
          columnList={columnNames}
          IsASC={true}
          ShowLengthPostponedHtml={DateFilter}
          rowList={EquipmentConsumptionList ?? []}
          SearchPrependHtml={ApprovedBtn}
          // SearchPostponedHtml={SubmitBtn}
          id={"EquipmentConsumptionTable"}
          stateSave={false}
          ordering={false}
          FixedColums={{
            left: 2,
            right: 1,
          }}
        />
      </div>
    </div>
  );
}
