import React, { useContext, useEffect, useState } from "react";
import Logo from "../assets/img/cmrl-logo.png";
import {
  AppContext,
  DefaultAppContextValues,
} from "./../services/ContextProvider";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { Encrypt, GetLoginUserID } from "../services/CommonService";
import Global from "../services/Global";
import { Link } from "react-router-dom";
import NotificationService from "./NotificationService";
import { COOKIES } from "./../services/Global";

import periodicIcon from "../assets/img/toolsWhite.png";
import breakdownIcon from "../assets/img/engineerWhite.png";
import UserIcon from "../assets/img/user-icon.png";
import Sterlingwilsonlogo from "../assets/img/Sterlingwilsonlogo.png";

import Helpdocumentengineer from "../assets/documents/CMMS_UserManual_Engineer.pdf";
import { getUserDetails, getL0RoleID } from "../services/CommonService";
import Helpdocumenttechnician from "../assets/documents/CMMS_UserManual_Technician.pdf";
import AuthService from "../screens/auth/AuthService";
import { PeriodicService } from "../screens/periodic/PeriodicService";
import NotificationList from "./NotificationList";
import { getNotification } from "../services/NotificationService";
import { PeriodicMaintenance_GetEquipmentDetailForJobSave } from "../screens/PeriodicMaintenance/PeriodicService";

const _notificationService = new NotificationService();
const _PeriodicService = new PeriodicService();
const _authService = new AuthService();

const Header = () => {
  //variables
  const {
    removeCookies,
    getCookies,
    setCookies,
    handleBreakdownTab,
    GlobalStationName,
    handleGlobalCorridor,
    GlobalCorridor,
    HandleSafetyMeasurement,
    CommonLogout,
  } = useContext(AppContext);

  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showIcon, setShowIcon] = useState(false);
  const IsTvsvac = getCookies(Global.COOKIES.IsTvsvac);
  const { setNotificationModel, NotificationModel } = DefaultAppContextValues();
  const {
    GeneralNotification,
    PeriodicNotification,
    BreakdownNotification,
    CheckListNotification,
    AlarmNotification,
    DailyEleListNotification,
  } = NotificationModel;
  const IconToggle = () => {
    setShowIcon(!showIcon);
  };

  useEffect(() => {
    let connection;

    const initNotification = async () => {
      connection = await getNotification();
      connection.on("ReceiveMessage", () => {
        GetCount();
      });
    };
    initNotification();
    return () => {
      connection?.stop()?.then(() => console.log("SignalR Disconnected"));
    };
  }, []);

  useEffect(() => {
    GetCount();
    //  getPrivilegeByUserID();
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //function
  const logout = () => {
    CommonLogout();
    removeCookies("BAPPP");

    localStorage.clear();
    navigate("/");
  };

  const GetCount = (id = GetLoginUserID()) => {
    _notificationService
      .GetCount(id)
      .then((res) => {
        if (res?.data?.length) {
          const NotificationModel = res.data[0];
          setNotificationModel(NotificationModel);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function fromNow(
    date,
    nowDate = Date.now(),
    rft = new Intl.RelativeTimeFormat(undefined, { numeric: "auto" })
  ) {
    const SECOND = 1000;
    const MINUTE = 60 * SECOND;
    const HOUR = 60 * MINUTE;
    const DAY = 24 * HOUR;
    const WEEK = 7 * DAY;
    const MONTH = 30 * DAY;
    const YEAR = 365 * DAY;
    const intervals = [
      { ge: YEAR, divisor: YEAR, unit: "year" },
      { ge: MONTH, divisor: MONTH, unit: "month" },
      { ge: WEEK, divisor: WEEK, unit: "week" },
      { ge: DAY, divisor: DAY, unit: "day" },
      { ge: HOUR, divisor: HOUR, unit: "hour" },
      { ge: MINUTE, divisor: MINUTE, unit: "minute" },
      { ge: 30 * SECOND, divisor: SECOND, unit: "seconds" },
      { ge: 0, divisor: 1, text: "just now" },
    ];
    const now =
      typeof nowDate === "object"
        ? nowDate.getTime()
        : new Date(nowDate).getTime();
    const diff =
      now - (typeof date === "object" ? date : new Date(date)).getTime();
    const diffAbs = Math.abs(diff);
    for (const interval of intervals) {
      if (diffAbs >= interval.ge) {
        const x = Math.round(Math.abs(diff) / interval.divisor);
        const isFuture = diff < 0;
        return interval.unit
          ? rft.format(isFuture ? x : -x, interval.unit)
          : interval.text;
      }
    }
  }

  function moveTrainTo(e) {
    $("#train").hide("slide", { direction: "left" }, 1000);
    setTimeout(() => {
      handleGlobalCorridor();
    }, 1000);
  }

  const NotificationContent = (
    data,
    URL,
    Type,
    onClickForNotification = null
  ) => {
    return (
      <li className="notification-message">
        <Link
          to={{
            pathname: onClickForNotification ? URL : "#",
          }}
          onClick={() => {
            if (onClickForNotification) {
              onClickForNotification(data?.moduleSpecificID);
            }
          }}
        >
          <div className="media d-flex">
            <div className="media-body flex-grow-1">
              <p className="noti-details">
                <span className="noti-title">{data.notification}</span>

                {data.name != null && data.name != "" && (
                  <>
                    <span className="noti-title"> by </span>
                    <span className="noti-title">{data.name}</span>
                  </>
                )}
              </p>
            </div>
          </div>
        </Link>
      </li>
    );
  };

  const BreakdownNotificationContent = (data) => {
    let originalString = data.notification;

    let pattern =
      data.notificationType == "MANUALBREAKDOWN"
        ? /MTKT([^ ]*) /
        : /TKT([^ ]*) /; // Matches "TKD" followed by any characters (except space) until a space is encountered
    let match = originalString.match(pattern);
    let ticketNo = match?.[0].trim() ?? "";
    return (
      <li className="notification-message">
        {data.notificationType == "CYCLICCHECK" && (
          <Link
            to={{
              pathname: "/BreakdownDepartmentIndex",
            }}
            state={{ TICKET_NO: ticketNo }}
          >
            <div className="media d-flex">
              <div className="media-body flex-grow-1">
                <p className="noti-details">
                  <span
                  // className={`noti-title ${data.notification.includes("rejected")
                  //   ? "text-danger"
                  //   : ""
                  //   }`}
                  >
                    {data.notification}
                  </span>
                </p>
              </div>
            </div>
          </Link>
        )}
        {data.notificationType == "CRITICALBREAKDOWN" && (
          <Link
            to={{
              pathname: "/BreakdownDepartmentIndex",
            }}
            state={{ TICKET_NO: ticketNo }}
          >
            <div className="media d-flex">
              <div className="media-body flex-grow-1">
                <p className="noti-details">
                  <span
                    className={`noti-title ${
                      data.notification.includes("rejected")
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    {data.notification}
                  </span>

                  {/* <span className="noti-title"> by </span>
            <span className="noti-title">
              {data.name}
            </span> */}

                  {data.name != null && data.name != "" && (
                    <>
                      <span
                        className={`noti-title ${
                          data.notification.includes("rejected")
                            ? "text-danger"
                            : ""
                        }`}
                      >
                        {" "}
                        by{" "}
                      </span>
                      <span
                        className={`noti-title ${
                          data.notification.includes("rejected")
                            ? "text-danger"
                            : ""
                        }`}
                      >
                        {data.name}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </div>
          </Link>
        )}
        {data.notificationType == "MANUALBREAKDOWN" && (
          <Link
            to={{
              pathname: "/breakdownmaintenance",
            }}
            state={{ TICKET_NO: ticketNo }}
          >
            <div className="media d-flex">
              <div className="media-body flex-grow-1">
                <p className="noti-details">
                  <span
                    className={`noti-title ${
                      data.notification.includes("rejected")
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    {data.notification}
                  </span>

                  {/* <span className="noti-title"> by </span>
            <span className="noti-title">
              {data.name}
            </span> */}

                  {data.name != null && data.name != "" && (
                    <>
                      <span
                        className={`noti-title ${
                          data.notification.includes("rejected")
                            ? "text-danger"
                            : ""
                        }`}
                      >
                        {" "}
                        by{" "}
                      </span>
                      <span
                        className={`noti-title ${
                          data.notification.includes("rejected")
                            ? "text-danger"
                            : ""
                        }`}
                      >
                        {data.name}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </div>
          </Link>
        )}

        {data.notificationType == "SYSTEMBREAKDOWN" && (
          <Link
            to={{
              pathname: "/breakdownmaintenance",
              state: "SYSTEM",
            }}
            state={{ TICKET_NO: ticketNo }}
          >
            <div className="media d-flex">
              <div className="media-body flex-grow-1">
                <p className="noti-details">
                  <span
                    className={`noti-title ${
                      data.notification.includes("rejected")
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    {data.notification}
                  </span>

                  {data.name != null && data.name != "" && (
                    <>
                      <span
                        className={`noti-title ${
                          data.notification.includes("rejected")
                            ? "text-danger"
                            : ""
                        }`}
                      >
                        {" "}
                        by{" "}
                      </span>
                      <span
                        className={`noti-title ${
                          data.notification.includes("rejected")
                            ? "text-danger"
                            : ""
                        }`}
                      >
                        {data.name}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </div>
          </Link>
        )}
      </li>
    );
  };

  const MessageNotificationContent = (data) => {
    return (
      <li className="notification-message">
        {data.notificationType == "DOCUMENT" && (
          <Link to="/manualupload">
            <div className="media d-flex">
              <div className="media-body flex-grow-1">
                <p className="noti-details">
                  <span className="noti-title">{data.name}</span>{" "}
                  <span className="noti-title">added new document </span>
                  <span className="noti-title">{data.notification}</span>
                </p>
                <div className="row">
                  <p className="noti-time">
                    <span className="notification-time">
                      {fromNow(data.notificationTime)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Link>
        )}
        {data.notificationType == "SPARE_REORDER_LIMIT" && (
          <Link to="/spare">
            <div className="media d-flex">
              <div className="media-body flex-grow-1">
                <p className="noti-details">
                  <span className="noti-title">{data.notification}</span>
                </p>
              </div>
            </div>
          </Link>
        )}
      </li>
    );
  };
  const AlarmNotificationContent = (data) => {
    return (
      <li className="notification-message">
        {data.notificationType == "BREAKDOWNALARMDIGITAL" && (
          <Link
            to={{
              pathname: "/breakdownalarmreport",
              // state: "SYSTEM"
            }}
          >
            <div className="media d-flex">
              <div className="media-body flex-grow-1">
                <p className="noti-details">
                  <span className="noti-title">{data.notification}</span>
                </p>
              </div>
            </div>
          </Link>
        )}

        {data.notificationType == "BREAKDOWNALARM" && (
          <Link
            to="/breakdownalarmreport"
            // onClick={() => {
            //   ClearNotificationCount(
            //     getCookies(Global.COOKIES.EmployeeID),
            //     "BREAKDOWNALARM"
            //   );
            // }}
          >
            <div className="media d-flex">
              <div className="media-body flex-grow-1">
                <p className="noti-details">
                  <span className="noti-title">{data.notification}</span>

                  {data.name != null && data.name != "" && (
                    <>
                      <span className="noti-title"> by </span>
                      <span className="noti-title">{data.name}</span>
                    </>
                  )}
                </p>
              </div>
            </div>
          </Link>
        )}
      </li>
    );
  };

  const AlarmIcon = () => {
    return (
      <>
        {" "}
        {AlarmNotification > 0 ? (
          <div class="notification-box">
            <span class="notification-count">{AlarmNotification}</span>
            <div class="notification-bell">
              <span class="bell-top"></span>
              <span class="bell-middle"></span>
              <span class="bell-bottom"></span>
              <span class="bell-rad"></span>
            </div>
          </div>
        ) : (
          <>
            <i className="fa fa-bell-o"></i>{" "}
            <span className="badge rounded-pill">{0}</span>
          </>
        )}
      </>
    );
  };

  const NavigatePMPage = async (moduleSpecificID) => {
    if (moduleSpecificID > 0) {
      await PeriodicMaintenance_GetEquipmentDetailForJobSave(
        moduleSpecificID,
        GetLoginUserID()
      ).then((res) => {
        if (res?.data?.data?.length) {
          const { data } = res.data;
          if (data?.length) {
            const {
              DepartmentID,
              FromDate,
              ToDate,
              ScheduleType,
              EquipmentShortName,
              IsAuditor
            } = data[0];
            const PMScheduleTypes = {
              1: "daily",
              2: "monthly",
              3: "quarterly",
              4: "halfyearly",
              5: "yearly",
              6: "weekly",
            };

            if (IsAuditor) {
              navigate("/AuditingProcess");
            } else {
              navigate("/periodic/Depart-periodics", {
                state: {
                  PeriodicCheckListMaintenanceID: moduleSpecificID,
                  DepartmentID,
                  FromDate,
                  EndDate: ToDate,
                  CurrentView: PMScheduleTypes[ScheduleType] ?? "daily",
                  EquipmentDetails: res.data.data,
                },
              });
            }
          }
        }
      });
    }
  };

  return (
    <>
      <div className="header">
        <div className="header-left">
          <a
            href="https://chennaimetrorail.org/"
            target={"_blank"}
            className="logo"
          >
            <img
              src={Logo}
              width="40"
              height="auto"
              alt=""
              className="contrast"
            />
          </a>
          {(showIcon || windowWidth < 1000) && (
            <a
              href="https://sterlingandwilson.com/"
              target={"_blank"}
              className="logo"
            >
              <img
                src={Sterlingwilsonlogo}
                width="130"
                height="auto"
                alt=""
                className="contrast"
                id="sterlingLogo"
                style={{ marginLeft: "10px" }}
              />
            </a>
          )}
        </div>

        <a id="toggle_btn" onClick={IconToggle}>
          <span className="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>
        <div className="col-md-12">
          <div
            className="page-title-box col-md-5"
            style={{ paddingRight: "0px", paddingLeft: "0px" }}
          >
            {windowWidth > 1000 ? (
              <>
                <h4 className="p-0 m-0">
                  Comprehensive Maintenance Management System{" "}
                </h4>
                <p className="ellipsis-text">
                  {GlobalStationName ? `(${GlobalStationName})` : ""}{" "}
                </p>
              </>
            ) : (
              " "
            )}
          </div>

          <a id="mobile_btn" className="mobile_btn" href="#sidebar">
            <i className="fa fa-bars"></i>
          </a>

          <ul className="nav user-menu">
            {getCookies(Global.COOKIES.CorridorChangePrivilege) == 1 && (
              <li className="nav-item" onClick={moveTrainTo}>
                <a
                  href="#"
                  className="nav-link"
                  title={
                    GlobalCorridor == 2
                      ? "Goto C1- LINE"
                      : "Goto \ LINE"
                  }
                >
                  {/* <WiTrain height={150} width={50}  ></WiTrain> */}
                  <i className="las  la-train " id="train"></i>{" "}
                </a>
              </li>
            )}
            <NotificationList
              HeaderName={"Manual Log Entry"}
              Icon={"fa fa-wrench"}
              Count={DailyEleListNotification}
              TypeNotification={"DailyEleListNotification"}
              URL="/manuallog"
              Type={
                "DAILYELECTRICALPANEL,DAILYCLOSINGCHECKLIST,CRITICALEQUIPMENT,CLOSINGCHECKLIST,ROUTINECHECKS"
              }
              NotificationContent={NotificationContent}
            ></NotificationList>

            <NotificationList
              HeaderName={"Operation Checks"}
              Icon={"la la-users"}
              TypeNotification={"CheckListNotification"}
              Count={CheckListNotification}
              URL="/CheckListCalenderView"
              Type={"CHECKLIST"}
              NotificationContent={NotificationContent}
            ></NotificationList>

            <NotificationList
              HeaderName={"Breakdown Maintenance"}
              Icon={breakdownIcon}
              IconImage
              imageProps={{
                width: "30",
                height: "auto",
              }}
              Count={BreakdownNotification}
              TypeNotification={"BreakdownNotification"}
              Type={
                "MANUALBREAKDOWN,SYSTEMBREAKDOWN,CRITICALBREAKDOWN,CYCLICCHECK"
              }
              NotificationContent={BreakdownNotificationContent}
            ></NotificationList>

            {/*Periodic */}
            <NotificationList
              HeaderName={"Periodic Maintenance"}
              Icon={periodicIcon}
              IconImage
              IconImageStyle={{}}
              imageProps={{
                width: "24",
                height: "auto",
                style: { marginRight: "1px" },
              }}
              Count={PeriodicNotification}
              TypeNotification={"PeriodicNotification"}
              Type={"PERIODIC"}
              NotificationContent={NotificationContent}
              onClickForNotification={NavigatePMPage}
              URL={IsTvsvac == "true" ? "/periodic" : "Periodic-Department"}
            ></NotificationList>

            {/* Meassage*/}

            <NotificationList
              HeaderName={"Messages"}
              Icon={"fa fa-comment-o"}
              Count={GeneralNotification}
              TypeNotification={"GeneralNotification"}
              Type={"DOCUMENT,SPARE_REORDER_LIMIT"}
              NotificationContent={MessageNotificationContent}
              URL={"/manualupload"}
            ></NotificationList>

            <NotificationList
              HeaderName={"Alarm"}
              CustomIcon
              Icon={AlarmIcon}
              Count={AlarmNotification}
              TypeNotification={"AlarmNotification"}
              Type={"BREAKDOWNALARM,BREAKDOWNALARMDIGITAL"}
              NotificationContent={AlarmNotificationContent}
              URL={"/manualupload"}
            ></NotificationList>

            <li className="nav-item dropdown has-arrow main-drop">
              <a
                href="#"
                className="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
              >
                <span className="user-img">
                  <img src={UserIcon} alt="" />
                  <span className="status online mb-1"></span>
                </span>
                <span style={{ marginLeft: "8px" }}>
                  {IsTvsvac === "true"
                    ? `${getCookies(COOKIES.UserName)}`
                    : `${getCookies(COOKIES.UserName)}-${getCookies(
                        Global.COOKIES.Role
                      )}`}
                </span>
              </a>
              <div className="dropdown-menu">
                {getUserDetails().RoleID != COOKIES.TechRoleID ? (
                  <a
                    className="dropdown-item"
                    href={Helpdocumentengineer}
                    download="CMMS_Help_Document"
                  >
                    <span> Help Document</span>{" "}
                  </a>
                ) : (
                  <a
                    className="dropdown-item"
                    href={Helpdocumenttechnician}
                    download="CMMS_Help_Document"
                  >
                    <span> Help Document</span>{" "}
                  </a>
                )}

                {/* <a className="dropdown-item" href="settings.html">
              Settings
            </a>  */}
                <a className="dropdown-item" href="#;" onClick={logout}>
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div className="dropdown mobile-user-menu">
          <a
            href="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#;" onClick={logout}>
              Logout
            </a>
          </div>
        </div>
      </div>
      <div className="header1"></div>
      <button
        id="SafetyMesurementModelBtn"
        data-bs-toggle="modal"
        data-bs-target="#SafetyMesurementModel"
        hidden
      />
    </>
  );
};

export default Header;
